<template>
  <section
    v-if="item"
    class="heat-pump-offer-show"
  >
    <el-card v-loading="loading">
      <div class="d-flex justify-content-between flex-lg-row flex-xl-row flex-column flex-nowrap align-items-center">
        <div
          v-if="item.id"
          class="offer-information"
        >
          <div class="d-flex align-items-center">
            <div><i class="fsize fas fa-fire"></i></div>
            <div class="title-box">
              <h1 class="my-0">
                Oferta pompy ciepła nr #{{ item.id }}
              </h1>
              <small>Przygotowana w oparciu o dane kalkulacji</small>
            </div>
          </div>
        </div>
        <div>
          <el-button @click="download">
            <i class="fas fa-download"></i> Pobierz ofertę
          </el-button>
          <el-button
            type="primary"
            @click="handleRouteCalculation"
          >
            Przejdź do kalkulacji
          </el-button>
          <el-button
            type="danger"
            @click="handleDelete"
          >
            Usuń
          </el-button>
          <el-button @click="$router.go(-1)">
            Powrót
          </el-button>
        </div>
      </div>
    </el-card>
    <div class="row mt-3">
      <div class="col-md-12 col-lg-9">
        <div class="row">
          <div class="col-4">
            <el-card class="mt-2">
              <title-card name="Dane oferty" />
              <div class="row">
                <div class="col-12">
                  <dl>
                    <dt>Nazwa pompy ciepła</dt>
                    <dd>{{ item.pump_name }}</dd>
                  </dl>
                </div>
                <div class="col-12">
                  <dl>
                    <dt>Opis pompy ciepła</dt>
                    <dd>{{ item.pump_description }}</dd>
                  </dl>
                </div>
                <div class="col-12">
                  <dl>
                    <dt>Nazwa wybranego pakietu</dt>
                    <dd>{{ item.package_name }}</dd>
                  </dl>
                </div>
                <div class="col-12">
                  <dl>
                    <dt>Kwota oferty brutto</dt>
                    <dd>
                      {{ item.price }} <el-tag
                        type="success"
                        size="mini"
                      >
                        PLN
                      </el-tag>
                    </dd>
                  </dl>
                </div>
              </div>
            </el-card>
          </div>
          <div class="col-8">
            <el-card class="mt-2">
              <title-card name="Zestaw w ofercie" />
              <div class="table">
                <table class="w-100">
                  <tr
                    v-for="element in item.package_components"
                    style="padding:20px 0px"
                  >
                    <td>
                      <strong> <i class="fas fa-dot-circle"></i>  {{ element.name }}</strong>
                      <div class="description">
                        <small>{{ element.description }}</small>
                      </div>
                    </td>
                    <td style="text-align: right">
                      {{ element.pivot.count }} / {{ element.pivot.unit }}
                    </td>
                  </tr>
                </table>
              </div>
            </el-card>
          </div>
        </div>
        <el-card
          class="mt-2"
        >
          <div class="title">
            <div class="d-flex justify-content-between flex-lg-row flex-xl-row flex-column flex-nowrap align-items-center">
              <div>
                <h3 class="my-0">
                  Lista dodatków
                </h3>
              </div>
              <div>
              </div>
            </div>
            <hr>
          </div>
          <div class="addons-list">
            <el-alert
              v-if="!item.addons.length"
              type="info"
              :closable="false"
            >
              Brak informacji o dodatkach
            </el-alert>
            <el-card v-for="el in item.addons">
              <div class="d-flex justify-content-between align-items-center">
                <div> {{ el.name }} {{ el.description }}</div>
                <div>{{ el.price }} <el-tag>PLN</el-tag></div>
              </div>
            </el-card>
          </div>
        </el-card>
        <div class="attachments mt-3">
          <attachments
            v-if="item.attachments"
            :files="item.attachments.data"
            :name-endpoint="nameEndpoint"
            :upload-endpoint="uploadEndpoint"
            :delete-endpoint="deleteEndpoint"
            :allowed-mime-types="$config.uploads.tickets.allowed_mime_types"
            :allowed-file-size="$config.uploads.tickets.allowed_file_size"
            :allowed-file-types="$config.uploads.tickets.allowed_file_types"
            multiple
            @added="onAttachmentAdded"
            @media-name="onAttachmentNameChanged"
            @deleted="onAttachmentDeleted"
          />
        </div>
      </div>
      <div class="col-md-12 col-lg-3">
        <contractor
          v-if="item.contractor"
          :item="item.contractor"
          :contractor-type="item.contractor.type"
        />
        <user-block
          resource="admin.heat-pump-offers.update"
          type="heat-pump-offers"
          :object-id="item.id"
          permission="admin.heat-pump-offers.update"
          :item="item.user"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import Contractor from '@/admin/components/Contractor'
  import UserBlock from '@/admin/components/User'
  import TitleCard from '@/admin/components/Title'
  import { deleteRequest, downloadRequest, getRequest } from '@/shared/utils/http'
  import Attachments from '@/admin/components/Attachments'
  export default {
    name: 'Show',
    components: {
      Attachments,
      UserBlock,
      Contractor,
      TitleCard
    },
    data () {
      return {
        item: null,
        loading: false
      }
    },
    computed: {
      id () {
        return parseInt(this.$route.params.id)
      },
      nameEndpoint: function () {
        return `/api/heat-pump-offers/${this.id}/attachments`
      },

      uploadEndpoint: function () {
        return `/api/heat-pump-offers/${this.id}/upload`
      },

      deleteEndpoint: function () {
        return `/api/heat-pump-offers/${this.id}/attachments`
      }
    },
    mounted () {
      this.$root.title = 'Wyświetl - Ofera Pomp Ciepła'
      this.fetchData()
    },
    methods: {
      fetchData () {
        getRequest('/api/heat-pump-offers/' + this.id).then(response => {
          this.item = response.data
        }).catch(error => {
          alert('error')
        })
      },
      handleDelete () {
        this.loading = true
        deleteRequest('/api/heat-pump-offers/' + this.id)
          .then(response => {
            this.$router.push({ name: 'admin.heat-pump-offers.index' })
          })
          .catch(error => {
            this.$snotify.error('Nie można usunąć oferty.')
          })
      },
      handleRouteCalculation () {
        this.$router.push({ name: 'admin.heat-pump-calculations.show', params: { id: this.item.heat_pump_calculation_id } })
      },
      onAttachmentAdded (file) {
        this.item.attachments.data.push(file.file)
      },
      onAttachmentNameChanged (file) {
        const index = this.item.attachments.findIndex((item) => {
          return item.id === file.id
        })
        this.item.attachments.splice(index, 1, file)
      },
      onAttachmentDeleted (id) {
        const index = this.item.attachments.data.findIndex((item) => {
          return item.id === id
        })
        this.item.attachments.data.splice(index, 1)
      },
      download () {
        downloadRequest(`/api/heat-pump-offers/${this.id}/download`, 'nowypliktest.pdf', {})
          .then(response => {
            alert('done')
          })
          .catch(error => {
            alert('error')
          })
      }
    }
  }
</script>

<style scoped>
tr {
  border-bottom: 1px solid #dedede !important;
}
.title-box{
  margin-left: 20px;
}
.fsize{
  font-size: 3rem;
}
</style>
