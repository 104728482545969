
<script>
  import {Line, Bar} from 'vue-chartjs'
  import {getRequest} from '../../../shared/utils/http'

  export default {
    extends: Bar,
    props: {
      monthData: {
        type: Array,
        required: true
      },
      solarData: {
        type: Array,
        required: true
      },
      kwp: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        chartData: {
          labels: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
          datasets: [
            {
              label: 'Energia generowana',
              data: [],
              fill: true,
              borderColor: '#97d700',
              backgroundColor: '#97d700',
              borderWidth: 0.5
            },
            {
              label: 'Nasłonecznienie',
              data: [],
              fill: true,
              borderColor: '#b39e16',
              backgroundColor: '#bcc102',
              borderWidth: 0.5
            }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [ {
              gridLines: {
                display: true
              }
            }]
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted () {
      let monthData = this.monthData.map((el) => {
        return Math.round((el * parseFloat(this.kwp)) * 100) / 100
      })
      let solarData = this.solarData.map((el) => {
        return el
      })

      this.chartData.datasets[0].data = monthData
      this.chartData.datasets[1].data = solarData
      this.renderChart(this.chartData, this.options)
    }
  }
</script>
<style scoped>
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Aligner-item {
  max-width: 50%;
}

.Aligner-item--top {
  align-self: flex-start;
}

.Aligner-item--bottom {
  align-self: flex-end;
}
</style>
