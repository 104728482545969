.el-button {
  svg + span,
  i + span {
    margin-left: 5px;
  }

  svg + span:empty,
  i + span:empty {
    margin-left: 0;
  }

  font-size:12px !important;
}

.el-button--primary.is-plain {
  color: #fff;
}
.el-button--primary.is-plain {
  color: #fff !important;
}

.el-button--text {
  color: map_get($colors, primary);
}

.el-button--text:focus, .el-button--text:hover, .el-button--text:active {
  color: map_get($colors, active-green);
}

.el-button--primary {
  border-color: map_get($colors, primary)
}

.el-button--primary {
  color: #FFFFFF;
  background-color: #7dab0a !important;
}

.el-button--primary.is-plain:hover, .el-button--primary.is-plain:focus {
  background: #51944d !important;
  color: #FFFFFF;
}

.el-button--primary.is-plain {
  color: #fff;
}

.el-button--block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80% !important;
  margin: 0px auto !important;
  min-height: 100px;
  text-align: center;
  font-size: 1.5rem;
  padding: 30px 0;

  + .el-button {
    margin-left: 0;
  }

  &--image {
    width: 48px;
    height: 48px;
    border-radius: 6px;
    margin: 0 auto;
    background-color: map_get($colors, primary);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
  }

  span {
    span {
      display: block;
      margin: 0.5rem 0 0;
      white-space: normal;
    }
  }

  &:hover {
    .el-button--block--image {
      background-color: #fff;
    }

    .fa-stack {
      .fa-inverse {
        color: map_get($colors, primary);
      }
    }
  }
}

.header-button{
  padding: 4px 17px;
  font-size: 10px;
}

.el-input-group__append, .el-input-group__prepend {
  background-color: #f7f7f7;
  color: #afacac !important;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 0px;
  padding: 0 20px;
  width: 1px;
  white-space: nowrap;
}

.btn-toolbar {
  margin-top: auto;
  margin-bottom: auto;
}


.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: #cecece;
  background-color: #dcdcdc !important;
  border-color: #c3c3c3;
}

.el-dropdown__caret-button{
  padding: 6.5px !important;
}

.el-button--text {
  font-weight: 800 !important;
}