<template>
  <el-card
    class="mt-2 dashboard-button"
    @click.native="actionMoveToAgreement"
    @mouseover.native="setHoverState(true)"
    @mouseleave.native="setHoverState(false)"
  >
    <div class="icons"><i :class="icon"></i> <span style="font-size:20px">Umowa PV</span></div>
    <div class="mt-2 title-box">
      <slot></slot>
    </div>
    <div><small><slot name="desc"></slot></small></div>
  </el-card>
</template>

<script>
  export default {
    name: 'AgreementButton',
    props: {
      icon: {
        type: String | Boolean,
        default: 'fab fa-sellsy'
      },
      agreement_id: {
        type: Number,
        default: 0
      },
      type: {
        type: String,
        default: 'info',
        validator: (value) => {
          return [
            'primary', 'success', 'warning', 'danger', 'info', 'text'
          ].includes(value)
        }
      }
    },
    data () {
      return {
        hoverState: false
      }
    },
    methods: {
      setHoverState (state) {
        this.hoverState = state
      },
      actionMoveToAgreement () {
        this.$router.push({name: 'admin.agreements.show', props: {id: this.agreement_id}})
      }
    }
  }
</script>

<style scoped>
.dashboard-button{
    cursor: pointer;
    color: #3f4443;
    padding: 10px 0px;
    transition: 0.6s;
  background-image: radial-gradient(circle at 13% 47%, rgba(140, 140, 140,0.03) 0%, rgba(140, 140, 140,0.03) 25%,transparent 25%, transparent 100%),radial-gradient(circle at 28% 63%, rgba(143, 143, 143,0.03) 0%, rgba(143, 143, 143,0.03) 16%,transparent 16%, transparent 100%),radial-gradient(circle at 81% 56%, rgba(65, 65, 65,0.03) 0%, rgba(65, 65, 65,0.03) 12%,transparent 12%, transparent 100%),radial-gradient(circle at 26% 48%, rgba(60, 60, 60,0.03) 0%, rgba(60, 60, 60,0.03) 6%,transparent 6%, transparent 100%),radial-gradient(circle at 97% 17%, rgba(150, 150, 150,0.03) 0%, rgba(150, 150, 150,0.03) 56%,transparent 56%, transparent 100%),radial-gradient(circle at 50% 100%, rgba(25, 25, 25,0.03) 0%, rgba(25, 25, 25,0.03) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 55% 52%, rgba(69, 69, 69,0.03) 0%, rgba(69, 69, 69,0.03) 6%,transparent 6%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));
}
.dashboard-button:hover{
  color: #97d700;
  border-color: #e0f3b3;
  background-color: #f5fbe6;
}
.title-box{
  font-size:24px
}
.icons {
  margin-left: 5px;
  font-size:35px;
  color: #97d700;
}
</style>
