<template>
  <div>
    <el-card class="mt-3">
      <div
        v-if="type == 'create'"
        class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1"
      >
        <el-row class="text-end">
          <el-button
            type="info"
            @click="previousPage"
          >
            Cofnij <i class="fas fa-long-arrow-alt-left"></i>
          </el-button>
          <el-button
            v-if="!form.product_number && $can('products.save-draft')"
            type="warning"
            @click="saveDraft"
          >
            <i class="far fa-save"></i> {{ form.is_draft ? 'Nadpisz wersje roboczą' : 'Zapisz wersje roboczą' }}
          </el-button>
          <el-button
            type="primary"
            @click="nextPage"
          >
            Przejdź dalej<i class="fas fa-long-arrow-alt-right"></i>
          </el-button>
        </el-row>
      </div>
    </el-card>
    <div
      v-if="errorBlock"
      class="row my-3"
    >
      <el-alert
        title="Wystąpił błąd podczas przetwarzania kroku formularza"
        type="error"
        description="Sprawdź czy wszystkie wymagane pola w tym kroku formularza zostały poprawnie wypełnione."
        show-icon
      />
    </div>
    <div class="row">
      <div class="col-lg-9 col-xl-8 col-sm-12 order-lg-1 order-xl-1 mt-3">
        <el-card>
          <div>
            <div>
              <h5 class="mt-1 pt-0">
                Rodzaj instalacji
              </h5>
              <hr>
            </div>
            <div class="row">
              <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="installation_place"
                  label="Miejsce montażu instalacji"
                >
                  <el-select
                    v-model="form.addons.installation_place"
                    :filterable="true"
                    name="installation_place"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.installation_place.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
            </div>
            <div
              v-if="form.addons.installation_place == 0"
              class="row"
            >
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.direct_of_the_slope)"
                  :errors="formErrors"
                  prop="direct_of_the_slope"
                  label="Kierunek połaci"
                >
                  <el-select
                    v-model="form.addons.direct_of_the_slope"
                    v-validate="rules.direct_of_the_slope"
                    :filterable="true"
                    name="direct_of_the_slope"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.direct_of_the_slope.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="roof_insulation"
                  label="Izolacja dachu"
                >
                  <el-select
                    v-model="form.addons.roof_insulation"
                    :filterable="true"
                    name="roof_insulation"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.roof_insulation.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div
                v-if="form.addons.roof_insulation != 0"
                class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
              >
                <custom-form-item
                  :required="checkRequired(rules.insulation_thickness)"
                  :errors="formErrors"
                  prop="grubość izolacji"
                  label="Grubość izolacji [mm]"
                >
                  <input-number
                    v-model="form.addons.insulation_thickness"
                    v-validate="rules.insulation_thickness"
                    class="w-100"
                    name="grubość izolacji"
                  />
                </custom-form-item>
              </div>
              <div
                v-if="form.addons.roof_insulation != 0"
                class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
              >
                <custom-form-item
                  :required="checkRequired(rules.insulation_material)"
                  :errors="formErrors"
                  prop="insulation_material"
                  label="Materiał izolacji"
                >
                  <el-input
                    v-model="form.addons.insulation_material"
                    v-validate="rules.insulation_material"
                    name="insulation_material"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="lightning_protection"
                  label="Instalacja odgromowa"
                >
                  <el-select
                    v-model="form.addons.lightning_protection"
                    :filterable="true"
                    name="lightning_protection"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.lightning_protection.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="roof_type"
                  label="Rodzaj dachu"
                >
                  <el-select
                    v-model="form.addons.roof_type"
                    :filterable="true"
                    name="roof_type"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.roof_type.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.building_height)"
                  :errors="formErrors"
                  prop="building_height"
                  label="Wysokość budynku [m]"
                >
                  <input-number
                    v-model="form.addons.building_height"
                    v-validate="rules.building_height"
                    :precision="2"
                    name="building_height"
                    class="w-100"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.slope_size)"
                  :errors="formErrors"
                  prop="slope_size_width"
                  label="Wymiary połaci szerokość[m]"
                >
                  <input-number
                    v-model="form.addons.slope_size_width"
                    v-validate="rules.slope_size"
                    name="slope_size_width"
                    class="w-100"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.slope_size)"
                  :errors="formErrors"
                  prop="slope_size_height"
                  label="Wymiary połaci wysokość[m]"
                >
                  <input-number
                    v-model="form.addons.slope_size_height"
                    v-validate="rules.slope_size"
                    name="slope_size_height"
                    class="w-100"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.slope_degree)"
                  :errors="formErrors"
                  prop="slope_degree"
                  label="Kąt nachylenia połaci [°]"
                >
                  <input-number
                    v-model="form.addons.slope_degree"
                    v-validate="rules.slope_degree"
                    :precision="2"
                    class="w-100"
                    name="slope_degree"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="dimensional_accuracy"
                  label="Dokładność wymiarów"
                >
                  <el-select
                    v-model="form.addons.dimensional_accuracy"
                    :filterable="true"
                    name="dimensional_accuracy"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.dimensional_accuracy.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="roof_pattern"
                  label="Wzór dachu"
                >
                  <el-select
                    v-model="form.addons.roof_pattern"
                    :filterable="true"
                    name="roof_pattern"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.roof_pattern.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="material_roof"
                  label="Materiał Krokwi/płatw."
                >
                  <el-select
                    v-model="form.addons.material_roof"
                    :filterable="true"
                    name="material_roof"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.material_roof.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="roof_sheathing"
                  label="Poszycie dachu"
                >
                  <el-select
                    v-model="form.addons.roof_sheathing"
                    :filterable="true"
                    name="roof_sheathing"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.roof_sheathing.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div
                v-if="form.addons.roof_sheathing === 16"
                class="col-lg-4 col-xl-4 col-sm-12"
              >
                <custom-form-item
                  :required="checkRequired(rules.sandwich_panel)"
                  :errors="formErrors"
                  prop="sandwich_panel"
                  label="Płyta warstwowa - typ oraz grubość do wpisania"
                >
                  <el-input
                    v-model="form.addons.sandwich_panel"
                    v-validate="rules.sandwich_panel"
                    class="w-100"
                    name="sandwich_panel"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div
                v-if="form.addons.roof_sheathing == 5"
                class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
              >
                <custom-form-item
                  :required="checkRequired(rules.trapezoidal_bridge)"
                  :errors="formErrors"
                  prop="trapezoidal_bridge"
                  label="Mostek trapezowy"
                >
                  <el-select
                    v-model="form.addons.trapezoidal_bridge"
                    v-validate="rules.trapezoidal_bridge"
                    :filterable="true"
                    name="trapezoidal_bridge"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.trapezoidal_bridge.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div
                v-if="form.addons.roof_sheathing == 0 || form.roof_sheathing == 1"
                class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
              >
                <custom-form-item
                  :required="checkRequired(rules.tile_size)"
                  :errors="formErrors"
                  prop="tile_size_a"
                  label="Wymiary pojedynczej dachówki A"
                >
                  <el-input
                    v-model="form.addons.tile_size_a"
                    v-validate="rules.tile_size"
                    v-mask=""
                    name="tile_size_a"
                    type="text"
                  >
                    <template slot="append">
                      [cm]
                    </template>
                  </el-input>
                </custom-form-item>
              </div>
              <div
                v-if="form.addons.roof_sheathing == 0 || form.roof_sheathing == 1"
                class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
              >
                <custom-form-item
                  :required="checkRequired(rules.tile_size)"
                  :errors="formErrors"
                  prop="tile_size_b"
                  label="Wymiary pojedynczej dachówki B"
                >
                  <el-input
                    v-model="form.addons.tile_size_b"
                    v-validate="rules.tile_size"
                    v-mask=""
                    name="tile_size_b"
                    type="text"
                  >
                    <template slot="append">
                      [cm]
                    </template>
                  </el-input>
                </custom-form-item>
              </div>
              <div
                v-if="form.addons.roof_sheathing == 13 || form.roof_sheathing == 14"
                class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
              >
                <custom-form-item
                  :required="checkRequired(rules.flat_roof_system)"
                  :errors="formErrors"
                  prop="flat_roof_system"
                  label="System na dach płaski"
                >
                  <el-select
                    v-model="form.addons.flat_roof_system"
                    v-validate="rules.flat_roof_system"
                    :filterable="true"
                    name="flat_roof_system"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.flat_roof_system.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
            </div>
            <div
              v-else
              class="row"
            >
              <div class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.side_1th_length)"
                  :errors="formErrors"
                  prop="side_1th_length"
                  label="Dł. 1 boku miejsca pod instalację [m]"
                >
                  <input-number
                    v-model="form.addons.side_1th_length"
                    v-validate="rules.side_1th_length"
                    :precision="2"
                    name="side_1th_length"
                    class="w-100"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.side_2nd_length)"
                  :errors="formErrors"
                  prop="side_2nd_length"
                  label="Dł. 2 boku miejsca pod instalację [m]"
                >
                  <input-number
                    v-model="form.addons.side_2nd_length"
                    v-validate="rules.side_2nd_length"
                    :precision="2"
                    class="w-100"
                    name="side_2th_length"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="type_of_ground"
                  label="Rodzaj podłoża"
                >
                  <el-select
                    v-model="form.addons.type_of_ground"
                    :filterable="true"
                    name="type_of_ground"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.type_of_ground.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="installation_place_type"
                  label="Instalacja na potrzeby"
                >
                  <el-select
                    v-model="form.addons.installation_place_type"
                    :filterable="true"
                    name="installation_place_type"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.installation_place_type.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="mounting_on"
                  label="Montaż na"
                >
                  <el-select
                    v-model="form.addons.mounting_on"
                    :filterable="true"
                    name="mounting_on"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.mounting_on.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="hinders_access_roof"
                  label="Utrutdnienia w dostępie do dachu"
                >
                  <el-select
                    v-model="form.addons.hinders_access_roof"
                    :filterable="true"
                    name="hinders_access_roof"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.hinders_access_roof.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
            </div>
          </div>
        </el-card>

        <el-card
          v-if="form.addons.installation_place === 0"
          class="mt-3"
        >
          <div class="row">
            <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
              <div>
                <div>
                  <h5 class="my-0">
                    Rozszerzenie techniczne - dla Dachu
                  </h5>
                  <hr>
                </div>
                <div class="row">
                  <div
                    v-if="form.addons.mounting_on === 1"
                    class="col-lg-4 col-xl-4 col-sm-12"
                  >
                    <custom-form-item
                      :required="checkRequired(rules.distance_from_home_pv)"
                      :errors="formErrors"
                      prop="distance_from_home_pv"
                      label="Odległość od domu dla montażu [m]"
                    >
                      <input-number
                        v-model="form.addons.distance_from_home_pv"
                        v-validate="rules.distance_from_home_pv"
                        :precision="2"
                        class="w-100"
                        name="distance_from_home_pv"
                        type="text"
                      />
                    </custom-form-item>
                  </div>
                  <div
                    class="col-lg-4 col-xl-4 col-sm-12"
                  >
                    <custom-form-item
                      :required="checkRequired(rules.height_from_base_roof)"
                      :errors="formErrors"
                      prop="height_from_base_roof"
                      label="Wysokość od podstawy dachu [m]"
                    >
                      <input-number
                        v-model="form.addons.height_from_base_roof"
                        v-validate="rules.height_from_base_roof"
                        :precision="2"
                        class="w-100"
                        name="height_from_base_roof"
                        type="text"
                      />
                    </custom-form-item>
                  </div>
                  <div
                    class="col-lg-4 col-xl-4 col-sm-12"
                  >
                    <custom-form-item
                      :required="checkRequired(rules.usable_area_roof)"
                      :errors="formErrors"
                      prop="usable_area_roof"
                      label="Powierzchnia użytkowa dachu [m2]"
                    >
                      <input-number
                        v-model="form.addons.usable_area_roof"
                        v-validate="rules.usable_area_roof"
                        :precision="2"
                        class="w-100"
                        name="usable_area_roof"
                        type="text"
                      />
                    </custom-form-item>
                  </div>
                  <div
                    class="col-lg-4 col-xl-4 col-sm-12"
                  >
                    <custom-form-item
                      :required="checkRequired(rules.roof_construction)"
                      :errors="formErrors"
                      prop="roof_construction"
                      label="Konstrukcja dachu"
                    >
                      <el-select
                        v-model="form.addons.roof_construction"
                        v-validate="rules.roof_construction"
                        :filterable="true"
                        name="roof_construction"
                      >
                        <el-option
                          v-for="(item,index) in constructionsList"
                          :key="index"
                          :label="item.name"
                          :value="item.value"
                        />
                      </el-select>
                    </custom-form-item>
                  </div>
                  <div
                    v-if="form.addons.roof_sheathing === 2"
                    class="col-lg-4 col-xl-4 col-sm-12"
                  >
                    <custom-form-item
                      :required="checkRequired(rules.dimensions_roof_tile_module)"
                      :errors="formErrors"
                      prop="dimensions_roof_tile_module"
                      label="Wymiary modułu blachodachówki - RozmiarA x RozmiarB"
                    >
                      <el-input
                        v-model="form.addons.dimensions_roof_tile_module"
                        v-validate="rules.dimensions_roof_tile_module"
                        name="dimensions_roof_tile_module"
                        type="text"
                      />
                    </custom-form-item>
                  </div>

                  <div
                    class="col-lg-4 col-xl-4 col-sm-12"
                  >
                    <custom-form-item
                      :required="checkRequired(rules.tech_condition_roof_sheathing)"
                      :errors="formErrors"
                      prop="tech_condition_roof_sheathing"
                      label="Stan techniczny poszycia dachowego"
                    >
                      <el-input
                        v-model="form.addons.tech_condition_roof_sheathing"
                        v-validate="rules.tech_condition_roof_sheathing"
                        name="tech_condition_roof_sheathing"
                        type="text"
                      />
                    </custom-form-item>
                  </div>
                  <div
                    class="col-lg-4 col-xl-4 col-sm-12"
                  >
                    <custom-form-item
                      :required="checkRequired(rules.inverter_mounting_place)"
                      :errors="formErrors"
                      prop="inverter_mounting_place"
                      label="Miejsce montażu falownika"
                    >
                      <el-select
                        v-model="form.addons.inverter_mounting_place"
                        v-validate="rules.inverter_mounting_place"
                        :filterable="true"
                        name="inverter_mounting_place"
                      >
                        <el-option
                          v-for="(item,index) in inverterPlacesList"
                          :key="index"
                          :label="item.name"
                          :value="item.value"
                        />
                      </el-select>
                    </custom-form-item>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-lg-4 col-xl-4 col-sm-12"
                  >
                    <custom-form-item
                      prop="is_same_building_installation"
                      label="Panele i falownik w tym samym budynku?"
                    >
                      <el-switch
                        v-model="form.addons.is_same_building_installation"
                        active-text="Tak"
                        inactive-text="Nie"
                        name="is_same_building_installation"
                      />
                    </custom-form-item>
                  </div>
                  <div
                    v-if="form.addons.is_same_building_installation === false"
                    class="col-lg-4 col-xl-4 col-sm-12"
                  >
                    <custom-form-item
                      :required="checkRequired(rules.distance_from_home_inverter)"
                      :errors="formErrors"
                      prop="distance_from_home_inverter"
                      label="Odległość budynku od falownika"
                    >
                      <input-number
                        v-model="form.addons.distance_from_home_inverter"
                        v-validate="rules.distance_from_home_inverter"
                        :precision="2"
                        name="distance_from_home_inverter"
                        type="number"
                      />
                    </custom-form-item>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-lg-4 col-xl-4 col-sm-12"
                  >
                    <custom-form-item
                      prop="is_possible_shading"
                      label="Czy występuje zacienienie ?"
                    >
                      <el-switch
                        v-model="form.addons.is_possible_shading"
                        active-text="Tak"
                        inactive-text="Nie"
                        name="is_possible_shading"
                      />
                    </custom-form-item>
                  </div>

                  <div
                    v-if="form.addons.is_possible_shading === true"
                    class="col-lg-4 col-xl-4 col-sm-12"
                  >
                    <custom-form-item
                      :required="checkRequired(rules.shading_percent)"
                      :errors="formErrors"
                      prop="shading_percent"
                      label="Procent zacienienia"
                    >
                      <input-number
                        v-model="form.addons.shading_percent"
                        v-validate="rules.shading_percent"
                        :precision="2"
                        class="w-100"
                        name="shading_percent"
                        type="text"
                      />
                    </custom-form-item>
                  </div>

                  <div
                    v-if="form.addons.is_possible_shading === true"
                    class="col-lg-12 col-xl-12 col-sm-12"
                  >
                    <custom-form-item
                      :required="checkRequired(rules.shading_description)"
                      :errors="formErrors"
                      prop="shading_description"
                      label="Krótki opis zacienienia"
                    >
                      <el-input
                        v-model="form.addons.shading_description"
                        v-validate="rules.shading_description"
                        name="shading_description"
                        type="textarea"
                      />
                    </custom-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card
          v-if="form.addons.installation_place === 1"
          class="mt-3"
        >
          <div class="row">
            <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
              <div>
                <div>
                  <h5 class="my-0">
                    Rozszerzenie techniczne - dla Gruntu
                  </h5>
                  <hr>
                </div>

                <div class="row">
                  <div class="col-lg-4 col-xl-4 col-sm-12">
                    <custom-form-item
                      :required="checkRequired(rules.panels_arranged)"
                      :errors="formErrors"
                      prop="panels_arranged"
                      label="Sposób ułożenia paneli"
                    >
                      <el-select
                        v-model="form.addons.panels_arranged"
                        v-validate="rules.panels_arranged"
                        :filterable="true"
                        name="panels_arranged"
                      >
                        <el-option
                          v-for="(item,index) in arrangedList"
                          :key="index"
                          :label="item.name"
                          :value="item.value"
                        />
                      </el-select>
                    </custom-form-item>
                  </div>
                  <div class="col-lg-4 col-xl-4 col-sm-12">
                    <custom-form-item
                      prop="is_ethernet_required"
                      label="Czy dodać kabel internetowy?"
                    >
                      <el-switch
                        v-model="form.addons.is_ethernet_required"
                        active-text="Tak"
                        inactive-text="Nie"
                        name="is_ethernet_required"
                      />
                    </custom-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-card>

        <el-card class="mt-3">
          <div class="row">
            <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
              <div>
                <div>
                  <h5 class="my-0">
                    Rozszerzenie techniczne - Dane dodatkowe
                  </h5>
                  <hr>
                </div>

                <div class="row">
                  <div class="col-lg-4 col-xl-4 col-sm-12">
                    <custom-form-item
                      prop="is_cut_required"
                      label="Czy przekop jest wymagany?"
                    >
                      <el-switch
                        v-model="form.addons.is_cut_required"
                        active-text="Tak"
                        inactive-text="Nie"
                        name="is_cut_required"
                      />
                    </custom-form-item>
                  </div>

                  <div
                    v-if="form.addons.is_cut_required === true"
                    class="col-lg-4 col-xl-4 col-sm-12"
                  >
                    <custom-form-item
                      :required="checkRequired(rules.cut_length)"
                      :errors="formErrors"
                      prop="cut_length"
                      label="Długość przekopu"
                    >
                      <input-number
                        v-model="form.addons.cut_length"
                        v-validate="rules.cut_length"
                        :precision="2"
                        class="w-100"
                        name="cut_length"
                        type="text"
                      />
                    </custom-form-item>
                  </div>
                  <div
                    v-if="form.addons.is_cut_required === true"
                    class="col-lg-12 col-xl-12 col-sm-12"
                  >
                    <custom-form-item
                      :required="checkRequired(rules.difficulties_cut)"
                      :errors="formErrors"
                      prop="difficulties_cut"
                      label="Utrudnienia w miejscu przekopu (opis)"
                    >
                      <el-input
                        v-model="form.addons.difficulties_cut"
                        v-validate="rules.difficulties_cut"
                        class="w-100"
                        name="difficulties_cut"
                        type="textarea"
                      />
                    </custom-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-card>

        <el-card class="mt-3">
          <div>
            <div>
              <h5 class="mt-1 pt-0">
                Instalacja elektryczna - część pierwsza
              </h5>
              <hr>
            </div>
            <div class="row">
              <div class="col-lg-6 col-xl-6 col-sm-12">
                <custom-form-item
                  :errors="formErrors"
                  prop="electrical_installation_type"
                  label="Typ instalacji elektrycznej"
                >
                  <el-select
                    v-model="form.addons.electrical_installation_type"
                    :filterable="true"
                    name="electrical_installation_type"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.electrical_installation_type.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-6 col-xl-6 col-sm-12">
                <custom-form-item
                  :errors="formErrors"
                  prop="electric_provider"
                  label="Dostawca energii el."
                >
                  <el-select
                    v-model="form.addons.electric_provider"
                    :filterable="true"
                    name="electric_provider"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.electric_provider.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-6 col-xl-6 col-sm-12">
                <custom-form-item
                  :errors="formErrors"
                  prop="pv_installation_place"
                  label="Miejsce wpięcia instalacji"
                >
                  <el-select
                    v-model="form.addons.pv_installation_place"
                    :filterable="true"
                    name="pv_installation_place"
                  >
                    <el-option
                      v-for="(item,index) in pvInstallationPlaceList"
                      :key="index"
                      :label="item.name"
                      :value="item.value"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div
                v-if="form.addons.pv_installation_place === 'Gniazdo siłowe'"
                class="col-lg-6 col-xl-6 col-sm-12"
              >
                <custom-form-item
                  :required="checkRequired(rules.thickness_cable)"
                  :errors="formErrors"
                  prop="thickness_cable"
                  label="Grubość kabla siłowego"
                >
                  <input-number
                    v-model="form.addons.thickness_cable"
                    v-validate="rules.thickness_cable"
                    class="w-100"
                    :precision="2"
                    name="thickness_cable"
                  />
                </custom-form-item>
              </div>
              <div
                class="col-lg-6 col-xl-6 col-sm-12"
              >
                <custom-form-item
                  :required="checkRequired(rules.monthly_electricity_consumption)"
                  :errors="formErrors"
                  prop="monthly_electricity_consumption"
                  label="Miesięczne zużycie energii elektrycznej [kWh/ms]"
                >
                  <input-number
                    v-model="form.addons.monthly_electricity_consumption"
                    v-validate="rules.monthly_electricity_consumption"
                    :precision="2"
                    class="w-100"
                    name="monthly_electricity_consumption"
                    type="text"
                  />
                </custom-form-item>
              </div>
            </div>
          </div>
        </el-card>

        <el-card class="mt-3">
          <div>
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="my-0">
                  Instalacja elektryczna - część druga
                </h5>
                <el-button
                  type="primary"
                  size="small"
                  @click="getClientElectricalData"
                >
                  Pobierz informacje o kliencie
                </el-button>
              </div>
              <hr>
            </div>
            <div class="row">
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.name_invoice_buyer)"
                  :errors="formErrors"
                  prop="name_invoice_buyer"
                  label="Nabywca faktury za prąd"
                >
                  <el-input
                    v-model="form.addons.name_invoice_buyer"
                    v-validate="rules.name_invoice_buyer"
                    name="name_invoice_buyer"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.energy_fv_pesel)"
                  :errors="formErrors"
                  prop="energy_fv_pesel"
                  label="Numer PESEL nabywcy faktury"
                >
                  <el-input
                    v-model="form.addons.energy_fv_pesel"

                    v-validate="rules.energy_fv_pesel"
                    name="energy_fv_pesel"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.energy_fv_document_id_number)"
                  :errors="formErrors"
                  prop="energy_fv_document_id_number"
                  label="Numer dokumentu nabywcy faktury"
                >
                  <el-input
                    v-model="form.addons.energy_fv_document_id_number"

                    v-validate="rules.energy_fv_document_id_number"
                    name="energy_fv_document_id_number"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.premeter_security)"
                  :errors="formErrors"
                  prop="premeter_security"
                  label="Zabezpieczenie przedlicznikowe [A]"
                >
                  <input-number
                    v-model="form.addons.premeter_security"
                    v-validate="rules.premeter_security"
                    :precision="2"
                    class="w-100"
                    name="premeter_security"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.ppe_number)"
                  :errors="formErrors"
                  prop="ppe_number"
                  label="Nr PPE"
                >
                  <el-input
                    v-model="form.addons.ppe_number"
                    v-validate="rules.ppe_number"
                    name="ppe_number"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.counter_number)"
                  :errors="formErrors"
                  prop="counter_number"
                  label="Nr licznika"
                >
                  <el-input
                    v-model="form.addons.counter_number"
                    v-validate="rules.counter_number"
                    name="counter_number"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.counter_place)"
                  :errors="formErrors"
                  prop="counter_place"
                  label="Gdzie znajduje się licznik?"
                >
                  <el-input
                    v-model="form.addons.counter_place"
                    v-validate="rules.counter_place"
                    name="counter_place"
                    type="text"
                  />
                </custom-form-item>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="mt-3">
          <div>
            <div>
              <h5 class="my-0">
                Instalacja elektryczna - część trzecia
              </h5>
              <hr>
            </div>
            <div class="row">
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.power_installation)"
                  :errors="formErrors"
                  prop="power_installation"
                  label="Moc instalacji [kW]"
                >
                  <input-number
                    v-model="form.addons.power_installation"
                    v-validate="rules.power_installation"
                    :precision="2"
                    :disabled="true"
                    class="w-100"
                    name="power_installation"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.power_connection)"
                  :errors="formErrors"
                  prop="power_connection"
                  label="Moc przyłączeniowa [kW]"
                >
                  <input-number
                    v-model="form.addons.power_connection"
                    v-validate="rules.power_connection"
                    :precision="2"
                    class="w-100"
                    name="power_connection"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.contracted_power_installation)"
                  :errors="formErrors"
                  prop="contracted_power_installation"
                  label="Moc umowna [kW]"
                >
                  <input-number
                    v-model="form.addons.contracted_power_installation"
                    v-validate="rules.contracted_power_installation"
                    :precision="2"
                    class="w-100"
                    name="contracted_power_installation"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="type_connection"
                  label="Rodzaj przyłącza"
                >
                  <el-select
                    v-model="form.addons.type_connection"
                    :filterable="true"
                    name="type_connection"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.type_connection.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="connection_level"
                  label="Przyłączenie do sieci"
                >
                  <el-select
                    v-model="form.addons.connection_level"
                    :filterable="true"
                    name="connection_level"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.connection_level.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.energy_complex_agreement_number)"
                  :errors="formErrors"
                  prop="energy_complex_agreement_number"
                  label="Numer umowy kompleksowej"
                >
                  <el-input
                    v-model="form.addons.energy_complex_agreement_number"
                    v-validate="rules.energy_complex_agreement_number"
                    name="Numer umowy kompleksowej"
                    type="text"
                  />
                </custom-form-item>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="mt-3">
          <div>
            <div>
              <h5 class="mt-1 pt-0">
                Rozmieszczenie modułów
              </h5>
              <hr>
            </div>
            <div class="row">
              <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.arrangement_description)"
                  :errors="formErrors"
                  prop="arrangement_description"
                  label="Opis rozmieszczenia"
                >
                  <el-input
                    v-model="form.addons.arrangement_description"
                    v-validate="rules.arrangement_description"
                    name="arrangement_description"
                    type="textarea"
                  />
                </custom-form-item>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="mt-3">
          <div>
            <div>
              <h5 class="mt-1 pt-0">
                Uwagi do umowy
              </h5>
              <hr>
            </div>
            <div class="row">
              <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.due_to_agreement)"
                  :errors="formErrors"
                  prop="due_to_agreement"
                  label="Uwagi do umowy"
                >
                  <el-input
                    v-model="form.addons.due_to_agreement"
                    v-validate="rules.due_to_agreement"
                    name="due_to_agreement"
                    type="textarea"
                  />
                </custom-form-item>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="mt-3">
          <div>
            <h5 class="mt-1 pt-0">
              Oświadczenia
            </h5>
            <hr>
            <div class="row">
              <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
                <div class="d-flex justify-content-between">
                  <h5 class="my-0">
                    Strefa PPOŻ.
                  </h5>
                  <el-switch
                    v-model="form.addons.document_statement_fire_zone"
                    inactive-text="Jedna strefa pożarowa"
                    active-text="Wiele stref pożarowych"
                  />
                </div>
                <div class="mt-6">
                  <div
                    v-if="!form.addons.document_statement_fire_zone"
                    class="statement-one-zone"
                  >
                    <span>Oświadczam, że wskazane miejsce realizacji inwestycji jest jedną główną strefą pożarową.</span>
                  </div>
                  <div
                    v-else
                    class="statement-multi-zone"
                  >
                    <p>Oświadczam, że wskazane miejsce realizacji inwestycji nie jest jedyną główną strefą pożarową.</p>
                    <span>Jednocześnie potwierdzam przekazanie kopii pełnej dokumentacji pożarowej reprezentantowi Sprzedawcy wymienionemu w umowie, w celu prawidłowej realizacji inwestycji.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="col-lg-3 col-xl-4 col-sm-12 order-lg-1 order-xl-1 block-border-left mt-3">
        <offer-technical-information
          :offer="form.offer"
        />
      </div>
    </div>
    <el-card class="mt-3">
      <div
        v-if="type == 'create'"
        class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1"
      >
        <el-row class="text-end">
          <el-button
            type="info"
            @click="previousPage"
          >
            Cofnij <i class="fas fa-long-arrow-alt-left"></i>
          </el-button>
          <el-button
            v-if="!form.product_number && $can('products.save-draft')"
            type="warning"
            @click="saveDraft"
          >
            <i class="far fa-save"></i> {{ form.is_draft ? 'Nadpisz wersje roboczą' : 'Zapisz wersje roboczą' }}
          </el-button>
          <el-button
            type="primary"
            @click="nextPage"
          >
            Przejdź dalej <i class="fas fa-long-arrow-alt-right"></i>
          </el-button>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>

  import Avatar from 'vue-avatar'
  import forms from '@/shared/mixins/forms'
  import { getRequest, postRequest } from '@/shared/utils/http'
  import OfferTechnicalInformation from '@/admin/pages/sales/components/OfferTechnicalInformation'

  export default {
    name: 'TechnicalCreate',
    components: {
      OfferTechnicalInformation,
      Avatar
    },
    mixins: [
      forms
    ],
    props: {
      form: {
        type: Object,
        required: true
      },
      rules: {
        type: Object,
        required: true
      },
      type: {
        type: String,
        default: 'create'
      }
    },
    data () {
      return {
        errorBlock: false,
        availableOffersList: null
      }
    },
    computed: {
      constructionsList () {
        return [
          { name: 'Żelbetowa', value: 'Żelbetowa' },
          { name: 'Kratownica drewniana pełne deskowanie', value: 'Kratownica drewniana pełne deskowanie' },
          { name: 'Kratownica drewniana łaty', value: 'Kratownica drewniana łaty' },
          { name: 'Kratownica metalowa', value: 'Kratownica metalowa' },
          { name: 'Płyta warstwowa', value: 'Płyta warstwowa' }
        ]
      },
      inverterPlacesList () {
        return [
          { name: 'Pomieszczenie techniczne', value: 'Pomieszczenie techniczne' },
          { name: 'Garaż', value: 'Garaż' },
          { name: 'Piwnica', value: 'Piwnica' },
          { name: 'Elewacja(tylko północa ściana)', value: 'Elewacja(tylko północa ściana)' }
        ]
      },
      arrangedList () {
        return [
          { name: '2 rzędy pionowo', value: '2 rzędy pionowo' },
          { name: '4 rzędy poziomo', value: '4 rzędy poziomo' }
        ]
      },
      pvInstallationPlaceList () {
        return [
          { name: 'Puszka przyłączeniowa', value: 'Puszka przyłączeniowa' },
          { name: 'Rozdzielnia Główna', value: 'Rozdzielnia Główna' },
          { name: 'Gniazdo siłowe', value: 'Gniazdo siłowe' }
        ]
      }
    },
    methods: {
      nextPage: function () {
        this.$validator.validate().then(valid => {
          if (valid) {
            this.$emit('nextPage')
          } else {
            this.$snotify.error('Błąd formularza. Nie wypełniono wszystkich pól.')
            this.errorBlock = true
          }
        })
      },
      previousPage: function () {
        this.$emit('previousPage')
      },
      saveDraft: function () {
        this.$emit('save-draft')
      },
      getClientElectricalData () {
        this.$message.info('Pobieranie dodatkowych danych o kliencie')
        postRequest('/api/clients/' + this.form.client_id + '/get-custom-data', {
          type: 'electric_data'
        }).then(response => {
          this.form.addons.name_invoice_buyer = response.data.name_invoice_buyer
          this.form.addons.energy_fv_pesel = response.data.energy_fv_pesel
          this.form.addons.energy_fv_document_id_number = response.data.energy_fv_document_id_number
          this.$message.success('Pobrano dodatkowe dane o kliencie')
        }).catch(error => {
          this.$message.error('Ups. Nie pobrano danych dot. klienta')
        })
      }
    }
  }
</script>

<style scoped>
.block-border-left {
  border-left: 1px solid #e5e6e6;
}

.el-select {
  width: 100%;
}
</style>
