<template>
  <el-form
    ref="userForm"
    v-loading="loading"
    :model="form"
    label-position="top"
    @submit.native.prevent="handleForm('userForm')"
  >
    <form-alerts :messages="invalidMsg" />

    <fieldset>
      <div class="row">
        <div class="col-lg-3 col-xl-2">
          <h6>Dane personalne</h6>
        </div>

        <div class="col-lg-9 col-xl-10">
          <div>
            <h5>Pracownik {{ form.firstName }} {{ form.lastName }}</h5>
            <hr>
          </div>
          <custom-form-item
            :required="checkRequired(rules.firstName)"
            :errors="formErrors"
            prop="firstName"
            label="Imię"
          >
            <el-input
              v-model="form.firstName"
              v-validate="rules.firstName"
              name="firstName"
              type="text"
            />
          </custom-form-item>

          <custom-form-item
            :required="checkRequired(rules.lastName)"
            :errors="formErrors"
            prop="lastName"
            label="Nazwisko"
          >
            <el-input
              v-model="form.lastName"
              v-validate="rules.lastName"
              name="lastName"
              type="text"
            />
          </custom-form-item>

          <custom-form-item
            v-if="!isEdit"
            :errors="formErrors"
            prop="password"
            label="Hasło (generowane automatycznie)"
          >
            <el-alert
              title="Co z hasłem do konta?"
              type="info"
              description="Po utworzeniu konta specjalny link zostanie wygenerowany i przesłany do użytkownika na jego konto email. Hasło nie jest wymagane przy formularzu."
              show-icon
            />
          </custom-form-item>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <div class="row">
        <div class="col-lg-3 col-xl-2">
          <h6>Kontakt</h6>
        </div>

        <div class="col-lg-9 col-xl-10">
          <div>
            <h5>Dane kontaktowe z pracownikiem</h5>
            <hr>
          </div>
          <div class="row">
            <div class="col">
              <custom-form-item
                :required="checkRequired(rules.email)"
                :errors="formErrors"
                prop="email"
                label="E-mail (login do konta)"
              >
                <el-input
                  v-model="form.email"
                  v-validate="rules.email"
                  name="email"
                  type="text"
                />
              </custom-form-item>
            </div>
            <div class="col">
              <custom-form-item
                :required="checkRequired(rules.email)"
                :errors="formErrors"
                prop="email_private"
                label="E-mail prywatny"
              >
                <el-input
                  v-model="form.email_private"
                  v-validate="rules.email"
                  name="email_private"
                  type="text"
                />
              </custom-form-item>
            </div>
          </div>

          <custom-form-item
            :required="checkRequired(rules.mobile)"
            :errors="formErrors"
            prop="mobile"
            label="Telefon komórkowy"
          >
            <el-input
              v-model="form.mobile"
              v-validate="rules.mobile"
              v-mask="'### ### ###'"
              name="mobile"
              type="text"
            />
          </custom-form-item>

          <custom-form-item
            :required="checkRequired(rules.phone)"
            :errors="formErrors"
            prop="work_phone"
            label="Telefon służbowy"
          >
            <el-input
              v-model="form.work_phone"
              v-validate="rules.phone"
              v-mask="'### ### ###'"
              name="work_phone"
              type="text"
            />
          </custom-form-item>
        </div>
      </div>
    </fieldset>

    <fieldset v-if="canEditHiddenData">
      <div class="row">
        <div class="col-lg-3 col-xl-2">
          <h6>
            Adres zamieszkania
            <i
              class="fas fa-eye"
              title="Pokaż/ukryj dane"
              @click="addressVisibility = !addressVisibility"
            ></i>
          </h6>
        </div>

        <div class="col-lg-9 col-xl-10">
          <div
            v-if="addressVisibility"
            class="pb-3"
          >
            <div>
              <h5>Dane adresowe pracownika</h5>
              <hr>
            </div>
            <teryt-address
              :rules="rules"
              :form="form"
            />
          </div>
          <hidden-data-alert v-else />
        </div>
      </div>
    </fieldset>

    <fieldset v-if="canEditHiddenData">
      <div class="row">
        <div class="col-lg-3 col-xl-2">
          <h6 style="text-align: center">
            Dokumenty tożsamości i prawo jazdy
            <i
              class="fas fa-eye"
              title="Pokaż/ukryj dane"
              @click="documentVisibility = !documentVisibility"
            ></i>
          </h6>
        </div>

        <div class="col-lg-9 col-xl-10">
          <div v-if="documentVisibility">
            <div>
              <h5>Dane wrażliwe</h5>
              <hr>
            </div>
            <custom-form-item
              :required="checkRequired(rules.pesel)"
              :errors="formErrors"
              prop="pesel"
              label="PESEL"
            >
              <el-input
                v-model="form.pesel"
                v-validate="rules.pesel"
                v-mask="'###########'"
                name="pesel"
                type="text"
              />
            </custom-form-item>

            <custom-form-item
              :required="checkRequired(rules.birthday_date)"
              :errors="formErrors"
              prop="birthday_date"
              label="Data urodzenia"
            >
              <el-input
                v-model="form.birthday_date"
                v-validate="rules.birthday_date"
                v-mask="'####-##-##'"
                name="birthday_date"
                type="text"
              />
            </custom-form-item>

            <div>
              <h5>Dane urodzenia</h5>
              <hr>
            </div>

            <custom-form-item
              :required="checkRequired(rules.city_born)"
              :errors="formErrors"
              prop="city_born"
              label="Miejsce urodzenia"
            >
              <el-input
                v-model="form.city_born"
                v-validate="rules.city_born"
                name="city_born"
                type="text"
              />
            </custom-form-item>

            <custom-form-item
              :required="checkRequired(rules.father_name)"
              :errors="formErrors"
              prop="father_name"
              label="Imię Ojca"
            >
              <el-input
                v-model="form.father_name"
                v-validate="rules.father_name"
                name="father_name"
                type="text"
              />
            </custom-form-item>

            <custom-form-item
              :required="checkRequired(rules.mother_name)"
              :errors="formErrors"
              prop="mother_name"
              label="Imię Matki"
            >
              <el-input
                v-model="form.mother_name"
                v-validate="rules.mother_name"
                name="mother_name"
                type="text"
              />
            </custom-form-item>
            <div>
              <h5>Prawo jazdy</h5>
              <hr>
            </div>
            <custom-form-item
              :required="checkRequired(rules.drive_license_number)"
              :errors="formErrors"
              prop="drive_license_number"
              label="Numer Prawa Jazdy"
            >
              <el-input
                v-model="form.drive_license_number"
                v-validate="rules.drive_license_number"
                name="drive_license_number"
                type="text"
              />
            </custom-form-item>

            <custom-form-item
              :required="checkRequired(rules.drive_license_valid_date)"
              :errors="formErrors"
              prop="drive_license_valid_date"
              label="Data ważności Prawa Jazdy"
            >
              <el-date-picker
                id="drive_license_valid_date"
                v-model="form.drive_license_valid_date"
                v-validate="rules.drive_license_valid_date"
                v-bind="$config.datepicker"
                :picker-options="$config.datepickerOptions"
                format="yyyy-MM-dd HH:mm:SS"
                value-format="yyyy-MM-dd HH:mm:ss"
                name="drive_license_valid_date"
                type="date"
                unlink-panels
              />
            </custom-form-item>

            <div>
              <h5>Dane tożsamościowe</h5>
              <hr>
            </div>

            <custom-form-item
              :errors="formErrors"
              :required="checkRequired(rules.document_type)"
              prop="document_type"
              label="Rodzaj dokumentu tożsamości"
            >
              <el-radio-group
                v-model="form.document_type"
                v-validate="rules.document_type"
                name="document_type"
              >
                <el-radio
                  v-for="(documentType, key) in $config.docs.document_type"
                  :key="key"
                  :label="key"
                >
                  {{ documentType }}
                </el-radio>
              </el-radio-group>
            </custom-form-item>

            <custom-form-item
              :required="checkRequired(rules.document_number)"
              :errors="formErrors"
              prop="document_number"
              label="Numer dokumentu tożsamości"
            >
              <el-input
                v-model="form.document_number"
                v-validate="rules.document_number"
                name="document_number"
                type="text"
              />
            </custom-form-item>
          </div>
          <hidden-data-alert v-else />
        </div>
      </div>
    </fieldset>

    <fieldset v-if="canEditHiddenData">
      <div class="row">
        <div class="col-lg-3 col-xl-2">
          <h6 style="text-align: center">
            Dane do umowy
          </h6>
        </div>

        <div class="col-lg-9 col-xl-10">
          <div class="row">
            <div class="col-12">
              <div>
                <h5>Dane umowy z pracodawcą</h5>
                <hr>
              </div>
              <custom-form-item
                :required="checkRequired(rules.tax_office_name)"
                :errors="formErrors"
                prop="tax_office_name"
                label="Nazwa urzędu skarbowego"
              >
                <el-select
                  v-model="form.tax_office_name"
                  :filterable="true"
                  :clearable="true"
                  :remote="true"
                  class="w-100"
                  data-cy="tax_office_name"
                  name="tax_office_name"
                  placeholder="Wybierz z listy urzędów"
                >
                  <el-option
                    v-for="(item,index) in $config.taxes"
                    :key="index"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </custom-form-item>
            </div>
            <div class="col-12">
              <custom-form-item
                :required="checkRequired(rules.contract_number)"
                :errors="formErrors"
                prop="contract_number"
                label="Numer umowy"
              >
                <el-input
                  v-model="form.contract_number"
                  v-validate="rules.contract_number"
                  name="contract_number"
                  type="text"
                />
              </custom-form-item>
            </div>
            <div class="col-12">
              <custom-form-item
                :required="checkRequired(rules.type_contract)"
                :errors="formErrors"
                prop="type_contract"
                label="Forma rozliczenia podatku"
              >
                <el-select
                  v-model="form.type_contract"
                  v-validate="rules.type_contract"
                  placeholder="Wybierz"
                >
                  <el-option
                    v-for="(item, index) in $config.userInformations.employment.type_contract"
                    :key="index"
                    :label="item"
                    :value="index"
                  />
                </el-select>
              </custom-form-item>
            </div>
            <div class="col-12">
              <custom-form-item
                :required="checkRequired(rules.contract_date_start)"
                :errors="formErrors"
                prop="contract_date_start"
                label="Data rozpoczęcia pracy"
              >
                <el-date-picker
                  id="contract_date_start"
                  v-model="form.contract_date_start"
                  v-validate="rules.contract_date_start"
                  v-bind="$config.datepicker"
                  :picker-options="$config.datepickerOptions"
                  format="yyyy-MM-dd HH:mm:SS"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  name="contract_date_start"
                  type="date"
                  unlink-panels
                />
              </custom-form-item>
            </div>
            <div class="col-12">
              <custom-form-item
                :required="checkRequired(rules.contract_date_sign)"
                :errors="formErrors"
                prop="contract_date_sign"
                label="Data podpisania umowy"
              >
                <el-date-picker
                  id="contract_date_sign"
                  v-model="form.contract_date_sign"
                  v-validate="rules.contract_date_sign"
                  v-bind="$config.datepicker"
                  :picker-options="$config.datepickerOptions"
                  format="yyyy-MM-dd HH:mm:SS"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  name="contract_date_sign"
                  type="date"
                  unlink-panels
                />
              </custom-form-item>
            </div>
            <div class="col-12">
              <custom-form-item
                :required="checkRequired(rules.contract_date_sign)"
                :errors="formErrors"
                prop="contract_date_end"
                label="Data zakończenia umowy"
              >
                <el-date-picker
                  id="contract_date_end"
                  v-model="form.contract_date_end"
                  v-validate="rules.contract_date_end"
                  v-bind="$config.datepicker"
                  :picker-options="$config.datepickerOptions"
                  format="yyyy-MM-dd HH:mm:SS"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  name="contract_date_end"
                  type="date"
                  unlink-panels
                />
              </custom-form-item>
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <div class="row">
        <div class="col-lg-3 col-xl-2">
          <h6>Dział firmy pracownika</h6>
        </div>

        <div class="col-lg-9 col-xl-10">
          <div>
            <h5>Dostęp i przydział</h5>
            <hr>
          </div>
          <branches-field
            :form="form"
            :rules="rules"
          />

          <custom-form-item
            :required="checkRequired(rules.role)"
            :errors="formErrors"
            prop="role"
            label="Rola użytkownika w systemie"
          >
            <el-select
              v-model="form.role"
              v-validate="rules.role"
              name="role"
              data-cy="role"
              filterable
              placeholder="Wybierz z listy"
            >
              <el-option
                v-for="item in $config.roles.map(item => { return [item.id, item.name, item.display_name] })"
                :key="item[1]"
                :label="item[2]"
                :value="item[1]"
                name="role"
              />
            </el-select>
          </custom-form-item>
        </div>
      </div>
    </fieldset>

    <fieldset v-if="canEditHiddenData">
      <div class="row">
        <div class="col-lg-3 col-xl-2">
          <h6 style="text-align: center">
            Dane do rozliczeń płatności
          </h6>
        </div>

        <div class="col-lg-9 col-xl-10">
          <div class="row">
            <div class="col-12">
              <div>
                <h5>Dane rozliczeniowe</h5>
                <hr>
              </div>
              <custom-form-item
                :required="checkRequired(rules.bank_account_number)"
                :errors="formErrors"
                prop="bank_account_number"
                label="Numer rachunku bankowego"
              >
                <el-input
                  v-model="form.bank_account_number"
                  v-validate="rules.bank_account_number"
                  v-mask="'## #### #### #### #### #### ####'"
                  name="bank_account_number"
                  type="text"
                />
              </custom-form-item>
            </div>
            <div class="col-12">
              <custom-form-item
                :required="checkRequired(rules.bank_name)"
                :errors="formErrors"
                prop="bank_name"
                label="Nazwa banku"
              >
                <el-input
                  v-model="form.bank_name"
                  v-validate="rules.bank_name"
                  name="bank_name"
                  type="text"
                />
              </custom-form-item>

              <custom-form-item
                :required="checkRequired(rules.is_above_average_payment)"
                :errors="formErrors"
                prop="is_above_average_payment"
                label="Czy obecnie zatrudniony gdzieś z wynagrodzeniem w wysokości co najmniej minimalnej krajowej "
              >
                <el-switch
                  v-model="form.is_above_average_payment"
                  active-text="Tak"
                  inactive-text="Nie"
                />
              </custom-form-item>

              <custom-form-item
                :required="checkRequired(rules.has_pension)"
                :errors="formErrors"
                prop="has_pension"
                label="Czy obecnie zatrudniony posiada rentę?"
              >
                <el-switch
                  v-model="form.has_pension"
                  active-text="Tak"
                  inactive-text="Nie"
                />
              </custom-form-item>
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset v-if="form.type_contract > 0">
      <div class="row">
        <div class="col-lg-3 col-xl-2">
          <h6>Dane o nauce (TYLKO GDY STUDENT)</h6>
        </div>

        <div class="col-lg-9 col-xl-10">
          <div>
            <h5>Dane dla uczeń/student</h5>
            <hr>
          </div>
          <custom-form-item
            :errors="formErrors"
            prop="name_of_school"
            label="Nazwa szkoły do której uczęszcza"
          >
            <el-input
              v-model="form.name_of_school"
              name="name_of_school"
              type="text"
            />
          </custom-form-item>

          <custom-form-item
            :errors="formErrors"
            prop="id_school_card"
            label="Numer legitymacji"
          >
            <el-input
              v-model="form.id_school_card"
              name="id_school_card"
              type="text"
            />
          </custom-form-item>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <div class="row">
        <div class="col-lg-3 col-xl-2">
          <h6>Notatki tworzącego</h6>
        </div>

        <div class="col-lg-9 col-xl-10">
          <div>
            <h5>Uwagi</h5>
            <hr>
          </div>
          <custom-form-item
            :required="checkRequired(rules.admin_notes)"
            :errors="formErrors"
            prop="admin_notes"
            label="Informacje dodatkowe o nowym pracowniku (uwagi, itp)"
          >
            <el-input
              v-model="form.admin_notes"
              v-validate="rules.admin_notes"
              name="admin_notes"
              type="textarea"
            />
          </custom-form-item>
        </div>
      </div>
    </fieldset>

    <div class="row">
      <div class="col offset-lg-3 offset-xl-2">
        <el-form-item>
          <el-button
            native-type="submit"
            type="primary"
          >
            {{ isEdit ? 'Edytuj' : 'Dodaj' }}
          </el-button>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
  import forms from '@/shared/mixins/forms'
  import { postRequest, putRequest } from '@/shared/utils/http'
  import { user as userRules } from '@/admin/utils/validation'
  import HiddenDataAlert from '@/admin/components/HiddenDataAlert'
  import BranchesField from './components/BranchesField.vue'
  import TerytAddress from '../../components/TerytAddress'

  export default {
    name: 'UserForm',

    components: {
      TerytAddress,
      HiddenDataAlert,
      BranchesField
    },

    mixins: [
      forms
    ],

    props: {
      form: {
        type: Object,
        required: true
      },

      isEdit: {
        type: Boolean,
        default: false
      },

      chargingPoint: {
        type: [Object, Boolean],
        default: false
      },

      chargingPoints: {
        type: Array,
        default: () => {
          return []
        }
      }
    },

    data () {
      return {
        addressVisibility: false,
        documentVisibility: false
      }
    },
    computed: {
      currentId: function () {
        return this.isEdit ? this.$route.params.id : 0
      },

      rules: function () {
        return userRules(this.currentId)
      },

      showAssignChargingPoint: function () {
        return this.isEdit && this.form.role.includes('user')
      },

      canEditHiddenData: function () {
        return !this.isEdit || (this.isEdit && this.$can('admin.hidden_data.lvl2'))
      }
    },
    watch: {
      'form.pesel': function (pesel) {
        const val = pesel.replace(/_/g, '')
        if (val.length >= 6) {
          const year = parseInt(val.substring(0, 2), 10)
          const month = parseInt(val.substring(2, 4), 10) - 1
          const day = parseInt(val.substring(4, 6), 10)
          const d = new Date(year, month, day)
          this.form.birthday_date = this.$dayjs(d).format('YYYY-MM-DD')
        }
      }
    },

    mounted () {
      if (!this.isEdit) {
        this.addressVisibility = true
        this.documentVisibility = true
      }
    },

    methods: {
      submitForm: function () {
        return new Promise((resolve, reject) => {
          let request
          if (this.isEdit) {
            const frm = Object.assign({}, this.form)
            delete frm.address
            delete frm.postcode
            delete frm.city
            delete frm.pesel
            delete frm.document_number
            request = putRequest('/api/users/' + this.$route.params.id, frm)
          } else {
            request = postRequest('/api/users', this.form)
          }

          request
            .then(response => {
              this.$emit('saved')
              this.$router.push({ name: 'admin.user.index' })

              resolve(response)
            })
            .catch(error => {
              this.$snotify.error(error.data.message)
              reject(error)
            })
        })
      }
    }
  }
</script>
<style scoped>
fieldset>.row>div:first-child {
  background: #7dab0a!important;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
