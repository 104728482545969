.blc {
  position: relative;
  border-radius: 4px;
  padding: 20px;
  margin: 5px 0;
  height: 75px;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
  .value {
    font-size:12px;
    line-height: 12px;
    margin-top:4px;
  }

}

.blc--broker{
  background: #3f4443;
  cursor: pointer;
}

.blc--broker-delete{
  background: #a01132;
  cursor: pointer;
}


.blc--employee{
  background: #97d700;
  cursor: pointer;
}


.stats-block{
  color: #000000;
  position: relative;
  border-radius: 5px;
  border-left: 5px solid #9dd700;
  padding: 5px 38px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
background-image: radial-gradient(circle at 13% 47%, rgba(140, 140, 140,0.03) 0%, rgba(140, 140, 140,0.03) 25%,transparent 25%, transparent 100%),radial-gradient(circle at 28% 63%, rgba(143, 143, 143,0.03) 0%, rgba(143, 143, 143,0.03) 16%,transparent 16%, transparent 100%),radial-gradient(circle at 81% 56%, rgba(65, 65, 65,0.03) 0%, rgba(65, 65, 65,0.03) 12%,transparent 12%, transparent 100%),radial-gradient(circle at 26% 48%, rgba(60, 60, 60,0.03) 0%, rgba(60, 60, 60,0.03) 6%,transparent 6%, transparent 100%),radial-gradient(circle at 97% 17%, rgba(150, 150, 150,0.03) 0%, rgba(150, 150, 150,0.03) 56%,transparent 56%, transparent 100%),radial-gradient(circle at 50% 100%, rgba(25, 25, 25,0.03) 0%, rgba(25, 25, 25,0.03) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 55% 52%, rgba(69, 69, 69,0.03) 0%, rgba(69, 69, 69,0.03) 6%,transparent 6%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));
  .header-box {
    font-size:16px;
  }

  .value{
    font-size:26px;
  }

  .icon-block{
    color: #777777;
    text-align: center;
    font-size: 30px;
    padding-top: 7px;
    margin: 0;
    position: absolute;
    right: 4%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
}

.el-dialog {
margin-top: 6vh !important;
}