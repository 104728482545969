.h-100 {
  height: 100%;
}

.grid-auto {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1rem;

  @for $i from 1 through 12 {
    .grid-cell-#{$i} {
      grid-column: span $i;
    }
  }
}

.grid-10 {
  grid-template-columns: repeat(10, 1fr);
}

.grid-no-gap {
  grid-gap: unset;
}

.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem;
}

// FLEXBOX grid
// http://flexboxgrid.com/
// github: https://github.com/kristoferjoseph/flexboxgrid
