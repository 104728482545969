<template>
  <section>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xl-12 order-lg-1 order-xl-1">
        <el-card v-loading="loading">
          <div class="row">
            <div
              v-if="showAgreementNumberChange === false"
              class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1 my-auto"
            >
              <h1>
                Produkt: {{ productType }} {{ item.is_draft ? '- Wersja robocza dokumentu' : '' }}
              </h1>
              <span>Numer umowy produktu: {{ item.product_number }}
                <el-tooltip
                  v-if="$can('admin.sales.number-change')"
                  placement="top"
                  content="Kliknij by zmienić numer umowy"
                >
                  <el-button
                    type="text"
                    size="default"
                    class="text-success"
                    icon="far fa-edit"
                    @click="actionChangeProductNumber(item.product_number, $event)"
                  >
                    <slot></slot>
                  </el-button>
                </el-tooltip>
              </span>
              <div>
                <small>Podpisano {{ item.agreement_sign }}</small>
              </div>
              <dl v-if="item.user">
                <dt>Pracownik</dt>
                <dd>
                  {{ item.user.full_name }}
                  <el-popover
                    v-if="$can('admin.sales.user')"
                    v-model="updateUserVisible"
                    placement="top"
                  >
                    <div>
                      <autocomplete
                        v-model="NewUser"
                        placetext="Wybierz nową osobę"
                        group="all"
                        name="user_id"
                      />
                      <el-button
                        type="primary"
                        size="mini"
                        @click="updateUser"
                      >
                        Przypisz
                      </el-button>
                    </div>
                    <custom-button
                      :id="item.id"
                      slot="reference"
                      :circle="true"
                      type="primary"
                      size="mini"
                      title="Przypisz"
                      permission="admin.sales.user"
                      resource="admin.sales.update"
                    />
                  </el-popover>
                </dd>
              </dl>
              <dl v-if="item.attached_agreement">
                <dt>Dopięty do umowy - ID {{ item.attached_agreement }}</dt>
                <dd>
                  <el-button
                    type="text"
                    @click="toProduct(item.attached_agreement)"
                  >
                    Przejdź do umowy PV
                  </el-button>
                </dd>
              </dl>
              <dl v-if="!item.is_draft">
                <dt>Podpięta oferta</dt>
                <dd v-if="!item.offer_id">
                  Brak podpiętej oferty
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Zmień podpiętą ofertę"
                    placement="top"
                  >
                    <el-button
                      type="text"
                      @click="openOfferAttachDialog"
                    >
                      <i class="fas fa-edit"></i>
                    </el-button>
                  </el-tooltip>
                </dd>
                <dd v-else>
                  <el-link @click="moveToOffer">
                    Oferta # {{ item.offer_id }}
                  </el-link>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Zmień podpiętą ofertę"
                    placement="top"
                  >
                    <el-button
                      type="text"
                      @click="openOfferAttachDialog"
                    >
                      <i class="fas fa-edit"></i>
                    </el-button>
                  </el-tooltip>
                </dd>
              </dl>
            </div>
            <div
              v-else
              class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1 my-auto"
            >
              <div>
                <h3>Zmiana numeru umowy</h3>
                <hr>
              </div>
              <custom-form-item
                :errors="formErrors"
                prop="new_agreement_number"
                label="Wpisz nowy numer umowy"
              >
                <el-input
                  v-model="newAgreementNumber"
                  name="new_agreement_number"
                />
              </custom-form-item>
              <el-button
                type="primary"
                @click="updateProductNumber"
              >
                Aktualizuj numer umowy
              </el-button>
              <el-button @click="cancelChanging">
                Anuluj
              </el-button>
            </div>
            <div class="offset-lg-6 offset-xl-6 col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
              <div>
                <h6 class="my-0">
                  Operacje
                </h6>
                <hr>
              </div>
              <div class="mt-2">
                <el-button
                  v-if="canEdit() && $can('admin.sales.update')"
                  type="primary"
                  class="w-100"
                  @click="actionEdit()"
                >
                  <i class="fas fa-edit"></i> Edycja produktu
                </el-button>
              </div>
              <div class="mt-2">
                <el-button
                  v-if="$can('admin.sales.delete')"
                  class="w-100"
                  type="danger"
                  @click="deleteAction()"
                >
                  <i class="far fa-trash-alt"></i> Usuwanie produktu
                </el-button>
              </div>
              <div class="mt-2">
                <el-button
                  class="w-100"
                  type="info"
                  @click="backList"
                >
                  <i class="far fa-long-arrow-left"></i> Powrót do listy
                </el-button>
              </div>
            </div>
          </div>
        </el-card>
        <el-card
          v-if="item.is_draft"
          class="mt-3"
        >
          <div class="d-flex justify-content-between align-content-center">
            <h4 class="my-0">
              Dokument jest w wersji roboczej.
            </h4>
            <i
              style="font-size:36px"
              class="fas fa-exclamation-triangle"
            ></i>
          </div>
        </el-card>
      </div>
    </div>
    <div
      v-if="item.type_product === 1 && !item.is_draft"
      class="row"
    >
      <div class="col-lg-12 col-md-12 col-xl-12 order-lg-1 order-xl-1 mt-3">
        <el-card v-loading="loading">
          <div>
            <h5 class="my-0">
              Statusy umowy
            </h5>
            <hr>
          </div>
          <div class="row">
            <div class="col-lg-2 col-md-2 col-xl-2 order-lg-1 order-xl-1">
              <dl v-if="statusesGroup.document_pv">
                <dt>Dokumenty PV</dt>
                <dd>
                  {{ statusesGroup.document_pv.label }}
                  <el-popover
                    v-model="updateStatusVisible"
                    placement="top"
                  >
                    <el-radio-group
                      v-model="newStatus"
                      name="status"
                    >
                      <el-radio
                        v-for="(val, key) in $config.agreementSettings.data.statuses_office.settings"
                        :key="key"
                        :label="parseInt(key)"
                      >
                        {{ val.label }}
                      </el-radio>
                    </el-radio-group>
                    <div>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="updateStatusAgreement('documents_status_office')"
                      >
                        Zmień status
                      </el-button>
                      <el-button
                        size="mini"
                        type="text"
                        @click="updateStatusVisible = false"
                      >
                        anuluj
                      </el-button>
                    </div>
                    <custom-button
                      :id="item.id"
                      slot="reference"
                      :circle="true"
                      type="primary"
                      size="mini"
                      title="Zmień status"
                      permission="admin.sales.update"
                      resource="admin.sales.update"
                    />
                  </el-popover>
                </dd>
              </dl>
            </div>
            <div class="col-lg-2 col-md-2 col-xl-2 order-lg-1 order-xl-1">
              <dl v-if="statusesGroup.document_pv_credit">
                <dt>Dokumenty Kredytowe PV</dt>
                <dd>
                  {{ statusesGroup.document_pv_credit.label }}
                  <el-popover
                    v-model="updateStatusVisible_2"
                    placement="top"
                  >
                    <el-radio-group
                      v-model="newStatus"
                      name="status"
                    >
                      <el-radio
                        v-for="(val, key) in $config.agreementSettings.data.statuses_office.settings"
                        :key="key"
                        :label="parseInt(key)"
                      >
                        {{ val.label }}
                      </el-radio>
                    </el-radio-group>

                    <div>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="updateStatusAgreement('documents_credit_status_office')"
                      >
                        Zmień status
                      </el-button>
                      <el-button
                        size="mini"
                        type="text"
                        @click="updateStatusVisible_2 = false"
                      >
                        anuluj
                      </el-button>
                    </div>
                    <custom-button
                      :id="item.id"
                      slot="reference"
                      :circle="true"
                      type="primary"
                      size="mini"
                      title="Zmień status"
                      permission="admin.sales.update"
                      resource="admin.sales.update"
                    />
                  </el-popover>
                </dd>
              </dl>
            </div>
            <div class="col-lg-2 col-md-2 col-xl-2 order-lg-1 order-xl-1">
              <dl v-if="statusesGroup.document_bo">
                <dt>BackOffice Dokumenty PV</dt>
                <dd>
                  {{ statusesGroup.document_bo.label }}
                  <el-popover
                    v-model="updateStatusVisible_3"
                    placement="top"
                  >
                    <el-radio-group
                      v-model="newStatus"
                      name="status"
                    >
                      <el-radio
                        v-for="(val, key) in $config.agreementSettings.data.statuses_backoffice.settings"
                        :key="key"
                        :label="parseInt(key)"
                      >
                        {{ val.label }}
                      </el-radio>
                    </el-radio-group>

                    <div>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="updateStatusAgreement('documents_status_backoffice')"
                      >
                        Zmień status
                      </el-button>
                      <el-button
                        size="mini"
                        type="text"
                        @click="updateStatusVisible_3 = false"
                      >
                        anuluj
                      </el-button>
                    </div>
                    <custom-button
                      :id="item.id"
                      slot="reference"
                      :circle="true"
                      type="primary"
                      size="mini"
                      title="Zmień status"
                      permission="admin.sales.update"
                      resource="admin.sales.update"
                    />
                  </el-popover>
                </dd>
              </dl>
            </div>
            <div class="col-lg-2 col-md-2 col-xl-2 order-lg-1 order-xl-1">
              <dl v-if="statusesGroup.document_bo_credit">
                <dt>BackOffice Dokumenty Kredytowe</dt>
                <dd>
                  {{ statusesGroup.document_bo_credit.label }}
                  <el-popover
                    v-model="updateStatusVisible_4"
                    placement="top"
                  >
                    <el-radio-group
                      v-model="newStatus"
                      name="status"
                    >
                      <el-radio
                        v-for="(val, key) in $config.agreementSettings.data.statuses_backoffice.settings"
                        :key="key"
                        :label="parseInt(key)"
                      >
                        {{ val.label }}
                      </el-radio>
                    </el-radio-group>

                    <div>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="updateStatusAgreement('documents_credit_status_backoffice')"
                      >
                        Zmień status
                      </el-button>
                      <el-button
                        size="mini"
                        type="text"
                        @click="updateStatusVisible_4 = false"
                      >
                        anuluj
                      </el-button>
                    </div>
                    <custom-button
                      :id="item.id"
                      slot="reference"
                      :circle="true"
                      type="primary"
                      size="mini"
                      title="Zmień status"
                      permission="admin.sales.update"
                      resource="admin.sales.update"
                    />
                  </el-popover>
                </dd>
              </dl>
            </div>
            <div class="col-lg-2 col-md-2 col-xl-2 order-lg-1 order-xl-1">
              <dl v-if="statusesGroup.document_4eco">
                <dt>4eco Dokumenty PV</dt>
                <dd>
                  {{ statusesGroup.document_4eco.label }}
                  <el-popover
                    v-model="updateStatusVisible_5"
                    placement="top"
                  >
                    <el-radio-group
                      v-model="newStatus"
                      name="status"
                    >
                      <el-radio
                        v-for="(val, key) in $config.agreementSettings.data.statuses_4eco.settings"
                        :key="key"
                        :label="parseInt(key)"
                      >
                        {{ val.label }}
                      </el-radio>
                    </el-radio-group>

                    <div>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="updateStatusAgreement('documents_status_provider')"
                      >
                        Zmień status
                      </el-button>
                      <el-button
                        size="mini"
                        type="text"
                        @click="updateStatusVisible_5 = false"
                      >
                        anuluj
                      </el-button>
                    </div>
                    <custom-button
                      :id="item.id"
                      slot="reference"
                      :circle="true"
                      type="primary"
                      size="mini"
                      title="Zmień status"
                      permission="admin.sales.update"
                      resource="admin.sales.update"
                    />
                  </el-popover>
                </dd>
              </dl>
            </div>
            <div class="col-lg-2 col-md-2 col-xl-2 order-lg-1 order-xl-1">
              <dl v-if="statusesGroup.document_4eco_credit">
                <dt>4eco Dokumenty Kredytowe</dt>
                <dd>
                  {{ statusesGroup.document_4eco_credit.label }}
                  <el-popover
                    v-model="updateStatusVisible_6"
                    placement="top"
                  >
                    <el-radio-group
                      v-model="newStatus"
                      name="status"
                    >
                      <el-radio
                        v-for="(val, key) in $config.agreementSettings.data.statuses_4eco.settings"
                        :key="key"
                        :label="parseInt(key)"
                      >
                        {{ val.label }}
                      </el-radio>
                    </el-radio-group>

                    <div>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="updateStatusAgreement('documents_status_provider')"
                      >
                        Zmień status
                      </el-button>
                      <el-button
                        size="mini"
                        type="text"
                        @click="updateStatusVisible_6 = false"
                      >
                        anuluj
                      </el-button>
                    </div>
                    <custom-button
                      :id="item.id"
                      slot="reference"
                      :circle="true"
                      type="primary"
                      size="mini"
                      title="Zmień status"
                      permission="admin.sales.update"
                      resource="admin.sales.update"
                    />
                  </el-popover>
                </dd>
              </dl>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <el-tabs
      v-model="activeName"
      class="mt-3"
    >
      <el-tab-pane
        v-if="item.type_product === 1"
        label="Umowa PV"
        name="first"
      >
        <div
          v-if="creatorActive"
          class="row"
        >
          <div
            class="col-lg-12 col-md-12 col-xl-12 order-lg-1 order-xl-1 mt-3"
          >
            <el-card>
              <div
                class="d-flex justify-content-between align-content-center"
              >
                <h5 class="mt-2">
                  Tryb tworzenia aneksu - {{ creatorAneksItem.product_number }}
                </h5>
                <div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Przerwij tworzenie aneksu"
                    placement="top"
                  >
                    <el-button
                      size="small"
                      type="danger"
                      @click="deleteAneksDraft()"
                    >
                      <i class="fas fa-undo-alt"></i>
                    </el-button>
                  </el-tooltip>
                  <el-button
                    size="small"
                    type="primary"
                    @click="completeAgreement()"
                  >
                    Zapisz zmiany
                  </el-button>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-9 col-md-12 col-xl-9 mt-3">
            <el-card
              v-if="item.offer"
              v-loading="loading"
            >
              <div>
                <h5 class="mt-0 pt-0">
                  Specyfikacja techniczna
                </h5>
                <hr>
              </div>
              <div class="row">
                <!--                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">-->
                <!--                  &lt;!&ndash;                  <custom-agreement-value&ndash;&gt;-->
                <!--                  &lt;!&ndash;                    :default-value="$config.agreementSettings.data.construction.settings[item.addons.construction]"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                    :changed-value="$config.agreementSettings.data.construction.settings[item.changes.addons.construction]"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                    name-module="Konstrukcja"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                  >&ndash;&gt;-->
                <!--                  &lt;!&ndash;                    <template #button-section>&ndash;&gt;-->
                <!--                  &lt;!&ndash;                      <el-button&ndash;&gt;-->
                <!--                  &lt;!&ndash;                        v-if="creatorActive"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                        type="primary"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                        size="mini"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                        round&ndash;&gt;-->
                <!--                  &lt;!&ndash;                        @click="editForAneks(item.addons.construction, 'addons.construction', 'list', $config.agreementSettings.data.construction.settings)"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                      >&ndash;&gt;-->
                <!--                  &lt;!&ndash;                        <i class="far fa-edit"></i>&ndash;&gt;-->
                <!--                  &lt;!&ndash;                      </el-button>&ndash;&gt;-->
                <!--                  &lt;!&ndash;                    </template>&ndash;&gt;-->
                <!--                  &lt;!&ndash;                  </custom-agreement-value>&ndash;&gt;-->
                <!--                </div>-->
                <!--                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">-->
                <!--                  &lt;!&ndash;                  <custom-agreement-value&ndash;&gt;-->
                <!--                  &lt;!&ndash;                    :default-value="$config.agreementSettings.data.installation_place.settings[item.addons.installation_place]"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                    :changed-value="$config.agreementSettings.data.installation_place.settings[item.changes.addons.installation_place]"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                    name-module="Miejsce montazu instalacji"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                  >&ndash;&gt;-->
                <!--                  &lt;!&ndash;                    <template #button-section>&ndash;&gt;-->
                <!--                  &lt;!&ndash;                      <el-button&ndash;&gt;-->
                <!--                  &lt;!&ndash;                        v-if="creatorActive"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                        type="primary"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                        size="mini"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                        round&ndash;&gt;-->
                <!--                  &lt;!&ndash;                        @click="editForAneks(item.addons.installation_place, 'addons.installation_place', 'list',$config.agreementSettings.data.installation_place.settings)"&ndash;&gt;-->
                <!--                  &lt;!&ndash;                      >&ndash;&gt;-->
                <!--                  &lt;!&ndash;                        <i class="far fa-edit"></i>&ndash;&gt;-->
                <!--                  &lt;!&ndash;                      </el-button>&ndash;&gt;-->
                <!--                  &lt;!&ndash;                    </template>&ndash;&gt;-->
                <!--                  &lt;!&ndash;                  </custom-agreement-value>&ndash;&gt;-->
                <!--                </div>-->
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <!--                  <custom-agreement-value-->
                  <!--                    :default-value="item.addons.power_installation"-->
                  <!--                    :changed-value="item.changes.addons.power_installation"-->
                  <!--                    name-module="Moc instalacji [kWp]"-->
                  <!--                  />-->

                  <dl>
                    <dt>Moc instalacji [kWp]</dt>
                    <dd>{{ item.offer.kwp }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <custom-agreement-value
                    :default-value="item.addons.phase"
                    :changed-value="item.changes.addons.phase"
                    name-module="Faza"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.phase,
                                             'addons.phase',
                                             'numeric')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <!--                  <custom-agreement-value-->
                  <!--                    :default-value="$config.agreementSettings.data.type_module.settings[item.addons.type_module]"-->
                  <!--                    :changed-value="$config.agreementSettings.data.type_module.settings[item.changes.addons.type_module]"-->
                  <!--                    name-module="Typ modułu"-->
                  <!--                  >-->
                  <!--                    <template #button-section>-->
                  <!--                      <el-button-->
                  <!--                        v-if="creatorActive"-->
                  <!--                        type="primary"-->
                  <!--                        size="mini"-->
                  <!--                        round-->
                  <!--                        @click="editForAneks(item.addons.type_module,-->
                  <!--                                             'addons.type_module',-->
                  <!--                                             'list',-->
                  <!--                                             $config.agreementSettings.data.type_module.settings)"-->
                  <!--                      >-->
                  <!--                        <i class="far fa-edit"></i>-->
                  <!--                      </el-button>-->
                  <!--                    </template>-->
                  <!--                  </custom-agreement-value>-->
                  <dl>
                    <dt>Rodzaj modułu</dt>
                    <dd>{{ item.offer.panel }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <!--                  <custom-agreement-value-->
                  <!--                    :default-value="item.addons.modules_quantity"-->
                  <!--                    :changed-value="item.changes.addons.modules_quantity"-->
                  <!--                    name-module="Ilość modułów"-->
                  <!--                  >-->
                  <!--                    <template #button-section>-->
                  <!--                      <el-button-->
                  <!--                        v-if="creatorActive"-->
                  <!--                        type="primary"-->
                  <!--                        size="mini"-->
                  <!--                        round-->
                  <!--                        @click="editForAneks(item.addons.modules_quantity, 'addons.modules_quantity', 'numeric')"-->
                  <!--                      >-->
                  <!--                        <i class="far fa-edit"></i>-->
                  <!--                      </el-button>-->
                  <!--                    </template>-->
                  <!--                  </custom-agreement-value>-->
                  <dl>
                    <dt>Ilość modułów</dt>
                    <dd>{{ item.offer.panel_count }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <!--                  <custom-agreement-value-->
                  <!--                    :default-value="$config.agreementSettings.data.type_inverter.settings[item.addons.type_inverter]"-->
                  <!--                    :changed-value="$config.agreementSettings.data.type_inverter.settings[item.changes.addons.type_inverter]"-->
                  <!--                    name-module="Typ falownika"-->
                  <!--                  >-->
                  <!--                    <template #button-section>-->
                  <!--                      <el-button-->
                  <!--                        v-if="creatorActive"-->
                  <!--                        type="primary"-->
                  <!--                        size="mini"-->
                  <!--                        round-->
                  <!--                        @click="editForAneks(item.addons.type_inverter,-->
                  <!--                                             'addons.type_inverter',-->
                  <!--                                             'list',-->
                  <!--                                             $config.agreementSettings.data.type_inverter.settings)"-->
                  <!--                      >-->
                  <!--                        <i class="far fa-edit"></i>-->
                  <!--                      </el-button>-->
                  <!--                    </template>-->
                  <!--                  </custom-agreement-value>-->
                  <dl>
                    <dt>Rodzaj falownika</dt>
                    <dd>{{ item.offer.inverter }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <custom-agreement-value
                    :default-value="item.addons.mppt"
                    :changed-value="item.changes.addons.mppt"
                    name-module="Ilość MPPT"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.mppt, 'addons.mppt', 'text')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
              </div>
              <div class="row">
                <div>
                  <h6>Użyte konstrukcje</h6>
                </div>
                <div>
                  <ul>
                    <li v-for="element in item.offer.construction_slopes">
                      {{ $config.constructions[element.construction_id] }} - {{ element.panel_count }} szt.
                    </li>
                  </ul>
                </div>
              </div>
            </el-card>
            <el-card class="mt-3">
              <div class="header-section">
                <h5 class="my-0">
                  Specyfikacja podłoża
                </h5>
                <hr>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.installation_place_type.settings[item.addons.installation_place_type]"
                    :changed-value="$config.agreementSettings.data.installation_place_type.settings[item.changes.addons.installation_place_type]"
                    name-module="Rodzaj podłoża"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.installation_place_type,
                                             'addons.installation_place_type',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.mounting_on.settings[item.addons.mounting_on]"
                    :changed-value="$config.agreementSettings.data.mounting_on.settings[item.changes.addons.mounting_on]"
                    name-module="Montaż na"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.mounting_on,
                                             'addons.mounting_on',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.hinders_access_roof.settings[item.addons.hinders_access_roof]"
                    :changed-value="$config.agreementSettings.data.hinders_access_roof.settings[item.changes.addons.hinders_access_roof]"
                    name-module="Utrutdnienia w dostępie do dachu"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.hinders_access_roof,
                                             'addons.hinders_access_roof',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
              </div>
            </el-card>
            <el-card
              v-if="item.addons.installation_place === 0"
              v-loading="loading"
              class="mt-3"
            >
              <div>
                <h5 class="mt-0 pt-0">
                  Specyfikacja dachu
                </h5>
                <hr>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-12 col-xl-3 ">
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.direct_of_the_slope.settings[item.addons.direct_of_the_slope]"
                    :changed-value="$config.agreementSettings.data.direct_of_the_slope.settings[item.changes.addons.direct_of_the_slope]"
                    name-module="Kierunek połaci"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.direct_of_the_slope,
                                             'addons.direct_of_the_slope',
                                             'list',
                                             $config.agreementSettings.data.direct_of_the_slope.settings)"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 ">
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.roof_insulation.settings[item.addons.roof_insulation]"
                    :changed-value="$config.agreementSettings.data.roof_insulation.settings[item.changes.addons.roof_insulation]"
                    name-module="Izolacja dachu"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.roof_insulation,
                                             'addons.roof_insulation',
                                             'list',
                                             $config.agreementSettings.data.roof_insulation.settings)"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.insulation_thickness"
                  class="col-lg-3 col-md-12 col-xl-3 "
                >
                  <custom-agreement-value
                    :default-value="item.addons.insulation_thickness"
                    :changed-value="item.changes.addons.insulation_thickness"
                    name-module="Grubość izolacji [cm]"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.insulation_thickness,
                                             'addons.insulation_thickness',
                                             'numeric')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.insulation_material"
                  class="col-lg-3 col-md-12 col-xl-3 "
                >
                  <custom-agreement-value
                    :default-value="item.addons.insulation_material"
                    :changed-value="item.changes.addons.insulation_material"
                    name-module="Materiał izolacji"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.insulation_material,
                                             'addons.insulation_material',
                                             'text')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 ">
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.lightning_protection.settings[item.addons.lightning_protection]"
                    :changed-value="$config.agreementSettings.data.lightning_protection.settings[item.changes.addons.lightning_protection]"
                    name-module="Instalacja odgromowa"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.lightning_protection,
                                             'addons.lightning_protection',
                                             'list',
                                             $config.agreementSettings.data.lightning_protection.settings)"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 ">
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.roof_type.settings[item.addons.roof_type]"
                    :changed-value="$config.agreementSettings.data.roof_type.settings[item.changes.addons.roof_type]"
                    name-module="Rodzaj dachu"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.roof_type,
                                             'addons.roof_type',
                                             'list',
                                             $config.agreementSettings.data.roof_type.settings)"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 ">
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.roof_pattern.settings[item.addons.roof_pattern]"
                    :changed-value="$config.agreementSettings.data.roof_pattern.settings[item.changes.addons.roof_pattern]"
                    name-module="Wzór dachu"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.roof_pattern,
                                             'addons.roof_pattern',
                                             'list',
                                             $config.agreementSettings.data.roof_pattern.settings)"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 ">
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.material_roof.settings[item.addons.material_roof]"
                    :changed-value="$config.agreementSettings.data.material_roof.settings[item.changes.addons.material_roof]"
                    name-module="Materiał Krokwi/płatw."
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.material_roof,
                                             'addons.material_roof',
                                             'list',
                                             $config.agreementSettings.data.material_roof.settings)"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 ">
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.roof_sheathing.settings[item.addons.roof_sheathing]"
                    :changed-value="$config.agreementSettings.data.roof_sheathing.settings[item.changes.addons.roof_sheathing]"
                    name-module="Poszycie dachu"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.roof_sheathing,
                                             'addons.roof_sheathing',
                                             'list',
                                             $config.agreementSettings.data.roof_sheathing.settings)"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.trapezoidal_bridge"
                  class="col-lg-3 col-md-12 col-xl-3 "
                >
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.trapezoidal_bridge.settings[item.addons.trapezoidal_bridge]"
                    :changed-value="$config.agreementSettings.data.trapezoidal_bridge.settings[item.changes.addons.trapezoidal_bridge]"
                    name-module="Mostek trapezowy"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.trapezoidal_bridge,
                                             'addons.trapezoidal_bridge',
                                             'list',
                                             $config.agreementSettings.data.trapezoidal_bridge.settings)"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.tile_size_a"
                  class="col-lg-3 col-md-12 col-xl-3 "
                >
                  <dl>
                    <dt>Wymiary pojedynczej dachówki</dt>
                    <dd>{{ item.addons.tile_size_a }} x {{ item.addons.tile_size_b }} [cm x cm]</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.flat_roof_system"
                  class="col-lg-3 col-md-12 col-xl-3 "
                >
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.flat_roof_system.settings[item.addons.flat_roof_system]"
                    :changed-value="$config.agreementSettings.data.flat_roof_system.settings[item.changes.addons.flat_roof_system]"
                    name-module="System na dach płaski "
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.flat_roof_system,
                                             'addons.flat_roof_system',
                                             'list',
                                             $config.agreementSettings.data.flat_roof_system.settings)"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>

                <div
                  v-if="item.addons.distance_from_home_pv"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <custom-agreement-value
                    :default-value="item.addons.distance_from_home_pv"
                    :changed-value="item.changes.addons.distance_from_home_pv"
                    name-module="Odległość od domu dla montażu [m]"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.distance_from_home_pv,
                                             'addons.distance_from_home_pv',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.height_from_base_roof"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <custom-agreement-value
                    :default-value="item.addons.height_from_base_roof"
                    :changed-value="item.changes.addons.height_from_base_roof"
                    name-module="Wysokość od podstawy dachu [m]"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.height_from_base_roof,
                                             'addons.height_from_base_roof',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.usable_area_roof"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <custom-agreement-value
                    :default-value="item.addons.usable_area_roof"
                    :changed-value="item.changes.addons.usable_area_roof"
                    name-module="Powierzchnia użytkowa dachu [m2]"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.usable_area_roof,
                                             'addons.usable_area_roof',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.dimensions_roof_tile_module"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <custom-agreement-value
                    :default-value="item.addons.dimensions_roof_tile_module"
                    :changed-value="item.changes.addons.dimensions_roof_tile_module"
                    name-module="Wymiary modułu blachodachówki - RozmiarA x RozmiarB"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.dimensions_roof_tile_module,
                                             'addons.dimensions_roof_tile_module',
                                             'text')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.roof_construction"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <custom-agreement-value
                    :default-value="item.addons.roof_construction"
                    :changed-value="item.changes.addons.roof_construction"
                    name-module="Konstrukcja dachu"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.roof_construction,
                                             'addons.roof_construction',
                                             'list',
                                             [])"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.panels_arranged"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <custom-agreement-value
                    :default-value="item.addons.panels_arranged"
                    :changed-value="item.changes.addons.panels_arranged"
                    name-module="Sposób ułożenia paneli"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.panels_arranged,
                                             'addons.panels_arranged',
                                             'list',[])"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.is_ethernet_required !== null"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <dl>
                    <dt>Czy dodać kabel internetowy?</dt>
                    <dd>{{ item.addons.is_ethernet_required ? 'Tak' : 'Nie' }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.is_cut_required !== null"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <dl>
                    <dt>Czy przekop jest wymagany?</dt>
                    <dd>{{ item.addons.is_cut_required ? 'Tak' : 'Nie' }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.cut_length"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <custom-agreement-value
                    :default-value="item.addons.cut_length"
                    :changed-value="item.changes.addons.cut_length"
                    name-module="Długość przekopu"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.cut_length,
                                             'addons.cut_length',
                                             'text')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.difficulties_cut"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <custom-agreement-value
                    :default-value="item.addons.difficulties_cut"
                    :changed-value="item.changes.addons.difficulties_cut"
                    name-module="Utrudnienia w miejscu przekopu (opis)"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.difficulties_cut,
                                             'addons.difficulties_cut',
                                             'text')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.pv_installation_place"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <custom-agreement-value
                    :default-value="item.addons.pv_installation_place"
                    :changed-value="item.changes.addons.pv_installation_place"
                    name-module="Miejsce wpięcia instalacji"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.pv_installation_place,
                                             'addons.pv_installation_place',
                                             'list',[])"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.thickness_cable"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <custom-agreement-value
                    :default-value="item.addons.thickness_cable"
                    :changed-value="item.changes.addons.thickness_cable"
                    name-module="Grubość kabla siłowego"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.thickness_cable,
                                             'addons.thickness_cable',
                                             'text')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.monthly_electricity_consumption"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <custom-agreement-value
                    :default-value="item.addons.monthly_electricity_consumption"
                    :changed-value="item.changes.addons.monthly_electricity_consumption"
                    name-module="Miesięczne zużycie energii elektrycznej [kWh/ms]"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.monthly_electricity_consumption,
                                             'addons.monthly_electricity_consumption',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.tech_condition_roof_sheathing"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <custom-agreement-value
                    :default-value="item.addons.tech_condition_roof_sheathing"
                    :changed-value="item.changes.addons.tech_condition_roof_sheathing"
                    name-module="Stan techniczny poszycia dachowego"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.tech_condition_roof_sheathing,
                                             'addons.tech_condition_roof_sheathing',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.addons.inverter_mounting_place"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <dl>
                    <dt>Miejsce montażu falownika</dt>
                    <dd>{{ item.addons.inverter_mounting_place }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.is_same_building_installation !== null"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <dl>
                    <dt>Panele i falownik w tym samym budynku?</dt>
                    <dd>{{ item.addons.is_same_building_installation ? 'Tak' : 'Nie' }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.distance_from_home_inverter"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <dl>
                    <dt>Odległość budynku od falownika</dt>
                    <dd>{{ item.addons.distance_from_home_inverter }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.is_possible_shading !== null"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <dl>
                    <dt>Czy występuje zacienienie ?</dt>
                    <dd>{{ item.addons.is_possible_shading ? 'Tak' : 'Nie' }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.shading_percent"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <dl>
                    <dt>Procent zacienienia</dt>
                    <dd>{{ item.addons.shading_percent }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.shading_description"
                  class="col-lg-3 col-md-12 col-xl-3"
                >
                  <dl>
                    <dt>Krótki opis zacienienia</dt>
                    <dd>{{ item.addons.shading_description }}</dd>
                  </dl>
                </div>
              </div>
            </el-card>
            <el-card class="mt-3">
              <div>
                <h5 class="mt-0 pt-0">
                  Specyfikacja budynku
                </h5>
                <hr>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-12 col-xl-3 ">
                  <custom-agreement-value
                    :default-value="item.addons.building_height"
                    :changed-value="item.changes.addons.building_height"
                    name-module="Wysokość budynku [m]"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.building_height,
                                             'addons.building_height',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div
                  v-if="item.slope_size"
                  class="col-lg-3 col-md-12 col-xl-3 "
                >
                  <custom-agreement-value
                    :default-value="item.addons.slope_size"
                    :changed-value="item.changes.addons.slope_size"
                    name-module="Wysokość połaci [m]"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.slope_size,
                                             'addons.slope_size',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <custom-agreement-value
                    :default-value="item.addons.slope_degree"
                    :changed-value="item.changes.addons.slope_degree"
                    name-module="Kąt nachylenia połaci [°]"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.slope_degree,
                                             'addons.slope_degree',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.dimensional_accuracy.settings[item.addons.dimensional_accuracy]"
                    :changed-value="$config.agreementSettings.data.dimensional_accuracy.settings[item.changes.addons.dimensional_accuracy]"
                    name-module="Dokładność wymiarów"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.dimensional_accuracy,
                                             'addons.dimensional_accuracy',
                                             'list',
                                             $config.agreementSettings.data.dimensional_accuracy.settings)"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
              </div>
            </el-card>
            <el-card
              v-if="item.addons.installation_place === 1"
              v-loading="loading"
              class="mt-3"
            >
              <div>
                <h5 class="mt-0 pt-0">
                  Specyfikacja Gruntu
                </h5>
                <hr>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-12 col-xl-6 order-lg-1 order-xl-1">
                  <custom-agreement-value
                    :default-value="item.addons.side_1th_length"
                    :changed-value="item.changes.addons.side_1th_length"
                    name-module="Dł. 1 boku miejsca pod instalację [m] "
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.side_1th_length,
                                             'addons.side_1th_length',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div class="col-lg-6 col-md-12 col-xl-6 order-lg-1 order-xl-1">
                  <custom-agreement-value
                    :default-value="item.addons.side_2nd_length"
                    :changed-value="item.changes.addons.side_2nd_length"
                    name-module="Dł. 2 boku miejsca pod instalację [m] "
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.side_2nd_length,
                                             'addons.side_2nd_length',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
                <div class="col-lg-6 col-md-12 col-xl-6 order-lg-1 order-xl-1">
                  <custom-agreement-value
                    :default-value="$config.agreementSettings.data.type_of_ground.settings[item.addons.type_of_ground]"
                    :changed-value="$config.agreementSettings.data.type_of_ground.settings[item.changes.addons.type_of_ground]"
                    name-module="Rodzaj podłoża"
                  >
                    <template #button-section>
                      <el-button
                        v-if="creatorActive"
                        type="primary"
                        size="mini"
                        round
                        @click="editForAneks(item.addons.type_of_ground,
                                             'addons.type_of_ground',
                                             'decimal')"
                      >
                        <i class="far fa-edit"></i>
                      </el-button>
                    </template>
                  </custom-agreement-value>
                </div>
              </div>
            </el-card>
            <el-card
              v-loading="loading"
              class="mt-3"
            >
              <div>
                <h5 class="mt-0 pt-0">
                  Specyfikacja finansowa
                </h5>
                <hr>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Rodzaj finansowania</dt>
                    <dd>
                      {{ financial.type_financial.label }}
                    </dd>
                  </dl>
                </div>
                <div class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Opodatkowanie VAT</dt>
                    <dd>{{ financial.type_vat.label }}</dd>
                  </dl>
                </div>
                <div class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Kwota netto</dt>
                    <dd>{{ item.addons.price_netto }} PLN</dd>
                  </dl>
                </div>
                <div class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Kwota brutto</dt>
                    <dd>{{ item.addons.price_brutto }} PLN</dd>
                  </dl>
                </div>
              </div>
              <div>
                <hr>
              </div>
              <div
                v-if="item.addons.type_financial !== 1"
                class="row"
              >
                <div class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Transza pierwsza</dt>
                    <dd>{{ item.addons.cash_1 }} PLN</dd>
                  </dl>
                </div>
                <div class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Transza druga</dt>
                    <dd>{{ item.addons.cash_2 }} PLN</dd>
                  </dl>
                </div>
                <div class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Transza trzecia</dt>
                    <dd>{{ item.addons.cash_3 }} PLN</dd>
                  </dl>
                </div>
              </div>
              <div v-if="item.addons.type_financial === 1">
                <hr>
              </div>
              <div
                v-if="item.addons.type_financial === 1"
                class="row"
              >
                <div
                  v-if="item.addons.bank_1"
                  class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Kredytodawca nr 1</dt>
                    <dd>{{ item.addons.bank_1 }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.credit_cash_1"
                  class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Kwota kredytu nr 1</dt>
                    <dd>{{ item.addons.credit_cash_1 }} PLN</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.credit_1_started_date"
                  class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Data rozpoczecia kredytu nr 1</dt>
                    <dd>
                      {{ item.addons.credit_1_started_date }}
                    </dd>
                  </dl>
                </div>

                <div
                  v-if="item.addons.bank_2"
                  class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Kredytodawca nr 2</dt>
                    <dd>{{ item.addons.bank_2 }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.credit_cash_2"
                  class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Kwota kredytu nr 2</dt>
                    <dd>{{ item.addons.credit_cash_2 }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.credit_2_started_date"
                  class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Data rozpoczecia kredytu nr 2</dt>
                    <dd>
                      {{ item.addons.credit_2_started_date }}
                    </dd>
                  </dl>
                </div>
              </div>
              <div
                v-if="item.addons.lessor"
                class="row"
              >
                <div class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Leasingodawca</dt>
                    <dd>{{ item.addons.lessor }}</dd>
                  </dl>
                </div>
              </div>
            </el-card>
            <el-card class="mt-3">
              <div>
                <h5 class="mt-0 pt-0">
                  Specyfikacja elektryczna - nabywca faktury
                </h5>
                <hr>
              </div>
              <div class="row">
                <div
                  v-if="item.addons.name_invoice_buyer"
                  class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Nabywca z faktury za prąd</dt>
                    <dd>{{ item.addons.name_invoice_buyer }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.energy_fv_pesel"
                  class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>PESEL Nabywcy z faktury za prąd</dt>
                    <dd>{{ item.addons.energy_fv_pesel }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.energy_fv_document_id_number"
                  class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Dokument Nabywcy z faktury za prąd</dt>
                    <dd>{{ item.addons.energy_fv_document_id_number }}</dd>
                  </dl>
                </div>
              </div>
            </el-card>
            <el-card
              v-loading="loading"
              class="mt-3"
            >
              <div>
                <h5 class="mt-0 pt-0">
                  Specyfikacja elektryczna - podstawowe informacje
                </h5>
                <hr>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <dl v-if="item.addons.electrical_installation_type">
                    <dt>Typ instalacji elektrycznej</dt>
                    <dd>
                      {{
                        $config.agreementSettings.data.electrical_installation_type.settings[item.addons.electrical_installation_type].label
                      }}
                    </dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <dl v-if="item.addons.electric_provider">
                    <dt>Dostawca energii el.</dt>
                    <dd>
                      {{
                        $config.agreementSettings.data.electric_provider.settings[item.addons.electric_provider].label
                      }}
                    </dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Nr PPE</dt>
                    <dd>{{ item.addons.ppe_number }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.addons.energy_complex_agreement_number"
                  class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Nr umowy kompleksowej</dt>
                    <dd>{{ item.addons.energy_complex_agreement_number }}</dd>
                  </dl>
                </div>

                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Nr licznika</dt>
                    <dd>{{ item.addons.counter_number }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Miejsce licznika</dt>
                    <dd>{{ item.addons.counter_place }}</dd>
                  </dl>
                </div>
              </div>
            </el-card>
            <el-card class="mt-3">
              <div>
                <h5 class="mt-0 pt-0">
                  Specyfikacja elektryczna - moce i zabezpieczenia
                </h5>
                <hr>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Zabezpieczenie przedlicznikowe</dt>
                    <dd>{{ item.addons.premeter_security }} [A]</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Moc przyłączeniowa</dt>
                    <dd>{{ item.addons.power_connection }} [kW]</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Moc umowna</dt>
                    <dd>{{ item.addons.contracted_power_installation }} [kW]</dd>
                  </dl>
                </div>
              </div>
            </el-card>
            <el-card class="mt-3">
              <div>
                <h5 class="mt-0 pt-0">
                  Specyfikacja elektryczna - przyłączenia
                </h5>
                <hr>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Rodzaj przyłącza</dt>
                    <dd>
                      {{
                        $config.agreementSettings.data.type_connection.settings[item.addons.type_connection].label
                      }}
                    </dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Przyłączenie do sieci</dt>
                    <dd>
                      {{
                        $config.agreementSettings.data.connection_level.settings[item.addons.connection_level].label
                      }}
                    </dd>
                  </dl>
                </div>
              </div>
            </el-card>
            <el-card
              v-loading="loading"
              class="mt-3"
            >
              <div>
                <h5 class="my-0">
                  Dodatkowe opisy i adnotacje
                </h5>
                <hr>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-xl-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Opis rozmieszczenia</dt>
                    <dd>{{ item.addons.arrangement_description }}</dd>
                  </dl>
                </div>
                <div class="col-lg-12 col-md-12 col-xl-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Uwagi do umowy</dt>
                    <dd>{{ item.addons.due_to_agreement }}</dd>
                  </dl>
                </div>
                <div class="col-lg-12 col-md-12 col-xl-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Zaświadczenie sterfy pożarowej</dt>
                    <dd>
                      <div
                        v-if="!item.addons.document_statement_fire_zone"
                        class="statement-one-zone"
                      >
                        <span>Oświadczam, że wskazane miejsce realizacji inwestycji jest jedną główną strefą pożarową.</span>
                      </div>
                      <div
                        v-else
                        class="statement-multi-zone"
                      >
                        <p>
                          Oświadczam, że wskazane miejsce realizacji inwestycji nie jest jedyną główną strefą
                          pożarową.
                        </p>
                        <span>Jednocześnie potwierdzam przekazanie kopii pełnej dokumentacji pożarowej reprezentantowi Sprzedawcy wymienionemu w umowie, w celu prawidłowej realizacji inwestycji.</span>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </el-card>
          </div>
          <div class="col-lg-3 col-md-12 col-xl-3 mt-3">
            <el-card
              v-if="$can('admin.sales.download')"
              v-loading="loading"
            >
              <div>
                <h5 class="mt-0 pt-0">
                  Dostępne akcje
                </h5>
                <hr>
              </div>
              <div class="buttons-section">
                <div class="button mb-3">
                  <document-generate-component
                    :id="id"
                    :offer-id="item.offer_id"
                    :product-number="item.product_number"
                    :can-execute="true"
                  />
                </div>
                <div class="button">
                  <document-sign-component
                    v-if="$can('admin.document-signing.create') && !item.signed_document"
                    :id="id"
                    :can-execute="true"
                  />
                </div>
              </div>
            </el-card>
            <el-card
              v-if="$can('admin.document-signing.read') && item.signed_document"
              class="mt-3"
            >
              <div>
                <div class="align-content-center">
                  <div>
                    <h5 class="mt-0 pt-0">
                      Podpisany dokument Autenti
                    </h5>
                  </div>
                </div>
                <hr>
                <div class="content">
                  <dl>
                    <dt>Status dokumentu</dt>
                    <dd>
                      <el-tag
                        v-if="item.signed_document.status === 'DRAFT'"
                        class="text-center"
                        type="warning"
                      >
                        <i class="fas fa-pencil-ruler"></i>
                        {{ item.signed_document.is_complete ? 'Oczekuje na przesłanie' : 'Wersja robocza' }}
                      </el-tag>
                      <el-tag
                        v-if="item.signed_document.status === 'PROCESSING'"
                        class="text-center"
                        type="info"
                      >
                        <i class="fas fa-spinner"></i> Procesowany
                      </el-tag>
                      <el-tag
                        v-if="item.signed_document.status === 'COMPLETED'"
                        class="text-center"
                        type="success"
                      >
                        <i class="fas fa-check"></i> Podpisano
                      </el-tag>
                      <el-tag
                        v-if="item.signed_document.status === 'REJECTED'"
                        class=" text-center"
                        type="danger"
                      >
                        <i class="fas fa-hand-paper"></i> Odrzucono
                      </el-tag>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Dostępne akcje</dt>
                    <dd>
                      <el-button
                        type="primary"
                        class="w-100"
                        @click="$router.push({name: 'admin.document-signing.show', params: {id: item.signed_document.id}})"
                      >
                        <i class="fas fa-arrow-right"></i> Przejdź do podpisywanej umowy
                      </el-button>
                    </dd>
                  </dl>
                </div>
              </div>
            </el-card>
            <el-card
              v-if="!item.is_draft"
              class="mt-3"
            >
              <div>
                <div class="d-flex justify-content-between align-content-center">
                  <div>
                    <h5 class="mt-0 pt-0">
                      Podpięte aneksy
                    </h5>
                  </div>
                  <div>
                    <el-button
                      v-if="!creatorActive && $can('admin.sales.create')"
                      size="small"
                      type="primary"
                      @click="createNewAneks"
                    >
                      Nowy aneks
                    </el-button>
                  </div>
                </div>
                <hr>
              </div>
              <div v-if="!item.aneks.length">
                Brak aneksów dla tej umowy
              </div>
              <div
                v-for="(el,index) in item.aneks"
                class="aneks-list d-flex justify-content-between align-items-center"
              >
                <div class="name-space">
                  <p class="my-0 py-0">
                    <i class="fas fa-file-contract"></i> Aneks PV
                    <el-tag
                      v-if="el.is_draft"
                      type="warning"
                    >
                      Szkic aneksu
                    </el-tag>
                    <el-tag
                      v-if="creatorAneksItem !== null && el.id === creatorAneksItem.id"
                      type="primary"
                    >
                      Wybrany
                    </el-tag>
                  </p>
                  <div>
                    <small>Numer dokumentu: {{ el.product_number }}</small>
                  </div>
                </div>
                <div class="button-space">
                  <el-button
                    v-if="el.is_draft"
                    type="text"
                    @click="returnToEditing(el,index)"
                  >
                    Dokończ
                  </el-button>
                  <el-button
                    v-else
                    type="text"
                    @click="toProduct(el.id)"
                  >
                    Przejdź
                  </el-button>
                </div>
              </div>
            </el-card>
            <contractor
              :item="item.client"
              contractor-type="client"
            />
            <installation-place :item="item" />
            <el-card class="mt-3">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-xl-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Status nieruchomości</dt>
                    <dd>{{ $config.agreementSettings.data.property_type.settings[item.property_type].label }}</dd>
                  </dl>
                </div>
                <div class="col-lg-12 col-md-12 col-xl-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Numer działki</dt>
                    <dd>{{ item.tech_property_number }}</dd>
                  </dl>
                </div>
                <div class="col-lg-12 col-md-12 col-xl-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Link do pinezki google</dt>
                    <dd>
                      <a
                        :href="item.google_link"
                        target="_blank"
                      >Kliknij tutaj aby przenieść do Google Maps</a>
                    </dd>
                  </dl>
                </div>
              </div>
            </el-card>
            <el-dialog
              :visible.sync="attachOfferDialogVisibility"
              :before-close="closeOfferAttachDialog"
              title="Podpinanie oferty do umowy"
              width="40%"
            >
              <div class="row mt-3">
                <div class="col-12">
                  <el-select
                    v-model="newOfferId"
                    class="w-100"
                    placeholder="Wybierz ofertę do podpięcia"
                  >
                    <el-option
                      v-for="(item,index) in availableOffersList"
                      :key="index"
                      :label="item.id"
                      :value="item.id"
                    />
                  </el-select>
                </div>
              </div>
              <span
                slot="footer"
                class="dialog-footer"
              >
                <el-button @click="closeOfferAttachDialog">Przerwij</el-button>
                <el-button
                  type="primary"
                  @click="updateProductByOffer"
                >Potwierdź</el-button>
              </span>
            </el-dialog>
          </div>
        </div>
        <creator-aneks
          :show-dialog="openCreatorDialog"
          :aneks="creatorAneksItem"
          :settings="creatorSettings"
          @creator-close="closeCreatorDialog"
          @add-changes="updateAddonProduct"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="item.type_product === 2"
        label="Aneks PV"
        name="first"
      >
        <aneks-p-v
          :attached-agreement="item.attached_agreement"
          :addons="item.aneks"
          @input="updateAneks"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="item.type_product === 3 || item.type_product === 6"
        label="Umowa na grzewkę"
        name="first"
      >
        <heater-agreement
          :company="item.company"
          :client="item.client"
          :user="item.user"
          :addons="item.heater"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="item.type_product === 4"
        label="Sprzedaż towarów"
        name="first"
      >
        <div class="row">
          <div class="col-lg-9 col-md-12 col-xl-9 order-lg-1 order-xl-1 mt-3">
            <good-sales-show
              :goods="item.goods.data"
              class="mt-3"
            />
          </div>
          <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1 mt-3">
            <contractor
              :item="item.client"
              contractor-type="client"
            />
            <installation-place :item="item" />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
        v-if="item.type_product === 5"
        label="Umowa na grzewkę"
        name="first"
      >
        <heater-agreement-old
          :client="item"
          :addons="item.heater"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="!item.is_draft"
        label="Załączniki"
        name="attachments"
      >
        <div>
          <el-alert
            title="Zamieszczanie dokumentów"
            type="info"
            show-icon
          >
            Do końca wprowadzenia umowy należy wprowadzić jeszcze zdjęcie obiektu oraz miejsce na faktury za prąd. Dla
            ułatwienia wprowadziliśmy możliwość wrzucenia wszystkich plików z tego poziomu i podpisanie ich w
            odpowiednich nazwach. <br>
            <ul>
              <li>Faktura za prąd</li>
              <li>Szkic rozmieszczenia</li>
            </ul>
          </el-alert>
        </div>
        <div>
          <attachments
            v-if="item.attachments"
            :multiple="true"
            :files="item.attachments.data"
            :name-endpoint="nameEndpoint"
            :upload-endpoint="uploadEndpoint"
            :delete-endpoint="deleteEndpoint"
            :allowed-mime-types="$config.uploads.agreements.allowed_mime_types"
            :allowed-file-size="$config.uploads.agreements.allowed_file_size"
            :allowed-file-types="$config.uploads.agreements.allowed_file_types"
            @added="onAttachmentAdded"
            @media-name="onAttachmentNameChanged"
            @deleted="onAttachmentDeleted"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane
        v-if="item.settlements"
        label="Rozliczenia"
        name="settlements"
      >
        <el-card
          v-loading="loading"
          class="box-card"
        >
          <div class="title">
            <h5 class="my-0">
              Rozliczenia
            </h5>
            <hr>
          </div>
          <el-table
            :data="item.settlements.data"
            style="width: 100%"
          >
            <el-table-column
              prop="type"
              label="Typ rozliczenia"
            />
            <el-table-column
              prop="name"
              label="Nazwa rozliczenia"
            />
            <el-table-column
              prop="created_at"
              label="Utworzono w systemie"
            />
          </el-table>
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
  import { deleteRequest, getRequest, patchRequest } from '@/shared/utils/http'
  import Attachments from '@/admin/components/Attachments'
  import Autocomplete from '../users/components/Autocomplete'
  import AneksPV from '@/admin/pages/sales/components/AneksPV'
  import HeaterAgreement from '@/admin/pages/sales/components/HeaterAgreement'
  import CustomAgreementValue from '@/admin/pages/sales/components/CustomAgreementValue'
  import CreatorAneks from '@/admin/pages/sales/components/CreatorAneks'
  import pickBy from 'lodash-es/pickBy'
  import { changesDefault } from '@/admin/pages/sales/config'
  import Contractor from '@/admin/components/Contractor'
  import GoodSalesShow from '@/admin/pages/sales/components/GoodSalesShow'
  import InstallationPlace from '@/admin/components/InstallationPlace'
  import HeaterAgreementOld from '@/admin/pages/sales/components/HeaterAgreementOld'
  import DocumentSignComponent from '@/admin/pages/sales/components/DocumentSignComponent'
  import DocumentGenerateComponent from '@/admin/pages/sales/components/DocumentGenerateComponent'

  export default {
    name: 'ShowProduct',
    components: {
      DocumentGenerateComponent,
      DocumentSignComponent,
      HeaterAgreementOld,
      InstallationPlace,
      GoodSalesShow,
      Contractor,
      CreatorAneks,
      HeaterAgreement,
      AneksPV,
      Attachments,
      Autocomplete,
      CustomAgreementValue
    },
    data () {
      return {
        item: {},
        loading: true,
        statuses: {},
        activeName: 'first',
        barcodeValue: '1231231231',
        updateUserVisible: false,
        NewUser: '',
        showAgreementNumberChange: false,
        newAgreementNumber: null,
        updateStatusVisible: false,
        updateStatusVisible_2: false,
        updateStatusVisible_3: false,
        updateStatusVisible_4: false,
        updateStatusVisible_5: false,
        updateStatusVisible_6: false,
        newStatus: '',
        openCreatorDialog: false,
        creatorSettings: null,
        creatorActive: false,
        creatorAneksItem: null,
        changesDefaultList: changesDefault,
        attachOfferDialogVisibility: false,
        newOfferId: null,
        availableOffersList: null
      }
    },
    computed: {
      statusesGroup () {
        return {
          document_pv: this.$config.agreementSettings.data.statuses_office.settings[this.item.addons.documents_status_office],
          document_pv_credit: this.$config.agreementSettings.data.statuses_office.settings[this.item.addons.documents_credit_status_office],
          document_bo: this.$config.agreementSettings.data.statuses_backoffice.settings[this.item.addons.documents_status_backoffice],
          document_bo_credit: this.$config.agreementSettings.data.statuses_backoffice.settings[this.item.addons.documents_credit_status_backoffice],
          document_4eco: this.$config.agreementSettings.data.statuses_4eco.settings[this.item.addons.documents_status_provider],
          document_4eco_credit: this.$config.agreementSettings.data.statuses_4eco.settings[this.item.addons.documents_credit_status_provider]
        }
      },
      id () {
        return parseInt(this.$route.params.id)
      },
      productType () {
        const selectedProductType = this.$config.productList.find(el => el.id === this.item.type_product)
        if (!selectedProductType) {
          return ''
        }
        return selectedProductType.name
      },
      nameEndpoint: function () {
        return `/api/sales/${this.id}/attachments`
      },

      uploadEndpoint: function () {
        return `/api/sales/${this.id}/upload`
      },

      deleteEndpoint: function () {
        return `/api/sales/${this.id}/attachments`
      }
    },

    watch: {
      'id' (newValue, oldValue) {
        getRequest('/api/sales/' + newValue)
          .then((response) => {
            const changes = response.data.changes
            this.item = response.data
            if (this.item.type_product === 1) {
              this.financial = {
                type_financial: this.$config.agreementSettings.data.type_financial.settings[this.item.addons.type_financial],
                type_vat: this.$config.agreementSettings.data.type_vat.settings[this.item.addons.type_vat],
                type_settlement: this.$config.agreementSettings.data.to_settlement.settings[this.item.addons.to_settlement]
              }
              this.item.changes.addons = pickBy(changes.addons, (value, key) => Object.keys(this.changesDefaultList.changes.addons).includes(key))
            }
            this.loading = false
          })
      }
    },
    mounted () {
      this.$root.title = 'Produkt fotowoltaika'
      this.fetchData()
    },
    methods: {
      fetchData () {
        getRequest('/api/sales/' + this.id)
          .then((response) => {
            const changes = response.data.changes
            this.item = response.data
            if (this.item.type_product === 1) {
              this.financial = {
                type_financial: this.$config.agreementSettings.data.type_financial.settings[this.item.addons.type_financial],
                type_vat: this.$config.agreementSettings.data.type_vat.settings[this.item.addons.type_vat],
                type_settlement: this.$config.agreementSettings.data.to_settlement.settings[this.item.addons.to_settlement]
              }
              this.item.changes.addons = pickBy(changes.addons, (value, key) => Object.keys(this.changesDefaultList.changes.addons).includes(key))
            }
            this.loading = false
          })
      },
      onAttachmentAdded (file) {
        this.item.attachments.data.push(file.file)
      },
      onAttachmentNameChanged (file) {
        const index = this.item.attachments.data.findIndex((item) => {
          return item.id === file.id
        })
        this.item.attachments.splice(index, 1, file)
      },
      onAttachmentDeleted (id) {
        const index = this.item.attachments.data.findIndex((item) => {
          return item.id === id
        })
        this.item.attachments.data.splice(index, 1)
      },
      deleteAction: function () {
        this.$confirm('Czy chcesz usunąć ten produkt z systemu?', 'Uwaga', {
          confirmButtonText: this.label,
          cancelButtonText: this.cancelLabel,
          type: 'warning'
        })
          .then(() => {
            this.$emit('requestStart')
            deleteRequest('/api/sales/' + this.id)
              .then(() => {
                this.$emit('success')
                this.$router.push({ name: 'admin.sales.index' })
              })
              .catch(() => {
                this.$emit('fail')
              })
          })
          .catch(() => {
          })
      },
      actionEdit: function () {
        this.$router.push({ name: 'admin.sales.update', params: { id: this.id } })
      },
      actionChangeProductNumber: function (val) {
        this.showAgreementNumberChange = true
        this.newAgreementNumber = val
      },
      cancelChanging: function () {
        this.showAgreementNumberChange = false
      },
      updateProductNumber: function () {
        patchRequest(`/api/sales/${this.id}`, { product_number: this.newAgreementNumber })
          .then(response => {
            this.$message({
              message: 'Numer produktu został zaaktualizowany',
              type: 'success'
            })
            this.item.product_number = response.data.product_number
            this.showAgreementNumberChange = false
          }).catch(response => {
            this.$message({
              message: 'Problem ze zmianą numeru umowy. Przerwano',
              type: 'danger'
            })
          })
      },
      updateAneks (val) {
        patchRequest(`/api/sales/${this.id}`, { attached_agreement: val })
          .then(response => {
            this.$message({
              message: 'Aneks został podpięty do umowy',
              type: 'success'
            })
            this.item.attached_agreement = response.data.attached_agreement
          }).catch(response => {
            this.$message({
              message: 'Przerwano. Umowa nie została podpięta prawidłowo',
              type: 'danger'
            })
          })
      },
      updateUser: function () {
        patchRequest(`/api/sales/${this.id}`, { user_id: this.NewUser })
          .then(response => {
            this.$message({
              message: 'Osoba prowadząca została zmieniona',
              type: 'success'
            })
            this.item.user = response.data.user
          })
        this.updateUserVisible = false
      },
      updateStatusAgreement: function (statusType) {
        patchRequest(`/api/sales/${this.id}`, {
          status_pv_agreement: {
            status_type: statusType,
            value: this.newStatus
          }
        })
          .then(response => {
            this.$message({
              message: 'Status dla pola został przetworzony',
              type: 'success'
            })
            this.item.addons.documents_status_office = response.data.addons.documents_status_office
            this.item.addons.documents_credit_status_office = response.data.addons.documents_credit_status_office
            this.item.addons.documents_status_backoffice = response.data.addons.documents_status_backoffice
            this.item.addons.documents_credit_status_backoffice = response.data.addons.documents_credit_status_backoffice
            this.item.addons.documents_status_provider = response.data.addons.documents_status_provider
            this.item.addons.documents_credit_status_provider = response.data.addons.documents_credit_status_provider
            this.resetStatusPopover()
          })
        this.updateUserVisible = false
        this.resetStatusPopover()
      },
      resetStatusPopover: function () {
        this.updateStatusVisible = false
        this.updateStatusVisible_2 = false
        this.updateStatusVisible_3 = false
        this.updateStatusVisible_4 = false
        this.updateStatusVisible_5 = false
        this.updateStatusVisible_5 = false
        this.updateStatusVisible_6 = false
        this.newStatus = ''
      },
      toProduct (productId) {
        this.$router.push({ name: 'admin.sales.show', params: { id: productId } })
        this.loading = true
        this.item = null
        getRequest('/api/sales/' + productId)
          .then((response) => {
            this.item = response.data
            this.loading = false
            this.financial = {
              type_financial: this.$config.agreementSettings.data.type_financial.settings[this.item.addons.type_financial],
              type_vat: this.$config.agreementSettings.data.type_vat.settings[this.item.addons.type_vat],
              type_settlement: this.$config.agreementSettings.data.to_settlement.settings[this.item.addons.to_settlement]
            }
          })
      },
      editForAneks (oldValue, name, type, list = null) {
        this.openCreatorDialog = true
        const creatorSettings = {
          name: name,
          list: null,
          type: type,
          old_value: oldValue
        }

        if (type === 'list') {
          creatorSettings.list = list
        }

        this.creatorSettings = creatorSettings
      },
      closeCreatorDialog () {
        this.openCreatorDialog = false
      },
      createNewAneks () {
        this.$confirm('Utworzona zostanie wersja robocza aneksu. Zostanie przypisany numer. Kontynuować?', 'Przeczytaj', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Przerwij',
          type: 'warning'
        }).then(() => {
          getRequest('/api/sales/' + this.id + '/draft')
            .then((response) => {
              this.creatorAneksItem = response.data
              this.item.aneks.push({
                id: this.creatorAneksItem.id,
                product_number: this.creatorAneksItem.product_number,
                is_draft: this.creatorAneksItem.is_draft
              })
              this.creatorActive = true
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Przerwano. Aneks nie został utworzony'
          })
        })
      },
      returnToEditing (item, index) {
        this.creatorActive = true
        this.creatorAneksItem = {
          id: item.id,
          product_number: item.product_number,
          is_draft: item.is_draft,
          index: index
        }
      },
      deleteAneksDraft () {
        this.$confirm('Wszystkie wprowadzone zmiany do umowy zostaną usunięte z systemu. Kontynuować?', 'WAŻNE!', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Przerwij',
          type: 'warning'
        }).then(() => {
          const toDeleteItemId = this.creatorAneksItem.id
          deleteRequest('/api/sales/' + toDeleteItemId + '/draft')
            .then(() => {
              this.$emit('success')
              this.creatorActive = false
              if (this.creatorAneksItem.index === null) {
                this.item.aneks.splice(this.item.aneks.length - 1, 1)
              } else {
                this.item.aneks.splice(this.creatorAneksItem.index, 1)
              }
              this.creatorAneksItem = null
              this.fetchData()
            })
            .catch(() => {
              this.$emit('fail')
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Przerwano. Aneks nie został utworzony'
          })
        })
      },
      updateAddonProduct (val) {
        this.item.changes.addons = {
          ...this.item.changes.addons,
          ...val.addons
        }
      },
      completeAgreement () {
        this.$router.push({ name: 'admin.sales.update', params: { id: this.creatorAneksItem.id } })
      },
      downloadRenderedDocument () {
        if (!this.item.offer_id) {
          this.$message.error('Żadna oferta nie jest powiązana z tą umową. Zgłoś sprawę do BO.')
          return false
        }
        this.loading = true
        axios.get('/api/sales/' + this.id + '/download', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'no-cors'
          },
          responseType: 'blob'
        })
          .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Umowa ' + this.item.product_number + '.pdf'
            link.click()
            this.loading = false
          }).catch(e => {
            this.$snotify.error(e.data.message)
            this.loading = false
          })
      },
      moveToOffer () {
        this.$router.push({ name: 'admin.offers.show', params: { id: this.item.offer_id } })
      },
      openOfferAttachDialog () {
        this.loading = true
        getRequest('/api/clients/' + this.item.client_id + '/offers-list')
          .then((response) => {
            this.attachOfferDialogVisibility = true
            this.availableOffersList = response.data
            this.loading = false
          })
      },

      closeOfferAttachDialog () {
        this.attachOfferDialogVisibility = false
        this.newOfferId = null
      },

      updateProductByOffer () {
        if (this.newOfferId === '') {
          this.$message.error('Wybierz ofertę do podpięcia.')
          return false
        }
        patchRequest(`/api/sales/${this.id}`, { offer_id: this.newOfferId })
          .then(response => {
            this.$message({
              message: 'Do umowy została przypisana oferta.',
              type: 'success'
            })
            this.item.offer_id = response.data.offer_id
            this.closeOfferAttachDialog()
          }).catch(response => {
            this.$message({
              message: 'Oferta nie została przypisana. Przerwano',
              type: 'danger'
            })
          })
      },
      startSigningProcess () {
        this.$router.push({ name: 'admin.document-signing.create', params: { id: this.id } })
      },
      backList () {
        this.$router.push({ name: 'admin.sales.index' })
      },
      canEdit () {
        const allowedProducts = [1, 2, 3, 4, 5, 6]
        return allowedProducts.includes(this.item.type_product)
      }
    }
  }
</script>
<style scoped>
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.aneks-list {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d2cfcf;
}
</style>
