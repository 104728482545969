<template>
  <div>
    <el-select
      v-if="!edit"
      v-model="val"
      v-validate="validate"
      :loading="isLoading"
      :filterable="true"
      :clearable="true"
      :remote="true"
      :remote-method="autocompleteMethod"
      :name="name"
      :disabled="disabled"
      reserve-keyword
      style="width:100%"
      @change="emitChange"
    >
      <el-option
        v-for="(item,index) in addresses"
        :key="index"
        :label="item.prefix +' '+item.name_1+' '+item.name_2"
        :value="item.prefix +' '+item.name_1+' '+item.name_2"
        class="el-select-dropdown__item-custom"
      >
        <span>{{ item.prefix }} {{ item.name_1 }} {{ item.name_2 }}</span>
      </el-option>
    </el-select>
    <el-input
      v-else
      v-model="value"
      :name="name"
      :disabled="true"
    />
  </div>
</template>

<script>
  import { postRequest } from '../../../shared/utils/http'

  export default {
    name: 'AutocompleteCity',

    props: {
      group: {
        type: String,
        default: 'all',
        validator: (value) => {
          return ['admin', 'agency', 'all'].includes(value)
        }
      },
      addons: {
        type: Object,
        default: () => {
        }
      },
      name: {
        type: String,
        required: true
      },
      validate: {
        type: Object,
        default: () => {
        }
      },
      value: {
        type: Number | String,
        default: 0
      },
      disabled: {
        type: Boolean,
        default: true
      },
      edit: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        addresses: [],
        isLoading: false,
        val: null
      }
    },

    computed: {
      url () {
        return '/api/teryt/address'
      }
    },

    watch: {
      'value' (nextVal) {
        if (!this.addresses.length) {
          this.val = nextVal
        // this.cities.push({
        //   city: nextVal,
        //   voivodeship: null,
        //   county: null,
        //   community: null,
        //   addons: null
        // })
        }
      }
    },

    mounted () {
    },

    methods: {
      emitChange (val) {
        this.$emit('input', val)
      },

      autocompleteMethod (query) {
        if (query == null) {
          return false
        }
        this.isLoading = true
        if (query.length >= 3) {
          postRequest(this.url + '/' + query, { addons: this.addons })
            .then(response => {
              this.isLoading = false
              this.addresses = response.map(item => {
                return {
                  prefix: item.prefix,
                  name_1: item.name_1,
                  name_2: item.name_2
                }
              })
            })
            .catch(error => {
              this.isLoading = false
              console.error(error)
            })
        }
      }
    }
  }
</script>
<style scoped>
.el-select-dropdown__item-custom span:last-child {
  color: #6b6d6d;
}
</style>
