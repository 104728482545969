<template>
  <div class="col-md-12 col-lg-4">
    <el-card class="my-2">
      <div class="d-flex justify-content-between flex-lg-row flex-xl-row flex-column flex-nowrap align-items-center">
        <div class="mt-1">
          <h2>Proponowana oferta #{{ item.code }}</h2>
        </div>
        <div class="buttons">
          <el-tooltip
            class="item"
            effect="dark"
            content="Usuń"
            placement="top-start"
          >
            <el-button
              type="text"
              class="text-danger"
              @click="deleteElement"
            >
              <i class="fas fa-trash"></i>
            </el-button>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="Duplikuj"
            placement="top-start"
          >
            <el-button
              type="text"
              @click="duplicateElement"
            >
              <i class="fas fa-clone"></i>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <el-card
        v-loading="loading"
        class="mt-3"
      >
        <div class="mt-2">
          <span>Wybierz pompę do kalkulacji</span>
          <div class="row row-cols-3 mt-2">
            <div
              v-for="(element,index) in pumps"
              class="col"
            >
              <el-button
                class="button-select-item w-100"
                :type="index === isActivePump ? 'primary' : ''"
                @mouseover.native="setHoverState(true)"
                @mouseleave.native="setHoverState(false)"
                @click="setCard(index, element.id, 'pump')"
              >
                Pompa {{ element.name }}
              </el-button>
            </div>
          </div>
          <hr>
          <div class="mt-2 selected-description-pump">
            {{ pumps[isActivePump] ? pumps[isActivePump].description : '' }}
          </div>
        </div>
      </el-card>
      <el-card
        v-loading="loading"
        class="mt-2"
      >
        <div>
          <span>Wybierz pakiet do kalkulacji</span>
          <div class="row mt-2">
            <div
              v-for="(element, index) in packages"
              class="col-12"
            >
              <el-button
                class="button-select-item w-100"
                :type="index === isActive ? 'primary' : ''"
                @mouseover.native="setHoverState(true)"
                @mouseleave.native="setHoverState(false)"
                @click="setCard(index, element.id, 'package')"
              >
                {{ element.name }}#{{ element.id }}
              </el-button>
            </div>
          </div>
        </div>
      </el-card>
      <el-card
        v-if="item.package_id"
        v-loading="loading"
        class="mt-2"
      >
        <div class="title">
          <div class="d-flex justify-content-between flex-lg-row flex-xl-row flex-column flex-nowrap align-items-center">
            <div>
              <h5 class="my-0">
                Lista osprzętu w zestawie
              </h5>
            </div>
            <div>
            </div>
          </div>
          <hr>
        </div>
        <div
          v-if="item.package_components"
          class="package-elements"
        >
          <table class="w-100">
            <tr
              v-for="(element,index) in item.package_components"
              style="padding:20px 0px"
            >
              <td>
                <strong> <i class="fas fa-dot-circle"></i>  {{ element.name }}</strong> <el-button
                  v-if="canBeEdited(element)"
                  type="text"
                  size="mini"
                  class="text-success"
                  @click="handleEditComponent(element, index)"
                >
                  <i class="far fa-edit"></i>
                </el-button>
                <div class="description">
                  <small v-if="element.description">{{ element.description }}</small>
                  <small v-else>Brak opisu tej pompy</small>
                </div>
              </td>
              <td style="text-align: right">
                {{ element.pivot.count }} / {{ element.pivot.unit }}
              </td>
            </tr>
          </table>
        </div>
        <div class="add-new-item mt-2">
        </div>
      </el-card>
      <el-card
        v-if="item.package_id"
        class="mt-2"
      >
        <div class="title">
          <div class="d-flex justify-content-between flex-lg-row flex-xl-row flex-column flex-nowrap align-items-center">
            <div>
              <h5 class="my-0">
                Lista dodatków
              </h5>
            </div>
            <div>
              <el-button
                size="mini"
                type="primary"
                @click="openAddonsDialog"
              >
                Dodaj dodatek
              </el-button>
            </div>
          </div>
          <hr>
        </div>
        <div class="addons-list">
          <el-alert
            v-if="!item.addons.length"
            type="info"
            :closable="false"
          >
            Brak informacji o dodatkach
          </el-alert>
          <el-card v-for="el in item.addons">
            <div class="d-flex justify-content-between align-items-center">
              <div> {{ el.name }} {{ el.description }}</div>
              <div>
                <span>{{ el.price }} <el-tag>PLN</el-tag></span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Usuń ten dodatek"
                  placement="top-start"
                >
                  <el-button
                    type="text"
                    class="text-danger"
                    @click="deleteAddon(el.id)"
                  >
                    <i class="fas fa-trash"></i>
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          </el-card>
        </div>
      </el-card>
      <el-card
        v-if="item.package_id"
        v-loading="loading"
        class="mt-2"
      >
        <div class="row row-cols-1">
          <div class="col text-center">
            <h3>Kwota brutto</h3>
            <el-tag class="align-items-center">
              <h2 class="my-0">
                {{ fullPrice }} PLN
              </h2>
            </el-tag>
          </div>
        </div>
      </el-card>
      <el-card
        v-if="item.package_id && item.pump_id"
        v-loading="loading"
        class="mt-2"
      >
        <el-button
          type="primary"
          class="w-100"
          @click="createOffer"
        >
          Dodaj ofertę
        </el-button>
      </el-card>
    </el-card>
    <el-dialog
      title="Zmiana podzespołu pakietu"
      :visible.sync="editComponentShowDialog"
      width="100%"
      :before-close="closeEditComponent"
    >
      <custom-form-item
        prop="new_value"
        label="Nowy dobrany podzespół"
      >
        <el-select
          v-model="newComponent"
          class="w-100"
          placeholder="Wybierz podzespół"
          name="status"
        >
          <el-option
            v-for="(item,index) in selectedComponents"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </custom-form-item>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="closeEditComponent">Cofnij</el-button>
        <el-button
          type="primary"
          @click="changeComponent"
        >Zamień</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Dodawanie dodatków do oferty"
      :visible.sync="showDialogAddon"
      :before-close="closeAddonsDialog"
    >
      <custom-form-item
        prop="new_value"
        label="Wybierz dodatek do dodania"
      >
        <el-select
          v-model="newAddon"
          class="w-100"
          placeholder="Wybierz dodatek z listy"
          name="status"
        >
          <el-option
            v-for="(item,index) in availableAddons"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </custom-form-item>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          :loading="loading"
          @click="closeAddonsDialog"
        >Anuluj</el-button>
        <el-button
          :loading="loading"
          type="primary"
          @click="addAddonToElement"
        >Dodaj</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { deleteRequest, getRequest, patchRequest, postRequest } from '@/shared/utils/http'

  export default {
    name: 'CalculationItem',
    props: {
      indexOffer: {
        type: Number,
        default: 0
      },
      item: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        loading: false,
        showDialog: false,
        newPackageId: null,
        newPumpId: null,
        isActive: null,
        isActivePump: null,
        editComponentShowDialog: false,
        newComponent: null,
        selectedComponents: [],
        index: null,
        showDialogAddon: false,
        newAddon: null
      }
    },
    computed: {
      id () {
        return parseInt(this.$route.params.id)
      },
      pumps () {
        return this.$config.pumps
      },
      packageName () {
        return `Pompa ${this.item.pump.name} - Wybrany pakiet - ${this.item.package_name}`
      },
      packages () {
        return this.$config.heatPumpPackages
      },
      availableAddons () {
        return this.$config.heatPumpAddons
      },
      fullPrice () {
        return this.item.price
      }
    },
    watch: {
      loading (newValue) {
        this.loading = newValue
      }
    },
    mounted () {
      this.newPackageId = this.item.package_id
      this.newPumpId = this.item.pump_id
      this.isActive = this.packages.findIndex(e => {
        return e.id === this.item.package_id
      })
      this.isActivePump = this.pumps.findIndex(e => {
        return e.id === this.item.pump_id
      })
    },
    methods: {
      setCard (index, value, type) {
        if (type === 'package') this.isActive = index
        if (type === 'pump') this.isActivePump = index

        this.changePackageAndPump(index, value, type)
      },
      setHoverState (state) {
        this.hoverState = state
      },
      closeEditComponent () {
        this.editComponentShowDialog = false
        this.newComponent = null
        this.oldComponent = null
      },
      canBeEdited (element) {
        const uneditablePackageId = 2
        return element.is_editable && this.item.package_id !== uneditablePackageId
      },
      handleEditComponent (element, index) {
        this.editComponentShowDialog = true
        this.newComponent = null
        this.oldComponent = element.id
        this.index = index
        this.selectedComponents = this.$config.editableHeatPumpPackages[element.type]
      },

      openAddonsDialog () {
        this.showDialogAddon = true
      },
      closeAddonsDialog () {
        this.showDialogAddon = false
        this.newAddon = null
      },
      validAddons () {
        return this.item.addons.find(el => {
          return el.id === this.newAddon
        })
      },
      changePackageAndPump (index, id, type) {
        if (type === 'package') {
          this.newPackageId = id
        }
        if (type === 'pump') {
          this.newPumpId = id
        }
        if (!this.newPackageId) {
          this.$snotify.error('Brak wybranego nowego pakietu')
          return false
        }
        if (!this.newPumpId) {
          this.$snotify.error('Brak wybranej pompy')
          return false
        }

        this.loading = true
        patchRequest('/api/heat-pump-calculations-elements/' + this.item.id, {
          package_id: this.newPackageId,
          pump_id: this.newPumpId
        }).then(response => {
          this.item = response.data
          this.newPackageId = response.data.package_id
          this.newPumpId = response.data.pump_id
          this.loading = false
        })
          .catch(error => {
            this.$snotify.error('Błąd w dobraniu pakietu do oferty.')
            this.loading = false
          })
      },
      changeComponent () {
        this.loading = true
        patchRequest('/api/heat-pump-calculations-elements/' + this.item.id, {
          new_component_id: this.newComponent,
          old_component_id: this.oldComponent,
          index: this.index
        }).then(response => {
          this.item = response.data
          this.closeEditComponent()
          this.loading = false
        })
          .catch(error => {
            this.$snotify.error('Błąd w dobraniu pakietu do oferty.')
            this.loading = false
          })
      },
      addAddonToElement () {
        if (this.validAddons()) {
          this.$snotify.error('Taki element już istnieje.')
          return false
        }
        if (!this.newAddon) {
          return false
        }
        this.loading = true
        patchRequest(`/api/heat-pump-calculations-elements/${this.item.id}`, {
          addon_id: this.newAddon
        })
          .then(response => {
            this.item = response.data
            this.loading = false
            this.closeAddonsDialog()
          })
          .catch(errorResponse => {
            this.$snotify.error('Nie można dodać dodatku do kalkualcji')
            this.loading = false
          })
      },
      deleteAddon (addonId) {
        getRequest(`/api/heat-pump-calculations-elements/${this.item.id}/addons-delete/${addonId}`)
          .then(response => {
            this.item = response.data
          })
          .catch(error => {
            this.$snotify.error('Nie można usunąć dodatku')
          })
      },
      createOffer () {
        this.loading = true
        postRequest('/api/heat-pump-offers', {
          element_id: this.item.id
        })
          .then(response => {
            this.$router.push({ name: 'admin.heat-pump-offers.show', params: { id: response.data.id } })
            this.loading = false
          })
          .catch(error => {
            this.$snotify.error('Nie udało się wygenerować oferty.')
            this.loading = false
          })
      },

      duplicateElement () {
        this.loading = true
        getRequest('/api/heat-pump-calculations-elements/' + this.item.id + '/duplicate')
          .then(response => {
            this.loading = false
            this.$emit('add-element', response.data)
          })
          .catch(response => {
            this.loading = false
            this.$snotify.error('Nie można duplikować elementu')
          })
      },
      deleteElement () {
        this.loading = true
        deleteRequest('/api/heat-pump-calculations-elements/' + this.item.id)
          .then(response => {
            this.loading = false
            this.$emit('delete-element', this.indexOffer)
          })
          .catch(response => {
            this.loading = false
            this.$snotify.error('Nie można usunąć elementu')
          })
      }
    }
  }
</script>

<style scoped>

.el-button {
  white-space: initial;
}
tr {
  border-bottom: 1px solid #dedede !important;
}
</style>
