<template>
  <el-card
    class="my-3 client-box"
    @click.native="showClientInfo">
    <div class="row">
      <div
        class="col-6"
        style="display: flex">
        <div style="margin-left:10px">
          <div>{{ client.full_name }}</div>
          <div>
            <div style="font-size:12px"> {{ client.phone }} </div>
            <div style="font-size:12px">{{ client.email }}</div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div>{{ client.city }}</div>
        <div>
          <div style="font-size:12px"> {{ client.address }}</div>
          <div style="font-size:12px">{{ client.city }}</div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
  import {getRequest} from '@/shared/utils/http'

  export default {
    name: 'ClientCard',
    props: {
      client_id: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        city: '',
        client: '',
        dialogTableVisible: false
      }
    },
    mounted () {
      if (this.client_id === '') {
        return false
      }
      getRequest('/api/clients/' + this.client_id)
        .then((response) => {
          this.client = response.data
          this.$emit('city_sign', this.client.city)
        })
    }

  }
</script>

<style scoped>
    .client-box{
    }
    .client-bar{
        padding:10px;
        color: #908e8e;
    }
    .client-box:hover{
        color: #97d700;
        border-color: #e0f3b3;
        background-color: #f5fbe6;
    }
</style>
