<template>
  <div>
    <el-card
      v-if="type != 'update'"
      class="mt-3"
    >
      <div>
        <div class="text-end">
          <el-button
            @click="previousPage"
          >
            Cofnij
          </el-button>
          <el-button
            v-if="!form.product_number && $can('products.save-draft')"
            type="warning"
            @click="saveDraft"
          >
            <i class="far fa-save"></i> {{ form.is_draft ? 'Nadpisz wersje roboczą' : 'Zapisz wersje roboczą' }}
          </el-button>
          <el-button
            type="primary"
            @click="submitForm"
          >
            Zatwierdź i przejdź do podsumowania
          </el-button>
        </div>
      </div>
    </el-card>
    <el-card
      class="mt-3"
      loading="isLoading"
    >
      <div
        v-if="errorBlock"
        class="row mt-3 mb-3"
      >
        <el-alert
          title="Wystąpił błąd podczas generowania formularza"
          type="error"
          description="Sprawdź czy wszystkie wymagane pola w tym formularzu zostały poprawnie wypełnione."
          show-icon
        />
      </div>
      <div class="row">
        <div class="col-lg-8 col-xl-8 col-sm-12 order-lg-1 order-xl-1">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h5 class="mt-0 pt-0">
                Ustawienia finansowe umowy
              </h5>
            </div>
            <div>
              <el-button
                size="mini"
                type="primary"
                @click="generatePaymentCalculation"
              >
                <i class="fas fa-money-bill"></i> Ustaw transze
              </el-button>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
              <custom-form-item
                :errors="formErrors"
                prop="type_financial"
                label="Finansowanie"
              >
                <el-select
                  v-model="form.addons.type_financial"
                  :filterable="true"
                  name="type_financial"
                  @change="resetPaymentCalculation"
                >
                  <el-option
                    v-for="(item,index) in $config.agreementSettings.data.type_financial.settings"
                    :key="index"
                    :label="item.label"
                    :value="index"
                  />
                </el-select>
              </custom-form-item>
            </div>
            <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
              <custom-form-item
                :errors="formErrors"
                prop="type_vat"
                label="VAT"
              >
                <el-select
                  v-model="form.addons.type_vat"
                  :filterable="true"
                  name="type_vat"
                  @change="updateAgreementPrice"
                >
                  <el-option
                    v-for="(item,index) in $config.agreementSettings.data.type_vat.settings"
                    :key="index"
                    :label="item.label"
                    :value="index"
                  />
                </el-select>
              </custom-form-item>
            </div>
          </div>
          <div>
            <hr>
          </div>
          <div class="row">
            <div class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1">
              <custom-form-item
                :required="checkRequired(rules.price_netto)"
                :errors="formErrors"
                prop="price_netto"
                label="Cena netto umowy [PLN]"
              >
                <input-number
                  v-model="form.addons.price_netto"
                  v-validate="rules.price_netto"
                  :precision="2"
                  class="w-100"
                  name="price"
                  :disabled="true"
                  @change="updatePriceBrutto"
                />
              </custom-form-item>
            </div>
            <div class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1">
              <custom-form-item
                :required="checkRequired(rules.price_brutto)"
                :errors="formErrors"
                prop="price_brutto"
                label="Cena brutto umowy [PLN]"
              >
                <input-number
                  ref="price_brutto"
                  v-model="form.addons.price_brutto"
                  v-validate="rules.price_brutto"
                  :precision="2"
                  class="w-100"
                  :disabled="true"
                  name="price_brutto"
                  type="number"
                  @change="updatePriceNetto"
                />
              </custom-form-item>
            </div>
          </div>
          <div v-if="form.addons.type_financial == 1 || form.addons.type_financial == 2">
            <div class="row">
              <div class="col">
                <h5 class="mt-0 pt-0">
                  Transze kredytowe
                </h5>
              </div>
              <div class="btn-toolbar col-auto">
                <el-tag
                  class="btn"
                  style="cursor: pointer"
                  size="mini"
                  type="warning"
                  @click="resetPaymentCalculation"
                >
                  Resetuj transze płatnicze
                </el-tag>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="bank_1"
                  label="Bank kredytodawca 1"
                >
                  <el-select
                    v-model="form.addons.bank_1"
                    :filterable="true"
                    name="bank_1"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.banks.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.credit_cash_1)"
                  :errors="formErrors"
                  prop="credit_cash_1"
                  label="Kwota kredytu 1 [PLN]"
                >
                  <input-number
                    v-model="form.addons.credit_cash_1"
                    v-validate="rules.credit_cash_1"
                    :precision="2"
                    name="credit_cash_1"
                    class="w-100"
                  />
                </custom-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="bank_2"
                  label="Bank kredytodawca 2"
                >
                  <el-select
                    v-model="form.addons.bank_2"
                    :filterable="true"
                    name="bank_2"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.banks.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.credit_cash_2)"
                  :errors="formErrors"
                  prop="credit_cash_2"
                  label="Kwota kredytu 2 [PLN]"
                >
                  <input-number
                    v-model="form.addons.credit_cash_2"
                    v-validate="rules.credit_cash_2"
                    :precision="2"
                    class="w-100"
                    name="credit_cash_2"
                  />
                </custom-form-item>
              </div>
            </div>
          </div>
          <div v-if="form.addons.type_financial == 0 || form.addons.type_financial == 2">
            <div class="d-flex ">
              <div>
                <h5 class="mt-0 pt-0">
                  Transze gotówkowe
                </h5>
              </div>
              <hr>
            </div>
            <div class="row">
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.cash_1)"
                  :errors="formErrors"
                  prop="cash_1"
                  label="Transza I brutto - Zaliczka"
                >
                  <input-number
                    v-model="form.addons.cash_1"
                    v-validate="rules.cash_1"
                    :precision="2"
                    class="w-100"
                    name="cash_1"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.cash_2)"
                  :errors="formErrors"
                  prop="cash_2"
                  label="Transza II brutto - Po dostawie materiałów"
                >
                  <input-number
                    v-model="form.addons.cash_2"
                    v-validate="rules.cash_2"
                    :precision="2"
                    name="cash_2"
                    class="w-100"
                    type="number"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.cash_3)"
                  :errors="formErrors"
                  prop="cash_3"
                  label="Transza III - Po montażu instalacji"
                >
                  <input-number
                    v-model="form.addons.cash_3"
                    v-validate="rules.cash_3"
                    :precision="2"
                    name="cash_3"
                    class="w-100"
                  />
                </custom-form-item>
              </div>
            </div>
          </div>
          <div v-if="form.addons.type_financial == 3">
            <div>
              <h5 class="mt-0 pt-0">
                Leasing
              </h5>
              <hr>
            </div>
            <div>
              <custom-form-item
                :errors="formErrors"
                prop="lessor"
                label="Leasingodawca"
              >
                <el-input
                  v-model="form.addons.lessor"
                  name="lessor"
                  type="text"
                />
              </custom-form-item>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card
      v-if="type != 'update'"
      class="mt-3"
    >
      <div>
        <div class="text-end">
          <el-button
            @click="previousPage"
          >
            Cofnij
          </el-button>
          <el-button
            v-if="!form.product_number && $can('products.save-draft')"
            type="warning"
            @click="saveDraft"
          >
            <i class="far fa-save"></i> {{ form.is_draft ? 'Nadpisz wersje roboczą' : 'Zapisz wersje roboczą' }}
          </el-button>
          <el-button
            type="primary"
            @click="submitForm"
          >
            Zatwierdź i przejdź do podsumowania
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  import forms from '@/shared/mixins/forms'
  import { postRequest } from '@/shared/utils/http'
  import Dinero from 'dinero.js'

  export default {
    name: 'FinancialCreate',

    mixins: [
      forms
    ],
    props: {
      form: {
        type: Object,
        required: true
      },
      rules: {
        type: Object,
        required: true
      },
      type: {
        type: String,
        default: 'create'
      }
    },
    data: () => {
      return {
        isLoading: false,
        settingsAttributes: {},
        calculationsList: [],
        errorBlock: false
      }
    },
    computed: {
      agreementConfig () {
        return this.$config.agreementSettings.data
      }
    },
    mounted () {
      if (this.form.addons.price_brutto || this.form.addons.price_netto) {
        this.updateAgreementPrice(this.form.addons.type_vat)
      }
    },
    methods: {
      submitForm: function () {
        this.isLoading = true
        this.$validator.validate().then(valid => {
          if (valid) {
            this.$emit('nextPage')
          } else {
            this.errorBlock = true
            this.isLoading = false
            this.$message.error('Nie wszystko wypełnione!')
          }
        })
      },
      saveDraft: function () {
        this.$emit('save-draft')
      },
      updatePriceNetto: function (val) {
        const priceBrutto = Dinero({ amount: Math.round(val * 100), precision: 2 })
        if (this.form.addons.type_vat === 0) {
          this.form.addons.price_netto = priceBrutto.divide(1.23).toUnit()
        }
        if (this.form.addons.type_vat === 1) {
          this.form.addons.price_netto = priceBrutto.divide(1.08).toUnit()
        }
        if (this.form.addons.type_vat === 2) {
          this.form.addons.price_netto = priceBrutto.toUnit()
        }
        if (this.form.addons.type_financial !== 2) {
          this.setCashGroupToDefault(priceBrutto)
        }
      },
      updatePriceBrutto: function (val) {
        const priceNetto = Dinero({ amount: Math.round(val * 100), precision: 2 })
        let actualPrice = {}
        if (this.form.addons.type_vat === 0) {
          actualPrice = priceNetto.multiply(1.23)
        } else if (this.form.addons.type_vat === 1) {
          actualPrice = priceNetto.multiply(1.08)
        } else if (this.form.addons.type_vat === 2) {
          actualPrice = priceNetto
        }
        this.form.addons.price_brutto = actualPrice.toUnit()
        if (this.form.addons.type_financial !== 2) {
          this.setCashGroupToDefault(actualPrice)
        }
      },
      updateAgreementPrice: function (val) {
        const priceNetto = Dinero({ amount: Math.round(this.form.addons.price_netto * 100), precision: 2 })
        let actualPrice = {}
        if (val === 0) {
          actualPrice = priceNetto.multiply(1.23)
        }
        if (val === 1) {
          actualPrice = priceNetto.multiply(1.08)
        }
        if (val === 2) {
          actualPrice = priceNetto
        }
        this.form.addons.price_brutto = actualPrice.toUnit()
        if (this.form.addons.type_financial !== 2) {
          this.setCashGroupToDefault(actualPrice)
        }
      },
      setCashGroupToDefault: function (priceBrutto) {
        if (!(priceBrutto instanceof Object)) {
          this.$snotify.error('Nie można wytworzyć transz. Błąd w kwocie brutto.')
          return false
        }
        if (this.form.addons.type_financial === 2) {
          const creditCash1 = Dinero({ amount: Math.round(this.form.addons.credit_cash_1 * 100), precision: 2 })
          const creditCash2 = Dinero({ amount: Math.round(this.form.addons.credit_cash_2 * 100), precision: 2 })
          priceBrutto = priceBrutto.subtract(creditCash1.add(creditCash2))
        }
        const cash1 = priceBrutto.multiply(0.3)
        const cash2 = priceBrutto.multiply(0.5)
        this.form.addons.cash_1 = cash1.toUnit()
        this.form.addons.cash_2 = cash2.toUnit()
        this.form.addons.cash_3 = priceBrutto.subtract(cash1.add(cash2)).toUnit()
      },
      generatePaymentCalculation: function () {
        this.setCashGroupToDefault(
          Dinero({ amount: Math.round(this.form.addons.price_brutto * 100), precision: 2 })
        )
      },
      resetPaymentCalculation: function () {
        this.form.addons.cash_1 = 0.00
        this.form.addons.cash_2 = 0.00
        this.form.addons.cash_3 = 0.00
        this.form.addons.credit_cash_1 = 0.00
        this.form.addons.credit_cash_2 = 0.00
      },
      previousPage: function () {
        this.$emit('previousPage')
      }

    }
  }
</script>
<style scoped>
.el-input-group__append, .el-input-group__prepend {
  border: 0px !important;
  border-radius: 0px !important;
  color: #afacac !important;
}

.el-select {
  width: 100%;
}
</style>
