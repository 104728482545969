.password-field {
  .el-input__suffix-inner {
    position: relative;
    top: 10px;
    padding: 3px;
    background: #fff;

    i {
      color: #333;
    }
  }
}

.password-lines {
  > div {
    display: inline-block;
    height: 4px;
    width: 24%;
    background: $bg-muted;

    &.password-lines-filled {
      &.bg-gray {
        background: $bg-muted;
      }

    }
  }
}