<template>
  <div class="row mb-4">
    <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
      <el-card class="mt-3 el-card-height-max">
        <div class="header-box">
          <h4 class="my-0">
            Podsumowanie produktu
          </h4>
          <hr>
        </div>
        <div class="row">
          <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1">
            <el-alert
              v-if="!validationDone"
              :closable="false"
              class="mb-3"
              title="Trwa weryfikowanie umowy przez system. Proszę czekać."
              type="info"
              effect="dark"
            />
            <el-alert
              v-if="!canUpdateProduct && validationDone"
              :closable="false"
              class="mb-3"
              title="Uwaga"
              type="error"
              effect="dark"
            >
              <p>Nie można nadać umowie numeru i zatwierdzić ją do obiegu. Spowodowane jest to przez:</p>
              <ul>
                <li>Brak uzupełnionych wszystkich pól w umowie</li>
              </ul>
            </el-alert>
            <el-alert
              v-if="canUpdateProduct && validationDone"
              :closable="false"
              class="mb-3"
              title="Mamy to!"
              type="success"
              effect="dark"
            >
              <p>Umowa jest kompletna i system pozwoli Ci na przetworzenie jej z wersji roboczej do kompletnej umowy. Możesz nacisnąć przycisk.</p>
            </el-alert>
            <el-alert
              v-if="canUpdateProduct && validationDone"
              :closable="false"
              title="Informacja"
              type="info"
              effect="dark"
            >
              <p v-if="isEdit">
                Upewnij się, że wszystkie potrzebne informacje
                zostały podczas tej edycji zawarte. Po wcisnięciu zielonego klawisza system przeniesie Cię do edytowanego
                dokumentu.
              </p>
              <p v-else>
                Przygotowano szablon nowej umowy. Upewnij się, że najważniejsze dane dla tego dokumentu zostały poprawnie
                wprowadzone. Kwoty możesz porównać z dokumentem papierowym lub uzgodnić z Menadżerem twojego oddziału. Jeśli
                zauważyłeś błędy lub nieściśłości poinformuj o tym przełożonego lub stwórz zgłoszenie w Systemie.
              </p>
            </el-alert>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import { postRequest } from '@/shared/utils/http'

  export default {
    name: 'ProductSummary',
    props: {
      form: {
        type: Object,
        required: true
      },
      isEdit: {
        type: Boolean,
        required: true
      }
    },
    data () {
      return {
        canUpdateProduct: false,
        validationDone: false,
        errors: []
      }
    },
    computed: {
      financial () {
        return {
          type_financial: this.$config.agreementSettings.data.type_financial.settings[this.form.addons.type_financial],
          type_vat: this.$config.agreementSettings.data.type_vat.settings[this.form.addons.type_vat],
          type_settlement: this.$config.agreementSettings.data.to_settlement.settings[this.form.addons.to_settlement]
        }
      }
    },
    mounted () {
      this.validBeforeSummary()
    },
    methods: {
      validBeforeSummary () {
        postRequest('/api/sales/verify', this.form).then(response => {
          this.canUpdateProduct = true
          this.validationDone = true
          this.$emit('update-allow', this.canUpdateProduct)
        }).catch(errorResponse => {
          this.validationDone = true
          this.errors = errorResponse.data.data.errors // todo:  Poprawić to
          this.$snotify.error('Ups, dodanie umowy zablokowane!')
          this.showErrors()
          this.$emit('update-allow', this.canUpdateProduct)
        })
      },
      showErrors () {
        const errors = Object.values(this.errors)
        errors.forEach((e, i) => {
          this.$snotify.error(e)
        })
      }
    }

  }
</script>

<style scoped>
.el-card-height-max{
  min-height: 100%;
}
</style>
