<template>
  <div v-if="item">
    <el-card class="box-card">
      <div class="d-flex justify-content-between flex-lg-row flex-xl-row flex-column flex-nowrap align-items-center">
        <div class="order-1 p-2 bd-highlight ml-10">
          <div class="header-block">
            <h4>{{ $root.title }} - {{ item.full_name }}</h4>
            <span v-if="item.city !== null ">{{ item.full_address }}, {{ item.city }} {{ item.postcode }}</span>
          </div>
        </div>
        <div class="order-2">
          <el-button
            v-if="item.lead_id"
            class="button-mobile-app"
            @click="actionMoveToLead"
          >
            <i class="fas fa-arrow-right"></i> Przejdź do Leada
          </el-button>
          <el-dropdown
            class="button-mobile-app"
            @command="handleCommand"
          >
            <el-button
              type="primary"
              class="button-mobile-app"
            >
              <i class="fas fa-calculator"></i> Nowa kalkulacja <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="$can('admin.calculations.create')"
                :command="{type: 'pv', contractor: 'client'}"
              >
                PV
              </el-dropdown-item>
              <el-dropdown-item
                v-if="$can('admin.heat-pump-calculations.create')"
                :command="{type: 'heat_pump', contractor: 'client'}"
              >
                Pompa Ciepła
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            v-if="$can('admin.sales.create')"
            class="button-mobile-app"
            type="primary"
            @click="createProduct"
          >
            <i class="fas fa-folder-plus"></i> Nowa sprzedaż
          </el-button>
          <el-button
            v-if="$can('clients.update')"
            class="button-mobile-app"
            type="primary"
            @click="editAction"
          >
            <i class="far fa-edit"></i> Edytuj
          </el-button>
          <el-button
            v-if="$can('clients.delete')"
            class="button-mobile-app"
            type="danger"
            @click="deleteAction"
          >
            <i class="far fa-trash-alt"></i> Usuń klienta
          </el-button>
          <el-button
            class="button-mobile-app"
            @click="$router.go(-1)"
          >
            <i class="fas fa-long-arrow-alt-left"></i> Powrót
          </el-button>
        </div>
      </div>
    </el-card>
    <el-tabs
      type="card"
      class="mt-10"
    >
      <el-tab-pane label="Dane osobowe">
        <div class="row mt-3">
          <div class="col-lg-9 col-md-12">
            <el-card class="box-card">
              <div class="block--information">
                <div class="block--header">
                  <h5 class="my-0 py-0">
                    Informacje podstawowe
                  </h5>
                  <hr>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                    <dl><dt>Dane personalne</dt> <dd>{{ item.full_name }}</dd></dl>
                  </div>
                  <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                    <dl><dt>Email</dt> <dd>{{ item.email }}</dd></dl>
                  </div>
                  <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                    <dl><dt>Telefon 1</dt> <dd>{{ item.phone }}</dd></dl>
                  </div>
                </div>
                <div class="row">
                  <div
                    v-if="item.phone_2"
                    class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
                  >
                    <dl><dt>Telefon 2</dt> <dd>{{ item.phone_2 }}</dd></dl>
                  </div>
                </div>
                <div class="block--content">
                  <div class="row">
                    <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                      <dl><dt>Źródło kontaktu</dt> <dd>{{ source }}</dd></dl>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                      <dl><dt>Typ klienta</dt> <dd>{{ type_contact }} </dd></dl>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                      <dl><dt>Czym zainteresowany?</dt> <dd>{{ interested }}</dd></dl>
                    </div>
                    <div
                      v-if="item.pesel"
                      class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
                    >
                      <dl><dt>Numer PESEL</dt> <dd>{{ item.pesel }}</dd></dl>
                    </div>
                  </div>
                </div>
                <div class="block--content">
                  <div v-if="item.type_contact != 0">
                    <div class="row">
                      <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                        <div><dl><dt>Nazwa firmy</dt> <dd> {{ item.name_company }}</dd></dl></div>
                      </div>
                      <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                        <dl><dt>NIP</dt> <dd> {{ item.nip }}</dd></dl>
                      </div>
                      <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                        <dl><dt>REGON</dt> <dd> {{ item.regon }}</dd></dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
            <el-card class="box-card mt-3">
              <div>
                <h5 class="my-0 py-0">
                  Informacje adresowe
                </h5>
                <hr>
              </div>
              <div class="row">
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Adres </dt> <dd>{{ item.address }}</dd></dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Numer budynku </dt> <dd>{{ item.property_number }}</dd></dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Numer lokalu</dt> <dd>{{ item.apartment_number }}</dd></dl>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Miasto </dt> <dd>{{ item.city }}</dd></dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Kod pocztowy</dt> <dd>{{ item.postcode }}</dd></dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Województwo</dt> <dd>{{ item.province }}</dd></dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Gmina</dt> <dd>{{ item.county }}</dd></dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Powiat</dt> <dd>{{ item.community }}</dd></dl>
                </div>
              </div>
            </el-card>
            <el-card
              v-if="item.type_contact != 0"
              class="box-card mt-3"
            >
              <div class="">
                <h5 class="mt-0 pt-0">
                  Osoba reprezentacyjna
                </h5>
              </div>
              <div class="row">
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Reprezentant 1</dt><dd>{{ item.representatnt1_name }}</dd></dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Telefon kontaktowy</dt><dd>{{ item.representatnt1_phone }}</dd></dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Email</dt><dd>{{ item.representatnt1_email }}</dd></dl>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Reprezentant 2</dt><dd>{{ item.representatnt2_name }}</dd></dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Telefon kontaktowy</dt><dd>{{ item.representatnt2_phone }}</dd></dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Email</dt><dd>{{ item.representatnt2_email }}</dd></dl>
                </div>
              </div>
            </el-card>
            <el-card class="box-card mt-3">
              <div>
                <h4>Dane techniczne</h4>
                <hr>
              </div>
              <div class="row">
                <div
                  v-if="item.google_map_url"
                  class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Mapka do terenu klienta</dt>
                    <dd>
                      <el-link
                        :href="item.google_map_url"
                        target="_blank"
                        type="success"
                      >
                        Kliknij, aby przejść do linku
                      </el-link>
                    </dd>
                  </dl>
                </div>
                <div
                  class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Dostawca</dt>
                    <dd>{{ item.provider !== null ? providerShow : 'Brak danych' }}</dd>
                  </dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Rodzaj dachu</dt>
                    <dd>{{ item.roof_type !== null ? roofTypeShow : 'Brak danych' }}</dd>
                  </dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Powierzchnia dachu</dt>
                    <dd>{{ item.roof_area ? item.roof_area + ' m²' : 'Brak danych' }} </dd>
                  </dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Roczne zużycie energii</dt>
                    <dd>{{ item.annual_energy_consumption ? item.annual_energy_consumption : 'Brak danych' }}</dd>
                  </dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Zgoda marketingowa 1</dt>
                    <dd>{{ item.permission_first ? 'Tak' : 'Nie' }}</dd>
                  </dl>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Zgoda marketingowa 2</dt>
                    <dd>{{ item.permission_second ? 'Tak' : 'Nie' }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.source === 13"
                  class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Ankieter</dt>
                    <dd>{{ item.pollster }}</dd>
                  </dl>
                </div>
              </div>
            </el-card>
            <attachments
              v-if="item.attachments"
              :files="item.attachments.data"
              :name-endpoint="nameEndpoint"
              :upload-endpoint="uploadEndpoint"
              :delete-endpoint="deleteEndpoint"
              :allowed-mime-types="$config.uploads.tickets.allowed_mime_types"
              :allowed-file-size="$config.uploads.tickets.allowed_file_size"
              :allowed-file-types="$config.uploads.tickets.allowed_file_types"
              multiple
              @added="onAttachmentAdded"
              @media-name="onAttachmentNameChanged"
              @deleted="onAttachmentDeleted"
            />
          </div>
          <div class="col-lg-3 col-md-12">
            <el-card class="box-card">
              <div class="block--information">
                <div class="block--header">
                  <h5 class="my-0 py-0">
                    Karta kontaktu
                  </h5>
                  <hr>
                  <dl><dt>Ostatnia edycja klienta</dt><dd>{{ item.last_call_date }}</dd></dl>
                </div>
                <div class="block--content">
                  <div class="row">
                    <div class="col-12">
                      <dl>
                        <dt>Notatka BO</dt> <dd
                          v-if="item.notes_office"
                          class="textform-notes"
                          v-html="item.notes_office"
                        ></dd>
                        <dd v-else>
                          Brak informacji w systemie
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <dl>
                        <dt>Notatka o kliencie</dt><dd
                          v-if="item.notes"
                          class="textform-notes"
                          v-html="item.notes"
                        ></dd>
                        <dd v-else>
                          Brak informacji w systemie
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
            <user-block
              resource="admin.clients.change"
              type="clients"
              :object-id="item.id"
              permission="admin.clients.change"
              :item="item.user"
            />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
        v-if="item.calculations"
        label="Kalkulacje PV"
      >
        <calculations-list :items="item.calculations" />
      </el-tab-pane>
      <el-tab-pane
        v-if="item.offers"
        label="Oferty PV"
      >
        <offers-list :items="item.offers" />
      </el-tab-pane>
      <el-tab-pane
        v-if="item.heat_pump_calculations"
        label="Kalkulacje Pomp Ciepła"
      >
        <calculations-heat-pump-list :items="item.heat_pump_calculations" />
      </el-tab-pane>
      <el-tab-pane
        v-if="item.heat_pump_offers"
        label="Oferty Pomp Ciepła"
      >
        <offer-heat-pump-list :items="item.heat_pump_offers" />
      </el-tab-pane>
      <el-tab-pane
        v-if="item.sales"
        label="Umowy"
      >
        <agreements-list :items="item.sales" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<style>
  .block--last-contact{
    text-align:center;
  }
</style>

<script>
  import { deleteRequest, getRequest } from '@/shared/utils/http'
  import TasksClient from './components/TasksClient'
  import Avatar from 'vue-avatar'
  import Attachments from '@/admin/components/Attachments'
  import AgreementList from './components/AgreementList'
  import OfferList from '@/admin/pages/calculator/components/OfferList'
  import CalculationList from '@/admin/pages/calculator/components/CalculationList'
  import UserBlock from '@/admin/components/User'
  import CalculationsList from '@/admin/components/lead-client-addons/CalculationsList'
  import OffersList from '@/admin/components/lead-client-addons/OffersList'
  import AgreementsList from '@/admin/components/lead-client-addons/AgreementsList'
  import sellingProcess from '@/admin/mixins/sellingProcess'
  import CalculationsHeatPumpList from '@/admin/components/lead-client-addons/CalculationsHeatPumpList'
  import OfferHeatPumpList from '@/admin/components/lead-client-addons/OfferHeatPumpList'

  export default {
    name: 'ClientShow',

    components: {
      OfferHeatPumpList,
      CalculationsHeatPumpList,
      AgreementsList,
      OffersList,
      CalculationsList,
      UserBlock,
      Attachments
    },
    mixins: [sellingProcess],
    data: () => {
      return {
        activeName: 'first',
        item: '',
        message: 'Jesteś pewien, że chcesz usunąć tego klienta?',
        tableData: [{
          date: 'brak',
          name: 'brak',
          address: 'brak',
          who: 'brak'
        }],
        isLoaded: false,
        canCreateAgreement: false,
        loading: false
      }
    },

    computed: {
      id () {
        return parseInt(this.$route.params.id)
      },
      source () {
        if (!this.$config.agreementSettings.data.source.settings[this.item.source]) {
          return ''
        }
        return this.$config.agreementSettings.data.source.settings[this.item.source].label
      },
      type_contact () {
        if (!this.$config.agreementSettings.data.type_client.settings[this.item.type_contact]) {
          return ''
        }
        return this.$config.agreementSettings.data.type_client.settings[this.item.type_contact].label
      },
      interested () {
        if (!this.$config.agreementSettings.data.interested.settings[this.item.interested]) {
          return ''
        }
        return this.$config.agreementSettings.data.interested.settings[this.item.interested].label
      },
      nameEndpoint: function () {
        return `/api/clients/${this.id}/attachments`
      },

      uploadEndpoint: function () {
        return `/api/clients/${this.id}/upload`
      },

      deleteEndpoint: function () {
        return `/api/clients/${this.id}/attachments`
      },

      providerShow () {
        if (!this.$config.agreementSettings.data.electric_provider.settings[this.item.provider]) {
          return ''
        }
        return this.$config.agreementSettings.data.electric_provider.settings[this.item.provider].label
      },
      roofTypeShow () {
        if (!this.$config.agreementSettings.data.roof_type.settings[this.item.roof_type]) {
          return ''
        }
        return this.$config.agreementSettings.data.roof_type.settings[this.item.roof_type].label
      }
    },

    watch: {
      'id' (newValue, oldValue) {
        getRequest('/api/clients/' + newValue)
          .then((response) => {
            this.item = response.data
            this.isLoaded = true
          }).catch((response) => {
            this.$router.go(-1)
          })
      }
    },

    mounted () {
      this.$root.title = 'Karta klienta'

      getRequest('/api/clients/' + this.id)
        .then((response) => {
          this.item = response.data
          this.isLoaded = true
        }).catch((response) => {
          this.$router.go(-1)
        })
    },
    methods: {
      backToList () {
        this.$router.push({ path: '/admin/clients' })
      },
      actionMoveToUser: function () {
        this.$router.push({ path: '/admin/users/' + parseInt(this.item.user_id) })
      },
      editAction () {
        this.$router.push({ name: 'admin.clients.update', params: { id: this.id } })
      },
      createProduct () {
        this.verifyClient()
      },
      warningCalculation () {
        this.$message({
          message: 'Należy uzupełnić pola związane z adresem podmiotu.',
          type: 'info'
        })
        if (this.$can('clients.update')) {
          this.$router.push({ name: 'admin.clients.update', params: { id: this.id } })
        }
      },
      actionMoveToLead () {
        this.$router.push({ name: 'admin.lead.show', params: { id: this.item.lead_id } })
      },
      deleteAction: function () {
        this.$confirm(this.message, 'Uwaga', {
          confirmButtonText: this.label,
          cancelButtonText: this.cancelLabel,
          type: 'warning'
        })
          .then(() => {
            this.$emit('requestStart')
            deleteRequest('/api/clients/' + this.id)
              .then(() => {
                this.$emit('success')
                this.$router.push({ path: '/admin/clients' })
              })
              .catch(() => {
                this.$emit('fail')
              })
          })
          .catch(() => {
          })
      },
      onAttachmentAdded (file) {
        this.item.attachments.data.push(file.file)
      },

      onAttachmentNameChanged (file) {
        const index = this.item.attachments.findIndex((item) => {
          return item.id === file.id
        })
        this.item.attachments.splice(index, 1, file)
      },

      onAttachmentDeleted (id) {
        const index = this.item.attachments.data.findIndex((item) => {
          return item.id === id
        })
        this.item.attachments.data.splice(index, 1)
      },
      verifyClient () {
        getRequest('/api/clients/' + this.id + '/verify')
          .then((response) => {
            this.canCreateAgreement = true
            this.$router.push({ path: '/admin/sales/create/' + this.id })
          })
          .catch((response) => {
            response.data.error.data.forEach(el => {
              this.$snotify.error(el.message, 'Uwaga', {
                timeout: 4000,
                showProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true
              })
            })

            this.canCreateAgreement = false
            this.$router.push({ name: 'admin.clients.update', params: { id: this.id } })
          })
      }
    }

  }
</script>
<style scoped>
  .header-block{
    margin-left: 20px;
  }
  .textform-notes p{
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
  }
</style>
