
@import '_variables.scss';

* {
  box-sizing: border-box;
}
//@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~normalize.css/normalize.css';
@import "~bootstrap/scss/bootstrap";
@import 'vendors/element-io-custom';
@import "~gleap/build/index.min.css";
@import 'inc/mixins';
@import 'inc/colors';
@import 'inc/transitions';
@import 'inc/general';
@import 'inc/grid';
@import 'inc/spacing';
@import 'inc/main';
@import 'inc/header';
@import 'inc/aside';
@import 'inc/footer';
@import 'inc/icons';
@import 'inc/lists';
@import 'inc/pagination';
@import 'inc/forms';
@import 'inc/buttons';
@import 'inc/table';
@import 'inc/avatar';
@import 'inc/attachment';
@import 'inc/auth';
@import 'inc/password';
@import 'inc/block';
@import 'inc/dashboard';
@import 'inc/branches';
@import 'inc/tasks';
@import 'inc/drawer';
@import 'inc/typograpgy';
@import "~vue-snotify/styles/material";
@import 'vendors/element-theme-chalk';

tr {
  cursor: pointer;
}

