<template>
  <el-select
    v-model="val"
    :loading="isLoading"
    :filterable="true"
    :clearable="true"
    :remote="true"
    placeholder="Zacznij wpisywać dane leada..."
    :remote-method="autocompleteMethod"
    :name="name"
    reserve-keyword
    style="width:100%"
    @change="emitChange"
  >
    <el-option
      v-for="item in leads"
      :key="item.id"
      :label="item.label"
      :value="item.value"
      class="el-select-dropdown__item-custom"
    >
      <span>{{ item.label }}</span>
    </el-option>
  </el-select>
</template>

<script>
  import { getRequest } from '@/shared/utils/http'

  export default {
    name: 'LeadAutocomplete',

    props: {
      group: {
        type: String,
        default: 'all',
        validator: (value) => {
          return ['admin', 'all'].includes(value)
        }
      },
      name: {
        type: String,
        required: true
      },
      value: {
        type: Number | String,
        default: ''
      },
      agencyId: {
        type: Number,
        default: 0
      },
      initial: {
        type: Object,
        default: () => {}
      }
    },

    data () {
      return {
        users: [],
        leads: [],
        isLoading: false,
        val: null
      }
    },

    computed: {
      url () {
        return '/api/leads/autocomplete'
      }
    },

    mounted () {
      this.val = this.value
      this.$nextTick(function () {
        if (this.initial) {
          this.leads = [this.initial]
        }
      })
    },

    methods: {
      emitChange (val) {
        this.$emit('input', val)
      },

      autocompleteMethod (query) {
        this.isLoading = true
        getRequest(this.url, { keyword: query })
          .then(response => {
            this.isLoading = false
            this.leads = response.data.map(item => {
              return {
                label: item.fullName,
                value: item.id
              }
            })
          })
          .catch(error => {
            this.isLoading = false
            console.error(error)
          })
      }
    }
  }
</script>
<style scoped>
  .el-select-dropdown__item-custom span:last-child {
    color: #6b6d6d;
  }
  </style>
