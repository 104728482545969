
.breadcrumb {
  background-color: #3f4443 !important;
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
}

.router-link-active {
  color: #ffffff !important;
}

.router-link-exact-active .router-link-active {
  color: black !important;
  font-weight: 800;
}
