<template>
  <div :key="componentKey">
    <el-card class="my-3">
      <el-row style="text-align: right;">
        <el-button @click="previousFolder($store.state.app.files.previous_file)">
          Powrót
        </el-button>
        <el-button
          v-if="$can('admin.files.delete')"
          type="danger"
          @click="deleteAction"
        >
          Usuń
        </el-button>
        <el-button
          v-if="$can('admin.files.create')"
          type="primary"
          @click="createSubCatalog()"
        >
          <i class="fas fa-plus"></i> Utwórz folder
        </el-button>
        <el-button
          v-if="$can('admin.files.update')"
          type="primary"
          @click="editAction"
        >
          <i class="fas fa-plus"></i> Edytuj katalog
        </el-button>
      </el-row>
    </el-card>
    <el-card>
      <div class="row">
        <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
          <dl>
            <dt>Nazwa katalogu</dt>
            <dd>{{ item.name }}</dd>
          </dl>
        </div>
        <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
          <dl>
            <dt>Opis katalogu</dt>
            <dd>{{ item.description }}</dd>
          </dl>
        </div>
        <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
          <dl>
            <dt>Data utworzenia</dt>
            <dd>{{ item.created_at | moment($config.date_format.short) }}</dd>
          </dl>
        </div>
      </div>
    </el-card>
    <div class="row my-4">
      <div
        v-for="sub in item.subcatalogs"
        class="col-lg-2 col-xl-2 col-sm-6 order-lg-1 order-xl-1 mt-2"
      >
        <el-card
          class="folder-button"
          @mouseover.native="setHoverState(true)"
          @mouseleave.native="setHoverState(false)"
          @click.native="moveToCatalog(sub.id)"
        >
          <i class="fas fa-folder"></i> {{ sub.name | truncate(20) }}
        </el-card>
      </div>
    </div>
    <attachments
      :files="item.attachments.data"
      :name-endpoint="nameEndpoint"
      :upload-endpoint="uploadEndpoint"
      :delete-endpoint="deleteEndpoint"
      :allowed-mime-types="$config.uploads.tickets.allowed_mime_types"
      :allowed-file-size="$config.uploads.tickets.allowed_file_size"
      :allowed-file-types="$config.uploads.tickets.allowed_file_types"
      multiple
      @added="onAttachmentAdded"
      @media-name="onAttachmentNameChanged"
      @deleted="onAttachmentDeleted"
    />
    <el-dialog
      v-loading="loading"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      title="Utwórz folder"
      width="100%"
    >
      <div v-if="alertCreate">
        <el-alert
          :closable="false"
          title="*Nazwa folderu jest wymagana!"
          type="error"
        />
      </div>
      <custom-form-item
        prop="name"
        label="Nazwa folderu"
      >
        <el-input
          v-model="newFolder"
          name="name"
          type="text"
          @keyup.enter.native.stop.prevent="saveFolder()"
        />
        <small>Enter by zatwierdzić, Esc lub opuść pole by anulować.</small>
      </custom-form-item>
    </el-dialog>
  </div>
</template>

<script>
  import Attachments from '@/admin/components/AttachmentsFiles'
  import { postRequest, getRequest, deleteRequest } from '@/shared/utils/http'

  export default {
    name: 'CatalogShow',
    components: { Attachments },
    data () {
      return {
        componentKey: 0,
        item: {
          attachments: {
            data: []
          }
        },
        currentId: '',
        newFolder: '',
        dialogVisible: false,
        alertCreate: false,
        loading: false,
        hoverState: false
      }
    },
    computed: {
      id () {
        return parseInt(this.$route.params.id)
      },
      nameEndpoint: function () {
        return `/api/catalogs/${this.id}/attachments`
      },

      uploadEndpoint: function () {
        return `/api/catalogs/${this.id}/upload`
      },

      deleteEndpoint: function () {
        return `/api/catalogs/${this.id}/attachments`
      }
    },
    mounted () {
      this.$root.title = 'Lista katalogów z plikami'
      this.fetchData(0)
    },
    methods: {
      fetchData (currentId) {
        // eslint-disable-next-line no-unused-vars
        let currentIdPage = 0

        if (currentId !== 0) {
          currentIdPage = currentId
        } else {
          currentIdPage = this.id
        }

        getRequest('/api/catalogs/' + currentIdPage)
          .then((response) => {
            this.item = response
          }).catch((response) => {
            // this.$router.go(-1)
          })
      },
      editAction () {
        this.$router.push({ name: 'admin.files.update', params: { id: this.item.id } })
      },
      createSubCatalog () {
        this.dialogVisible = true
      },
      previousFolder (fileId) {
        this.$router.go(-1)
        this.currentId = this.$store.state.app.files.previous_file
        if (this.id <= 0) {
          return false
        }
        this.fetchData(this.currentId)
      },
      handleClose () {
        this.dialogVisible = false
        this.newFolder = ''
        this.alertCreate = false
      },
      saveFolder () {
        if (this.newFolder === '') {
          this.alertCreate = true
          return false
        }
        return new Promise((resolve, reject) => {
          this.$message('Tworzenie folderu...')
          let request
          request = postRequest('/api/catalogs', { name: this.newFolder, subcatalog_id: this.id })
          request
            .then(response => {
              this.$emit('saved')
              this.alertCreate = false
              this.handleClose()
              this.$message({
                message: 'Folder utworzony',
                type: 'success'
              })
              this.item.subcatalogs.push(response)
              resolve(response)
            })
            .catch(error => {
              this.alertCreate = false
              reject(error)
            })
        })
      },
      moveToCatalog (subId) {
        this.$store.dispatch('app/setPreviousFile', this.id)
        this.$router.push({ path: '/admin/files/' + subId })
        this.fetchData(subId)
      },
      setHoverState (state) {
        this.hoverState = state
      },
      onAttachmentAdded (file) {
        this.item.attachments.data.push(file.file)
      },

      onAttachmentNameChanged (file) {
        const index = this.item.attachments.findIndex((item) => {
          return item.id === file.id
        })
        this.item.attachments.splice(index, 1, file)
      },

      onAttachmentDeleted (id) {
        const index = this.item.attachments.data.findIndex((item) => {
          return item.id === id
        })
        this.item.attachments.data.splice(index, 1)
      },

      deleteAction () {
        this.$confirm('Czy chcesz usunąć?', 'Uwaga', {
          confirmButtonText: this.label,
          cancelButtonText: this.cancelLabel,
          type: 'warning'
        })
          .then(() => {
            this.$emit('requestStart')
            deleteRequest('/api/catalogs/' + this.id)
              .then(() => {
                this.$emit('success')
                this.$router.push({ name: 'admin.files.index' })
              })
              .catch(() => {
                this.$emit('fail')
              })
          })
          .catch(() => {
          })
      }
    }
  }
</script>

<style scoped>
.folder-button{
  cursor: pointer;
  color: #3f4443;
  transition: 0.6s;
  background-image: radial-gradient(circle at 13% 47%, rgba(140, 140, 140,0.03) 0%, rgba(140, 140, 140,0.03) 25%,transparent 25%, transparent 100%),radial-gradient(circle at 28% 63%, rgba(143, 143, 143,0.03) 0%, rgba(143, 143, 143,0.03) 16%,transparent 16%, transparent 100%),radial-gradient(circle at 81% 56%, rgba(65, 65, 65,0.03) 0%, rgba(65, 65, 65,0.03) 12%,transparent 12%, transparent 100%),radial-gradient(circle at 26% 48%, rgba(60, 60, 60,0.03) 0%, rgba(60, 60, 60,0.03) 6%,transparent 6%, transparent 100%),radial-gradient(circle at 97% 17%, rgba(150, 150, 150,0.03) 0%, rgba(150, 150, 150,0.03) 56%,transparent 56%, transparent 100%),radial-gradient(circle at 50% 100%, rgba(25, 25, 25,0.03) 0%, rgba(25, 25, 25,0.03) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 55% 52%, rgba(69, 69, 69,0.03) 0%, rgba(69, 69, 69,0.03) 6%,transparent 6%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));
}
</style>
