<template>
  <section>
    <el-form
      ref="clientsForm"
      v-loading="loading"
      :model="form"
      label-position="top"
      @submit.native.prevent="handleForm('clientsForm')"
    >
      <form-alerts
        :messages="invalidMsg"
        class="my-6"
      />

      <div class="row">
        <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1 mt-3">
          <el-card class="el-card-height-max">
            <div>
              <h4>Dane personalne</h4>
              <hr>
            </div>
            <custom-form-item
              :errors="formErrors"
              :required="checkRequired(rules.firstName)"
              prop="firstName"
              label="Imię"
            >
              <el-input
                v-model="form.firstName"
                v-validate="rules.firstName"
                name="firstName"
                type="text"
              />
            </custom-form-item>

            <custom-form-item
              :required="checkRequired(rules.lastName)"
              :errors="formErrors"
              prop="lastName"
              label="Nazwisko"
            >
              <el-input
                v-model="form.lastName"
                v-validate="rules.lastName"
                name="lastName"
                type="text"
              />
            </custom-form-item>

            <custom-form-item
              :required="checkRequired(rules.pesel)"
              :errors="formErrors"
              prop="pesel"
              label="PESEL"
            >
              <el-input
                v-model="form.pesel"
                v-validate="rules.pesel"
                v-mask="'###########'"
                name="pesel"
                type="text"
              />
            </custom-form-item>

            <custom-form-item
              :required="checkRequired(rules.document_id_number)"
              :errors="formErrors"
              prop="document_id_number"
              label="Numer dokumentu tożsamości"
            >
              <el-input
                v-model="form.document_id_number"
                v-validate="rules.document_id_number"
                name="document_id_number"
                type="text"
              />
            </custom-form-item>
          </el-card>
        </div>
        <div class="col-lg-8 col-xl-8 col-sm-12 order-lg-1 order-xl-1 mt-3">
          <el-card
            v-loading="loading"
            class="el-card-height-max"
          >
            <div>
              <h4>Typ klienta</h4>
              <hr>
            </div>
            <div class="row">
              <div class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.type_contact)"
                  :errors="formErrors"
                  prop="type_contact"
                  label="Wybierz typ klienta"
                >
                  <el-select
                    v-model="form.type_contact"
                    v-validate="rules.type_contact"
                    class="w-100"
                    placeholder="Typ klienta"
                    name="type_contact"
                  >
                    <el-option
                      v-for="(item,index) in type_client"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.source)"
                  :errors="formErrors"
                  prop="source"
                  label="Źródło kontaktu"
                >
                  <el-select
                    v-model="form.source"
                    v-validate="rules.source"
                    class="w-100"
                    name="source"
                    placeholder="Źródło kontaktu"
                  >
                    <el-option
                      v-for="(item,index) in sourceContactList"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.interested)"
                  :errors="formErrors"
                  prop="interested"
                  label="Czym zainteresowany?"
                >
                  <el-select
                    v-model="form.interested"
                    v-validate="rules.interested"
                    class="w-100"
                    name="interested"
                    placeholder="Wybierz"
                  >
                    <el-option
                      v-for="(item,index) in interested"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
              <div class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :required="checkRequired(rules.provider)"
                  :errors="formErrors"
                  prop="provider"
                  label="Dostawca?"
                >
                  <el-select
                    v-model="form.provider"
                    v-validate="rules.provider"
                    class="w-100"
                    name="provider"
                    placeholder="Wybierz"
                  >
                    <el-option
                      v-for="(item,index) in provider"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
              </div>
            </div>
          </el-card>
        </div>
        <div
          v-if="form.type_contact != 0"
          class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1 mt-3"
        >
          <el-card v-loading="loading">
            <div>
              <h4>Dane firmy</h4>
              <hr>
            </div>
            <div class="row">
              <div class="col-4">
                <custom-form-item
                  :required="checkRequired(rules.nip)"
                  :errors="formErrors"
                  prop="nip"
                  label="NIP"
                >
                  <el-input
                    v-model="form.nip"
                    v-validate="rules.nip"
                    name="nip"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-4">
                <custom-form-item
                  :required="checkRequired(rules.regon)"
                  :errors="formErrors"
                  prop="regon"
                  label="REGON"
                >
                  <el-input
                    v-model="form.regon"
                    v-validate="rules.regon"
                    name="regon"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div
                v-if="form.type_contact == 2"
                class="col-4"
              >
                <custom-form-item
                  :required="checkRequired(rules.krs)"
                  :errors="formErrors"
                  prop="krs"
                  label="KRS"
                >
                  <el-input
                    v-model="form.krs"
                    v-validate="rules.krs"
                    name="krs"
                    type="text"
                  />
                </custom-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <custom-form-item
                  :required="checkRequired(rules.name_company)"
                  :errors="formErrors"
                  prop="name_company"
                  label="Nazwa firmy"
                >
                  <el-input
                    v-model="form.name_company"
                    v-validate="rules.name_company"
                    name="name_company"
                    type="text"
                  />
                </custom-form-item>
              </div>
            </div>
          </el-card>
        </div>
        <div
          v-if="form.type_contact != 0"
          class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1 mt-3"
        >
          <el-card>
            <div class="row">
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="representatnt1_name"
                  label="Reprezentant 1"
                >
                  <el-input
                    v-model="form.representatnt1_name"
                    name="representatnt1_name"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="representatnt1_phone"
                  label="Telefon reprezentanta 1"
                >
                  <el-input
                    v-model="form.representatnt1_phone"
                    name="representatnt1_phone"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="representatnt1_email"
                  label="Email reprezentanta 1"
                >
                  <el-input
                    v-model="form.representatnt1_email"
                    name="representatnt1_email"
                    type="text"
                  />
                </custom-form-item>
              </div>

              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="representatnt2_name"
                  label="Reprezentant 2"
                >
                  <el-input
                    v-model="form.representatnt2_name"
                    name="representatnt2_name"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="representatnt2_phone"
                  label="Telefon reprezentanta 2"
                >
                  <el-input
                    v-model="form.representatnt2_phone"
                    name="representatnt2_phone"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="representatnt2_email"
                  label="Email reprezentanta 2"
                >
                  <el-input
                    v-model="form.representatnt2_email"
                    name="representatnt2_email"
                    type="text"
                  />
                </custom-form-item>
              </div>
            </div>
          </el-card>
        </div>
        <div class="col-lg-8 col-xl-8 col-sm-12 order-lg-1 order-xl-1 mt-3">
          <el-card
            v-loading="loading"
            class="el-card-height-max"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h4>Dane adresowe</h4>
              </div>
              <div>
                <el-button
                  type="mini"
                  @click="resetTerytForm"
                >
                  Resetuj formularz
                </el-button>
              </div>
            </div>
            <hr>
            <teryt-address
              :rules="rules"
              :form="form"
              :edit="isEdit"
            />
          </el-card>
        </div>
        <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1 mt-3">
          <el-card
            v-loading="loading"
            class="el-card-height-max"
          >
            <div>
              <h4>Dane kontaktowe</h4>
              <hr>
            </div>
            <div>
              <custom-form-item
                :required="checkRequired(rules.email)"
                :errors="formErrors"
                prop="email"
                label="Adres email"
              >
                <el-input
                  v-model="form.email"
                  v-validate="rules.email"
                  name="email"
                  type="text"
                />
              </custom-form-item>

              <custom-form-item
                :required="checkRequired(rules.phone)"
                :errors="formErrors"
                prop="phone"
                label="Telefon"
              >
                <el-input
                  v-model="form.phone"
                  v-validate="rules.phone"
                  name="phone"
                  type="text"
                />
              </custom-form-item>

              <custom-form-item
                :errors="formErrors"
                prop="phone_2"
                label="Telefon 2"
              >
                <el-input
                  v-model="form.phone_2"
                  name="phone_2"
                  type="text"
                />
              </custom-form-item>

              <custom-form-item
                :errors="formErrors"
                prop="google_map_url"
                label="Adres do mapy URL Google"
              >
                <el-input
                  v-model="form.google_map_url"
                  name="google_map_url"
                  type="text"
                />
              </custom-form-item>
            </div>
          </el-card>
        </div>
        <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1 mt-3">
          <el-card
            v-loading="loading"
            class="el-card-height-max"
          >
            <div>
              <h4>Dane dodatkowe klienta</h4>
              <hr>
            </div>
            <div class="row">
              <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="roof_type"
                  label="Rodzaj dachu"
                >
                  <el-select
                    v-model="form.roof_type"
                    :filterable="true"
                    class="w-100"
                    name="roof_type"
                  >
                    <el-option
                      v-for="(item,index) in $config.agreementSettings.data.roof_type.settings"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>

                <custom-form-item
                  :errors="formErrors"
                  prop="roof_area"
                  label="Powierzchnia dachu"
                >
                  <input-number
                    v-model="form.roof_area"
                    :precision="2"
                    class="w-100"
                    name="roof_area"
                    type="text"
                  />
                </custom-form-item>

                <custom-form-item
                  :errors="formErrors"
                  prop="annual_energy_consumption"
                  label="Roczne zużycie energii"
                >
                  <input-number
                    v-model="form.annual_energy_consumption"
                    :precision="2"
                    class="w-100"
                    name="annual_energy_consumption"
                    type="text"
                  />
                </custom-form-item>
              </div>
              <div class="col-lg-8 col-xl-8 col-sm-12 order-lg-1 order-xl-1">
                <custom-form-item
                  :errors="formErrors"
                  prop="notes"
                  label="Notatki odnośnie kontaktu"
                >
                  <vue-editor
                    id="notes"
                    v-model="form.notes"
                    :editor-toolbar="$config.wysiwygToolbar"
                    name="notes"
                    placeholder="Wpisz treść wiadomości"
                  />
                </custom-form-item>
              </div>
            </div>
          </el-card>
        </div>
        <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1 mt-3">
          <el-card v-loading="loading">
            <custom-form-item
              :errors="formErrors"
              prop="notes_office"
              label="Notatki Biuro"
            >
              <vue-editor
                id="notes_office"
                v-model="form.notes_office"
                :editor-toolbar="$config.wysiwygToolbar"
                name="notes_office"
                placeholder="Wpisz treść wiadomości"
              />
            </custom-form-item>

            <div class="permission-switch">
              <div>
                Klient wyraża zgodę na przetwarzanie danych osobowych przekazanych za pośrednictwem formularza kontaktowego, wiadomości email i/lub innego środka komunikacji, w celu przedstawienia lub omówienia przedstawionej oferty przez Ekoradcy Sp. z o.o. z siedzibą w Krakowie.
              </div>
              <el-switch
                v-model="form.permission_first"
                class="my-2"
                active-text="Tak"
                inactive-text="Nie"
              />
            </div>
            <el-form-item class="text-end mt-2">
              <el-button
                v-if="isEdit"
                class="button-mobile-app"
                type="info"
                @click="$router.go(-1)"
              >
                Powrót
              </el-button>
              <el-button
                class="button-mobile-app"
                native-type="submit"
                type="primary"
              >
                {{ isEdit ? 'Zapisz' : 'Utwórz' }}
              </el-button>
              <el-button
                v-if="isEdit"
                class="button-mobile-app"
                @click="saveAndOut"
              >
                Zapisz i zamknij
              </el-button>
            </el-form-item>
          </el-card>
        </div>
      </div>
    </el-form>
  </section>
</template>

<script>
  import forms from '@/shared/mixins/forms'
  import { postRequest, putRequest } from '@/shared/utils/http'
  import { clients as clientsRules } from '@/admin/utils/validation'
  import { VueEditor } from 'vue2-editor'
  import Avatar from 'vue-avatar'
  import FormAlerts from '../../../shared/components/FormAlerts'
  import attributes from './attributes'
  import TerytAddress from '../../components/TerytAddress'

  export default {
    name: 'ClientsForm',
    components: { FormAlerts, TerytAddress, Avatar, VueEditor },

    mixins: [
      forms
    ],

    props: {
      form: {
        type: Object,
        required: true
      },

      isEdit: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        // all data
        sourceContact: '',
        gusActive: false,
        sourceContactList: this.$config.agreementSettings.data.source.settings,
        type_client: this.$config.agreementSettings.data.type_client.settings,
        interested: this.$config.agreementSettings.data.interested.settings,
        companies: this.$config.agreementSettings.data.companies.settings,
        provider: this.$config.agreementSettings.data.electric_provider.settings
      }
    },

    computed: {
      currentId: function () {
        return this.isEdit ? this.$route.params.id : 0
      },

      rules: function () {
        return clientsRules(this.currentId)
      },

      fullName: function () {
        return this.form.firstName + ' ' + this.form.lastName
      },

      attributes: function () {
        return attributes
      },
      idTypeOptions: function () {
        const result = []
        Object.entries(this.$config.clients.id_types).forEach(([value, label]) => {
          result.push({
            value: value.name,
            label
          })
        })
        return result
      }
    },

    methods: {
      submitForm: function () {
        return new Promise((resolve, reject) => {
          let request
          if (this.isEdit) {
            request = putRequest('/api/clients/' + this.$route.params.id, this.form)
          } else {
            request = postRequest('/api/clients', this.form)
          }

          request
            .then(response => {
              this.$emit('saved')
              if (this.outAfterSave || !this.isEdit) {
                this.$router.push({ name: 'admin.clients.show', params: { id: response.data.id } })
              }
              this.outAfterSave = false
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              this.outAfterSave = false
              this.loading = false
              reject(error)
            })
        })
      },
      saveAndOut () {
        this.outAfterSave = true
        this.submitForm()
      },
      resetTerytForm () {
        this.$events.fire('reset-teryt')
      }
    }
  }
</script>
<style scoped>
.avatar-lead{
  position:absolute;
  top: -40px;
  right: 40px;
}
.el-card-height-max{
  min-height: 100%;
}
</style>
