<template>
  <div>
    <el-card
      v-loading="loading"
      class="mt-3 ">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h5 class="my-0">Numer rozliczanej umowy: {{ item.product_number }} - {{ item.company }}</h5>
          <h6 class="my-0">Nazwa rozliczenia: {{ item.name }}</h6>
        </div>
        <div><el-switch
          v-model="item.mode"
          active-text="Tryb manualny"
          inactive-text="Tryb normalny"
          @change="changeMode"
        /></div>
      </div>
    </el-card>
    <div class="row">
      <div class="col-xl-9 col-lg-9 col-md-12">
        <el-card
          v-loading="loading"
          class="box-card mt-3">
          <div> <h5 class="my-0">Prowizje</h5><hr></div>
          <div class="row">
            <div class="col-3">
              <dl>
                <dt>Prowizja Firmy</dt>
                <dd>{{ item.provision_company ? item.provision_company+ ' PLN' : 'Brak wprowadzonej' }} </dd>
              </dl>
            </div>
            <div class="col-3">
              <dl>
                <dt>Prowizja PH</dt>
                <dd>{{ item.provision_ph ? item.provision_ph+ ' PLN' : 'Brak wprowadzonej' }} </dd>
              </dl>
            </div>
            <div class="col-3">
              <dl>
                <dt>Prowizja Agenta</dt>
                <dd>{{ item.provision_ag ? item.provision_ag+ ' PLN' : 'Brak wprowadzonej' }} </dd>
              </dl>
            </div>
            <div class="col-3">
              <dl>
                <dt>Rozliczenie Agenta</dt>
                <dd>{{ item.provision_ag_settlement ? item.provision_ag_settlement + ' PLN' : 'Brak wprowadzonej' }}</dd>
              </dl>
            </div>
          </div>
        </el-card>
        <el-card
          v-loading="loading"
          class="my-3">
          <div>
            <div class="d-flex justify-content-between align-items-center">
              <div> <h5 class="my-0">Dodatkowe informacje</h5></div>
              <div><el-button
                v-if="!showNotesInput"
                size="mini"
                type="primary"
                @click="openInputNotes">Zmień notatki</el-button>
                <el-button
                  v-if="showNotesInput"
                  size="mini"
                  type="primary"
                  @click="updateNotes">Zapisz zmiany</el-button>
              </div>
            </div>
            <hr>
          </div>
          <div>
            <div v-if="showNotesInput">
              <el-input
                v-model="newNotesInput"
                type="textarea"
                class="w-100"
                placeholder="Wprowadź notatki"/>
            </div>
            <div v-else>
              <dl>
                <dt>Notatki</dt>
                <dd v-html="item.notes"></dd>
              </dl>
            </div>
          </div>
        </el-card>
      </div>
      <div class="col-3">
        <el-card
          v-loading="loading"
          class="mt-3">
          <el-button
            class="w-100"
            type="primary"
            @click="handleRouteProduct"><i class="fas fa-long-arrow-alt-left"></i> Przejdź do umowy</el-button>
        </el-card>
        <el-card
          v-loading="loading"
          class="mt-3">
          <div>
            <h5 class="my-0">Daty dokumentacji</h5>
            <hr>
          </div>
          <dl>
            <dt>Data wygenerowania oferty</dt>
            <dd>{{ item.offer_generate_date }}</dd>
          </dl>
          <dl>
            <dt>Data podpisania umowy</dt>
            <dd>{{ item.agreement_sign }}</dd>
          </dl>

        </el-card>
        <el-card
          v-loading="loading"
          class="mt-3">
          <div>
            <h5 class="my-0">Statusy umowy</h5>
            <hr>
          </div>
          <dl>
            <dt>Status Dok. Biuro</dt>
            <dd>{{ $config.agreementSettings.data.statuses_office.settings[item.documents_status_office].label }}</dd>
          </dl>
          <dl>
            <dt>Status Kredyt Biuro</dt>
            <dd>{{ $config.agreementSettings.data.statuses_office.settings[item.documents_credit_status_office].label }}</dd>
          </dl>
          <dl>
            <dt>Status Dok. BO</dt>
            <dd>{{ $config.agreementSettings.data.statuses_office.settings[item.documents_status_backoffice].label }}</dd>
          </dl>
          <dl>
            <dt>Status Kredyt BO</dt>
            <dd>{{ $config.agreementSettings.data.statuses_office.settings[item.documents_credit_status_backoffice].label }}</dd>
          </dl>
          <dl>
            <dt>Status Dok. Dostawca</dt>
            <dd>{{ $config.agreementSettings.data.statuses_office.settings[item.documents_status_provider].label }}</dd>
          </dl>
          <dl>
            <dt>Status Kredyt Dostawc</dt>
            <dd>{{ $config.agreementSettings.data.statuses_office.settings[item.documents_credit_status_provider].label }}</dd>
          </dl>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
  import {deleteRequest, getRequest, patchRequest} from '@/shared/utils/http'

  export default {
    name: 'Show',
    data () {
      return {
        item: {},
        loading: true,

        showBasePriceInput: false,
        showCalculatorPriceInput: false,
        showSellingPriceInput: false,
        showNotesInput: false,

        newBasePrice: null,
        newCalculatorPrice: null,
        newSellingPriceInput: null,
        newNotesInput: null
      }
    },
    computed: {
      id () {
        return parseInt(this.$route.params.id)
      }
    },
    mounted () {
      this.$root.title = 'Rozliczenie umowy'
      getRequest('/api/settlements/' + this.id)
        .then((response) => {
          this.item = response.data
          this.$root.title = this.$root.title + ' ' + this.item.product_number
          this.loading = false
        }).catch((response) => {
          this.$router.go(-1)
          this.loading = false
        })
    },
    methods: {
      deleteSettlement () {
        this.$confirm('Próbujesz usunąć rozliczenie z systemu. Kontynuować?', 'Uwaga', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Przerwij',
          type: 'info'
        }).then(() => {
          this.deleteSettlementRequest()
        })
      },
      settleAgreement () {
        this.$confirm('Próbujesz zatwierdzić rozliczenie. Kontynuować?', 'Uwaga', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Przerwij',
          type: 'info'
        }).then(() => {
          this.pathUpdate({status: 2})
        })
      },
      unSettleAgreement () {
        this.$confirm('Próbujesz nierozliczyć tego rozliczenia. Kontynuować?', 'Uwaga', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Przerwij',
          type: 'info'
        }).then(() => {
          this.pathUpdate({status: 3})
        })
      },
      inProgressSettleAgreement () {
        this.$confirm('Ustawiasz rozliczenie jako przetwarzane w trakcie. Kontynuować?', 'Uwaga', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Przerwij',
          type: 'info'
        }).then(() => {
          this.pathUpdate({status: 1})
        })
      },
      changeMode (val) {
        this.pathUpdate({mode: val})
      },
      pathUpdate (object) {
        patchRequest('/api/settlements/' + this.id, object)
          .then(response => {
            this.item = response.data
            this.resetForm()
            this.$snotify.success('Dane rozliczenia zaktualizowane.')
          }).catch(() => {
            this.$snotify.error('Wystąpił problem z aktualizacją danych.')
          })
      },
      deleteSettlementRequest () {
        deleteRequest('/api/settlements/' + this.id)
          .then(response => {
            this.$snotify.success('Usunięto rozliczenie.')
            this.$router.push({name: 'admin.settlements.index'})
          }).catch(() => {
            this.$snotify.error('Wystąpił problem z usunięciem rozliczenia.')
          })
      },
      resetForm () {
        this.newCalculatorPrice = null
        this.newSellingPriceInput = null
        this.newBasePrice = null
        this.showBasePriceInput = false
        this.showCalculatorPriceInput = false
        this.showSellingPriceInput = false
        this.showNotesInput = false
        this.newNotesInput = null
      },
      updateValue (type) {
        if (type === 'selling') {
          this.pathUpdate({manual_selling_price: this.newSellingPriceInput})
        }
        if (type === 'base') {
          this.pathUpdate({manual_base_price: this.newBasePrice})
        }
        if (type === 'calculator') {
          this.pathUpdate({manual_calculator_price: this.newCalculatorPrice})
        }
      },
      updateNotes () {
        let note = this.newNotesInput
        this.pathUpdate({notes: note})
      },
      openInputNotes () {
        this.newNotesInput = this.item.notes
        this.showNotesInput = true
      },
      handleRouteProduct () {
        this.$router.push({ name: 'admin.products.show', params: {id: this.item.product_id}})
      }
    }
  }
</script>

<style scoped>
.el-button + .el-button {
  margin-left: 0px !important;
  margin-top:10px
}
</style>
