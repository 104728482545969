<template>
  <div>
    <div class="row">
      <div class="col">
        <el-form @submit.native.prevent="doFilter">
          <el-form-item prop="filterText">
            <el-input
              v-model="filterText"
              type="text">
              <el-button
                slot="append"
                type="primary"
                class="button-filter"
                native-type="submit"
                @click.prevent="doFilter">
                <i class="fas fa-search text-white"></i>
                <span class="text-white">Szukaj</span>
              </el-button>
            </el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="col-auto">
        <el-button
          v-show="!filtersOpened"
          type="text"
          class="resetFilterBtn"
          @click="resetFilter">Reset
        </el-button>
      </div>
    </div>

    <div
      v-if="hasSlotFilters"
      class="table-filters">
      <transition name="el-zoom-in-top">
        <el-form
          v-show="filtersOpened"
          ref="filtersForm"
          label-position="top"
          @submit.native.prevent="handleFilter('filtersForm')">
          <slot></slot>
          <el-form-item>
            <el-button
              native-type="submit"
              type="primary">Filtruj
            </el-button>

            <el-button
              type="text"
              class="resetFilterBtn"
              @click="resetFilter">Reset
            </el-button>
          </el-form-item>
        </el-form>
      </transition>

      <div
        class="table-filters--handle"
        @click="toggleFilters">
        <span><i :class="filtersCollapseIcon"></i> {{ filtersCollapseTitle }} <i :class="filtersCollapseIcon"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        filterText: '',
        filtersOpened: false
      }
    },

    computed: {
      filters () {
        return this.data
      },

      hasSlotFilters () {
        return this.$slots.default
      },

      filtersCollapseTitle () {
        return this.filtersOpened ? 'Ukryj filtry' : 'Pokaż filtry'
      },

      filtersCollapseIcon () {
        return this.filtersOpened ? 'fas fa-chevron-up' : 'fas fa-chevron-down'
      }
    },

    created () {
      this.$events.$on('filter-init', eventData => this.onFilterInit(eventData))
    },

    methods: {
      toggleFilters () {
        this.filtersOpened = !this.filtersOpened
      },

      onFilterInit (eventData) {
        this.filterText = eventData
      },

      doFilter (event) {
        this.$events.fire('keyword-set', this.filterText, event)
      },

      resetFilter () {
        this.filterText = ''
        this.filtersOpened = false
        this.$events.fire('filter-reset')
      },

      handleFilter () {
        this.filtersOpened = false
        this.$events.fire('filter-handle')
      },

      tableReload (event) {
        this.$events.fire('reload-table', event)
      }
    }
  }
</script>
<style scoped>
.button-filter{
  border-radius: 0px !important;
}
</style>
