<template>
  <div>
    <el-card
      v-loading="loading"
      class="mt-3"
    >
      <el-row style="text-align: right;">
        <el-button
          v-if="$can('admin.calculations.addons.update')"
          class="button-mobile-app"
          type="primary"
          @click="editAction"
        >
          <i class="far fa-edit"></i> Edytuj
        </el-button>
        <el-button
          v-if="$can('admin.calculations.addons.delete')"
          class="button-mobile-app"
          type="danger"
          @click="deleteAction"
        >
          <i class="far fa-trash-alt"></i> Usuń
        </el-button>
        <el-button @click="$router.go(-1)">
          Powrót
        </el-button>
      </el-row>
    </el-card>
    <div class="row mt-3">
      <div class="col-lg-8 col-md-12 col-xl-8">
        <el-card v-loading="loading">
          <div>
            <h5 class="my-0 py-0">
              Dane techniczne
            </h5>
            <hr>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1">
              <dl><dt>Nazwa panelu</dt><dd>{{ item.name }}</dd></dl>
            </div>
            <div
              v-if="item.company"
              class="col-lg-4 col-md-12 col-xl-4 order-lg-2 order-xl-2"
            >
              <dl><dt>Firma</dt><dd>{{ item.company.data.name }}</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-3 order-xl-3">
              <dl><dt>Typ</dt><dd>{{ item.type }}</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-4 order-xl-4">
              <dl><dt>Koszt</dt><dd>{{ cost }}</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-5 order-xl-5">
              <dl><dt>Jednostka</dt><dd>{{ item.unit }}</dd></dl>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { deleteRequest, getRequest, patchRequest } from '@/shared/utils/http'
  import { moneyFormatter } from '@/admin/utils/helpers'

  export default {
    name: 'Show',
    data () {
      return {
        item: '',
        companies: [],
        dataForm: [],
        loading: true
      }
    },
    computed: {
      id () {
        return parseInt(this.$route.params.id)
      },
      cost () {
        return moneyFormatter(this.item.cost)
      }
    },
    mounted () {
      this.$root.title = 'Dodatki - karta'

      getRequest('/api/addons/' + this.id)
        .then((response) => {
          this.item = response.data
          this.loading = false
        })
    },
    methods: {
      editAction () {
        this.$router.push({ name: 'admin.calculations.addons.update', params: { id: this.id } })
      },
      deleteAction: function () {
        this.$confirm('Czy chcesz usunąć ten dodatek?', 'Uwaga', {
          confirmButtonText: this.label,
          cancelButtonText: this.cancelLabel,
          type: 'warning'
        })
          .then(() => {
            this.$emit('requestStart')
            deleteRequest('/api/addons/' + this.id)
              .then(() => {
                this.$emit('success')
                this.$router.push({ path: '/admin/calculations/addons' })
              })
              .catch(() => {
                this.$emit('fail')
              })
          })
          .catch(() => {
          })
      }
    }
  }
</script>

<style scoped>
.list-group-item {
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
  margin-left: -35px;
}
.rounded-pill{
  border-radius: 11px;
  padding:2px 5px;
  color:white;
}
</style>
