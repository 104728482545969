<template>
  <div>
    <el-card
      v-loading="loading"
      class="mt-3"
    >
      <el-row style="text-align: right;">
        <el-button
          v-if="$can('admin.calculations.panels.update')"
          class="button-mobile-app"
          type="primary"
          @click="editAction"
        >
          <i class="far fa-edit"></i> Edytuj
        </el-button>
        <el-button
          v-if="$can('admin.calculations.panels.delete')"
          class="button-mobile-app"
          type="danger"
          @click="deleteAction"
        >
          <i class="far fa-trash-alt"></i> Usuń
        </el-button>
        <el-button @click="$router.go(-1)">
          Powrót
        </el-button>
      </el-row>
    </el-card>
    <div class="row mt-3">
      <div class="col-lg-9 col-md-12 col-xl-9">
        <el-card v-loading="loading">
          <div>
            <h5 class="my-0 py-0">
              Dane konstrukcji
            </h5>
            <hr>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1">
              <dl>
                <dt>Nazwa panelu</dt>
                <dd>{{ item.name }}</dd>
              </dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-3 order-xl-3">
              <dl>
                <dt>Konstrukcja</dt>
                <dd>{{ constructionType }}</dd>
              </dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-3 order-xl-3">
              <dl>
                <dt>Cennik</dt>
                <dd>{{ item.prices_table }}</dd>
              </dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-3 order-xl-3">
              <dl>
                <dt>Firma</dt>
                <dd>{{ company }}</dd>
              </dl>
            </div>
          </div>
        </el-card>
        <provisions
          v-if="item.provisions"
          :provisions="item.provisions.data"
          @update-provisions="updateProvisions"
          @add-provisions="addProvision"
        />
      </div>
      <div class="col-lg-3 col-md-12 col-xl-3">
        <el-card v-loading="loading">
          <div>
            <h5 class="my-0 py-0">
              Dane finansowe
            </h5>
            <hr>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xl-12 order-lg-2 order-xl-3">
              <dl>
                <dt>Kwota za kWp</dt>
                <dd>{{ perKwp }}</dd>
              </dl>
            </div>
            <div class="col-lg-12 col-md-12 col-xl-12 order-lg-3 order-xl-3">
              <dl>
                <dt>Kwota za Panel</dt>
                <dd>{{ perPanel }}</dd>
              </dl>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { deleteRequest, getRequest } from '@/shared/utils/http'
  import { moneyFormatter } from '@/admin/utils/helpers'
  import Provisions from './components/Provisions'

  export default {
    name: 'Show',
    components: { Provisions },
    data () {
      return {
        item: '',
        companies: [],
        dataForm: [],
        loading: true
      }
    },
    computed: {
      id () {
        return parseInt(this.$route.params.id)
      },
      perKwp () {
        return moneyFormatter(this.item.per_kwp)
      },
      perPanel () {
        return moneyFormatter(this.item.per_panel)
      },
      company () {
        return this.item.companies ? this.item.companies.name : ''
      },
      constructionType () {
        const typeConstruction = this.$config.typeConstruction.find(el => el.id === this.item.type)
        return typeConstruction ? typeConstruction.name : 'Błąd w wyświetleniu konstrukcji'
      }
    },
    mounted () {
      getRequest('/api/constructions/' + this.id)
        .then((response) => {
          this.item = response.data
          this.loading = false
          this.$root.title = 'Konstrukcja ' + this.item.name
        })
    },
    methods: {
      editAction () {
        this.$router.push({ name: 'admin.calculations.constructions.update', params: { id: this.id } })
      },
      deleteAction: function () {
        this.$confirm('Czy chcesz usunąć tą konstrukcję?', 'Uwaga', {
          confirmButtonText: this.label,
          cancelButtonText: this.cancelLabel,
          type: 'warning'
        })
          .then(() => {
            this.$emit('requestStart')
            deleteRequest('/api/constructions/' + this.id)
              .then(() => {
                this.$emit('success')
                this.$router.push({ path: '/admin/calculations/constructions' })
              })
              .catch(() => {
                this.$emit('fail')
              })
          })
          .catch(() => {
          })
      },
      updateProvisions (val, index) {
        this.item.provisions.data[index] = val
      },
      addProvision (val) {
        this.item.provisions.data.push(val)
      }
    }

  }
</script>

<style scoped>
.list-group-item {
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
  margin-left: -35px;
}

.rounded-pill {
  border-radius: 11px;
  padding: 2px 5px;
  color: white;
}
</style>
