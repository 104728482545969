<template>
  <div class="box-card dashboard-tasks">
    <div class="row">
      <div class="d-flex justify-content-between flex-lg-row flex-xl-row flex-column flex-nowrap align-items-center">
        <div class="dashboard-tasks-day-number">
          Dziś: {{ dayNumber }} {{ monthName }} {{ year }}
        </div>
        <div>
          <news-room />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { getRequest } from '../../../../shared/utils/http'
  import NewsRoom from '@/admin/pages/dashboard/components/NewsRoom'

  export default {
    name: 'TaskBlock',
    components: { NewsRoom },
    data: () => {
      return {
        monthName: '',
        dayNumber: '',
        year: '',
        tasks: [],
        loading: true,
        tasksStats: {
          today: 0,
          tomorrow: 0,
          late: 0
        }
      }
    },

    created () {
      this.monthName = moment().locale('pl').format('MMMM')
      this.dayNumber = moment().format('DD')
      this.year = moment().format('YYYY')
    },
    // optymalizacja pobieranych danych z systemu
    mounted () {
      // if (this.$can('admin.tasks.read')) {
      //   getRequest('/api/tasks-stats')
      //     .then((response) => {
      //       this.tasksStats = {
      //         tomorrow: response.data.tomorrow,
      //         today: response.data.today,
      //         late: response.data.late
      //       }
      //       this.isLoaded = true
      //     })
      //
      //   getRequest('/api/tasks-list')
      //     .then((response) => {
      //       this.tasks = response.data
      //       this.loading = false
      //     })
      // }
    },
    methods: {
      // actionGoTo: function (taskId) {
      //   this.$router.push({ name: 'admin.task.show', params: { id: taskId } })
      // },
      goToCreator (link) {
        this.$router.push({ path: link })
      },
      goToModule (link) {
        this.$router.push({ path: link })
      }
    }
  }
</script>
<style scoped>
.dashboard-tasks-day-number {
  font-size:18px;
}
.dashboard-tasks-name-month{
  font-size:14px
}
</style>
