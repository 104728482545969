<template>
  <section>
    <div
      v-if="item.offer_blocked"
      class="my-3"
    >
      <el-alert
        :closable="false"
        title="Oferta została zablokowana. "
        type="error"
        effect="dark"
        description="Jeśli widzisz tą informację, przygotuj nową kalkulację i ofertę."
      />
    </div>

    <el-card
      v-loading="loading"
      class="mb-2"
    >
      <div class="d-flex justify-content-end align-items-center">
        <div class="buttons">
          <el-button
            v-if="!item.offer_blocked && item.calculated_person.type === 'client' "
            class="button-mobile-app"
            type="primary"
            @click="createDraftAgreement()"
          >
            <i class="fas fa-plus"></i> Nowa umowa z oferty
          </el-button>
          <el-button
            v-if="item.type === 'simplified'"
            class="button-mobile-app"
            type="primary"
            @click="downloadSimplifiedOffer"
          >
            Generuj ofertę uproszczoną
          </el-button>
          <el-button
            v-if="!item.offer_blocked && $can('admin.offers.block')"
            class="button-mobile-app"
            type="danger"
            @click="blockStatusUpdate(true)"
          >
            <i class="fas fa-lock"></i> Zablokuj
          </el-button>
          <el-button
            v-if="item.offer_blocked && $can('admin.offers.block')"
            class="button-mobile-app"
            type="primary"
            @click="blockStatusUpdate(false)"
          >
            <i class="fas fa-unlock"></i> Odblokuj
          </el-button>
          <el-button
            v-if="$can('admin.offers.delete')"
            class="button-mobile-app"
            type="danger"
            @click="deleteAction"
          >
            <i class="far fa-trash-alt"></i> Usuń
          </el-button>
          <el-button
            v-if="$can('admin.calculations.read') && item.calculation"
            class="button-mobile-app"
            type="primary"
            @click="moveToCalculation"
          >
            <i class="fas fa-long-arrow-alt-left"></i> Kalkulacja
          </el-button>
          <el-button
            class="button-mobile-app"
            @click="reverseAction"
          >
            <i class="fas fa-long-arrow-alt-left"></i> Powrót
          </el-button>
          <el-row style="text-align: right;" />
        </div>
      </div>
    </el-card>

    <el-card
      v-loading="loading"
      class="mt-3"
    >
      <div class="d-flex justify-content-between flex-lg-row flex-xl-row flex-column flex-nowrap align-items-center">
        <img
          v-if="item.kits"
          :src="item.kits.logo"
          class="image mt-1"
        >
        <h1
          v-if="item.inverterItem || item.kits"
          class="my-0 mr-md-auto font-weight-normal"
        >
          Oferta: {{ item.kits ? item.kits.kwp : '' }} kWp,
          {{ item.kits ? item.kits.count_solar_panel: '' }}x {{ item.kits ? item.kits.solar_panel_name :'' }},
          {{ item.kits ? item.kits.count_inverter : '' }}x {{ item.inverterItem ? item.inverterItem : item.kits.inverter }},
          {{ item.construction_name }}<br>
          <small class="my-0 py-0">Kalkulacja oferty fotowoltaicznej dla <a
            class="cursor-pointer"
            @click="actionGoToPerson"
          >{{ item.calculated_person ? item.calculated_person.full_name: '' }}</a></small><br>
        </h1>
        <div class="my-2 my-md-0 mr-md-3 mt-sm-5">
          <el-switch
            v-model="item.type_offer"
            :disabled="item.offer_blocked"
            active-text="Oferta biznesowa"
            inactive-text="Oferta konsumencka"
            @change="changeTypeOffer"
          />
        </div>
      </div>
    </el-card>
    <setting-map-component
      v-if="item.type === 'main'"
      :disable-module="item.offer_blocked"
      :show-dialog="showMapSetting"
      :data-area="item.data_area"
      :data-azimuth="item.data_azimuth"
      :data-center="item.data_center"
      @close-map-setting-dialog="showMapSetting = false"
      @update-map-offer="updateOfferModel"
    />
    <div class="row">
      <div class="col-12">
        <el-card
          v-loading="loading"
          class="mt-3"
        >
          <div class="row">
            <div class="col-md-6 col-xl-12">
              <div class="border-light">
                <div>
                  <div class="row d-block d-xl-flex align-items-center">
                    <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                      <div class="icon icon-shape icon-md icon-shape-blue rounded mr-4 mr-sm-0">
                        <span class="number-intro">1.</span>
                      </div>
                      <div class="d-sm-none">
                        <h2 class="h5 ml-2">
                          Oferta została wygenerowana. Co dalej?
                        </h2>
                      </div>
                    </div>
                    <div class="col-12 col-xl-7 px-xl-0">
                      <div class="d-none d-sm-block">
                        <h2 class="h5">
                          Oferta została wygenerowana. Co dalej?
                        </h2>
                      </div>
                      <small class="text-justify">Pozostało do zrobienia wygenerowanie mapy oraz ustawienie raty miesięcznej.
                        Po ustawieniu parametrów uzyskasz dostęp do wydrukowania umowy.</small>
                      <p>Przejdź teraz do generowania oferty.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-12">
              <div class="border-light">
                <div>
                  <div class="row d-block d-xl-flex align-items-center">
                    <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                      <div class="icon icon-shape icon-md rounded mr-4 mr-sm-0">
                        <span class="number-intro">2.</span>
                      </div>
                      <div class="d-sm-none">
                        <h2 class="h5 ml-2">
                          Przejdź do oferty
                        </h2>
                      </div>
                    </div>
                    <div class="col-12 col-xl-7 px-xl-0">
                      <div class="d-none d-sm-block">
                        <h2 class="h5">
                          Drukowanie oferty
                        </h2>
                      </div>
                      <small class="text-justify">Jeśli ustawiłeś parametry z poprzedniego kroku, poniżej włączy się przycisk od drukowania oferty. Jeśli go nie widzisz, upewnij się, że posiadasz wszsytkie potrzebne elementy.</small>
                      <div
                        v-if="item.type === 'main'"
                        class="mt-3"
                      >
                        <el-button
                          v-if="item.loan_price && item.image_data && !item.offer_blocked"
                          class="button-mobile-app w-100 mt-2"
                          type="primary"
                          @click="downloadPDF"
                        >
                          <i class="fas fa-print"></i> Drukuj kartę oferty
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-9 col-xl-9 col-sm-12 order-lg-1 order-xl-1">
        <el-card
          v-loading="loading"
          class="mt-3"
        >
          <div>
            <h5 class="pt-0 mt-0">
              Dane kalkulacji
            </h5>
            <hr>
          </div>
          <div class="content row">
            <div
              v-if="item.user"
              class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1"
            >
              <dl><dt>Przygotował</dt><dd>{{ item.user.full_name }}</dd></dl>
            </div>
            <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
              <dl>
                <dt>Kwota netto</dt><dd>
                  {{ item.price_with_construction }} PLN   <el-tag
                    type="success"
                    size="small"
                    effect="dark"
                  >
                    netto
                  </el-tag>
                </dd>
              </dl>
            </div>
            <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
              <dl>
                <dt>Kwota brutto</dt><dd>
                  {{ item.price_brutto }} PLN   <el-tag
                    type="success"
                    size="small"
                    effect="dark"
                  >
                    brutto
                  </el-tag>
                </dd>
              </dl>
            </div>
            <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
              <dl>
                <dt>VAT</dt><dd>
                  <el-tag
                    type="success"
                    effect="dark"
                  >
                    {{ item.vat_23 ? '23' : '8' }}%
                  </el-tag>
                </dd>
              </dl>
            </div>
            <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Ilośc faz - zaznaczona</dt><dd>{{ item.phase }}</dd></dl>
            </div>
            <div
              v-if="item.discount"
              class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1"
            >
              <dl><dt>Rabat</dt><dd>{{ item.discount ? '-5%' : 'Nie' }}</dd></dl>
            </div>
          </div>
        </el-card>
        <el-card
          v-if="item.construction_slopes"
          v-loading="loading"
          class="mt-3"
        >
          <div>
            <h5 class="my-0 py-0">
              Wybrane konstrukcje na połacie
            </h5>
            <hr>
          </div>
          <div>
            <ul>
              <li v-for="construction in item.construction_slopes">
                {{ $config.constructions[construction.construction_id ] }} - {{ construction.panel_count }} szt.
              </li>
            </ul>
          </div>
        </el-card>

        <el-card
          v-if="item.addons_kit"
          v-loading="loading"
          class="mt-3"
        >
          <div class="card-title">
            <h5 class="pt-0 mt-0">
              Dodatki do kalkulacji
            </h5>
            <hr>
          </div>
          <div v-if="item.addons_kit.length === 0">
            <p>Brak wybranych dodatków dla tej oferty.</p>
          </div>
          <div v-else>
            <div
              v-for="element in item.addons_kit"
              class="d-flex justify-content-between align-items-center"
            >
              {{ element.count }}x {{ element.unit }} - {{ element.name }}
              <span class="badge bg-primary rounded-pill">{{ element.cost }} netto</span>
            </div>
            <small>Kwota netto za daną usługę pomnożona przez ich ilość</small>
          </div>
        </el-card>

        <el-card
          v-loading="loading"
          class="mt-3"
        >
          <div>
            <h5 class="pt-0 mt-0">
              Dane techniczne
            </h5>
            <hr>
          </div>
          <div class="row">
            <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Roczne zużycie klienta</dt><dd>{{ item.yearly_use }} kWh</dd></dl>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Przybliżona powierzchnia dachu</dt><dd>{{ item.area }} m2</dd></dl>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Odchylenie od południa</dt><dd>{{ item.azimuth }} ° </dd></dl>
            </div>
            <div
              v-if="item.inverterItem || item.kits"
              class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
            >
              <dl><dt>Wybrany falownik</dt><dd>{{ item.inverterItem ? item.inverterItem : item.kits.inverter }}</dd></dl>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Autokonsumpcja ustalona</dt><dd>{{ item.consumption * 100 }}%</dd></dl>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Nasłonecznienie roczne</dt><dd>{{ item.insulation }} kWh/m2</dd></dl>
            </div>
            <div
              v-if="item.kits"
              class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
            >
              <dl><dt>Produkcja energii roczna</dt><dd>{{ item.energy_production * item.kits.kwp }} kWh</dd></dl>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Kąt dachu</dt><dd>{{ item.angle }} ° </dd></dl>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Straty temperatury roczne</dt><dd>{{ item.loss_temperature }} %</dd></dl>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Straty systemowe roczne</dt><dd>-6 %</dd></dl>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Straty ułożenia roczne</dt><dd>-{{ item.loss_position }} %</dd></dl>
            </div>
          </div>
        </el-card>
        <el-card
          v-loading="loading"
          class="mt-3"
        >
          <div>
            <h5 class="pt-0 mt-0">
              Dane wybranego zestawu
            </h5>
            <hr>
          </div>
          <div class="row content">
            <div
              v-if="!item.construction_slopes"
              class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1"
            >
              <dl><dt>Typ dachu</dt><dd>{{ item.construction_name }}</dd></dl>
            </div>
            <div
              v-if="item.kits"
              class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
            >
              <dl><dt>Numer zestawu</dt><dd>{{ item.kits.number }}</dd></dl>
            </div>
            <div
              v-if="item.kits"
              class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
            >
              <dl><dt>Firma dostawcy</dt><dd>{{ item.kits.companies }}</dd></dl>
            </div>
            <div
              v-if="item.kits"
              class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
            >
              <dl><dt>Licza paneli</dt><dd>{{ item.kits.count_solar_panel }} szt.</dd></dl>
            </div>
            <div
              v-if="item.kits"
              class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
            >
              <dl><dt>Licza falowników</dt><dd>{{ item.kits.count_inverter }} szt.</dd></dl>
            </div>
            <div
              v-if="item.kits"
              class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
            >
              <dl><dt>Moc instalacji</dt><dd>{{ item.kits.kwp }} kWp</dd></dl>
            </div>
            <div
              v-if="item.kits"
              class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1"
            >
              <dl><dt>Ilość faz</dt><dd>{{ item.kits.phase }}</dd></dl>
            </div>
          </div>
        </el-card>
        <el-card
          v-loading="loading"
          class="mt-3"
        >
          <div>
            <h5 class="pt-0 mt-0">
              Dodatkowe informacje
            </h5>
            <hr>
          </div>
          <div
            v-if="item.kits"
            class="content row"
          >
            <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Gwarancja produktu</dt><dd>{{ item.kits.warranty_product ? item.kits.warranty_product : 'Brak informacji' }}</dd></dl>
            </div>
            <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Gwarancja wydajności paneli</dt><dd>{{ item.kits.warranty_performance ? item.kits.warranty_performance : 'Brak informacji' }}</dd></dl>
            </div>
            <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Informacja dodatkowa (bonus)</dt><dd>{{ item.kits.bonus ? item.kits.bonus : 'Brak informacji' }}</dd></dl>
            </div>
            <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
              <dl><dt>Informacja dodatkowa</dt><dd>{{ item.kits.addons_information ? item.kits.addons_information : 'Brak informacji' }}</dd></dl>
            </div>
          </div>
        </el-card>
        <el-card
          v-if="item.data_month_energy && item.data_month_energy.length > 0 && $can('admin.offers.admin.view')"
          v-loading="loading"
          class="mt-3"
        >
          <div>
            <h5 class="pt-0 mt-0">
              Nasłonecznienie oraz produkowana Energia dla {{ item.kits.kwp }} kWp
            </h5>
            <hr>
          </div>
          <div
            class="row"
          >
            <div class="col-12">
              <line-chart
                :month-data="item.data_month_energy"
                :solar-data="item.data_month_solar"
                :kwp="item.kits.kwp"
                class="my-6"
              />
            </div>
          </div>
        </el-card>
      </div>
      <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-2 order-xl-2">
        <financial-settings
          :disable-module="item.offer_blocked"
          :item="item"
        />
        <calculator-price
          :disable-module="item.offer_blocked"
          :calculation="item"
        />
        <contractor
          v-if="item.calculated_person"
          :item="item.calculated_person"
          :contractor-type="item.calculated_person.type"
        />
      </div>
    </div>
    <div
      v-if="item.inverter_id && item.inverter_id !== item.kits.inverter_id"
      class="row my-4"
    >
      <div class="col">
        <el-alert
          :closeable="false"
          title="Uwaga - dla tej kalkulacji został zmieniony falownik. Kwota kalkulacji dostosowana do wybranego falownika"
          type="warning"
        />
      </div>
    </div>
    <mail-client-component
      v-if="item.calculated_person"
      :email="item.calculated_person.email"
      :dialog-show="showMailDialog"
      @close-dialog="resetMailDialogVisibility"
    />
  </section>
</template>

<script>
  import { deleteRequest, getRequest, patchRequest } from '@/shared/utils/http'
  import LineChart from '../../components/charts/LineChart'
  import DownloadButton from '@/admin/pages/calculator/components/DownloadButton'
  import MapComponent from './components/MapComponent'
  import CalculatorPrice from '@/admin/pages/offers/components/CalculatorPrice'
  import FinancialSettings from '@/admin/pages/offers/components/FinancialSettings'
  import MailClientComponent from '@/admin/pages/offers/components/MailClientComponent'
  import SettingMapComponent from '@/admin/pages/offers/components/SettingMapComponent'
  import Contractor from '@/admin/components/Contractor'
  export default {
    name: 'OfferShow',
    components: {
      Contractor,
      SettingMapComponent,
      MailClientComponent,
      FinancialSettings,
      CalculatorPrice,
      MapComponent,
      DownloadButton,
      LineChart
    },
    data () {
      return {
        item: {},
        loading: true,
        disabledSendButton: false,
        showMailDialog: false,
        showMapSetting: false
      }
    },
    computed: {
      id () {
        return parseInt(this.$route.params.id)
      }
    },
    created () {
      this.$root.title = 'Oferta sprzedażowa'
    },
    mounted () {
      getRequest('/api/offers/' + this.id)
        .then((response) => {
          this.item = response.data
          this.loading = false
        }).catch((response) => {
          this.$router.go(-1)
        })
    },
    methods: {
      actionGoToPerson () {
        const person = this.item.calculated_person
        let route = ''
        if (person.type === 'client') {
          route = 'admin.clients.show'
        } else {
          route = 'admin.lead.show'
        }
        this.$router.push({ name: route, params: { id: person.id } })
      },
      processToAgreement: function () {
        this.$store.dispatch('app/resetCalculationMemory')
        this.$store.dispatch('app/setCalculationMemory', this.item.id)
        this.$router.push({ path: '/admin/products/create/' + this.item.calculated_person.id })
      },
      deleteAction: function () {
        this.loading = true
        this.$confirm('Czy chcesz usunąć tą kalkulację?', 'Uwaga', {
          confirmButtonText: this.label,
          cancelButtonText: this.cancelLabel,
          type: 'warning'
        })
          .then(() => {
            this.$emit('requestStart')
            deleteRequest('/api/offers/' + this.id)
              .then(() => {
                this.$emit('success')
                this.$router.push({ name: 'admin.offers.index' })
              })
              .catch(() => {
                this.$emit('fail')
                this.loading = false
              })
          })
          .catch(() => {
            this.loading = false
          })
      },
      editAction: function () {
        this.loading = true
        this.$confirm('Chcesz zedytować kalkulację, która została zapisana w systemie. Dane mogą nie zgadzać się z tymi przesłanymi klientowi.', 'Uwaga', {
          confirmButtonText: this.label,
          cancelButtonText: this.cancelLabel,
          type: 'warning'
        }).then(() => {
          this.$router.push({ name: 'admin.offers.update', params: { id: this.id } })
          this.loading = false
        })
          .catch(() => {
            this.loading = false
          })
      },
      downloadPDF: function () {
        const href = '/external/offer/' + this.item.token
        window.open(href, '_blank')
      },
      reverseAction: function () {
        this.$router.go(-1)
      },
      moveToCalculation: function () {
        this.$router.push({ name: 'admin.calculations.show', params: { id: this.item.calculation.id } })
      },
      resetButtonsLoading () {
        this.loading = false
        this.disabledSendButton = false
      },
      changeTypeOffer () {
        this.loading = true
        patchRequest('/api/offers/' + this.id, {
          type_offer: this.item.type_offer
        })
          .then((response) => {
            this.item.vat_23 = response.data.vat_23
            this.item.price_brutto = response.data.price_brutto
            this.resetButtonsLoading()
          }).catch((response) => {
            this.resetButtonsLoading()
          })
      },
      resetMailDialogVisibility (event) {
        this.showMailDialog = event
      },
      updateOfferModel (value) {
        // przerobić na ogólną metodę
        this.item.image_data = value
      },
      blockStatusUpdate (event) {
        this.loading = true
        patchRequest(`/api/offers/${this.id}`, { blocked: event })
          .then(response => {
            this.item = response.data
            this.loading = false
          })
      },
      downloadSimplifiedOffer () {
        this.loading = true
        this.$message({
          type: 'info',
          message: 'Pobieranie dokumentu proszę czekać...'
        })
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'no-cors'
          },
          responseType: 'blob'
        }
        axios.post('/api/offers/' + this.item.id + '/download', { checkedGroup: 'test' }, config)
          .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Oferta uproszczona.pdf'
            link.click()
            this.loading = false
          })
      },
      createDraftAgreement () {
        this.loading = true
        getRequest('/api/offers/' + this.id + '/create-sale')
          .then(response => {
            this.loading = false
            this.$router.push({ name: 'admin.sales.update', params: { id: response.data.id } })
          })
          .catch(error => {
            this.loading = false
            this.$snotify.error('Umowa nie została utworzona.')
          })
      }
    }
  }
</script>

<style scoped>

.my-auto{
  margin-top: auto;
  margin-bottom: auto;
}
.full-box{
  min-height:100%;
}
.list-group-item {
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
  margin-left: -35px;
}
.rounded-pill{
  border-radius: 11px;
  padding:2px 5px;
  color:white;
}
span.number-intro {
  font-size: 6em;
  background: #7dab0a;
  color: #fff;
  padding: 0px 21px 10px 30px;
  border-radius: 10px;
}

.el-card-height-max{
  min-height: 100%;
}
</style>
