.header {
    height: 60px;
    margin-bottom: 20px;
    color: #000;
    line-height: 50px;
    margin-top: -8px;
    margin-bottom: -10px;
    margin-top: 0px;

  .w-100-el-card{
    width:100%;
    background: #3f4443;
    padding-left: 20px;
  }
  .el-dropdown {
    margin: 0 1rem;
    //line-height: 2rem;

    .el-dropdown-link {
      color: #fff;
    }

    img {
      margin: 0 5px 0 0;
      vertical-align: middle;
    }
  }

  .el-badge__content {
    &.is-fixed {
      top: 17px;
    }
  }
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
  transition: .38s;
  transform-origin: 50% 50%;
  fill: #fff;
}

.hamburger.is-active {
  transform: rotate(0deg);
}
.notifications {
  .el-dropdown-menu__item.is-disabled {
    pointer-events: auto;
  }

  .icons-bg-round {
    width: 25px;
    height: 25px;
    padding: 5px;
    border-radius: 50%;
  }
}

.img-avatar {
  height: 35px;
  border-radius: 50%;
}

.el-dropdown-menu__item {
  padding: 0;

  a {
    display: block;
    padding: 12px 20px;
    text-align: left;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      padding: 5px 0;
      border-top: 1px solid map_get($colors, lighter-grey);

      &:first-child {
        border-top: 0;
      }
    }
  }
}

.search-box-input{
  width: 300px !important;
}