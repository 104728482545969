body.auth-page {
  //background: url(../../img/15061842_xxl.jpg) no-repeat fixed 50% 50%;
  //background-size: cover;
  //background:#f3f3f3;
  background-image: radial-gradient(circle at 97% 32%, rgba(131, 131, 131,0.05) 0%, rgba(131, 131, 131,0.05) 50%,rgba(20, 20, 20,0.05) 50%, rgba(20, 20, 20,0.05) 100%),
  radial-gradient(circle at 61% 40%, rgba(35, 35, 35,0.05) 0%, rgba(35, 35, 35,0.05) 50%,rgba(239, 239, 239,0.05) 50%, rgba(239, 239, 239,0.05) 100%),
  radial-gradient(circle at 47% 73%, rgba(122, 122, 122,0.05) 0%, rgba(122, 122, 122,0.05) 50%,rgba(5, 5, 5,0.05) 50%, rgba(5, 5, 5,0.05) 100%),
  linear-gradient(90deg, rgb(0, 209, 117),rgb(205, 241, 44)) !important;
}

.auth {
  max-width: 600px;
  background: #fff;
  @media screen and (max-width: 992px) {
    .auth{
      max-width: 100%;
      background: #fff;
    }
  }

  &--header {
    //background: map_get($colors, primary);
    background: #fff;
    text-align: center;
    padding-top: 70px;
    img {
      width: 55%;
    }
  }

  &--content {
    padding: 2.5rem;
  }

  &--footer {
    padding: 2.5rem;
    text-align: center;
  }
}

.auth-wrapper {
  flex: 1;
  width: 95%;
  max-width: 30rem;
  margin: 0 auto;
}