<template>
  <el-dialog
    :visible.sync="showDialog"
    :before-close="stopVisible"
    title="Ręczne dodawanie połaci"
  >
    <el-form
      ref="slopeCreatorForm"
      v-loading="loading"
      :model="form"
      label-position="top"
      @submit.native.prevent="handleForm('slopeCreatorForm')"
    >
      <form-alerts
        :messages="invalidMsg"
      />
      <div>
        <h5 class="py-0 my-0">
          Dodajesz połać dla kalkulacji
        </h5>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <custom-form-item
            :errors="formErrors"
            :required="checkRequired(rules.type_roof)"
            prop="type"
            label="Rodzaj instalacji dachu"
          >
            <el-select
              v-model="form.type_roof"
              v-validate="rules.type_roof"
              class="w-100"
              name="type"
              placeholder="Wybierz rodzaj"
            >
              <el-option
                v-for="item in constructionType"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </custom-form-item>
        </div>
        <div class="col-6">
          <custom-form-item
            :errors="formErrors"
            :required="checkRequired(rules.azimuth)"
            prop="azimuth"
            label="Azymut"
          >
            <input-number
              v-model="form.azimuth"
              v-validate="rules.azimuth"
              :precision="2"
              class="w-100"
              name="azimuth"
              type="text"
            />
          </custom-form-item>
        </div>
        <div class="col-6">
          <custom-form-item
            :errors="formErrors"
            :required="checkRequired(rules.area)"
            prop="area"
            label="Powierzchnia"
          >
            <input-number
              v-model="form.area"
              v-validate="rules.area"
              :precision="2"
              class="w-100"
              name="area"
              type="text"
            />
          </custom-form-item>
        </div>
        <div class="col-6">
          <custom-form-item
            :errors="formErrors"
            :required="checkRequired(rules.roof_degree)"
            prop="roof_degree"
            label="Skos dachu"
          >
            <input-number
              v-model="form.roof_degree"
              v-validate="rules.roof_degree"
              :precision="2"
              class="w-100"
              name="roof_degree"
              type="text"
            />
          </custom-form-item>
        </div>
      </div>
      <div
        class="dialog-footer"
      >
        <el-button @click="stopVisible = false">
          Cancel
        </el-button>
        <el-button
          native-type="submit"
          type="primary"
        >
          Dodaj
        </el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
  import forms from '@/shared/mixins/forms'
  import { slopeCreator as slopeCreatorRules } from '@/admin/utils/validation'
  import { objToSelect } from '@/shared/utils/helpers'

  export default {
    name: 'SlopeCreatorComponent',
    mixins: [forms],
    props: {
      showDialog: {
        type: Boolean,
        required: true
      }
    },
    data () {
      return {
        loading: false,
        form: Object.assign({}, {
          type_roof: '',
          azimuth: '',
          area: '',
          roof_degree: ''
        }),
        slopesOptions: [{
                          value: 1,
                          label: 'Grunt'
                        }, {
                          value: 2,
                          label: 'Płaski dach'
                        },
                        {
                          value: 3,
                          label: 'Skośny'
                        }
        ]
      }
    },
    computed: {
      currentId: function () {
        return this.$route.params.id
      },
      rules: function () {
        return slopeCreatorRules(this.currentId)
      },
      constructionType () {
        return objToSelect(this.$config.activeConstructionsTypes)
      }
    },
    methods: {
      submitForm () {
        if (this.form.type_roof === '') {
          return false
        }

        const slope = this.form
        this.updateSlopesCalculation(slope)
        this.stopVisible()
        this.form = Object.assign({}, {
          type_roof: '',
          azimuth: '',
          area: '',
          roof_degree: '',
          azimuth_points: '',
          area_points: ''
        })
        this.loading = false
      },
      stopVisible () {
        this.$emit('visibility', false)
      },
      updateSlopesCalculation (slope) {
        this.$emit('update-slopes', slope)
      }
    }
  }
</script>

<style scoped>
.dialog-footer{
  text-align: right;
}
</style>
