<template>
  <el-form
    ref="surveyForm"
    v-loading="loading"
    :model="form"
    label-position="top"
    @submit.native.prevent="handleForm('surveyForm')"
  >
    <form-alerts
      :messages="invalidMsg"
      class="my-6"
    />
    <div class="row">
      <div class="col-lg-4 col-xl-4 col-sm-12 order-lg-1 order-xl-1 mt-3">
        <el-card class="el-card-height-max">
          <div>
            <h4>Dane personalne</h4>
            <hr>
          </div>
          <custom-form-item
            :errors="formErrors"
            :required="checkRequired(rules.firstName)"
            prop="firstName"
            label="Imię"
          >
            <el-input
              v-model="form.firstName"
              v-validate="rules.firstName"
              name="firstName"
              type="text"
            />
          </custom-form-item>

          <custom-form-item
            :required="checkRequired(rules.lastName)"
            :errors="formErrors"
            prop="lastName"
            label="Nazwisko"
          >
            <el-input
              v-model="form.lastName"
              v-validate="rules.lastName"
              name="lastName"
              type="text"
            />
          </custom-form-item>

          <custom-form-item
            :required="checkRequired(rules.phone)"
            :errors="formErrors"
            prop="phone"
            label="Telefon"
          >
            <el-input
              v-model="form.phone"
              v-validate="rules.phone"
              name="phone"
              type="text"
            />
          </custom-form-item>

          <custom-form-item
            :required="checkRequired(rules.email)"
            :errors="formErrors"
            prop="email"
            label="Email"
          >
            <el-input
              v-model="form.email"
              v-validate="rules.email"
              name="email"
              type="text"
            />
          </custom-form-item>
        </el-card>
      </div>
      <div class="col-lg-8 col-xl-8 col-sm-12 order-lg-1 order-xl-1 mt-3">
        <el-card class="el-card-height-max">
          <div>
            <h4>Dane pre-leada</h4>
            <hr>
          </div>
          <div class="row">
            <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1 mt-3">
              <custom-form-item
                :required="checkRequired(rules.interested)"
                :errors="formErrors"
                prop="interested"
                label="Czym zainteresowany?"
              >
                <el-select
                  v-model="form.interested"
                  v-validate="rules.interested"
                  class="w-100"
                  name="interested"
                  placeholder="Wybierz"
                >
                  <el-option
                    v-for="(item,index) in interested"
                    :key="index"
                    :label="item.label"
                    :value="index"
                  />
                </el-select>
              </custom-form-item>
            </div>
            <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1 mt-3">
              <custom-form-item
                :required="checkRequired(rules.city)"
                :errors="formErrors"
                prop="city"
                label="Miasto"
              >
                <el-input
                  v-model="form.city"
                  v-validate="rules.city"
                  name="city"
                  type="text"
                />
              </custom-form-item>
            </div>
            <div class="col-lg-12 col-xl-8 col-sm-12 order-lg-1 order-xl-1 mt-3">
              <custom-form-item
                :required="checkRequired(rules.address)"
                :errors="formErrors"
                prop="address"
                label="Ulica"
              >
                <el-input
                  v-model="form.address"
                  v-validate="rules.address"
                  name="address"
                  type="text"
                />
              </custom-form-item>
            </div>
            <div class="col-lg-12 col-xl-2 col-sm-12 order-lg-1 order-xl-1 mt-3">
              <custom-form-item
                :required="checkRequired(rules.property_number)"
                :errors="formErrors"
                prop="property_number"
                label="Numer budynku"
              >
                <el-input
                  v-model="form.property_number"
                  v-validate="rules.property_number"
                  name="property_number"
                  type="text"
                />
              </custom-form-item>
            </div>
            <div class="col-lg-12 col-xl-2 col-sm-12 order-lg-1 order-xl-1 mt-3">
              <custom-form-item
                :required="checkRequired(rules.apartment_number)"
                :errors="formErrors"
                prop="apartment_number"
                label="Numer lokalu"
              >
                <el-input
                  v-model="form.apartment_number"
                  v-validate="rules.apartment_number"
                  name="apartment_number"
                  type="text"
                />
              </custom-form-item>
            </div>
            <div class="col-lg-12 col-xl-8 col-sm-12 order-lg-1 order-xl-1 mt-3">
              <custom-form-item
                :required="checkRequired(rules.province)"
                :errors="formErrors"
                prop="province"
                label="Województwo"
              >
                <el-select
                  v-model="form.province"
                  v-validate="rules.province"
                  class="w-100"
                  placeholder="Województwo"
                  data-cy="province"
                  default-first-option
                  name="province"
                >
                  <el-option
                    v-for="(item,index) in provincesList"
                    :key="index"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </custom-form-item>
            </div>
            <div class="col-lg-12 col-xl-4 col-sm-12 order-lg-1 order-xl-1 mt-3">
              <custom-form-item
                :required="checkRequired(rules.postcode)"
                :errors="formErrors"
                prop="postcode"
                label="Kod pocztowy"
              >
                <el-input
                  v-model="form.postcode"
                  v-validate="rules.postcode"
                  v-mask="'##-###'"
                  name="postcode"
                  type="text"
                />
              </custom-form-item>
            </div>
          </div>
        </el-card>
      </div>
      <div class="col-lg-12 col-xl-12 col-sm-12 order-lg-1 order-xl-1 mt-3">
        <el-card>
          <custom-form-item
            :errors="formErrors"
            prop="notes"
            label="Notatki"
          >
            <vue-editor
              id="notes"
              v-model="form.notes"
              :editor-toolbar="$config.wysiwygToolbar"
              name="notes"
              placeholder="Wpisz treść wiadomości"
            />
          </custom-form-item>
        </el-card>
      </div>
    </div>
    <el-form-item class="text-end mt-6">
      <el-button
        v-if="isEdit"
        type="info"
        @click="$router.go(-1)"
      >
        Anuluj
      </el-button>
      <el-button
        native-type="submit"
        type="primary"
      >
        {{ isEdit ? 'Zapisz' : 'Utwórz' }}
      </el-button>
      <el-button
        v-if="isEdit"
        @click="saveAndOut"
      >
        Zapisz i zamknij
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { surveys as surveyRules } from '@/admin/utils/validation'
  import forms from '@/shared/mixins/forms'
  import { postRequest, putRequest } from '@/shared/utils/http'
  import Avatar from 'vue-avatar'
  import FormAlerts from '../../../shared/components/FormAlerts'

  import { VueEditor } from 'vue2-editor'
  export default {
    name: 'Form',
    components: {
      FormAlerts, Avatar, VueEditor
    },
    mixins: [forms],
    props: {
      form: {
        type: Object,
        default: () => {}
      },
      isEdit: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        interested: this.$config.agreementSettings.data.interested.settings
      }
    },
    computed: {
      currentId: function () {
        return this.isEdit ? this.$route.params.id : 0
      },
      rules: function () {
        return surveyRules(this.currentId)
      },
      fullName: function () {
        return this.form.firstName + ' ' + this.form.lastName
      },
      provincesList () {
        return this.$config.clients.provinces
      }
    },
    methods: {
      submitForm: function () {
        return new Promise((resolve, reject) => {
          let request
          if (this.isEdit) {
            const frm = Object.assign({}, this.form)
            request = putRequest('/api/surveys/' + this.$route.params.id, frm)
          } else {
            request = postRequest('/api/surveys', this.form)
          }
          request
            .then(response => {
              this.$emit('saved')
              if (this.outAfterSave || !this.isEdit) {
                this.$router.push({ name: 'admin.surveys.show', params: { id: response.data.id } })
              }
              this.outAfterSave = false
              resolve(response)
            })
            .catch(error => {
              this.outAfterSave = false
              reject(error)
            })
        })
      },
      saveAndOut () {
        this.outAfterSave = true
        this.submitForm()
      }
    }
  }
</script>

<style scoped>
.avatar-lead{
  position:absolute;
  top: -40px;
  right: 40px;
}
.el-card-height-max{
  min-height: 100%;
}
</style>
