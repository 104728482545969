<template>
  <el-card class="mt-3">
    <div class="head text-center mb-6">
      <h2>Wybierz rodzaj instalacji</h2>
      <p>Kliknij na wybrany typ dachu klienta. Po kliknięciu zostanie zaznaczony odpowiedni blok, a następnie naciśnij "Przejdź".</p>
    </div>
    <div
      v-if="alertError"
      class="container-lg">
      <el-alert
        title="*Wybierz jedną z opcji"
        type="error"/>
    </div>
    <div class="row mt-3 container-lg">
      <div
        v-for="(item,index) in type_installation"
        class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1 my-md-3">
        <el-card
          :class="{ active: index === isActive, 'box-type-installation': true }"
          @mouseover.native="setHoverState(true)"
          @mouseleave.native="setHoverState(false)"
          @click.native="setCard(index, item.value)">
          <div class="text-center py-6">
            <img
              :src="item.img"
              :alt="item.alt">
          </div>
          <div class="text-xxl-right">
            <h6>{{ item.text }}</h6>
          </div>
        </el-card>
      </div>
    </div>
    <el-row class="text-end mt-6 container-lg">
      <el-button
        type="primary"
        @click="nextStep"><i class="fas fa-arrow-right"></i> Przejdź</el-button>
    </el-row>
  </el-card>

</template>

<script>
  export default {
    name: 'TypeInstallation',
    props: {
      form: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        alertError: false,
        alertDegreeError: false,
        isActive: '',
        type_installation: [{
                              img: '/img/icons/1.png',
                              alt: 'na-ziemii',
                              text: 'Na ziemi',
                              value: 1
                            },
                            {
                              img: '/img/icons/2.png',
                              alt: 'dach plaski',
                              text: 'Płaski dach',
                              value: 2
                            },
                            {
                              img: '/img/icons/3.png',
                              alt: 'dach skosny',
                              text: 'Skośny Dach',
                              value: 3
        }]
      }
    },
    methods: {
      setCard (index, value) {
        this.form.type_roof = value
        this.isActive = index
        console.log(value, index)
      },
      nextStep () {
        this.alertError = false
        if (this.form.type_roof === null) {
          this.alertError = true
          return false
        }
        if (this.form.type_roof === 1) {
          this.form.roof_degree = 35 // automatic -- to refactor
        }

        this.$emit('nextStepCalculation')
      },
      setHoverState (state) {
        this.hoverState = state
      }
    }

  }
</script>

<style scoped>
.box-type-installation{
  border-radius: 10px;
  cursor: pointer;
  color: #3f4443;
  transition: 0.6s;
  background-image: radial-gradient(circle at 13% 47%, rgba(140, 140, 140,0.03) 0%, rgba(140, 140, 140,0.03) 25%,transparent 25%, transparent 100%),radial-gradient(circle at 28% 63%, rgba(143, 143, 143,0.03) 0%, rgba(143, 143, 143,0.03) 16%,transparent 16%, transparent 100%),radial-gradient(circle at 81% 56%, rgba(65, 65, 65,0.03) 0%, rgba(65, 65, 65,0.03) 12%,transparent 12%, transparent 100%),radial-gradient(circle at 26% 48%, rgba(60, 60, 60,0.03) 0%, rgba(60, 60, 60,0.03) 6%,transparent 6%, transparent 100%),radial-gradient(circle at 97% 17%, rgba(150, 150, 150,0.03) 0%, rgba(150, 150, 150,0.03) 56%,transparent 56%, transparent 100%),radial-gradient(circle at 50% 100%, rgba(25, 25, 25,0.03) 0%, rgba(25, 25, 25,0.03) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 55% 52%, rgba(69, 69, 69,0.03) 0%, rgba(69, 69, 69,0.03) 6%,transparent 6%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));
}
.active {
  color: #97d700;
  border-color: #e0f3b3;
  background-color: #f5fbe6;
}
.box-type-installation:hover{
  color: #97d700;
  border-color: #e0f3b3;
  background-color: #f5fbe6;
}
</style>
