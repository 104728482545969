<template>
  <div>
    <app-form
      :form="form"
      :is-edit="edit"
      :is-loading="loading"
    />
  </div>
</template>

<script>
  import { formList } from './config'
  import pickBy from 'lodash-es/pickBy'
  import { getRequest } from '@/shared/utils/http'
  import AppForm from '@/admin/pages/sales/Form'

  export default {
    name: 'UpdateAgreement',
    components: {
      AppForm
    },

    data: () => {
      return {
        form: Object.assign({}, formList),
        edit: true,
        loading: true
      }
    },
    mounted () {
      this.$root.title = 'Edycja umowy sprzedaży'
      this.$store.dispatch('app/incrementStep')
      this.$store.dispatch('app/incrementStep')

      getRequest('/api/sales/' + this.$route.params.id)
        .then((response) => {
          this.form.client = response.data.client
          this.form = pickBy(response.data, (value, key) => Object.keys(this.form).includes(key))
          this.loading = false
        })
    }
  }
</script>
<style scope>
.flex-container {
  display: flex;
  flex-wrap: nowrap;
}
</style>
