$i: 10;
@while $i > 0 {
  // margins
  .mb-#{$i} {
    margin-bottom: 0.25rem * $i;
  }
  .mt-#{$i} {
    margin-top: 0.25rem * $i;
  }
  .mx-#{$i} {
    margin-left: 0.25rem * $i;
    margin-right: 0.25rem * $i;
  }
  .my-#{$i} {
    margin-top: 0.25rem * $i;
    margin-bottom: 0.25rem * $i;
  }

  // paddings
  .pb-#{$i} {
    margin-bottom: 0.25rem * $i;
  }
  .pt-#{$i} {
    margin-top: 0.25rem * $i;
  }
  .px-#{$i} {
    margin-left: 0.25rem * $i;
    margin-right: 0.25rem * $i;
  }
  .py-#{$i} {
    margin-top: 0.25rem * $i;
    margin-bottom: 0.25rem * $i;
  }

  $i: $i - 1;
}

// margins
.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mt-label {
  margin-top: 26px;
}

// paddings
.pb-0 {
  margin-bottom: 0;
}

.pt-0 {
  margin-top: 0;
}

.py-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.px-0 {
  margin-right: 0;
  margin-left: 0;
}
