
.file-details {
  .file-icon {
    display: inline-block;
    margin-bottom: 1rem;
    color: #ccc;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 0.75rem;
  }
}
