<template>
  <span>
    <el-dropdown trigger="click">
      <el-badge :value="cnt">
        <span class="el-dropdown-link">
          <i class="bigger-icon fas fa-bell"></i>
        </span>
      </el-badge>

      <el-dropdown-menu
        slot="dropdown"
        size="medium"
        class="notifications"
      >
        <el-dropdown-item disabled>Powiadomienia</el-dropdown-item>

        <el-dropdown-item
          v-if="cnt>0"
          divided
          disabled
        >
          <ul
            v-if="notifications.length > 0"
            class="notifications-list"
          >
            <li
              v-for="(notification,index) in notifications"
              :class="[{ 'read-notification': notification.read_at }, 'notifications-item']"
              @click="handleNotificationAction(notification,index)"
            >
              <div>
                <div>
                  {{ notification.data.message }}
                </div>
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="margin: -10px 0 0 0;"
                >
                  <small>{{ dateNotification(notification.created_at) }}</small>
                  <el-button
                    v-if="!notification.read_at"
                    size="mini"
                    type="text"
                  ><i class="fas fa-long-arrow-alt-right"></i> Przejdź</el-button>
                  <el-button
                    v-else
                    size="mini"
                    type="text"
                  ><i class="fas fa-check"></i> Odczytano</el-button>
                </div>
              </div>

            </li>
          </ul>
          <ul v-if="notifications.length === 0">
            <li>Brak nowych powiadomień.</li>
          </ul>
        </el-dropdown-item>

        <el-dropdown-item
          v-if="cnt>0"
          divided
        >
          <el-button
            type="text"
            @click.native="clearNotify"
          >
            Wyczyść powiadomienia
          </el-button>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </span>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { formatDate, formatDateTime } from '@/admin/utils/date'
  import ShowNotification from '@/admin/plugins/Notifications/ShowNotification'
  import { getRequest } from '@/shared/utils/http'

  export default {
    name: 'AppNotifications',
    components: { ShowNotification },
    computed: {
      ...mapGetters({
        user: 'app/user'
      }),
      userId () {
        return window.user.id
      },
      cnt: function () {
        return this.notifications.length
      }
    },
    // eslint-disable-next-line vue/order-in-components
    data () {
      return {
        notifications: [],
        isNotificationNotEmpty: false
      }
    },
    mounted () {
      Echo.private('App.Models.User.' + this.userId)
        .notification((notification) => {
          this.$snotify.info(notification.message)
          this.checkNotifications()
        })

      this.checkNotifications()
    },
    methods: {
      ...mapActions({
        clearNotifications: 'app/clearNotifications'
      }),

      iconClasses (notification) {
        const t = 'type' in notification ? notification.type : 'info'

        return [
          'icon' in notification ? notification.icon : this.notificationIconClass(t),
          this.notificationBgClass(t)
        ]
      },

      notificationBgClass (t) {
        if (t === 'success') {
          return 'bg-success'
        }
        if (t === 'warning') {
          return 'bg-warning'
        }
        if (t === 'error') {
          return 'bg-danger'
        }
        return 'bg-info'
      },

      notificationIconClass (t) {
        if (t === 'success') {
          return 'fas fa-check'
        }
        if (t === 'warning') {
          return 'fas fa-exclamation'
        }
        if (t === 'error') {
          return 'fas fa-times'
        }
        return 'fas fa-question'
      },
      handleNotificationAction (val, index) {
        this.notifications[index].read_at = Date.now()
        this.$emit('set-notification', val)
      },
      dateNotification (val) {
        return formatDateTime(val)
      },
      checkNotifications () {
        getRequest('/api/notifications')
          .then(response => {
            this.notifications = response.data
            if (!this.isNotificationNotEmpty && this.notifications.length > 0) {
              this.$snotify.success('Nowe powiadomienia do sprawdzenia.')
            }
            this.isNotificationNotEmpty = true
          })
          .catch(() => {
            this.$snotify.error('Problem z pobraniem powiadomień.')
          })
      },
      clearNotify () {
        getRequest('/api/notifications/clear')
          .then(response => {
            this.notifications = response.data
          })
          .catch(() => {
            this.$snotify.error('Problem z pobraniem powiadomień.')
          })
      }
    }
  }
</script>

<style scoped>
.notifications-list {
  max-height: 260px;
  overflow-y: auto;
}
.notifications-item{
  border-bottom: 1px solid #dfdfdf;
  padding: 4px 30px;
  cursor: pointer;
  color: #333;
}
.notifications-item:hover {
  background-color: #f2f2f2;
  transition: 0.4s;
}
.bigger-icon{
  font-size:20px;
}

</style>
