<template>
  <div>
    <el-card class="box-card mt-5">
      <div slot="header">
        <title-bar
          :title="$root.title"
          :links="toolbar"
        />
      </div>

      <vuetable
        ref="vuetable"
        v-loading="table.loading"
        class="table-scroll"
        v-bind="table.options"
        :fields="fields"
        :append-params="tableState.moreParams"
        v-on="table.events"
      >
        <template
          slot="logo"
          slot-scope="props"
        >
          <div class="text-center">
            <el-image :src="props.rowData.logo_company" />
          </div>
        </template>
      </vuetable>

      <div class="row justify-content-between align-items-center">
        <div class="col">
          <vuetable-pagination-info
            ref="paginationInfo"
            :info-template="table.pagination.infoTemplate"
            :no-data-template="table.pagination.noDataTemplate"
          />
        </div>

        <div class="col">
          <vuetable-pagination
            ref="pagination"
            :css="table.pagination.css"
            v-on="table.pagination.events"
          />
        </div>

        <div class="col-auto">
          <per-page
            :items="tableState.perPage"
            @changed="onChangePerPage"
          />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  import Tables from '@/shared/mixins/tables'
  import ExportTable from '@/admin/components/ExportTable'
  import SelectUser from '@/admin/pages/users/components/Autocomplete'
  import { fields, filters, sorting } from './config'
  import cloneDeep from 'lodash-es/cloneDeep'
  import { objToCheckboxArray } from '@/shared/utils/helpers'

  export default {
    name: 'CompanyList',

    mixins: [Tables],
    data: () => {
      return {
        centerDialogVisible: false,
        tableData: [],
        table: {
          options: {
            'api-url': '/api/companies'
          }
        },
        fields: fields,
        formFilters: filters,
        toolbar: [
          {
            type: 'create',
            name: 'admin.calculations.companies.create',
            permission: 'admin.calculations.companies.create',
            label: 'Dodaj'
          }
        ]
      }
    },
    created () {
      this.$root.title = 'Firmy do zestawów'
      this.setInitialOptions({ sorting: cloneDeep(sorting), filters: cloneDeep(filters) })
    },
    methods: {
      onFilterReset () {
        this.reset(filters, sorting)
      },

      goToModule (link) {
        this.$router.push({ path: link })
      },

      onRowClicked (e) {
        this.$router.push({ name: 'admin.calculations.companies.show', params: { id: e.data.id } })
      },

      onDeleted () {
        this.$refs.vuetable.reload()
        this.onTableLoaded()
      }

    }
  }
</script>

<style scoped>
.el-image{
  width: 100px !important;
}
</style>
