
.pagination {
  padding: 2px 5px;

  a {
    display: inline-block;
    padding: 0 4px;
    min-width: 35px;
    height: 28px;
    text-align: center;
    color: $color-text;
    cursor: pointer;

    &.active {
      font-weight: 700;
      color: map_get($colors, success);
    }

    &.disabled {
      pointer-events: none;
      color: map_get($colors, grey);
    }
  }

  /*.el-pager {
  li.active {
    color: map_get($colors, success);
    font-weight: 700;
  }
}*/

}