<template>
  <div>
    <el-card
      v-loading="loading"
      class="mb-2">
      <el-breadcrumb separator-class="fas fa-long-arrow-alt-right">
        <el-breadcrumb-item :to="{ name: 'admin.dashboard.read' }">Dashboard</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'admin.calculations.kits.index' }">Zestawy sprzedażowe</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'admin.calculations.kits.import' }">Importuj zestawy sprzedażowe</el-breadcrumb-item>
      </el-breadcrumb>
    </el-card>
    <el-card
      v-loading="loading"
      class="mb-2">
      <div>
        <h4>Importowanie pliku z zestawami</h4>
      </div>
    </el-card>
    <el-card
      v-loading="loading"
      class="mb-2">
      <div>
        <h5>Importer zestawów do systemu</h5>
        <hr>
        <p>Importowanie rozpocznie się po wrzuceniu pliku (automatycznie). Prosimy oczekiwać na zakończenie się importu. Może to potrwać parę minut.</p>
      </div>
      <el-upload
        :auto-upload="true"
        :show-file-list="false"
        :on-success="handleFileSuccess"
        :on-error="handleFileError"
        :before-upload="beforeFileUpload"
        :action="apiUrl"
        class="upload-demo"
        drag>
        <form-alerts :messages="invalidMsg"/>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Przerzuć tutaj gotowy plik lub <em>kliknij</em></div>
        <div
          slot="tip"
          class="el-upload__tip">Maksymalnie przesyłany plik to 15MB</div>
      </el-upload>
    </el-card>
  </div>
</template>

<script>
  import forIn from 'lodash-es/forIn'
  import forms from '../../../../../shared/mixins/forms'
  export default {
    name: 'Import',

    mixins: [forms],

    data () {
      return {
        apiUrl: '/api/kits/upload',
        csv: {},
        uploadEndpoint: '',
        allowedFileSize: 15,
        allowedMimeTypes: this.$config.uploads.tickets.allowed_file_size,
        allowedFileTypes: this.$config.uploads.tickets.allowed_file_types,
        loading: false,
        importFields: [ 'company_id', 'active', 'construction', 'solar_panel_name', 'count_solar_panel', 'price',
                        'price_construction', 'inverter', 'kwp', 'phase', 'count_inverter', 'kwp_per_solar',
                        'warranty_product', 'warranty_performance', 'bonus', 'addons_information',
                        'solar_card', 'inverter_card', 'start_date', 'end_date']
      }
    },
    methods: {
      handleFileSuccess (res, file) {
        if ('error' in res) {
          this.$notifyError(res.message)
        } else if (res.message) {
          this.$notifySuccess(res.message)
          this.$emit('added', res.data)
        }
        this.loading = false
      },
      handleFileError (res) {
        const response = JSON.parse(res.message)
        if ('error' in res) {
          this.$notifyError(response.message)
        }
        this.$notifyError(response.message)

        this.loading = false
      },
      beforeFileUpload (file) {
        this.loading = true
        const isValidType = this.$config.uploads.tickets.allowed_mime_types.includes(file.type)
        const isValidSize = file.size / 1024 / 1024 < this.allowedFileSize // 3MB

        this.clearInvalidMsg()

        if (!isValidType) {
          this.$message.error('Plik jest w nieobsługiwanym formacie.')
        }
        if (!isValidSize) {
          this.$message.error(`Plik ma rozmiar większy niż ${this.allowedFileSize}MB! Dodaj mniejszy plik.`)
        }

        const isOK = isValidType && isValidSize
        if (isOK) {
          this.loading = true
          this.$message.info('System rozpoczyna wgrywanie zestawów i podpinanie ich. Pozostań na stronie.')
        }

        return isOK
      }
    }
  }
</script>

<style scoped>
.form-group {
  display: inline-block !important;
}
</style>
