.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .35s;
}

.fade-enter-active {
  transition-delay: .35s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .5s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(-10px);
  opacity: 0;
}

.insert {
  &-enter, &-leave-to {
    opacity: 0;
  }

  &-enter {
    transform: translateX(-50vw);
  }

  &-leave-to {
    transform: translateX(50vw);
  }

  &-enter-active {
    transition: opacity .6s linear, transform 1s cubic-bezier(.68, -0.12, 0, 1.18);
  }

  &-leave-active {
    transition: opacity .6s linear, transform 1s cubic-bezier(.68, -0.12, 0, 1.18);
  }

  &-move {
    transition: all .5s ease-in-out;
  }
}

.slide {
  &-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
  }

  &-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  &-enter-to, &-leave {
    max-height: none;
    overflow: hidden;
  }

  &-enter, &-leave-to {
    overflow: hidden;
    max-height: 0;
  }
}
