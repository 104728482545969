<template>
  <div class="text-center">
    <el-upload
      :headers="uploadHeaders"
      :data="uploadData"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      name="avatar"
      class="avatar-uploader"
      action="/api/my-avatar">
      <img
        :src="user.avatar"
        class="avatar">
      <i
        v-if="!hasAvatar"
        class="avatar-upload fas fa-upload"></i>
    </el-upload>

    <el-button
      v-if="hasAvatar"
      type="text"
      class="text-danger"
      @click="removeAvatar"
    ><i class="fas fa-times"></i> usuń
    </el-button>
  </div>
</template>

<script>
  import forms from '@/shared/mixins/forms'
  import { postRequest } from '@/shared/utils/http'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Avatar',

    mixins: [
      forms
    ],

    data () {
      return {
        loading: false,
        uploadData: {}
      }
    },

    computed: {
      ...mapGetters({
        user: 'app/user'
      }),

      hasAvatar: function () {
        return this.imageUrl !== '' && this.imageUrl !== this.$config.app.default_avatar && this.imageUrl !== null
      }
    },

    methods: {
      handleAvatarSuccess (res, file) {
        if (res.status) {
          this.imageUrl = res.avatar
          this.$store.dispatch('app/updateAvatar', res.avatar)
        }
        this.loading.close()
      },

      beforeAvatarUpload (file) {
        const isJPG = file.type === 'image/jpeg'
        const isLt1M = file.size / 1024 / 1024 < 1

        if (!isJPG) {
          this.$message.error('Avatar powininen być w formacie jpg.')
        }
        if (!isLt1M) {
          this.$message.error('Avatar ma rozmiar większy niż 1MB! Dodaj mniejszy plik.')
        }
        if (isJPG && isLt1M) {
          this.avatarLoader()

          return true
        }

        return false
      },

      avatarLoader () {
        this.loading = this.$loading({
          target: '.avatar-uploader',
          text: 'Loading'
        })
      },

      removeAvatar () {
        this.avatarLoader()

        return new Promise((resolve, reject) => {
          postRequest('/api/remove-my-avatar', {})
            .then(response => {
              this.imageUrl = this.$config.app.default_avatar
              this.$store.dispatch('app/updateAvatar', this.$config.app.default_avatar)
              this.loading.close()

              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      }
    }
  }
</script>

<style>
  .el-upload {
    height: 180px;
  }
</style>
