<template>
  <el-card
    v-loading="loading"
    class="mt-3"
  >
    <div class="head text-center mb-6 container-lg">
      <h2>Wybierz adres instalacji</h2>
      <small>Kreator rysowania połaci. Poniżej wybierz adres wpisując go w wyszukiwarkę lub za pomocą mapy
        znajdź i rozpocznij rysowanie. Zacznij od zaznaczenia obszaru dachu lub powierzchni. W kolejnym kroku
        zaznacz jego spad (kierunek) i wybierz typ poszycia.</small>
    </div>
    <div class="container-lg alert-block">
      <el-alert
        v-if="alertError"
        class="my-3"
        title="*Wybierz i zaznacz odpowiednie elementy na mapie"
        type="error"
      />
      <el-alert
        v-if="alertDegreeError"
        class="my-3"
        title="*Dobierz odpowietni kąt dla tej połaci"
        type="error"
      />
    </div>
    <div class="container-lg">
      <div class="row mb-5">
        <div class="col-6">
          <div class="w-100">
            <input
              id="autocomplete"
              class="w-100 button-google"
              type="text"
              placeholder="Wpisz lokacje"
              @keyup.enter.native.stop.prevent="SetPlace"
            >
          </div>
        </div>
        <div class="col-6">
          <el-button
            type="primary"
            class="w-100"
            @click="SetPlace"
          >
            <i class="fas fa-search"></i> Znajdź
          </el-button>
        </div>
      </div>
    </div>
    <div class="row mt-2 container-lg">
      <div class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1">
        <div class="text-body">
          <div v-if="!canAddSlope">
            <h5 class="my-0 py-0">
              Dodawanie połaci
            </h5>
            <hr>
            <div>
              <div class="row">
                <div class="col-12">
                  <div class="card border-light">
                    <div class="card-body">
                      <div class="row d-block d-xl-flex align-items-center">
                        <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                          <div class="icon icon-shape icon-md icon-shape-blue rounded mr-4 mr-sm-0">
                            <span class="number-intro">1.</span>
                          </div>
                          <div class="d-sm-none">
                            <h2 class="h5">
                              Zaznacz powierzchnię instalacji
                            </h2>
                          </div>
                        </div>
                        <div class="col-12 col-xl-7 px-xl-0">
                          <div class="d-none d-sm-block">
                            <h2 class="h5">
                              Zaznacz powierzchnię instalacji
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                          <el-button
                            :disabled="canAddArea"
                            type="primary"
                            class="w-100"
                            @click="setActiveArea"
                          >
                            <i class="far fa-clone"></i> Zaznacz pow.
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                </div>
                <div class="col-12 mt-2">
                  <div class="card border-light">
                    <div class="card-body">
                      <div class="row d-block d-xl-flex align-items-center">
                        <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                          <div class="icon icon-shape icon-md icon-shape-blue rounded mr-4 mr-sm-0">
                            <span class="number-intro">2.</span>
                          </div>
                          <div class="d-sm-none">
                            <h2 class="h5">
                              Zaznacz kierunek powierzchni
                            </h2>
                          </div>
                        </div>
                        <div class="col-12 col-xl-7 px-xl-0">
                          <div class="d-none d-sm-block">
                            <h2 class="h5">
                              Zaznacz kierunek powierzchni
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                          <el-button
                            :disabled="canAddAzimuth"
                            type="primary"
                            class="w-100"
                            @click="setActiveAzimuth"
                          >
                            <i class="fas fa-arrow-down"></i> Zaznacz kierunek
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="col-12 mt-2">
                  <div class="card border-light">
                    <div class="card-body">
                      <div class="row d-block d-xl-flex align-items-center">
                        <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                          <div class="icon icon-shape icon-md icon-shape-blue rounded mr-4 mr-sm-0">
                            <span class="number-intro">3.</span>
                          </div>
                          <div class="d-sm-none">
                            <h2 class="h5">
                              Wybierz rodzaj instalacji dachu
                            </h2>
                          </div>
                        </div>
                        <div class="col-12 col-xl-7 px-xl-0">
                          <div class="d-none d-sm-block">
                            <h2 class="h5">
                              Wybierz rodzaj instalacji dachu
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                          <custom-form-item
                            prop="type"
                            label="Rodzaj instalacji dachu"
                          >
                            <el-select
                              v-model="slopeAttributes.type_roof"
                              class="w-100"
                              name="type"
                              placeholder="Wybierz rodzaj"
                            >
                              <el-option
                                v-for="item in constructionType"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                              />
                            </el-select>
                          </custom-form-item>
                          <custom-form-item
                            prop="kwp"
                            label="Kąt nachylenia panelu [*]"
                          >
                            <input-number
                              v-model="slopeAttributes.roof_degree"
                              :precision="2"
                              name="kwp"
                              class="w-100"
                              type="number"
                            />
                          </custom-form-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-end">
              <el-button
                type="text"
                @click="cancelAddingSlope"
              >
                Przerwij
              </el-button>
              <el-button
                type="primary"
                @click="addNewSlope"
              >
                <i class="fas fa-check"></i> Zakończ
              </el-button>
            </div>
          </div>
          <div v-if="canAddSlope">
            <h5>
              Wybrane połacie <el-button
                v-if="slopes.length"
                type="text"
                @click="resetDrawingsMap"
              >
                Resetuj
              </el-button>
            </h5>
            <small>Dowiedz się, w jaki sposób rysować połacie <el-button
              type="text"
              @click="openTutorial"
            >Kliknij tutaj</el-button></small>
            <hr>
            <div
              v-if="canAddSlope"
              class="mb-5"
            >
              <el-button
                class="w-100"
                type="primary"
                @click="setActiveAddingSlope"
              >
                <i class="fas fa-plus-circle"></i> Dodaj połać
              </el-button>
            </div>
            <div v-if="!slopes.length">
              <el-alert
                :closable="false"
                title="Brak wybranych połaci"
                type="warning"
                show-icon
              />
            </div>
            <div
              v-for="item in slopes"
              v-else
              class="slopes mb-3"
            >
              <p class="py-0 my-0">
                <i class="fas fa-caret-right"></i> {{ currentSlopesType(item.type_roof) }} {{ '-' + item.roof_degree + ' °' }}
              </p>
              <small>Azymut: {{ Math.round(item.azimuth * 100) / 100 }} / Powierzchnia: {{ (Math.round(item.area * 100) / 100) }} m2</small>
            </div>
          </div>
          <div v-if="canAddSlope">
            <h5>Pobieranie adresu</h5>
            <el-button
              class="w-100"
              @click="getPersonAddress"
            >
              <i class="fas fa-map-marker-alt"></i> Pobierz adres
            </el-button>
            <p class="text-justify">
              Po wciśnięciu guzika zostanie pobrany adres z systemu kalkulowanej osoby. Po pobraniu danych naciśnij na pole
              z adresem aby wywołać wyszukiwanie w mapie Google.
            </p>
          </div>
        </div>
        <div class="hidden-data">
          <input
            id="area_points"
            type="hidden"
            name="area_points"
            value
          >
          <input
            id="area"
            type="hidden"
            name="area"
            value
          >
          <input
            id="azimuth_points"
            type="hidden"
            name="azimuth_points"
            value
          >
          <input
            id="azimuth"
            type="hidden"
            name="azimuth"
            value
          >
        </div>
      </div>
      <div class="col-lg-9 col-md-12 col-xl-9 order-lg-2 order-xl-2">
        <div
          id="map"
          @click="clickMap"
        ></div>
      </div>
    </div>
    <div
      v-if="canAddSlope"
      class="grids-client mb-4"
    >
      <grid-calculable-person
        :slopes="slopes"
        @update-map="updateMapInfo"
      />
    </div>
    <div
      v-if="slopes.length"
      class="mt-6 container-lg"
    >
      <div class="row">
        <div class="col-12">
          <h5>Wyszukaj ofert w systemie</h5>
          <hr>
          <div>
            <el-button
              class="w-100"
              type="primary"
              @click="CHECK_VALUES"
            >
              <i class="fas fa-arrow-right"></i> Sprawdź oferty
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <tutorial-component
      :show-dialog="tutorialDialog"
      @close-dialog="closeTutorial"
    />
  </el-card>
</template>

<script>
  import $Scriptjs from 'scriptjs'
  import { getRequest } from '@/shared/utils/http'
  import GridCalculablePerson from '@/admin/pages/calculator/components/GridCalculablePerson'
  import { objToCheckboxArray, objToSelect } from '@/shared/utils/helpers'
  import TutorialComponent from '@/admin/pages/calculator/components/TutorialComponent'
  export default {
    name: 'Maps',
    components: { TutorialComponent, GridCalculablePerson },
    props: {
      form: {
        type: Object,
        required: true
      }
    },
    data: function () {
      return {
        tutorialDialog: false,
        canAddArea: false,
        canAddAzimuth: false,
        drawings: {
          polyline: '',
          polygon: '',
          group: {
            polylines: [],
            polygons: []
          }
        },
        map: null,
        alertDegreeError: false,
        mapEl: document.getElementById('map'),
        buttons: null,
        autocomplete: null,
        loading: false,
        alertError: false,
        line: [],
        area_points: [],
        area: '',
        azimuth_points: [],
        azimuth: '',
        slopes: [],
        canAddSlope: true,
        azimuthControlButton: '',
        roofControlButton: '',
        startButton: '',
        calculator: {
          map: null,
          drawingManager: null,
          polygon: null,
          line: null
        },
        slopeAttributes: {
          type_roof: '',
          roof_degree: 0
        },
        slopesArray: [],
        azimuthArray: [],
        slopesOptions: [{
                          value: 1,
                          label: 'Grunt'
                        }, {
                          value: 2,
                          label: 'Płaski dach'
                        },
                        {
                          value: 3,
                          label: 'Skośny'
                        }
        ]
      }
    },
    computed: {
      constructionType () {
        return objToSelect(this.$config.activeConstructionsTypes)
      }
    },
    mounted () {
      $Scriptjs('https://maps.googleapis.com/maps/api/js?key=AIzaSyCZNR1lMchILg8JAHPPOs4ytMuR18NDHO8&libraries=places,drawing', () => {
        this.initMap()
      })
    },
    methods: {
      initMap () {
        // tworzenie obiektu nowej mapy
        this.calculator.map = new google.maps.Map(document.getElementById('map'), {
          center: { lat: 52.0688246, lng: 19.4709897 },
          zoom: 7,
          disableDefaultUI: true,
          clickableIcons: false,
          zoomControl: true,
          // scrollwheel: false,
          fullscreenControl: true,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT
          },
          controlSize: 26,
          mapTypeId: google.maps.MapTypeId.HYBRID,
          tilt: 0,
          mapTypeControl: false
        })
        // Ustawienia dla autolokalizatora
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
          componentRestrictions: { country: ['PL'] },
          fields: ['place_id', 'geometry', 'name']
        })
        // Autolokalizacja użytkownika - działa gdy mamy aktywną usługę w przeglądarce
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }
              this.calculator.map.setCenter(pos)
              this.calculator.map.setZoom(20)
            },
            () => {
              console.log('cannot find position')
            })
        } else {
          // Browser doesn't support Geolocation
          console.log("browser dosen't support geolocation")
        }

        const lineSymbol = {
          path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
        }
        const polygonDeafaultOptions = {
          clickable: false,
          draggable: false,
          editable: true,
          zIndex: 1,
          fillColor: '#93d502',
          strokeColor: '#93d502'
        }
        const polylineDefaultOptions = {
          icons: [
            {
              icon: lineSymbol,
              offset: '100%'
            }
          ],
          clickable: false,
          draggable: false,
          editable: false,
          zIndex: 22,
          strokeColor: '#e40041'
        }

        this.calculator.drawingManager = new google.maps.drawing.DrawingManager({
          // drawingMode: google.maps.drawing.OverlayType.POLYGON,
          drawingControl: false,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ['polygon', 'polyline']
          },
          polygonOptions: polygonDeafaultOptions,
          polylineOptions: polylineDefaultOptions

        })

        this.calculator.drawingManager.setMap(this.calculator.map)

        // var initAreaPoints = $('#area_points').val()
        const initAreaPoints = document.getElementById('area_points').value
        if (initAreaPoints.length) {
          const polygonOptions = polygonDeafaultOptions
          polygonOptions.path = JSON.parse(initAreaPoints)
          polygonOptions.map = this.calculator.map
          this.calculator.polygon = new google.maps.Polygon(polygonOptions)
          this.calculator.map.setZoom(20)

          google.maps.event.addListener(this.calculator.polygon.getPath(), 'insert_at', this.tmpCalcArea)
          google.maps.event.addListener(this.calculator.polygon.getPath(), 'remove_at', this.tmpCalcArea)
          google.maps.event.addListener(this.calculator.polygon.getPath(), 'set_at', this.tmpCalcArea)

          const bounds = new google.maps.LatLngBounds()
          let i
          const polygonCoords = JSON.parse(initAreaPoints)

          for (i = 0; i < polygonCoords.length; i++) {
            bounds.extend(polygonCoords[i])
          }
          this.calculator.map.setCenter(bounds.getCenter())
          this.calculator.map.fitBounds(bounds)
        }

        // var initAzimuthPoints = $('#azimuth_points').val()
        const initAzimuthPoints = document.getElementById('azimuth_points').value
        if (initAzimuthPoints.length) {
          const polylineOptions = polylineDefaultOptions
          polylineOptions.path = JSON.parse(initAzimuthPoints)
          polylineOptions.map = this.calculator.map

          this.calculator.line = new google.maps.Polyline(polylineOptions)
        }

        google.maps.event.addListener(this.calculator.drawingManager, 'polygoncomplete', (polygon) => {
          this.slopesArray.push(polygon)
          this.calculator.drawingManager.setDrawingMode(null)
          this.calculator.polygon = polygon

          google.maps.event.addListener(polygon.getPath(), 'insert_at', this.tmpCalcArea)
          google.maps.event.addListener(polygon.getPath(), 'remove_at', this.tmpCalcArea)
          google.maps.event.addListener(polygon.getPath(), 'set_at', this.tmpCalcArea)

          this.calcArea(polygon)
          this.buttons.removeChild(this.roofControlButton)
          if (this.buttons.childElementCount === 0) {
            this.buttons.appendChild(this.startButton)
          }
          this.addControls() // utwórz kontrolki na mapie ?
        })

        google.maps.event.addListener(this.calculator.drawingManager, 'polylinecomplete', (polyline) => {
          this.azimuthArray.push(polyline)
          try {
            this.calculator.drawingManager.setDrawingMode(null)
            this.calculator.line = polyline
            const azimuthPoints = polyline.getPath()
            const aAzimuthPoints = []

            if (azimuthPoints.getLength() == 2) {
              for (let i = 0; i < azimuthPoints.getLength(); i++) {
                const xy = azimuthPoints.getAt(i)
                aAzimuthPoints[i] = { lat: xy.lat(), lng: xy.lng() }
              };

              const azimuth = google.maps.geometry.spherical.computeHeading(
                azimuthPoints.getAt(1),
                azimuthPoints.getAt(0)
              )
              const sAzimuthPoints = JSON.stringify(aAzimuthPoints)

              document.getElementById('azimuth_points').value = sAzimuthPoints
              document.getElementById('azimuth').value = azimuth
              this.canAddAzimuth = true
              this.line = []

              this.buttons.removeChild(this.azimuthControlButton)
              if (this.buttons.childElementCount === 0) {
                this.buttons.appendChild(this.startButton)
              }
              this.addControls() // utwórz kontrolki na mapie ?
            }
          } catch (e) {
          }
        })
      },
      onPlaceChanged () {
        const place = this.autocomplete.getPlace()
      },
      customControlButton (text, title, callback) {
        const controlDiv = document.createElement('div')
        const controlUI = document.createElement('div')
        const controlText = document.createElement('div')

        controlUI.classList.add('gm-control')
        controlUI.title = title
        controlDiv.appendChild(controlUI)
        controlText.innerHTML = text
        controlUI.appendChild(controlText)
        controlUI.addEventListener('click', function () {
          // this.calculator.map.setOptions({
          //   mapTypeId: google.maps.MapTypeId.HYBRID
          // })
          callback()
        })

        return controlDiv
      },
      SetPlace () {
        const place = this.autocomplete.getPlace()
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        this.calculator.map.setZoom(21)
        this.calculator.map.setCenter(new google.maps.LatLng(lat, lng))
      },
      addControls () {
        const oldPosition = google.maps.ControlPosition.TOP_RIGHT
        const newPosition = google.maps.ControlPosition.LEFT_TOP

        this.calculator.map.controls[oldPosition].pop(this.buttons)
        this.calculator.map.controls[newPosition].push(this.buttons)
      },
      calcArea (polygon) {
        const area = google.maps.geometry.spherical.computeArea(polygon.getPath())
        const areaPoints = polygon.getPath()
        const aAreaPoints = []
        for (let i = 0; i < areaPoints.getLength(); i++) {
          const xy = areaPoints.getAt(i)
          aAreaPoints[i] = { lat: xy.lat(), lng: xy.lng() }
        };
        const sAreaPoints = JSON.stringify(aAreaPoints)

        document.getElementById('area_points').value = sAreaPoints
        document.getElementById('area').value = area
        return {
          area_points: sAreaPoints,
          area: area
        }
      },
      clickMap (event) {
        if (this.calculator.drawingManager.getDrawingMode() === google.maps.drawing.OverlayType.POLYLINE) {
          if (this.line.length >= 1) {
            this.calculator.drawingManager.setDrawingMode(null)
            this.line = []
            return
          }
          this.line.push(event.target)
        }
      },
      tmpCalcArea () {
        this.calcArea(this.calculator.polygon)
      },
      updateMapInfo (mapData) {
        this.resetReadSlopesAndAzimuths()
        this.resetSlopesAndAzimuth()
        const lineSymbol = {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        }

        this.slopes = mapData.slopes
        this.calculator.map.setZoom(19)
        this.calculator.map.setCenter(new google.maps.LatLng(mapData.grid_center.lat, mapData.grid_center.lng))

        mapData.slopes.forEach((el) => {
          const azimuthsPoints = JSON.parse(el.azimuth_points) // chyba trzeba przerobić do json
          const areasPoints = JSON.parse(el.area_points) // chyba trzeba przerobić do json

          this.drawings.polygon = new google.maps.Polygon({
            paths: areasPoints,
            strokeColor: '#298115',
            strokeOpacity: 0.8,
            strokeWeight: 4,
            fillColor: '#05e509',
            fillOpacity: 0.45
          })
          this.drawings.polygon.setMap(this.calculator.map)
          this.drawings.group.polygons.push(this.drawings.polygon)

          this.drawings.polyline = new google.maps.Polyline({
            path: azimuthsPoints,
            icons: [
              {
                icon: lineSymbol,
                offset: '100%'
              }
            ],
            geodesic: true,
            strokeColor: '#FF0000',
            strokeOpacity: 1.0,
            strokeWeight: 4
          })
          this.drawings.polyline.setMap(this.calculator.map)
          this.drawings.group.polylines.push(this.drawings.polyline)
        })
        this.$message.success('Połacie zostały załadowane')
      },
      resetReadSlopesAndAzimuths () {
        for (let i = 0; i < this.drawings.group.polylines.length; i++) {
          this.drawings.group.polylines[i].setMap(null)
        }
        this.drawings.group.polylines = []

        for (let d = 0; d < this.drawings.group.polygons.length; d++) {
          this.drawings.group.polygons[d].setMap(null)
        }
        this.drawings.group.polygons = []
      },
      CHECK_VALUES () {
        this.loading = true
        this.alertError = false
        this.alertDegreeError = false
        this.form.slopes = this.slopes
        this.$emit('nextStepCalculation')
      },
      getPersonAddress () {
        let person = null
        let api = ''
        const userId = this.$route.params.id
        const userType = this.$route.params.type

        this.$message('Pobieranie danych osoby kalkulowanej...')
        if (userType === 'lead') {
          api = '/api/leads/' + userId
        } else {
          api = '/api/clients/' + userId
        }
        getRequest(api)
          .then((response) => {
            person = response.data
            if (person) {
              if (person.address === null) {
                this.$message('Brak adresu w karcie osoby. Wpisz dane ręcznie')
                return false
              }
            } else {
              this.$message('Brak adresu w karcie osoby. Wpisz dane ręcznie')
              return false
            }
            document.getElementById('autocomplete').value = person.address + ' ' + person.property_number + ',' + person.city
            this.$message({
              message: 'Pobrano. Naciśnij na pole z adresem aby wywołać obsługę mapy.',
              type: 'success'
            })
          })
      },
      addNewSlope () {
        const azimuth = document.getElementById('azimuth').value
        const area = document.getElementById('area').value

        this.alertError = false
        this.alertDegreeError = false

        if (area === '') {
          this.alertError = true
          this.loading = false
          return false
        }

        if (azimuth === '') {
          this.alertError = true
          this.loading = false
          return false
        }
        if (this.slopeAttributes.roof_degree === '') {
          this.alertDegreeError = true
          this.loading = false
          return false
        }

        if (this.slopeAttributes.type_roof === '') {
          this.alertDegreeError = true
          this.loading = false
          return false
        }

        this.canAddSlope = true

        const slope = {
          azimuth: azimuth,
          area: area,
          azimuth_points: document.getElementById('azimuth_points').value,
          area_points: document.getElementById('area_points').value,
          type_roof: this.slopeAttributes.type_roof,
          roof_degree: this.slopeAttributes.roof_degree
        }
        this.slopes.push(slope)

        this.slopeAttributes = {
          roof_degree: 0,
          type_roof: ''
        }
        document.getElementById('area').value = ''
        document.getElementById('area_points').value = ''
        document.getElementById('azimuth').value = ''
        document.getElementById('azimuth_points').value = ''
      },
      currentSlopesType (slopeId) {
        const slope = this.constructionType.find((slope) => {
          return slope.value === slopeId
        })
        return slope ? slope.name : 'Nie odczytano typu połaci.'
      },
      resetDrawingsMap () {
        this.$confirm('Próbujesz zresetować narysowaną mapę i wybrane połacie. Kontynuować?', 'Uwaga', {
          confirmButtonText: 'Tak',
          cancelButtonText: 'Porzuć',
          type: 'warning'
        }).then(() => {
          this.resetSlopesAndAzimuth()
          this.resetReadSlopesAndAzimuths()
          document.getElementById('area').value = ''
          document.getElementById('area_points').value = ''
          document.getElementById('azimuth').value = ''
          document.getElementById('azimuth_points').value = ''
          this.slopes = []
          this.$message({
            type: 'info',
            message: 'Resetowanie...'
          })
        }).catch((response) => {
          this.$message({
            type: 'info',
            message: 'Przerwano resetowanie.'
          })
        })
      },
      resetSlopesAndAzimuth () {
        for (let i = 0; i < this.azimuthArray.length; i++) {
          this.azimuthArray[i].setMap(null)
        }
        this.azimuthArray = []

        for (let d = 0; d < this.slopesArray.length; d++) {
          this.slopesArray[d].setMap(null)
        }
        this.slopesArray = []
      },
      setActiveAddingSlope () {
        this.canAddSlope = false
        this.canAddAzimuth = false
        this.canAddArea = false
      },
      setActiveArea () {
        this.calculator.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON)
        this.canAddArea = true
      },
      setActiveAzimuth () {
        this.calculator.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYLINE)
      },
      cancelAddingSlope () {
        this.canAddSlope = true
        this.canAddAzimuth = false
        this.canAddArea = false
        if (this.azimuthArray[this.slopes.length]) {
          this.azimuthArray[this.slopes.length].setMap(null)
          this.azimuthArray.splice(this.slopes.length, 1)
        }
        if (this.slopesArray[this.slopes.length]) {
          this.slopesArray[this.slopes.length].setMap(null)
          this.slopesArray.splice(this.slopes.length, 1)
        }
      },
      openTutorial () {
        this.tutorialDialog = true
      },
      closeTutorial () {
        this.tutorialDialog = false
      }
    }
  }
</script>
<style scoped>
.slopes{
  padding: 5px;
  border-bottom: 1px solid #eaeaea;
}
#map {
  width: 100%;
  height: 620px !important;
}
.gm-buttons {
  display: flex;
}
.gm-control{
  border-radius: 15px;
  cursor: pointer;
  z-index: 0;
  color: #333;
  padding: 10px 20px;
  margin: 5px;
  background: #fff;
}
.button-google{
  -webkit-appearance: none;
  background-color: #FFFFFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
span.number-intro {
  font-size: 3em;
  background: #f0f9eb;
  border: 1px solid #c2e7b0;
  color: #a2a2a2;
  padding: 0px 21px 10px 30px;
  border-radius: 10px;
}
.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: #cecece;
  background-color: #dcdcdc !important;
  border-color: #c3c3c3;
}
</style>
