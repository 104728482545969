<template>
  <section>
    <el-form
      ref="calculatorForm"
      :model="form"
      label-position="top"
      @submit.native.prevent="handleForm('calculatorForm')"
    >
      <technical-data
        v-if="$store.state.app.calculator.step === 0"
        :form="form"
        class="card"
        @nextStepCalculation="nextStep"
        @calculation-without-map="findCalculationWithoutMap"
        @update-slope="updateSlope"
      />
      <maps
        v-if="$store.state.app.calculator.step === 2 && !form.knowPowerTrigger"
        :form="form"
        class="card"
        @nextStepCalculation="pvgisConnection"
      />
      <phase-choose
        v-if="$store.state.app.calculator.step === 3 && !form.knowPowerTrigger"
        :pvigs="dataFromPvgis"
        :form="form"
        class="card"
        @nextStepCalculation="prepareToSubmit"
      />

      <el-card
        v-if="$store.state.app.calculator.step === 4"
        class="mt-3"
      >
        <div class="text-center my-6">
          <h1><i class="fas fa-search"></i> Szukam zestawów dla naszego klienta</h1>
          <p>Daj mi chwilę, na bank coś znajdę!</p>
          <div>
            <pulse-loader />
          </div>
        </div>
      </el-card>
    </el-form>
  </section>
</template>

<script>
  import CalculatorParameters from '@/admin/pages/calculator/steps/CalculatorParameters'
  import ViewOffers from '@/admin/pages/calculator/steps/ViewOffers'
  import map from 'lodash-es/map'
  import { postRequest } from '@/shared/utils/http'
  import { formFields, pvgisFields } from './config'
  import pickBy from 'lodash-es/pickBy'
  import Dinero from 'dinero.js'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

  import TechnicalData from './steps/TechnicalData'
  import TypeInstallation from './steps/TypeInstallation'
  import Maps from './steps/Maps'
  import PhaseChoose from './steps/PhaseChoose'
  import TestComponent from './steps/TestComponent'
  export default {
    name: 'Calculator',
    components: {
      TestComponent,
      PhaseChoose,
      Maps,
      TypeInstallation,
      TechnicalData,
      ViewOffers,
      CalculatorParameters,
      PulseLoader
    },
    data () {
      return {
        form: Object.assign({}, formFields),
        offerData: null,
        dataFromPvgis: Object.assign({}, pvgisFields),
        slopesWithoutMap: null
      }
    },

    computed: {
    },

    mounted () {
      this.$root.title = 'Kalkulator ofert'
      this.$store.dispatch('app/defaultCalculationStep')
    },

    methods: {
      findAndChangeByConstruction: function (index, construction, item) {
        return new Promise((resolve, reject) => {
          postRequest('/api/find-by-construction-kit', {
            kit_id: item.id, // to change
            construction_id: construction
          })
            .then(response => {
              this.$emit('saved')
              this.switchCurrentKitForGroup(index, response.data)
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      findAndChangeByKit: function (index, kit, item) {
        item.button_disabled = true
        return new Promise((resolve, reject) => {
          postRequest('/api/find-by-construction-kit', {
            kit_id: kit, // to change
            construction_id: item.construction
          })
            .then(response => {
              this.$emit('saved')
              this.switchCurrentKitForGroup(index, response.data)
              item.button_disabled = false
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      findAndChangeByPanel: function (index, panel, item) {
        // console.log(item, panel)
        item.button_disabled = true
        return new Promise((resolve, reject) => {
          postRequest('/api/find-by-panel', {
            count_panel: item.count_solar_panel, // to change
            construction_id: item.construction,
            phase: item.phase,
            company_id: item.company_id,
            panel: panel
          })
            .then(response => {
              this.$emit('saved')
              this.switchCurrentKitForGroup(index, response.data)
              item.button_disabled = false
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      getOfferData: function (val) {
        this.offerData = val
        this.$store.dispatch('app/incrementCalculationStep')
      },
      previousStep () {
        this.$store.dispatch('app/decrementCalculationStep')
      },
      nextStep () {
        this.$store.dispatch('app/incrementCalculationStep')
      },
      prepareToSubmit () {
        this.submitCalculationForm()
        this.$store.dispatch('app/incrementCalculationStep')
      },
      submitCalculationForm () {
        this.loading = true
        return new Promise((resolve, reject) => {
          postRequest('/api/generate-calculation', {
            form: this.form,
            phase: this.form.phase,
            kwp: this.dataFromPvgis.power_needed,
            pvgis: this.dataFromPvgis,
            model_id: this.$route.params.id,
            model_type: this.$route.params.type
          })
            .then(response => {
              this.$emit('saved')
              this.calculation = response
              if (isNaN(this.calculation.id)) {
                this.$message.error('Nie można wygenerować kalkulacji.')
                return false
              }
              this.$router.push({ name: 'admin.calculations.show', params: { id: this.calculation.id } })
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              this.$store.dispatch('app/defaultCalculationStep')
              this.loading = false
              reject(error)
            })
        })
      },
      pvgisConnection () {
        return new Promise((resolve, reject) => {
          postRequest('/api/pvgis', this.form)
            .then(response => {
              this.$emit('saved')
              this.loading = false
              this.dataFromPvgis = pickBy(response.data, (value, key) => Object.keys(this.dataFromPvgis).includes(key))
              this.$store.dispatch('app/incrementCalculationStep')
              resolve(response)
            })
            .catch(error => {
              this.$store.dispatch('app/decrementCalculationStep')
              reject(error)
            })
        })
      },
      switchCurrentKitForGroup (index, kit) {
        map(this.offerData, function (item, key) {
          if (key === index) {
            item.addons_information = kit.addons_information
            item.provider = kit.provider
            item.bonus = kit.bonus
            item.provider = kit.companies
            item.construction = kit.construction
            item.count_inverter = kit.count_inverter
            item.count_solar_panel = kit.count_solar_panel
            item.id = kit.id
            item.inverter = kit.inverter
            item.inverter_card = kit.inverter_card
            item.kwp = kit.kwp
            item.kwp_per_solar = kit.kwp_per_solar
            item.phase = kit.phase
            item.price = kit.price
            item.price_construction = kit.price_construction
            item.price_construction_vat8 = kit.price_construction_vat8
            item.price_construction_vat23 = kit.price_construction_vat23
            item.price_construction_with_discount = kit.price_construction_with_discount
            item.price_construction_with_discount_vat8 = kit.price_construction_with_discount_vat8
            item.price_construction_with_discount_vat23 = kit.price_construction_with_discount_vat23
            item.price_vat8 = kit.price_vat8
            item.price_vat23 = kit.price_vat23
            item.price_with_discount = kit.price_with_discount
            item.price_with_discount_vat8 = kit.price_with_discount_vat8
            item.price_with_discount_vat23 = kit.price_with_discount_vat23
            item.solar_card = kit.solar_card
            item.solar_panel_name = kit.solar_panel_name
            item.warranty_performance = kit.warranty_performance
            item.warranty_product = kit.warranty_product
            item.addons_kits = kit.addons_kits
            item.addons_panels = kit.addons_panels
          }
        })
      },
      resetCalculator () {
        this.form = Object.assign({}, formFields)
        this.offerData = null
        this.dataFromPvgis = Object.assign({}, pvgisFields)
        this.$store.dispatch('app/defaultCalculationStep')
      },
      findCalculationWithoutMap () {
        this.loading = true
        return new Promise((resolve, reject) => {
          postRequest('/api/generate-calculation-without-map', {
            form: this.form,
            model_id: this.$route.params.id,
            model_type: this.$route.params.type,
            slope: this.slopesWithoutMap
          })
            .then(response => {
              this.$emit('saved')
              this.calculation = response
              this.$router.push({ name: 'admin.calculations.show', params: { id: this.calculation.id } })
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      updateSlope (val) {
        this.slopesWithoutMap = val
      }
    }
  }
</script>
<style scoped>
.vue-progress-path path {
  stroke-width: 12;
}

.vue-progress-path .progress {
  stroke: red;
}

.vue-progress-path .background {
  stroke: #edd;
}
</style>
