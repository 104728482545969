<template>
  <el-card>
    <div>
      <h1>Lista kalkulacji</h1>
      <hr>
    </div>
    <div
      v-if="list.length > 0"
      class="row">
      <div
        v-for="item in list"
        class="col-lg-3 col-md-12 col-xl-3 order-lg-1 order-xl-1 my-3">
        <el-card
          class="button-background"
          @click.native="actionMoveToCalculation(parseInt(item.id))"
          @mouseover.native="setHoverState(true)"
          @mouseleave.native="setHoverState(false)"
        >
          <h1><i class="fas fa-calculator"></i> Kalkualcja nr #{{ item.id }}</h1>
          <div>
            <small>Kliknij, aby przejść do kalkulacji</small>
          </div>
        </el-card>
      </div>
    </div>

    <div
      v-else
      class="row">
      <el-alert
        :closable="false"
        title="Brak dostępnych kalkulacji."
        type="info"/></div>
  </el-card>
</template>

<script>

  export default {
    name: 'CalculationList',
    props: {
      list: {
        type: Array,
        required: true
      }
    },
    data () {
      return {

      }
    },
    methods: {
      setHoverState (state) {
        this.hoverState = state
      },
      actionMoveToCalculation (val) {
        this.$router.push({path: '/admin/calculations/' + val})
      }
    }
  }
</script>

<style scoped>
.button-background{
  cursor: pointer;
  color: #3f4443;
  transition: 0.6s;
  background-image: radial-gradient(circle at 13% 47%, rgba(140, 140, 140,0.03) 0%, rgba(140, 140, 140,0.03) 25%,transparent 25%, transparent 100%),radial-gradient(circle at 28% 63%, rgba(143, 143, 143,0.03) 0%, rgba(143, 143, 143,0.03) 16%,transparent 16%, transparent 100%),radial-gradient(circle at 81% 56%, rgba(65, 65, 65,0.03) 0%, rgba(65, 65, 65,0.03) 12%,transparent 12%, transparent 100%),radial-gradient(circle at 26% 48%, rgba(60, 60, 60,0.03) 0%, rgba(60, 60, 60,0.03) 6%,transparent 6%, transparent 100%),radial-gradient(circle at 97% 17%, rgba(150, 150, 150,0.03) 0%, rgba(150, 150, 150,0.03) 56%,transparent 56%, transparent 100%),radial-gradient(circle at 50% 100%, rgba(25, 25, 25,0.03) 0%, rgba(25, 25, 25,0.03) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 55% 52%, rgba(69, 69, 69,0.03) 0%, rgba(69, 69, 69,0.03) 6%,transparent 6%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));
}
.button-background:hover{
  color: #97d700;
  border-color: #e0f3b3;
  background-color: #f5fbe6;
}
.value{
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 15px;
}
</style>
