@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

@mixin background-color($args) {
  background-color: $args;
}

@mixin inner-color($args) {
  color: $args;
}