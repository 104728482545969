.dashboard-tasks {
  border-radius:5px;
  padding: 16px 30px;
  color: #fff;
  //background: rgb(73, 215, 0);
  //background: linear-gradient(180deg, rgba(151, 215, 0, 1) 0%, rgba(151, 215, 0, 1) 35%, rgba(203, 215, 0, 1) 100%);
  background-image: linear-gradient(169deg, rgba(214, 214, 214, 0.05) 0%, rgba(214, 214, 214, 0.05) 25%,rgba(147, 147, 147, 0.05) 25%, rgba(147, 147, 147, 0.05) 50%,rgba(106, 106, 106, 0.05) 50%, rgba(106, 106, 106, 0.05) 75%,rgba(58, 58, 58, 0.05) 75%, rgba(58, 58, 58, 0.05) 100%),linear-gradient(2deg, rgba(151, 151, 151, 0.05) 0%, rgba(151, 151, 151, 0.05) 25%,rgba(118, 118, 118, 0.05) 25%, rgba(118, 118, 118, 0.05) 50%,rgba(60, 60, 60, 0.05) 50%, rgba(60, 60, 60, 0.05) 75%,rgba(62, 62, 62, 0.05) 75%, rgba(62, 62, 62, 0.05) 100%),linear-gradient(260deg, rgba(84, 84, 84, 0.05) 0%, rgba(84, 84, 84, 0.05) 25%,rgba(74, 74, 74, 0.05) 25%, rgba(74, 74, 74, 0.05) 50%,rgba(232, 232, 232, 0.05) 50%, rgba(232, 232, 232, 0.05) 75%,rgba(88, 88, 88, 0.05) 75%, rgba(88, 88, 88, 0.05) 100%),linear-gradient(89deg, rgba(191, 191, 191, 0.05) 0%, rgba(191, 191, 191, 0.05) 25%,rgba(64, 64, 64, 0.05) 25%, rgba(64, 64, 64, 0.05) 50%,rgba(57, 57, 57, 0.05) 50%, rgba(57, 57, 57, 0.05) 75%,rgba(86, 86, 86, 0.05) 75%, rgba(86, 86, 86, 0.05) 100%),linear-gradient(26deg, rgba(189, 189, 189, 0.05) 0%, rgba(189, 189, 189, 0.05) 25%,rgba(134, 134, 134, 0.05) 25%, rgba(134, 134, 134, 0.05) 50%,rgba(143, 143, 143, 0.05) 50%, rgba(143, 143, 143, 0.05) 75%,rgba(12, 12, 12, 0.05) 75%, rgba(12, 12, 12, 0.05) 100%),linear-gradient(90deg, rgb(168, 254, 0),rgb(75, 207, 73));
  &-daynumber {
    font-size: 16px;
  }
  webkit-box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.4);
}


.progress-box{
  text-align: center;
  .circle-box{

  }
  .text-box{
    margin-top:30px;
  }
}

.tasks-dashboard{
  margin-top:25px;
}
.tasks-item{
  display:flex;
  margin-bottom: 0.5em;
}
.task-name{
  padding-left:1.4em;
  padding-right:0.5em;
  width:100%;
}
.task-name:before {
  background: #9dd700;
  position: absolute;
  display: block;
  width: 0.2rem;
  left: 40px;
  height: 30px;
  content: "";
  border-radius: 0.2rem;
}
