<template>
  <el-card
    v-loading="loading"
    class="mt-3"
  >
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="my-0 py-0">
        Kalkulator abonamentu
      </h5>
      <div class="my-2 my-md-0 mr-md-3">
        <el-tag
          type="primary"
          effect="dark"
        >
          {{ calculateLoan }} PLN
        </el-tag>

        <el-button
          :disabled="disableModule"
          type="primary"
          size="small"
          plain
          @click="saveLoan"
        >
          Zapisz
        </el-button>
      </div>
    </div>
    <hr>
    <div class="row mt-3">
      <div class="col-md-12 col-sm-12 mt-4">
        <span class="mb-4">Podaj oprocentowanie</span>
        <input-number
          v-model="calculation.credit_line"
          :precision="2"
          :disabled="disableModule"
          :max="100"
          :min="0.25"
          class="w-100"
        />
      </div>
      <div class="col-md-12 col-sm-12 mt-4">
        <span class="mb-4">Wybierz ilość rat</span>
        <el-slider
          v-model="calculation.loans"
          :min="3"
          :disabled="disableModule"
          :max="150"
          class="w-100"
        />
      </div>
    </div>
  </el-card>
</template>

<script>
  import Dinero from 'dinero.js'
  import { patchRequest } from '@/shared/utils/http'

  export default {
    name: 'CalculatorPrice',
    props: {
      disableModule: {
        type: Boolean,
        default: true
      },
      calculation: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        loading: false,
        alert: false,
        form: {},
        loans: 100,
        creditLine: 0.3
      }
    },
    computed: {
      calculateLoan () {
        return this.calculate()
      }
    },
    methods: {
      calculate () {
        if (this.calculation.loans === null && this.calculation.credit_line === null) {
          this.calculation.loans = this.loans
          this.calculation.credit_line = this.creditLine
        }
        const cost = this.calculation.price_brutto
        const creditFactor = this.calculation.credit_line * this.calculation.loans
        const costCreditNative = (creditFactor / 100) * cost

        if (cost === undefined || isNaN(cost)) {
          return 0
        }

        if (costCreditNative === undefined || isNaN(costCreditNative)) {
          return 0
        }

        const priceOne = Dinero({ amount: Math.round(cost * 100), precision: 2 })
        const priceTwo = Dinero({ amount: Math.round(costCreditNative * 100), precision: 2 })
        const fullCost = priceOne.add(priceTwo)
        return fullCost.divide(this.calculation.loans).getAmount() / 100
      },
      saveLoan () {
        this.alert = false
        this.form = {
          loans: this.calculation.loans,
          credit_line: this.calculation.credit_line,
          loan_price: this.calculate()
        }
        this.loading = true
        if (!this.validForm()) return false
        this.submit()
      },
      submit () {
        return new Promise((resolve, reject) => {
          patchRequest('/api/offers/' + this.calculation.id, this.form)
            .then(response => {
              this.$emit('saved')
              this.calculation.loans = response.data.loans
              this.calculation.credit_line = response.data.credit_line
              this.calculation.loan_price = response.data.loan_price
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              reject(error)
              this.loading = false
            })
        })
      },
      validForm () {
        if (this.calculation.credit_line === '' || this.calculation.credit_line === null) {
          this.alert = true
          return false
        }

        return true
      }
    }
  }
</script>

<style scoped>
.price-box {
  margin-top: 10px;
  position: absolute;
  padding: 10px;
  right: 60px;
  background-color: #fff;
  color: #97d700;
  border: 1px solid #b7b7b7;
  z-index: 1;
  font-size: 18px;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  border-radius: 3px;
}
.option-box-header{
  width: 100%;
  border-bottom: 2px dotted #ddd;
}
</style>
