.el-drawer__header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #72767b;
  background: #111314;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
   margin-bottom: 0px !important;
  padding: 20px;
  padding-bottom: 20px;
}

.el-drawer__body {
  overflow: scroll;
}

.body-drawer{
  padding:15px;
}
.subtask-item{
  display:flex;
  margin-bottom: 0.5em;
}
.subtask-name{
  padding-left:1.4em;
  padding-right:0.5em;
  width:100%;
}
.subtask-name:before {
  background: #9dd700;
  position: absolute;
  display: block;
  width: 0.2rem;
  left: 15px;
  height: 30px;
  content: "";
  border-radius: 0.2rem;
}

.comments-item{
  display:flex;
  margin-bottom: 0.5em;
  padding: 10px;
  border-left: 1px solid #9dd700;
  background: #f2f2f2;
}
.comments-user{
  padding-left:0.7em;
}
.comments-text{
  padding-right:0.5em;
  width:100%;
}

