<template>
  <el-card class="mt-3 ">
    <div class="head text-center mb-6 container-lg">
      <h2>Zużycie prądu </h2>
      <small>Podsumuj zużycie energii z ostatnich 12 miesięcy i wpisz je ręcznie.</small>
      <hr>
    </div>
    <div
      v-if="alertError"
      class="container-lg"
    >
      <el-alert
        title="*Musisz podać ile kwH zużywasz do kolejnego etapu"
        type="error"
      />
    </div>
    <div class="container-lg">
      <div class="row mt-3">
        <div class="col-12 text-end mb-6">
          <el-switch
            v-model="form.knowPowerTrigger"
            active-text="Znam moc instalacji"
          />
        </div>
        <div class="col-12">
          <el-alert
            v-if="form.knowPowerTrigger"
            :closable="false"
            type="info"
            show-icon
          >
            Posiadając moc instalacji, wprowadź ją w formacie np.: <strong>6.341</strong>, system wyszuka odpowiednich zestawów.
          </el-alert>
          <el-alert
            v-else
            :closable="false"
            type="info"
            show-icon
          >
            Pamiętaj, aby wprowadzać dane zużycia w formacie np.: <strong>5356.34</strong>, system przeliczy wymagane zapotrzebowanie.
          </el-alert>
        </div>
      </div>
      <div class="row mt-6">
        <div
          v-if="!form.knowPowerTrigger"
          class="col-lg-6 col-md-12 col-xl-6 order-lg-1 order-xl-1"
        >
          <custom-form-item
            prop="kwp"
            label="Roczne zużycie prądu dla klienta? [kWh]"
          >
            <input-number
              v-model="form.yearly_use"
              :precision="2"
              name="kwp"
              class="w-100"
              type="number"
              @keyup.enter.native.stop.prevent="nextStep"
            />
          </custom-form-item>
        </div>
        <div
          v-else
          class="col-lg-6 col-md-12 col-xl-6 order-lg-1 order-xl-1"
        >
          <custom-form-item
            prop="expected_power"
            label="Podaj oczekiwaną moc [kWp]"
          >
            <input-number
              v-model="form.expected_power"
              :precision="3"
              name="expected_power"
              class="w-100"
              type="number"
              @keyup.enter.native.stop.prevent="nextStep"
            />
          </custom-form-item>
        </div>
        <div class="col-lg-6 col-md-12 col-xl-6 order-lg-1 order-xl-1">
          <custom-form-item
            prop="consumption"
            label="Autokonsumpcja w %"
          >
            <input-number
              v-model="form.consumption_user"
              :min="1"
              :max="100"
              name="consumption_user"
              class="w-100"
              type="consumption"
              @keyup.enter.native.stop.prevent="nextStep"
            />
          </custom-form-item>
        </div>
        <div
          v-if="form.knowPowerTrigger"
          class="col-lg-6 col-md-12 col-xl-6 order-lg-1 order-xl-1"
        >
          <custom-form-item
            prop="type"
            label="Rodzaj instalacji dachu"
          >
            <el-select
              v-model="slope"
              class="w-100"
              name="type"
              placeholder="Wybierz rodzaj"
              @change="updateSlope"
            >
              <el-option
                v-for="item in constructionType"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </custom-form-item>
        </div>
      </div>
      <div
        v-if="form.knowPowerTrigger"
        class="phase-section my-2"
      >
        <div class="header">
          <h5 class="py-0 my-0">
            Wybierz fazę dla zestawu
          </h5>
          <hr>
        </div>
      </div>
      <div class="phases-list d-flex justify-content-start">
        <div
          v-for="(item,index) in phases"
          v-if="form.knowPowerTrigger"
          class="phase-element shadow-lg"
        >
          <div
            :class="{ active: index === isActive, 'box-type-installation': true }"
            @mouseover.native="setHoverState(true)"
            @mouseleave.native="setHoverState(false)"
            @click="setCard(index, item.value)"
          >
            <div class="text-center py-4">
              <strong>Zestawy dla <span class="text-phase">{{ item.text }}</span></strong>
            </div>
            <div class="text-xxl-right">
              <h6><strong>Kliknij, aby wybrać</strong></h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-row class="text-end mt-6 container-lg">
      <el-button
        type="primary"
        @click="nextStep"
      >
        <i class="fas fa-arrow-right"></i> Przejdź
      </el-button>
    </el-row>
  </el-card>
</template>

<script>
  import { objToSelect } from '@/shared/utils/helpers'

  export default {
    name: 'TechnicalData',
    props: {
      form: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        checked: false,
        alertError: false,
        isActive: '',
        slope: null,
        phases: [{
          text: '1-faz',
          value: 1
        }, {
          text: '3-faz',
          value: 3
        }
        ]
      }
    },

    computed: {
      constructionType () {
        return objToSelect(this.$config.activeConstructionsTypes)
      }
    },

    methods: {
      nextStep () {
        // TODO DO PRZEROBIENIA moduł
        this.alertError = false
        if (this.form.knowPowerTrigger === false) {
          if (this.form.yearly_use === 0.00) {
            this.alertError = true
            return false
          }
          this.$store.dispatch('app/incrementCalculationStep')
          this.$emit('nextStepCalculation')
        } else {
          if (this.form.expected_power === 0.000) {
            this.alertError = true
            return false
          }
          if (this.form.phase === null) {
            this.alertError = true
            return false
          }
          this.$emit('calculation-without-map')
          this.$store.dispatch('app/setCalculationStep', 4)
        }
      },
      setCard (index, value) {
        this.form.phase = value
        this.isActive = index
      },
      setHoverState (state) {
        this.hoverState = state
      },
      updateSlope (val) {
        this.$emit('update-slope', val)
      }
    }
  }
</script>

<style scoped>
.box-type-installation{
  border-radius: 10px;
  cursor: pointer;
  color: #3f4443;
  transition: 0.6s;
}
.active {
  color: #97d700;
  border-color: #e0f3b3;
}
.box-type-installation:hover{
  color: #97d700;
  border-color: #e0f3b3;
}
.text-phase{
  background: #333;
  padding: 10px;
  color: #97d700;
  border-radius: 15px;
}
.phase-element{
  width: 250px;
  background-image: radial-gradient(circle at 57% 36%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 10%,transparent 10%, transparent 100%),radial-gradient(circle at 22% 61%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 68% 97%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 41%,transparent 41%, transparent 100%),radial-gradient(circle at 57% 89%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 30%,transparent 30%, transparent 100%),radial-gradient(circle at 39% 80%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 22%,transparent 22%, transparent 100%),radial-gradient(circle at 88% 71%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 30%,transparent 30%, transparent 100%),linear-gradient(0deg, rgb(255,255,255),rgb(255,255,255));  border-radius: 10px;
  border: 1px solid #b9b9b9;
  margin:3px;
  padding:5px;
}
</style>
