.attachment {
  display: flex;
  align-items: flex-start;
  padding: 6px 12px;
  border-bottom: 1px solid #c7c7c7;
  background: map_get($colors, light-grey);

  &--thumbnail {
    margin-right: 1rem;
    width: 100px;
    padding: 5px 0 0;
    margin-top: auto;
    margin-bottom: auto;

    img {
      max-width: 100%;
    }

    i {
      color: map_get($colors, grey);
    }
  }

  &--name {
    margin-top: auto;
    margin-bottom: auto;
    button {
      opacity: 0.75;

      &:hover, &:focus {
        opacity: 1;
      }
    }
  }

  &--body {
    width: 100%;

    > div {
      display: flex;
      max-width: 100%;
      justify-content: space-between;
    }
  }

  &--name {
    min-width: 33%;
  }

  &--description {
    margin-top: auto;
    margin-bottom: auto;
  }
  &--filename {
    max-width: calc(100% - 4rem);
    overflow: hidden;
    padding-right: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: auto;
    margin-bottom: auto;
  }

  &--buttons {
    min-width: 3rem;
  }
}