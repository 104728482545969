<template>
  <el-card
    class="mt-3">
    <div class="row container">
      <div class="col-lg-12 col-md-12 col-xl-12 order-lg-1 order-xl-1">
        <h3 class="text-center">Wybierz rodzaj produktu</h3>
        <p class="text-center"><small>Wybierz jaki rodzaj produktu chcesz uzupełnić. Po wybraniu odpowiedniego naciśnij myszką. Program przeniesie Cię
        do odpowiedniego formularza.</small> </p>
        <hr>
      </div>
      <div
        v-for="item in $config.productList"
        v-if="item.active == 1"
        class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1">
        <el-card
          class="mt-2 dashboard-button"
          @click.native="selectProductType(item.id)"
          @mouseover.native="setHoverState(true)"
          @mouseleave.native="setHoverState(false)"
        >
          <div class="icons text-center"><i :class="item.icon"></i></div>
          <div class="desc text-center"><strong>{{ item.name }}</strong></div>
        </el-card>
      </div>
    </div>
  </el-card>
</template>

<script>
  export default {
    name: 'SelectProduct',
    props: {
      icon: {
        type: String | Boolean,
        default: 'fab fa-sellsy'
      },
      form: {
        type: Object,
        default: () => {}
      },
      type: {
        type: String,
        default: 'info',
        validator: (value) => {
          return [
            'primary', 'success', 'warning', 'danger', 'info', 'text'
          ].includes(value)
        }
      },
      resource: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        hoverState: false,
        products: {}
      }
    },
    methods: {
      nextPage: function () {
        this.$emit('nextPage')
      },
      setHoverState (state) {
        this.hoverState = state
      },
      actionMoveToComponent () {
        this.$router.push({name: this.resource})
      },
      selectProductType (val) {
        this.form.type_product = val
        this.nextPage()
      }
    }
  }
</script>

<style scoped>
.dashboard-button{
  cursor: pointer;
  color: #3f4443;
  padding: 40px 0px;
  transition: 0.6s;
  background-image: radial-gradient(circle at 13% 47%, rgba(140, 140, 140,0.03) 0%, rgba(140, 140, 140,0.03) 25%,transparent 25%, transparent 100%),radial-gradient(circle at 28% 63%, rgba(143, 143, 143,0.03) 0%, rgba(143, 143, 143,0.03) 16%,transparent 16%, transparent 100%),radial-gradient(circle at 81% 56%, rgba(65, 65, 65,0.03) 0%, rgba(65, 65, 65,0.03) 12%,transparent 12%, transparent 100%),radial-gradient(circle at 26% 48%, rgba(60, 60, 60,0.03) 0%, rgba(60, 60, 60,0.03) 6%,transparent 6%, transparent 100%),radial-gradient(circle at 97% 17%, rgba(150, 150, 150,0.03) 0%, rgba(150, 150, 150,0.03) 56%,transparent 56%, transparent 100%),radial-gradient(circle at 50% 100%, rgba(25, 25, 25,0.03) 0%, rgba(25, 25, 25,0.03) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 55% 52%, rgba(69, 69, 69,0.03) 0%, rgba(69, 69, 69,0.03) 6%,transparent 6%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));
}
.dashboard-button:hover{
  color: #97d700;
  border-color: #e0f3b3;
  background-color: #f5fbe6;
}
.title-box{
  font-size:18px
}
.icons {
  margin-left: 5px;
  font-size:70px;
  color: #757575;
}
</style>
