<template>
  <div>
    <div
      v-if="element.block_calculation"
      class="info"
    >
      <el-alert
        type="error"
        :closable="false"
        effect="dark"
      >
        <i class="fas fa-exclamation-triangle"></i> Kalkulacja wygasła/została zablokowana - możesz wygenerować nową w profilu osoby kalkulowanej.
      </el-alert>
    </div>
    <fixed-header :threshold="200">
      <div class="navbar">
        <el-card
          v-loading="loading"
          class="mt-1 w-100"
        >
          <div class="d-flex justify-content-between flex-lg-row flex-xl-row flex-column flex-nowrap align-items-center">
            <div class="title">
              <h1 class="my-0">
                Kalkulacja numer #{{ element.id }}
              </h1>
              <small>Kalkulacja wykonana przez użytkownika {{ element.user_name }}</small>
            </div>
            <div class="buttons mt-xs-3">
              <el-button
                v-if="!element.block_calculation && $can('admin.calculations.block')"

                class="button-mobile-app"
                type="danger"
                @click="blockStatusUpdate(true)"
              >
                <i class="fas fa-lock"></i> Zablokuj
              </el-button>
              <el-button
                v-if="element.block_calculation && $can('admin.calculations.block')"

                class="button-mobile-app"
                type="primary"
                @click="blockStatusUpdate(false)"
              >
                <i class="fas fa-unlock"></i> Odblokuj
              </el-button>
              <el-button
                v-if="savedCalculation && $can('admin.calculations.block')"

                class="button-mobile-app"
                :disabled="element.block_calculation"
                type="primary"
                @click="refreshKits"
              >
                <i class="fas fa-sync-alt"></i> Odśwież
              </el-button>
              <el-button
                v-if="savedCalculation"

                class="button-mobile-app"
                :disabled="element.block_calculation"
                type="primary"
                @click="generatePDF"
              >
                <i class="fas fa-file-pdf"></i> Porównanie PDF
              </el-button>
              <el-button
                class="button-mobile-app"

                @click="saveActuallyCalculations()"
              >
                <i class="fas fa-save"></i> Nadpisz
              </el-button>
              <el-button
                class="button-mobile-app"
                @click="$router.go(-1)"
              >
                Powrót
              </el-button>
            </div>
          </div>
        </el-card>
      </div>
    </fixed-header>
    <calculation-options
      class="mt-3"
      :vat="vat"
      :provision="provision"
      :discount="discount"
      :loading="loading"
      :disabled="element.block_calculation"
      @update-commission="changeCommissionMode"
      @update-vat="changeVatValue"
      @update-discount="changeDiscountValue"
      @update-display="updateDisplay"
      @update-provision="recalculate"
    />

    <div class="mt-3">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-xl-2 order-lg-1 order-xl-1">
          <div class="mt-5">
            <div>
              <h5>Dodatkowe opcje</h5>
              <hr style="border-top: 6px solid #97d700">
            </div>
            <div class="special-pricing-section">
              <el-button
                type="primary"
                class="w-100"
                @click="openSpecialPricing"
              >
                Zapytaj o wycenę
              </el-button>
              <hr>
              <el-button
                v-if="$can('admin.calculations.special-pricing')"

                type="primary"
                class="w-100"
                @click="openSpecialPricingAdmin"
              >
                Specjalna wycena
              </el-button>
            </div>
          </div>
          <div class="mt-3">
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <dl>
                <dt>Zapotrzebowanie mocy</dt>
                <dd v-if="!element.inputs.pvgis.length">
                  <el-tag type="info">
                    Moc min. : {{ element.inputs ? powerNeededSearching : '' }} kWp
                  </el-tag>
                </dd>
                <dd v-else>
                  <el-tag type="info">
                    Moc min. : {{ element.inputs ? kwpSearching : '' }} kWp
                  </el-tag>
                </dd>
              </dl>
              <dl>
                <dt>Fazy</dt>
                <dd>
                  <el-tag type="info">
                    Ilość faz: {{ element.inputs ? element.inputs.phase : '' }}
                  </el-tag>
                </dd>
              </dl>
            </div>
            <hr>
          </div>
          <div class="slopes-section">
            <div class="slopes-section-header">
              <div style="position: relative">
                <h5>Połacie </h5>
                <div
                  style="position:absolute; top:-5px; right:5px"
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Dodaj kolejną połać"
                    placement="top-start"
                  >
                    <el-button
                      v-if="canAddSlopes"
                      type="text"
                      @click="slopeCreatorVisible = true"
                    >
                      <i class="fas fa-plus"></i>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Załaduj domyślne konstrukcje do połaci"
                    placement="top"
                  >
                    <el-button
                      type="text"
                      @click="updateAllDefaultSlopes"
                    >
                      <i class="fas fa-magic"></i>
                    </el-button>
                  </el-tooltip>
                </div>
              </div>
              <slope-creator-component
                :show-dialog="slopeCreatorVisible"
                @update-slopes="updateSlopes"
                @visibility="resetSlopeCreator"
              />
              <hr style="border-top: 6px solid #97d700">
            </div>
            <slopes-component
              :slopes="element.slopes"
              :can-delete-slopes="canDeleteSlopes"
              @delete-slope="deleteSlope"
              @load-default-construction="updateDefaultSlope"
            />
          </div>
          <div v-if="!element.inputs.pvgis.length">
            <div>
              <h5>Uśrednione dane PVGIS </h5>
              <hr style="border-top: 6px solid #97d700">
            </div>
            <div class="mt-3">
              <dl>
                <dt>Produkcja roczna energii z 1 kWp</dt>
                <dd>{{ element.inputs ? element.inputs.pvgis.energy_production : '' }} kWh</dd>
              </dl>
              <dl>
                <dt>Nasłonecznienie roczne</dt>
                <dd>{{ element.inputs ? element.inputs.pvgis.insulation : '' }} kWh/m2</dd>
              </dl>
              <dl>
                <dt>Straty ułożeniowe roczne</dt>
                <dd>{{ element.inputs ? element.inputs.pvgis.loss.position : '' }} %</dd>
              </dl>
              <dl>
                <dt>Straty Systemu roczne</dt>
                <dd>-6 %</dd>
              </dl>
              <dl>
                <dt>Straty temperaturowe roczne</dt>
                <dd>{{ element.inputs ? element.inputs.pvgis.loss.temperature : '' }} %</dd>
              </dl>
            </div>
          </div>
          <div>
            <div>
              <h5>Osoba kalkulowana</h5>
              <hr style="border-top: 6px solid #97d700">
            </div>
            <div class="d-flex justify-content-between">
              <contractor
                v-if="element.person"
                :item="element.person"
                :type="element.person.type"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xl-10 order-lg-1 order-xl-1 mt-3">
          <div class="row">
            <div
              v-for="(item,index) in element.kits"
              class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1 mt-3"
            >
              <el-card
                v-loading="loading"
                :body-style="{padding: '0px'}"
                shadow="always"
              >
                <!--                                <overlay-construcition-->
                <!--                                  :kit-index="index"-->
                <!--                                  :constructions="item.constructions_list"-->
                <!--                                  @update-construction-kit="updateConstruction"-->
                <!--                                />-->
                <header class="d-flex justify-content-between">
                  <el-checkbox
                    v-model="item.checked"
                    class="checkbox-section"
                    @change="setCheckedGroup($event, index)"
                  >
                    Zaznacz
                  </el-checkbox>
                  <div class="mt-1">
                    <el-button
                      v-if="item.duplicate && item.duplicate === true"
                      type="text"
                      @click="deleteDuplicate(index)"
                    >
                      Usuń
                    </el-button>
                    <el-button
                      type="text"
                      class="duplicate-button"
                      @click="duplicate(index)"
                    >
                      Duplikuj
                    </el-button>
                  </div>
                </header>
                <div style="position: absolute;top: 0px;left: 0px;">
                  <el-tag
                    v-if="item.addons_information"
                    type="danger"
                    class="w-100"
                    effect="dark"
                  >
                    {{ item.addons_information ? item.addons_information : 'Brak informacji' }}
                  </el-tag>
                </div>
                <div class="my-6">
                  <img
                    :src="item.provider_logo"
                    class="image mt-1"
                  >
                </div>
                <div style="padding: 14px;">
                  <div class="w-100 text-center py-3">
                    <el-alert
                      v-if="!validConstructionSlopes(item)"
                      :closable="false"
                      class="mb-2"
                      title="Wybierz konstrukcję dla połaci. Rozłóż panele."
                      type="error"
                      show-icon
                    />
                    <div>
                      <small class="text-left">Wybierz komplet zestawu</small>
                      <table class="table">
                        <tr>
                          <th>Nazwa paneli</th>
                          <td class="special-select-record">
                            <el-select
                              v-model="item.solar_panel_name"
                              :disabled="element.block_calculation"
                              value-key="solar_panel_name"
                              class="w-100 calculator-select"
                              placeholder="Wybierz Panel"
                              name="solar_panel_name"
                              @change="changeKitByPanel(index, $event, item)"
                            >
                              <el-option
                                v-for="item in item.addons_panels"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                              />
                            </el-select>
                          </td>
                        </tr>
                        <tr>
                          <th>Ilość paneli</th>
                          <td class="special-select-record">
                            <el-select
                              v-model="item.count_solar_panel"
                              :disabled="element.block_calculation || !validConstructionSlopes(item)"
                              value-key="kit"
                              class="w-100 calculator-select"
                              placeholder="Dostosuj zestaw"
                              name="new_addons_kits"
                              @change="changeKitBySolarKit(index, $event, item)"
                            >
                              <el-option
                                v-for="(kit,i) in item.addons_kits"
                                :key="i"
                                :label="kit.count_solar_panel"
                                :value="kit.id"
                              >
                                {{ kit.count_solar_panel + ' szt.' }} - {{ kit.kwp + ' kwp' }}
                              </el-option>
                            </el-select>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            Falownik
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Resetuj do domyślnego falownika"
                              placement="top"
                            >
                              <el-button
                                type="text"
                                @click="resetInverterManually(item)"
                              >
                                <i class="fas fa-undo"></i>
                              </el-button>
                            </el-tooltip>
                          </th>
                          <td class="special-select-record">
                            <el-select
                              v-model="item.inverter_id"
                              :disabled="element.block_calculation || !validConstructionSlopes(item)"
                              value-key="inverter"
                              class="w-100 calculator-select"
                              placeholder="Falownik"
                              name="inverter"
                              @change="setNewInverter($event, item, index)"
                            >
                              <el-option
                                v-for="inverter in item.inverter_list"
                                :key="inverter.id"
                                :label="inverter.name"
                                :value="inverter.id"
                              >
                                {{ inverter.name }}
                              </el-option>
                            </el-select>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div
                      v-if="item.hasOwnProperty('construction_slopes')"
                      class="my-3"
                    >
                      <small>Konstrukcje na połaciach</small>
                      <div v-if="!item.construction_slopes.length">
                        <el-alert
                          :closable="false"
                          title="Brak wybranych połaci"
                          type="warning"
                          center
                          show-icon
                        />
                      </div>
                      <div
                        v-for="elementConstruction in item.construction_slopes"
                        v-else
                      >
                        <div class="row mt-1">
                          <div class="col-8">
                            <el-select
                              v-model="elementConstruction.construction_id"
                              :disabled="element.block_calculation"
                              class="w-100 calculator-select"
                              value-key="construction"
                              placeholder="Wybierz konstrukcje"
                              name="new_construction"
                              @change="changeKitBySolarConstruction(index, null, item)"
                            >
                              <el-option
                                v-for="construction in item.constructions_list"
                                :key="construction.id"
                                :label="construction.name"
                                :value="construction.id"
                              />
                            </el-select>
                          </div>
                          <div class="col-4">
                            <input-number
                              v-model="elementConstruction.panel_count"
                              :min="0"
                              :disabled="element.block_calculation"
                              :max="item.count_solar_panel"
                              class="w-100"
                              controls-position="right"
                              @change="changeKitBySolarConstruction(index, null, item)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <el-tag
                      v-if="discount"
                      effect="dark"
                      type="danger"
                      class="w-100 mt-2"
                    >
                      RABAT 5%
                    </el-tag>
                  </div>
                  <production-slope-component
                    v-if="!element.inputs.pvgis.length"
                    :slopes="element.slopes"
                    :constructions_slopes="item.construction_slopes"
                    :pvgis_production_slopes="element.inputs.pvgis.computed_slopes"
                    :panel="item.solar_panel_name"
                    :panels="item.addons_panels"
                  />
                  <div>
                    <small>Dane techniczne</small>
                    <table class="table table-information">
                      <tr>
                        <th>Moc zestawu</th><td>{{ !item.button_disabled ? item.kwp: '-' }} kWp</td>
                      </tr>
                      <tr>
                        <th>Fazy</th><td>{{ !item.button_disabled ? item.phase: '-' }}</td>
                      </tr>
                      <tr>
                        <th>Gwa. produktu</th><td>{{ !item.button_disabled ? item.warranty_product ? item.warranty_product : 'Brak informacji' : '-' }}</td>
                      </tr>
                      <tr>
                        <th>Gwa. wydajności</th><td>{{ !item.button_disabled ? item.warranty_performance ? item.warranty_performance : 'Brak informacji': '-' }}</td>
                      </tr>
                      <tr>
                        <th>VAT</th><td>
                          <el-tag
                            type="success"
                            size="small"
                            effect="dark"
                          >
                            {{ vat ? '23' : '8' }}%
                          </el-tag>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          {{ !item.button_disabled ? item.solar_info : '-' }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <el-checkbox
                        v-model="item.canAddons"
                        :disabled="!validConstructionSlopes(item)"
                      >
                        Chcesz dodać dodatki?
                      </el-checkbox>
                      <el-checkbox
                        v-model="item.notIncludeInverter"
                        disabled
                      >
                        Bez falownika
                      </el-checkbox>
                      <div v-if="item.canChangeInverter">
                        <div class="my-6">
                          <span>Wybierz inny falownik</span>
                          <el-select
                            v-model="item.inverter_id"
                            value-key="inverter"
                            class="w-100 calculator-select"
                            placeholder="Falownik"
                            name="inverter"
                            @change="setNewInverter"
                          >
                            <el-option
                              v-for="inverter in item.inverter_list"
                              :key="inverter.id"
                              :label="inverter.name"
                              :value="inverter.id"
                            >
                              {{ inverter.name }}
                            </el-option>
                          </el-select>
                        </div>
                        <hr>
                      </div>
                      <div
                        v-if="item.canAddons"
                        class="my-6"
                      >
                        <span>Dodaj dodatki do kalkulacji</span>
                        <el-select
                          v-model="item.current_addon"
                          value-key="addons"
                          class="w-100 calculator-select"
                          placeholder="Dodatki do oferty"
                          name="addons"
                          @change="addAddonsToInputs($event, index)"
                        >
                          <el-option
                            v-for="(addon,i) in item.addons_list"
                            :key="addon.id"
                            :label="addon.name"
                            :value="i"
                          >
                            {{ addon.name }} + {{ addon.cost }} PLN netto
                          </el-option>
                        </el-select>

                        <div
                          v-if="item.selected_addon.name !== ''"
                          class="list-container my-3"
                        >
                          <div class="row block-addons">
                            <div class="col-6 block-addons-auto">
                              <i class="fas fa-angle-right"></i>
                              <span>{{ item.selected_addon.count }} {{ item.selected_addon.unit }} - {{ item.selected_addon.name }}</span>
                            </div>
                            <div class="col-6">
                              <input-number
                                v-model="item.selected_addon.count"
                                :min="1"
                                :max="99"
                                controls-position="right"
                              />
                            </div>
                            <div class="col-12 my-3">
                              <el-button
                                type="primary"
                                class="w-100"
                                @click="addAddonsToTable(index)"
                              >
                                Dodaj do listy
                              </el-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-3">
                    <div v-for="(element,i) in item.added_addons_list">
                      <el-tooltip
                        class="item text-red"
                        effect="dark"
                        content="Usuń ten element"
                        placement="top"
                      >
                        <i
                          style="cursor:pointer"
                          class="fas fa-times"
                          @click="deleteElementList(index, i)"
                        ></i>
                      </el-tooltip>
                      - {{ element.count }}x
                      {{ element.name | truncate(35) }}
                      <el-tag
                        v-if="element.cost > 0.00"
                        type="success"
                      >
                        {{ element.cost }} PLN netto
                      </el-tag>
                    </div>
                  </div>
                  <div class="my-6">
                    <div v-if="item.bonus">
                      {{ item.bonus ? item.bonus : 'Brak informacji' }}
                    </div>
                    <hr>
                    <div
                      v-if="item.solar_card || item.inverter_card"
                      class="row"
                    >
                      <div
                        class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1"
                      >
                        <download-button
                          :disabled="!item.solar_card"
                          :file_name="item.solar_card"
                          icon="fas fa-solar-panel"
                          name="Panel"
                        />
                      </div>
                      <div
                        class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1"
                      >
                        <download-button
                          :disabled="!item.inverter_card"
                          :file_name="item.inverter_card"
                          icon="fas fa-window-restore"
                          name="Falownik"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="my-5"
                  >
                    <div class="text-center">
                      <h5>Kwota całkowita {{ showTypePrice ? 'netto' : 'brutto' }}</h5>
                    </div>
                    <el-tag
                      v-if="!item.button_disabled"
                      type="success"
                      style="    font-size: 25px;"
                      class="w-100 text-center"
                    >
                      {{ getFullPrice(item) }}
                    </el-tag>
                    <el-tag
                      v-else
                      type="danger"
                      style="    font-size: 25px;"
                      class="w-100 text-center"
                    >
                      Uzupełnij kalkulację!
                    </el-tag>
                  </div>
                  <div
                    v-if="!blockCreateOffers"
                    class="bottom clearfix mt-5"
                  >
                    <el-button
                      :disabled="element.block_calculation || item.button_disabled"
                      class="w-100"
                      type="primary"
                      @click="submitForm(item, 'main')"
                    >
                      Wybierz ofertę
                    </el-button>
                  </div>
                  <div
                    v-else
                    class="bottom clearfix mt-5"
                  >
                    <el-button
                      :disabled="element.block_calculation || item.button_disabled"
                      class="w-100"
                      type="primary"
                      @click="submitForm(item, 'simplified')"
                    >
                      Wybierz ofertę uproszczoną
                    </el-button>
                  </div>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <special-pricing
      :visibility="dialogSpecialPricingVisibility"
      :calculation_id="id"
      @close-dialog="closeSpecialPricing"
    />
  </div>
</template>

<script>
  import { getRequest, patchRequest, postRequest, putRequest } from '@/shared/utils/http'
  import Dinero from 'dinero.js'
  import DownloadButton from '@/admin/pages/calculator/components/DownloadButton'
  import map from 'lodash-es/map'
  import { floatFormatter, isString, moneyFormatter } from '@/admin/utils/helpers'
  import ProductionSlopeComponent from '@/admin/pages/calculations/components/ProductionSlopeComponent'
  import SlopeCreatorComponent from '@/admin/pages/calculations/components/SlopeCreatorComponent'
  import SlopesComponent from '@/admin/pages/calculations/components/SlopesComponent'
  import Tables from '@/shared/mixins/tables'
  import FixedHeader from 'vue-fixed-header'
  import OverlayConstrucition from '@/admin/pages/calculations/components/OverlayConstrucition'
  import SpecialPricing from '@/admin/pages/calculations/components/SpecialPricing'
  import forIn from 'lodash-es/forIn'
  import Contractor from '@/admin/components/Contractor'
  import CalculationOptions from '@/admin/pages/calculations/components/CalculationOptions'

  export default {
    name: 'ViewOffers',
    components: {
      CalculationOptions,
      Contractor,
      SpecialPricing,
      OverlayConstrucition,
      SlopesComponent,
      SlopeCreatorComponent,
      ProductionSlopeComponent,
      DownloadButton,
      FixedHeader
    },
    mixins: [Tables],
    data () {
      return {
        windowHeight: screen.height,
        element: {
          inputs: {
            pvgis: {
              energy_production: '',
              insulation: '',
              loss: {
                position: '',
                temperature: ''
              },
              roof_degree: '',
              power_needed: ''
            }
          }
        },
        test1: '',
        slopeCreatorVisible: false,
        showTypePrice: false,
        loading: true,
        discount: false,
        vat: false,
        checkedGroup: [],
        kitSelected: 0,
        activeNames: '1',
        alertCheckedGroup: false,
        savedCalculation: true,
        updateCalculation: false,
        blockCreateOffers: false,
        canAddSlopes: false,
        canDeleteSlopes: false,
        dialogSpecialPricingVisibility: false,
        provision: 0.00,
        commissionMode: false
      }
    },
    computed: {
      id () {
        return parseInt(this.$route.params.id)
      },
      powerNeededSearching () {
        return floatFormatter(this.element.inputs.pvgis.power_needed)
      },
      kwpSearching () {
        return floatFormatter(this.element.inputs.kwp)
      }
    },
    mounted () {
      this.loading = false
      this.$root.title = 'Kalkulacja'
      getRequest('/api/calculations/' + this.id)
        .then((response) => {
          this.element = response.data
          this.vat = response.data.vat_calculation
          this.discount = response.data.discount_calculation
          this.provision = response.data.provision
          this.loading = false
          this.checkTypeCalculation()
          this.setAllCalculationChecked()
          this.validAfterLoadedKits()
        }).catch((error) => {
          this.$message.error('Błąd w systemie: ' + error.data.message)
          this.$router.go(-1)
          this.loading = false
        })
    },
    methods: {
      validAfterLoadedKits () {
        this.element.kits.forEach((el) => {
          this.validConstructionSlopes(el)
        })
      },
      checkTypeCalculation () {
        if (isString(this.element.inputs.pvgis)) {
          this.blockCreateOffers = true
          this.canAddSlopes = true
          this.canDeleteSlopes = true
        }
      },
      submitForm: function (item, type) {
        item.button_disabled = true
        this.loading = true
        if (type === 'main') {
          this.submitMainOffer(item)
        } else {
          this.submitSimplifiedOffer(item)
        }
      },
      submitMainOffer (item) {
        if (!this.validConstructionSlopes(item)) {
          return false
        }
        return new Promise((resolve, reject) => {
          let request
          request = postRequest('/api/offers', {
            model_type: this.element.model_type, // tutaj podać TYPE użytkownika // MOŻE POWIĄZAĆ BEZPOŚREDNIO Z OFERTĄ?
            model_id: this.element.model_id,
            calculation_id: this.element.id, // tutaj podać ID użytkownika
            construction_id: item.construction,
            construction_slopes: item.construction_slopes,
            company_id: item.company_id,
            price: this.discount ? Dinero({ amount: Math.round(item.price_with_discount * 100), precision: 2 }).getAmount() / 100 : Dinero({ amount: Math.round(item.price * 100), precision: 2 }).getAmount() / 100,
            price_with_construction: this.discount ? Dinero({ amount: Math.round(item.price_construction_with_discount * 100), precision: 2 }).getAmount() / 100 : Dinero({ amount: Math.round(item.price_construction * 100), precision: 2 }).getAmount() / 100,
            price_brutto: this.getBruttoPrice(item),
            vat_23: this.vat,
            type_offer: this.vat, // przemyśleć czy type_offer ma sens dla takiego działania
            kwp: this.element.inputs.pvgis.power_needed,
            phase: this.element.phase,
            kit_id: item.id,
            discount: this.discount,
            area_points: this.element.area_points,
            area: this.element.area,
            azimuth: this.element.azimuth,
            azimuth_points: this.element.azimuth_points,
            yearly_use: this.element.yearly_use ? this.element.yearly_use : '',
            angle: this.element.inputs.pvgis.roof_degree,
            insulation: this.element.inputs.pvgis.insulation,
            energy_production: this.element.inputs.pvgis.energy_production ? this.element.inputs.pvgis.energy_production : '',
            loss_temperature: this.element.inputs.pvgis.loss.temperature,
            loss_system: -6,
            loss_position: this.element.inputs.pvgis.loss.position,
            per_month_data: this.element.inputs.pvgis.per_month_data,
            added_addons_list: item.added_addons_list,
            inverter_id: item.inverter_id,
            inverter_kit_id: item.inverterItem.id,
            consumption: this.element.inputs.pvgis.consumption,
            provision: this.element.provision,
            type: 'main'
          })
          request
            .then(response => {
              this.$emit('saved')
              this.$router.push({ name: 'admin.offers.show', params: { id: response.id } })
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      submitSimplifiedOffer (item) {
        if (!this.validConstructionSlopes(item)) {
          return false
        }
        return new Promise((resolve, reject) => {
          let request
          request = postRequest('/api/offers', {
            model_type: this.element.model_type, // tutaj podać TYPE użytkownika // MOŻE POWIĄZAĆ BEZPOŚREDNIO Z OFERTĄ?
            model_id: this.element.model_id,
            calculation_id: this.element.id, // tutaj podać ID użytkownika
            construction_id: item.construction,
            construction_slopes: item.construction_slopes,
            company_id: item.company_id,
            price: this.discount ? Dinero({ amount: Math.round(item.price_with_discount * 100), precision: 2 }).getAmount() / 100 : Dinero({ amount: Math.round(item.price * 100), precision: 2 }).getAmount() / 100,
            price_with_construction: this.discount ? Dinero({ amount: Math.round(item.price_construction_with_discount * 100), precision: 2 }).getAmount() / 100 : Dinero({ amount: Math.round(item.price_construction * 100), precision: 2 }).getAmount() / 100,
            price_brutto: this.getBruttoPrice(item),
            vat_23: this.vat,
            type_offer: this.vat, // przemyśleć czy type_offer ma sens dla takiego działania
            // kwp: this.element.inputs.pvgis.power_needed, //TODO: zmiana tego elementu
            kwp: this.element.inputs.kwp,
            phase: this.element.phase,
            kit_id: item.id,
            discount: this.discount,
            area_points: this.element.area_points,
            area: this.element.area,
            azimuth: this.element.azimuth,
            azimuth_points: this.element.azimuth_points,
            yearly_use: this.element.yearly_use ? this.element.yearly_use : '',
            angle: null,
            insulation: null,
            energy_production: null,
            loss_temperature: null,
            loss_system: -6,
            loss_position: null,
            per_month_data: null,
            added_addons_list: item.added_addons_list,
            inverter_id: item.inverter_id,
            inverter_kit_id: item.inverterItem.id,
            consumption: null,
            provision: this.element.provision,
            type: 'simplified'
          })
          request
            .then(response => {
              this.$emit('saved')
              this.$router.push({ name: 'admin.offers.show', params: { id: response.id } })
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      getAmountOffer (price) {
        return Dinero({ amount: Math.round(price * 100), precision: 2 }).getAmount() / 100
      },
      getNettoPrice (item) {
        let price = null
        if (!item) {
          console.log('Problem z wprowadzoną kwotą')
          return
        }
        price = (this.discount) ? item.price_construction_with_discount : item.price_construction

        return Dinero({ amount: Math.round(price * 100), precision: 2 }).getAmount() / 100
      },
      transformPrice (price) {
        return price * -1
      },
      getBruttoPrice (item) {
        let price = null
        let priceConstruction = null
        let priceConstructionWithDiscount = null

        if (!item) {
          console.log('Problem z wprowadzoną kwotą')
          return
        }

        if (this.vat) {
          priceConstruction = item.price_construction_vat23
          priceConstructionWithDiscount = item.price_construction_with_discount_vat23
        } else {
          priceConstruction = item.price_construction_vat8
          priceConstructionWithDiscount = item.price_construction_with_discount_vat8
        }
        price = (this.discount) ? priceConstructionWithDiscount : priceConstruction

        return Dinero({ amount: Math.round(price * 100), precision: 2 }).getAmount() / 100
      },
      getFullPrice (item) {
        let addonsPrice = 0
        let price = null
        let provision
        if (this.showTypePrice) {
          price = this.getNettoPrice(item)
        } else {
          price = this.getBruttoPrice(item)
        }
        const vat = (this.vat) ? 1.23 : 1.08

        item.added_addons_list.map(el => {
          if (this.showTypePrice) {
            addonsPrice = addonsPrice + (el.cost)
          } else {
            addonsPrice = addonsPrice + (el.cost * vat)
          }
        })
        addonsPrice = Dinero({ amount: Math.round(addonsPrice * 100), precision: 2 })
        price = Dinero({ amount: Math.round(price * 100), precision: 2 })
        provision = Dinero({ amount: Math.round(this.element.provision * 100), precision: 2 })

        if (this.discount === false) {
          addonsPrice = Dinero({ amount: Math.round(addonsPrice.getAmount() / 0.95), precision: 2 })
          provision = Dinero({ amount: Math.round(provision.getAmount() / 0.95), precision: 2 })
        }

        if (item.notIncludeInverter === true) {
          item.inverter_id = item.inverterItem.id
          let priceCurrentInverter = item.inverterItem.cost
          priceCurrentInverter = Dinero({ amount: Math.round(priceCurrentInverter * 100), precision: 2 })
          price = price.subtract(priceCurrentInverter)
          return price.add(addonsPrice).getAmount() / 100
        }

        if (item.inverter_id !== item.inverterItem.id) {
          let priceCurrentInverter = item.inverterItem.cost
          let priceChoosenInverter = this.getInverterCost(item.inverter_id, item.company_id)

          priceCurrentInverter = Dinero({ amount: Math.round(priceCurrentInverter * 100), precision: 2 })
          priceChoosenInverter = Dinero({ amount: Math.round(priceChoosenInverter * 100), precision: 2 })

          let differenceCost = priceCurrentInverter.subtract(priceChoosenInverter)
          if (this.discount === false) {
            differenceCost = Dinero({ amount: Math.round(differenceCost.getAmount() / 0.95), precision: 2 })
          }
          if (!this.showTypePrice) {
            differenceCost = differenceCost.multiply(vat)
          }
          item.diffrence_inverter_cost = differenceCost.getAmount() / 100
          price = price.subtract(differenceCost)
        } else {
          item.diffrence_inverter_cost = null
          this.resetCalculationInverter(item)
        }

        if (this.showTypePrice) {
          price = price.add(provision)
        } else {
          provision = provision.multiply(vat)
          price = price.add(provision)
        }

        return moneyFormatter(price.add(addonsPrice).getAmount() / 100)
      },
      getInverterCost (inverterId, companyId) {
        let chosenInverterCost = ''
        this.$config.calculator.inverters.map((item) => {
          if (item.id === inverterId) {
            if (item.company === companyId) {
              chosenInverterCost = parseFloat(item.cost)
            }
          }
        })

        return chosenInverterCost
      },
      switchCurrentKit (index, kit) {
        map(this.element.kits, function (item, key) {
          if (key === index) {
            item.addons_information = kit.addons_information
            item.provider = kit.provider
            item.bonus = kit.bonus
            item.provider = kit.companies
            item.construction = kit.construction
            item.count_inverter = kit.count_inverter
            item.count_solar_panel = kit.count_solar_panel
            item.id = kit.id
            item.inverter = kit.inverter
            item.inverter_card = kit.inverter_card
            item.kwp = kit.kwp
            item.kwp_per_solar = kit.kwp_per_solar
            item.phase = kit.phase
            item.price = kit.price
            item.price_construction = kit.price_construction
            item.price_construction_vat8 = kit.price_construction_vat8
            item.price_construction_vat23 = kit.price_construction_vat23
            item.price_construction_with_discount = kit.price_construction_with_discount
            item.price_construction_with_discount_vat8 = kit.price_construction_with_discount_vat8
            item.price_construction_with_discount_vat23 = kit.price_construction_with_discount_vat23
            item.inverter_id = kit.inverter_id
            item.price_vat8 = kit.price_vat8
            item.price_vat23 = kit.price_vat23
            item.price_with_discount = kit.price_with_discount
            item.price_with_discount_vat8 = kit.price_with_discount_vat8
            item.price_with_discount_vat23 = kit.price_with_discount_vat23
            item.solar_card = kit.solar_card
            item.inverter_card = kit.inverter_card
            item.solar_panel_name = kit.solar_panel_name
            item.warranty_performance = kit.warranty_performance
            item.warranty_product = kit.warranty_product
            item.addons_kits = kit.addons_kits
            item.addons_panels = kit.addons_panels
            item.construction_slopes = kit.construction_slopes
            item.solar_info = kit.solar_info
            item.inverter_list = kit.inverter_list
            item.inverterItem = kit.inverterItem
          }
        })
      },
      changeKitBySolarConstruction (index, constructionId, item) {
        if (!this.validConstructionSlopes(item)) {
          return false
        }
        this.loading = true
        this.savedCalculation = false
        return new Promise((resolve, reject) => {
          postRequest('/api/find-by-construction-kit', {
            kit_id: item.id, // to change
            // construction_id: constructionId,
            slopes: item.construction_slopes,
            commission_mode: this.commissionMode,
            calculation_id: this.id,
            slopes_list: this.element.slopes
          })
            .then(response => {
              this.$emit('saved')
              this.switchCurrentKit(index, response.data)
              this.savedCalculation = false
              this.updateCalculation = true
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              this.loading = false

              reject(error)
            })
        })
      },
      changeKitBySolarKit (index, kit, item) {
        this.loading = true
        item.button_disabled = true
        return new Promise((resolve, reject) => {
          postRequest('/api/find-by-count-panel-kit', {
            kit_id: kit, // to change
            construction_id: item.construction,
            slopes: item.construction_slopes,
            commission_mode: this.commissionMode,
            calculation_id: this.id,
            slopes_list: this.element.slopes
          })
            .then(response => {
              this.$emit('saved')
              this.loading = false
              this.switchCurrentKit(index, response.data)
              this.savedCalculation = false
              this.updateCalculation = true
              item.button_disabled = false
              resolve(response)
            })
            .catch(error => {
              this.loading = false

              reject(error)
            })
        })
      },
      changeKitByPanel (index, panel, item) {
        this.loading = true
        item.button_disabled = true
        return new Promise((resolve, reject) => {
          postRequest('/api/find-by-panel', {
            count_panel: item.count_solar_panel, // to change
            construction_id: item.construction,
            phase: item.phase,
            company_id: item.company_id,
            panel: panel,
            slopes: item.construction_slopes,
            commission_mode: this.commissionMode,
            calculation_id: this.id,
            slopes_list: this.element.slopes
          })
            .then(response => {
              this.$emit('saved')
              this.switchCurrentKit(index, response.data)
              this.savedCalculation = false
              this.updateCalculation = true
              item.button_disabled = false
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              item.button_disabled = true
              item.solar_panel_name = ''
              this.loading = false
              reject(error)
            })
        })
      },
      addAddonsToInputs (indexAddons, indexKit) {
        const chosenAddon = this.element.kits[indexKit].addons_list[indexAddons]
        this.element.kits[indexKit].selected_addon.id = chosenAddon.id
        this.element.kits[indexKit].selected_addon.unit = chosenAddon.unit
        this.element.kits[indexKit].selected_addon.name = chosenAddon.name
        this.element.kits[indexKit].selected_addon.cost = chosenAddon.cost
      },
      addAddonsToTable (indexKit) {
        const currentAddon = this.element.kits[indexKit].selected_addon

        if (currentAddon.count === '') {
          return false
        }
        this.element.kits[indexKit].added_addons_list.push({
          id: currentAddon.id,
          name: currentAddon.name,
          count: currentAddon.count,
          cost: currentAddon.cost * parseInt(currentAddon.count),
          unit: currentAddon.unit
        })
        this.$message.success('Dodano dodatek do zestawu')
        this.savedCalculation = false
        this.updateCalculation = true
        this.resetSelectedAddon(indexKit)
      },
      resetSelectedAddon (indexKit) {
        this.element.kits[indexKit].selected_addon.unit = ''
        this.element.kits[indexKit].selected_addon.name = ''
        this.element.kits[indexKit].selected_addon.cost = ''
        this.element.kits[indexKit].selected_addon.count = 1
        this.element.kits[indexKit].current_addon = null
      },
      deleteElementList (indexKit, i) {
        this.element.kits[indexKit].added_addons_list.splice(i, 1)
      },
      changeVatValue (e) {
        this.patchUpdateCalculation({ vat_calculation: e })
      },
      changeDiscountValue (e) {
        this.patchUpdateCalculation({ discount_calculation: e })
      },
      patchUpdateCalculation (form) {
        this.loading = true
        patchRequest(`/api/calculations/${this.id}`, form)
          .then(response => {
            this.vat = response.data.vat_calculation
            this.discount = response.data.discount_calculation
            this.element.block_calculation = response.data.block_calculation
            this.loading = false
          })
      },
      setCheckedGroup ($event, index) {
        if ($event === true) {
          this.checkedGroup.push(index)
        } else {
          const indexKit = this.checkedGroup.indexOf(index)
          this.checkedGroup.splice(indexKit, 1)
        }
      },
      duplicate (index) {
        this.updateCalculation = true
        this.savedCalculation = false

        // const cloneKit = { ...this.element.kits[index] }
        const cloneKit = Object.assign({}, this.element.kits[index])
        cloneKit.duplicate = true
        this.element.kits.splice(index + 1, 0, cloneKit)
        this.checkedGroup.push(this.element.kits.length - 1)
        this.saveActuallyCalculations()
      },
      deleteDuplicate (index) {
        this.element.kits.splice(index, 1)
      },
      saveActuallyCalculations () {
        this.loading = true
        if (this.updateCalculation) {
          this.updateCalc()
        }
      },
      updateCalc () {
        return new Promise((resolve, reject) => {
          let request
          request = putRequest('/api/calculations/' + this.id, {
            vat: this.vat,
            discount: this.discount,
            model_type: this.element.model_type,
            model_id: this.element.model_id,
            pvgisData: this.element.inputs.pvgis,
            calculations: this.element.kits,
            slopes: this.element.slopes
          })
          request
            .then(response => {
              this.$emit('saved')
              this.$message.success('Pomyślnie nadpisano kalkulację w systemie.')
              this.savedCalculation = true
              this.updateCalculation = true
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              this.loading = false
              reject(error)
            })
        })
      },
      resetSlopeCreator (status) {
        this.slopeCreatorVisible = status
      },
      updateSlopes (slope) {
        this.element.slopes.push(slope)
        this.element.kits.forEach((kit) => {
          kit.construction_slopes.push({
            construction_id: '',
            panel_count: 0
          })
          this.validConstructionSlopes(kit)
        })
        this.updateCalc()
      },
      deleteSlope (index) {
        this.element.slopes.splice(index, 1)
        this.element.kits.forEach((kit) => {
          kit.construction_slopes.splice(index, 1)
          this.validConstructionSlopes(kit)
        })
        this.updateCalc()
      },
      generatePDF () {
        if (!this.checkedGroup.length) {
          this.$message.error('Do porównania należy wybrać zestawy.')
          return false
        }

        this.isKitComplete()

        this.loading = true
        this.$message({
          type: 'info',
          message: 'Pobieranie dokumentu proszę czekać...'
        })
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'no-cors'
          },
          responseType: 'blob'
        }
        axios.post('/api/external-calculation/' + this.element.token, { checkedGroup: this.checkedGroup }, config)
          .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Wybrane kalkulacje.pdf'
            link.click()
            this.loading = false
          })
      },
      isKitComplete () {
        this.checkedGroup.forEach((el, index, object) => {
          const blockedCompare = this.element.kits[el].button_disabled
          if (blockedCompare) {
            object.splice(index, 1)
          }
        })
      },
      validConstructionSlopes (item) {
        let fullPanelCount = 0
        let hasConstruction = true

        if (item.solar_panel_name === '') {
          item.button_disabled = true
          return false
        }

        if (item.hasOwnProperty('construction_slopes')) {
          for (const el of item.construction_slopes) {
            if (el.construction_id === '' || el.construction_id === null) {
              hasConstruction = false
            }
          }

          item.construction_slopes.forEach((el) => {
            fullPanelCount = fullPanelCount + el.panel_count
          })

          if (!hasConstruction) {
            item.button_disabled = true
            return false
          }

          if (fullPanelCount !== item.count_solar_panel) {
            item.button_disabled = true
            return false
          }
        }
        item.button_disabled = false
        return true
      },
      setNewInverter (val, item, index) {
        console.log(item)
        forIn(item.inverter_list, (el) => {
          if (el.id === val) {
            if ('kit_id' in el) {
              this.changeKitBySolarKit(index, el.kit_id, item)
            }
          }
        })

        const selectedInverter = item.inverter_list.find(e => e.id === val)
        if ('card' in selectedInverter) {
          this.changeInverterCard(index, selectedInverter)
        } else {
          this.$snotify.warning('Karta falownika nie została zmieniona')
        }

        this.savedCalculation = false
        this.updateCalculation = true
      },
      changeInverterCard (index, inverter) {
        this.element.kits[index].inverter_card = inverter.card ?? ''
        // this.element.kits[index].inverter_card = ''
      },
      resetInverterManually (item) {
        this.resetCalculationInverter(item)
        this.setNewInverter()
      },
      resetCalculationInverter (item) {
        item.inverter_id = item.inverterItem.id
      },
      setAllCalculationChecked () {
        this.element.kits.forEach((item, index) => {
          if (item.checked) {
            this.checkedGroup.push(index)
          }
        })
      },
      addToConstructionManager (slopeIndex) {
        // let kitsCount = this.element.kits.length
      },
      updateAllDefaultSlopes () {
        this.element.slopes.forEach((el, index) => {
          this.updateDefaultSlope(index, false)
        })
        this.updateCalc()
      },
      updateDefaultSlope (slopeId, update = true) {
        const slope = this.element.slopes[slopeId]
        const constructionList = this.$config.constructionsFullList
        this.element.kits.forEach((kit) => {
          const construction = constructionList.find(el => {
            return el.default === 1 && el.company_id === kit.company_id && el.type === slope.type_roof
          })
          if (construction) {
            kit.construction_slopes[slopeId].construction_id = construction.id
            if (slopeId === 0) {
              kit.construction_slopes[slopeId].panel_count = kit.count_solar_panel
            }
          }
        })
        if (update) {
          this.updateCalc()
        }
      },
      blockStatusUpdate (status) {
        this.patchUpdateCalculation({ blocked: status })
      },
      toCalculablePerson () {
        const person = this.element.model_id
        const type = this.element.model_type
        let route = ''

        if (type === 'App\\Models\\Lead') {
          route = 'admin.lead.show'
        } else {
          route = 'admin.clients.show'
        }
        this.$router.push({ name: route, params: { id: person } })
      },
      refreshKits () {
        this.element.kits.forEach((kit, index) => {
          this.changeKitByPanel(index, kit.solar_panel_name, kit)
        })
      },

      openSpecialPricing () {
        this.dialogSpecialPricingVisibility = true
      },
      closeSpecialPricing () {
        this.dialogSpecialPricingVisibility = false
      },
      openSpecialPricingAdmin () {
        this.$router.push({ name: 'admin.calculations.special-pricing', params: { id: this.id } })
      },

      changeCommissionMode (status) {
        this.commissionMode = status
        this.$snotify.info('Uwaga. Został zmieniony system prowizji. Trwa przeliczanie.')
        this.refreshKits()
      },
      recalculate (value) {
        this.loading = true
        this.provision = value
        this.$snotify.info('Przeliczanie zestawów o nową marzę.')
        patchRequest(`/api/calculations/${this.id}`, { provision: this.provision })
          .then(response => {
            this.element.provision = response.data.provision
            this.loading = false
            this.$snotify.success('Marża zapisana. Oferty z nowymi cenami.')
          })
      },
      updateDisplay (status) {
        this.showTypePrice = status
      }
    }
  }
</script>

<style scoped>
.el-select > .el-input.el-input--suffix input {
  background: #67c23a !important;
  color: white !important;
}
input.el-input__inner {
  background: #97d700 !important;
  color: white !important;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.image {
  width: 264px;
  height: 132px;
  margin: auto;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
.adv-block{
  position: absolute;
}
.carousel-3d-slide {
  background-color: #fff !important;
}

.block-addons{
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid #f3f3f3;
}
.block-addons-auto{
  margin-top: auto;
  margin-bottom: auto;
}
.duplicate-button{
  margin-right: 15px;
}
.checkbox-section{
  margin:10px;
}
.table td {
  text-align: center;
  font-size:14px
}
.special-select-record{
  padding: 0px !important;
}
.el-button--text {
  padding: 0px !important;
}
.vue-fixed-header--isFixed {
  transition: 0.3s;
  position: fixed;
  top: 0px;
  right: 5px;
  z-index: 999;
}
.navbar:after{
  width:100%;
  height:100%;
  opacity: 0.5;
}

.el-card {
  position: relative;
}

.table > :not(caption) > * > * {
   padding: 0px 0px !important;
}

span.el-tag.el-tag--success.el-tag--small.el-tag--dark {
  padding: 0px 8px !important;
}

.table-information td, .table-information th {
 padding: 10px 0px;
}
</style>
