<template>
  <div class="row">
    <div class="col-12">
      <div id="mapComponentOffer"></div>
    </div>
  </div>

</template>

<script>
  import $Scriptjs from 'scriptjs'
  export default {
    name: 'MapComponent',
    props: {
      canFullScreenMode: {
        type: Boolean,
        required: true
      },
      dataCenter: {
        type: Object,
        required: true
      },
      dataArea: {
        type: Array,
        required: true
      },
      dataAzimuth: {
        type: Array,
        required: true
      }
    },
    data: function () {
      return {
        map: null,
        mapEl: document.getElementById('map'),
        buttons: null,
        autocomplete: null,
        loading: false,
        alertError: false,
        line: [],
        area_points: [],
        area: '',
        azimuth_points: [],
        azimuth: '',
        calculator: {
          map: null,
          drawingManager: null,
          polygon: null,
          line: null
        }
      }
    },
    mounted () {
      $Scriptjs('https://maps.googleapis.com/maps/api/js?key=AIzaSyCZNR1lMchILg8JAHPPOs4ytMuR18NDHO8&libraries=drawing', () => {
        this.initMap()
      })
    },
    methods: {
      initMap () {
        this.calculator.map = new google.maps.Map(document.getElementById('mapComponentOffer'), {
          center: this.dataCenter,
          zoom: 22,
          disableDefaultUI: true,
          clickableIcons: false,
          zoomControl: true,
          fullscreenControl: false,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT
          },
          controlSize: 26,
          mapTypeId: google.maps.MapTypeId.HYBRID,
          tilt: 0,
          mapTypeControl: false
        })

        // if (this.canFullScreenMode) {
        //   this.calculator.map.addListener('idle', () => {
        //   })
        // }
        const flightPlanCoordinates = this.dataAzimuth

        const flightPlanCoordinates2 = this.dataArea
        const lineSymbol = {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        }

        const bermudaTriangle = new google.maps.Polygon({
          paths: flightPlanCoordinates2,
          strokeColor: '#298115',
          strokeOpacity: 0.8,
          strokeWeight: 4,
          fillColor: '#05e509',
          fillOpacity: 0.45
        })

        bermudaTriangle.setMap(this.calculator.map)

        flightPlanCoordinates.forEach((item) => {
          const flightPath = new google.maps.Polyline({
            path: item,
            icons: [
              {
                icon: lineSymbol,
                offset: '100%'
              }
            ],
            geodesic: true,
            strokeColor: '#FF0000',
            strokeOpacity: 1.0,
            strokeWeight: 4
          })
          flightPath.setMap(this.calculator.map)
        })
      },
      initFullscreenControl (map) {
        map.getDiv().firstChild.requestFullscreen()
        this.$emit('full-screen-mode', false)
      }
    }
  }
</script>
<style scoped>
#mapComponentOffer {
  width: 100%;
  height: 250px;
}
</style>
