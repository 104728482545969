<template>
  <section>
    <el-form
      ref="leadsForm"
      :model="form"
      label-position="top"
      @submit.native.prevent="handleForm('leadsForm')"
    >
      <form-alerts
        :messages="invalidMsg"
        class="my-6"
      />
      <el-card class="mb-3">
        <fieldset>
          <div class="row">
            <div class="col-lg-3 col-xl-2">
              <h6>Dane personalne</h6>
            </div>
            <div class="col-lg-9 col-xl-10">
              <custom-form-item
                :errors="formErrors"
                :required="checkRequired(rules.firstName)"
                prop="firstName"
                label="Imię"
              >
                <el-input
                  v-model="form.firstName"
                  v-validate="rules.firstName"
                  name="firstName"
                  type="text"
                />
              </custom-form-item>
              <custom-form-item
                :required="checkRequired(rules.lastName)"
                :errors="formErrors"
                prop="lastName"
                label="Nazwisko"
              >
                <el-input
                  v-model="form.lastName"
                  v-validate="rules.lastName"
                  name="lastName"
                  type="text"
                />
              </custom-form-item>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <div class="row">
            <div class="col-lg-3 col-xl-2">
              <h6>Dane kontaktowe</h6>
            </div>
            <div class="col-lg-9 col-xl-10">
              <custom-form-item
                :required="checkRequired(rules.email)"
                :errors="formErrors"
                prop="email"
                label="Adres email"
              >
                <el-input
                  v-model="form.email"
                  v-validate="rules.email"
                  name="email"
                  type="text"
                />
              </custom-form-item>
              <custom-form-item
                :required="checkRequired(rules.phone)"
                :errors="formErrors"
                prop="phone"
                label="Telefon"
              >
                <el-input
                  v-model="form.phone"
                  v-validate="rules.phone"
                  name="phone"
                  type="text"
                />
              </custom-form-item>
              <custom-form-item
                :errors="formErrors"
                prop="phone_2"
                label="Telefon 2"
              >
                <el-input
                  v-model="form.phone_2"
                  name="phone_2"
                  type="text"
                />
              </custom-form-item>
              <custom-form-item
                :errors="formErrors"
                prop="google_map_url"
                label="Adres do mapy URL Google"
              >
                <el-input
                  v-model="form.google_map_url"
                  name="google_map_url"
                  type="text"
                />
              </custom-form-item>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <div class="row">
            <div class="col-lg-3 col-xl-2">
              <h6>Dane szczegółowe</h6>
            </div>
            <div class="col-lg-9 col-xl-10">
              <custom-form-item
                v-if="$can('admin.lead.status.admin')"
                :required="checkRequired(rules.status)"
                :errors="formErrors"
                prop="status"
                label="Status leada"
              >
                <el-select
                  v-model="form.status"
                  v-validate="rules.status"
                  class="w-100"
                  placeholder="Wybierz status"
                  name="status"
                >
                  <el-option
                    v-for="(item,index) in $config.agreementSettings.data.statuses_lead.settings"
                    :key="index"
                    :label="item.label"
                    :value="index"
                  />
                </el-select>
              </custom-form-item>
              <custom-form-item
                :required="checkRequired(rules.type_contact)"
                :errors="formErrors"
                prop="type_contact"
                label="Wybierz typ klienta"
              >
                <el-select
                  v-model="form.type_contact"
                  v-validate="rules.type_contact"
                  class="w-100"
                  placeholder="Typ klienta"
                  name="type_contact"
                >
                  <el-option
                    v-for="(item,index) in type_client"
                    :key="index"
                    :label="item.label"
                    :value="index"
                  />
                </el-select>
              </custom-form-item>
              <custom-form-item
                :required="checkRequired(rules.source)"
                :errors="formErrors"
                prop="source"
                label="Źródło kontaktu"
              >
                <el-select
                  v-model="form.source"
                  v-validate="rules.source"
                  class="w-100"
                  name="source"
                  placeholder="Źródło kontaktu"
                >
                  <el-option
                    v-for="(item,index) in sourceContactList"
                    :key="index"
                    :label="item.label"
                    :value="index"
                  />
                </el-select>
              </custom-form-item>
              <custom-form-item
                :required="checkRequired(rules.interested)"
                :errors="formErrors"
                prop="interested"
                label="Czym zainteresowany?"
              >
                <el-select
                  v-model="form.interested"
                  v-validate="rules.interested"
                  class="w-100"
                  name="interested"
                  placeholder="Wybierz"
                >
                  <el-option
                    v-for="(item,index) in interested"
                    :key="index"
                    :label="item.label"
                    :value="index"
                  />
                </el-select>
              </custom-form-item>
              <custom-form-item
                :required="checkRequired(rules.provider)"
                :errors="formErrors"
                prop="provider"
                label="Dostawca?"
              >
                <el-select
                  v-model="form.provider"
                  v-validate="rules.provider"
                  class="w-100"
                  name="provider"
                  placeholder="Wybierz"
                >
                  <el-option
                    v-for="(item,index) in provider"
                    :key="index"
                    :label="item.label"
                    :value="index"
                  />
                </el-select>
              </custom-form-item>
              <custom-form-item
                v-if="form.source === 13"
                :errors="formErrors"
                prop="pollster"
                label="Ankieter - Imię Nazwisko"
              >
                <el-input
                  v-model="form.pollster"
                  name="pollster"
                  type="text"
                />
              </custom-form-item>
            </div>
          </div>
        </fieldset>
        <fieldset v-if="form.type_contact != 0">
          <div class="row">
            <div class="col-lg-3 col-xl-2">
              <h6>Dane personalne</h6>
            </div>
            <div class="col-lg-9 col-xl-10">
              <custom-form-item
                :required="checkRequired(rules.nip)"
                :errors="formErrors"
                prop="nip"
                label="NIP"
              >
                <el-input
                  v-model="form.nip"
                  v-validate="rules.nip"
                  name="nip"
                  type="text"
                />
              </custom-form-item>
              <custom-form-item
                :required="checkRequired(rules.regon)"
                :errors="formErrors"
                prop="regon"
                label="REGON"
              >
                <el-input
                  v-model="form.regon"
                  v-validate="rules.regon"
                  name="regon"
                  type="text"
                />
              </custom-form-item>
              <custom-form-item
                v-if="form.type_contact == 2"
                :required="checkRequired(rules.krs)"
                :errors="formErrors"
                prop="krs"
                label="KRS"
              >
                <el-input
                  v-model="form.krs"
                  v-validate="rules.krs"
                  name="krs"
                  type="text"
                />
              </custom-form-item>
              <custom-form-item
                :required="checkRequired(rules.name_company)"
                :errors="formErrors"
                prop="name_company"
                label="Nazwa firmy"
              >
                <el-input
                  v-model="form.name_company"
                  v-validate="rules.name_company"
                  name="name_company"
                  type="text"
                />
              </custom-form-item>
            </div>
          </div>
        </fieldset>
        <teryt-address-form
          :rules="rules"
          :form="form"
          :edit="isEdit"
        />
        <fieldset class="mt-2">
          <div class="row">
            <div class="col-lg-3 col-xl-2">
              <h6>Dane do kalkulacji</h6>
            </div>
            <div class="col-lg-9 col-xl-10">
              <custom-form-item
                :errors="formErrors"
                prop="roof_type"
                label="Rodzaj dachu"
              >
                <el-select
                  v-model="form.roof_type"
                  :filterable="true"
                  class="w-100"
                  name="roof_type"
                >
                  <el-option
                    v-for="(item,index) in $config.agreementSettings.data.roof_type.settings"
                    :key="index"
                    :label="item.label"
                    :value="index"
                  />
                </el-select>
              </custom-form-item>
              <custom-form-item
                :errors="formErrors"
                prop="roof_area"
                label="Powierzchnia dachu"
              >
                <input-number
                  v-model="form.roof_area"
                  :precision="2"
                  class="w-100"
                  name="roof_area"
                  type="text"
                />
              </custom-form-item>
              <custom-form-item
                :errors="formErrors"
                prop="annual_energy_consumption"
                label="Roczne zużycie energii"
              >
                <input-number
                  v-model="form.annual_energy_consumption"
                  :precision="2"
                  class="w-100"
                  name="annual_energy_consumption"
                  type="text"
                />
              </custom-form-item>
              <custom-form-item
                :errors="formErrors"
                prop="notes"
                label="Notatki odnośnie kontaktu"
              >
                <vue-editor
                  id="notes"
                  v-model="form.notes"
                  :editor-toolbar="$config.wysiwygToolbar"
                  name="notes"
                  placeholder="Wpisz treść wiadomości"
                />
              </custom-form-item>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <div class="row">
            <div class="col-lg-3 col-xl-2">
              <h6>Pozostałe</h6>
            </div>
            <div class="col-lg-9 col-xl-10">
              <custom-form-item
                :errors="formErrors"
                prop="notes_office"
                label="Notatki Biuro"
              >
                <vue-editor
                  id="notes_office"
                  v-model="form.notes_office"
                  :editor-toolbar="$config.wysiwygToolbar"
                  name="notes_office"
                  placeholder="Wpisz treść wiadomości"
                />
              </custom-form-item>
              <div class="permission-switch">
                <div>
                  Klient wyraża zgodę na przetwarzanie danych osobowych przekazanych za pośrednictwem formularza kontaktowego, wiadomości email i/lub innego środka komunikacji, w celu przedstawienia lub omówienia przedstawionej oferty przez Ekoradcy Sp. z o.o. z siedzibą w Krakowie.
                </div>
                <el-switch
                  v-model="form.permission_first"
                  class="my-2"
                  active-text="Tak"
                  inactive-text="Nie"
                />
              </div>
              <div class="permission-switch">
                <div>
                  Klient wyraża zgodę na otrzymywanie od Ekoradcy Sp. z o.o z siedzibą w Krakowie, treści marketingowych (informacji handlowych) za pośrednictwem newslettera, w tym informacji o promocjach i ofertach specjalnych dotyczących Ekoradcy Sp. z o.o.
                </div>
                <el-switch
                  v-model="form.permission_second"
                  class="my-2"
                  active-text="Tak"
                  inactive-text="Nie"
                />
              </div>
              <el-form-item class="text-end mt-2">
                <el-button
                  v-if="isEdit"
                  class="button-mobile-app"
                  :loading="loading"
                  type="info"
                  @click="$router.go(-1)"
                >
                  Powrót
                </el-button>
                <el-button
                  class="button-mobile-app"
                  :loading="loading"
                  native-type="submit"
                  type="primary"
                >
                  {{ isEdit ? 'Zapisz' : 'Utwórz' }}
                </el-button>
                <el-button
                  v-if="isEdit"
                  class="button-mobile-app"
                  :loading="loading"
                  @click="saveAndOut"
                >
                  Zapisz i zamknij
                </el-button>
              </el-form-item>
            </div>
          </div>
        </fieldset>
      </el-card>
    </el-form>
  </section>
</template>

<script>
  import forms from '@/shared/mixins/forms'
  import { postRequest, putRequest } from '@/shared/utils/http'
  import { leads as leadsRules } from '@/admin/utils/validation'
  import FormAlerts from '../../../shared/components/FormAlerts'
  import { VueEditor } from 'vue2-editor'
  import Avatar from 'vue-avatar'
  import TerytAddress from '../../components/TerytAddress'
  import TerytAddressForm from '@/admin/components/TerytAddressForm'

  export default {
    name: 'LeadsForm',
    components: { TerytAddressForm, TerytAddress, FormAlerts, Avatar, VueEditor },
    mixins: [forms],

    props: {
      form: {
        type: Object,
        required: true
      },
      isEdit: { // czy element jest edytowany?
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        sourceContact: '',
        gusActive: false,
        sourceContactList: this.$config.agreementSettings.data.source.settings,
        type_client: this.$config.agreementSettings.data.type_client.settings,
        interested: this.$config.agreementSettings.data.interested.settings,
        companies: this.$config.agreementSettings.data.companies.settings,
        provider: this.$config.agreementSettings.data.electric_provider.settings,
        outAfterSave: false,
        loading: false
      }
    },

    computed: {
      currentId: function () {
        return this.isEdit ? this.$route.params.id : 0
      },
      rules: function () {
        return leadsRules(this.currentId)
      },
      fullName: function () {
        return this.form.firstName + ' ' + this.form.lastName
      }
    },

    methods: {
      submitForm: function () {
        this.loading = true
        return new Promise((resolve, reject) => {
          let request
          if (this.isEdit) {
            const frm = Object.assign({}, this.form)
            request = putRequest('/api/leads/' + this.$route.params.id, frm)
          } else {
            request = postRequest('/api/leads', this.form)
          }
          request
            .then(response => {
              this.$emit('saved')
              if (this.outAfterSave || !this.isEdit) {
                this.$router.push({ name: 'admin.lead.show', params: { id: response.data.id } })
              }
              this.outAfterSave = false
              this.loading = false

              resolve(response)
            })
            .catch(error => {
              reject(error)
              this.loading = false
              this.outAfterSave = false
            })
        })
      },
      saveAndOut () {
        this.outAfterSave = true
        this.submitForm()
      }
    }

  }
</script>
<style scoped>
fieldset > .row > div:first-child {
  background: #97d700 !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
  .avatar-lead{
    position:absolute;
    top: -40px;
    right: 40px;
  }
  .el-card-height-max{
    min-height: 100%;
  }
</style>
