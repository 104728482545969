<template>
  <el-card
    v-loading="loading"
    class="mt-2"
  >
    <div>
      <div>
        <div class="row">
          <div class="col-10">
            <el-badge
              :value="offers.length"
              :max="100"
              class="item"
            >
              <h1>Lista proponowanych ofert {{ savedCalculation === false ? '- Kalkulacja nie została zapisana!': '' }}</h1>
            </el-badge>
          </div>
          <div class="col-2">
            <div class="float-right">
              <el-button
                class="w-100-el-card"
                type="primary"
                @click="BackToCalculator"
              >
                Powrót
              </el-button>
              <el-button
                v-if="!savedCalculation"
                @click="saveActuallyCalculations()"
              >
                <i class="fas fa-save"></i> {{ updateCalculation === true ? 'Nadpisz' : 'Zapisz' }}
              </el-button>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
    <div
      v-if="offers.length === 0"
      class="my-3"
    >
      <el-alert
        :closable="false"
        title="Brak zestawów dla tego zapytania. Wciśnij guzik powrotu i wybierz inny zestaw."
        type="info"
        effect="dark"
      />
    </div>
    <div
      v-else
      class="row"
    >
      <div class="col-lg-12 col-md-12 col-xl-2 order-lg-1 order-xl-1 mt-3">
        <div>
          <h5>Dodatkowe opcje</h5>
          <hr style="border-top: 6px solid #97d700">
        </div>
        <div class="mt-3">
          <dl v-if="pvgisData.power_needed">
            <dt>Zapotrzebowanie mocy</dt>
            <dd>
              <el-tag type="info">
                Potrzebna moc minimalna: {{ pvgisData.power_needed }} kWp
              </el-tag>
            </dd>
          </dl>
          <hr>
          <dl>
            <dt>Fazy</dt>
            <dd>
              <el-tag type="info">
                Ilość faz: {{ form.phase }}
              </el-tag>
            </dd>
          </dl>
          <hr>
          <dl v-if="pvgisData.roof_degree > 0">
            <dt>Kąt dachu</dt>
            <dd>
              <el-tag type="info">
                {{ pvgisData.roof_degree }} [*]
              </el-tag>
            </dd>
          </dl>
          <hr v-if="pvgisData.roof_degree > 0">
          <dl>
            <dt>Rabat</dt>
            <dd>
              <el-switch
                v-model="discount"
                active-text="Z rabatem 5%"
                inactive-text="bez rabatu"
              />
            </dd>
          </dl>
          <hr>
          <dl>
            <dt>Opodatkowanie</dt>
            <dd>
              <el-switch
                v-model="vat"
                active-text="23% VAT"
                inactive-text="8% VAT"
              />
            </dd>
          </dl>
          <hr>
          <dl>
            <dt>Cena końcowa</dt>
            <dd>
              <el-switch
                v-model="showTypePrice"
                active-text="Kwoty netto"
                inactive-text="Kwota brutto"
              />
            </dd>
          </dl>
        </div>
        <div>
          <div>
            <h5>Dane z PVGIS lokalizacyjne</h5>
            <hr style="border-top: 6px solid #97d700">
          </div>
          <div class="mt-3">
            <dl>
              <dt>Produkcja roczna energii z 1 kWp</dt>
              <dd>{{ pvgisData.energy_production }} kWh</dd>
            </dl>
            <dl>
              <dt>Nasłonecznienie roczne</dt>
              <dd>{{ pvgisData.insulation }} kWh/m2</dd>
            </dl>
            <dl>
              <dt>Straty ułożeniowe roczne</dt>
              <dd>{{ pvgisData.loss.position }} %</dd>
            </dl>
            <dl>
              <dt>Straty Systemu roczne</dt>
              <dd>-6 %</dd>
            </dl>
            <dl>
              <dt>Straty temperaturowe roczne</dt>
              <dd>{{ pvgisData.loss.temperature }} %</dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-xl-10 order-lg-1 order-xl-1 mt-3">
        <div class="row">
          <div
            v-for="(item,index) in offers"
            class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1"
          >
            <el-card
              :body-style="{padding: '0px'}"
              shadow="always"
            >
              <div class="text-end">
                <el-button
                  type="text"
                  class="duplicate-button"
                  @click="duplicate(index)"
                >
                  Duplikuj
                </el-button>
              </div>
              <div style="position: absolute;top: 0px;left: 0px;">
                <el-tag
                  v-if="item.addons_information"
                  type="danger"
                  class="w-100"
                  effect="dark"
                >
                  {{ item.addons_information ? item.addons_information : 'Brak informacji' }}
                </el-tag>
              </div>
              <div class="my-6">
                <img
                  :src="item.provider_logo"
                  class="image mt-1"
                >
              </div>
              <div style="padding: 14px;">
                <div class="w-100 text-center py-3">
                  <el-select
                    v-model="item.construction"
                    class="w-100 calculator-select"
                    value-key="construction"
                    placeholder="Wybierz konstrukcje"
                    name="new_construction"
                    @change="changeKitBySolarConstruction(index, $event, item)"
                  >
                    <el-option
                      v-for="construction in item.constructions_list"
                      :key="construction.id"
                      :label="construction.name"
                      :value="construction.id"
                    />
                  </el-select>
                  <el-tag
                    v-if="discount"
                    effect="dark"
                    type="danger"
                    class="w-100 mt-2"
                  >
                    RABAT 5%
                  </el-tag>
                  <el-tag
                    v-if="item.kwp < pvgisData.power_needed"
                    effect="dark"
                    type="danger"
                    class="w-100 mt-2"
                  >
                    Moc mniejsza niż potrzebna!
                  </el-tag>
                </div>
                <div>
                  <table class="table">
                    <tr>
                      <td>Moc zestawu</td><td>{{ item.kwp }} kWp</td>
                    </tr>
                    <tr>
                      <td>Fazy</td><td>{{ item.phase }}</td>
                    </tr>
                    <tr>
                      <td>Nazwa paneli</td>
                      <el-select
                        v-model="item.solar_panel_name"
                        value-key="solar_panel_name"
                        class="w-100 calculator-select"
                        placeholder="Wybierz Panel"
                        name="solar_panel_name"
                        @change="changeKitByPanel(index, $event, item)"
                      >
                        <el-option
                          v-for="item in item.addons_panels"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </tr>
                    <tr>
                      <td>Ilość paneli</td>
                      <el-select
                        v-model="item.count_solar_panel"
                        value-key="kit"
                        class="w-100 calculator-select"
                        placeholder="Dostosuj zestaw"
                        name="new_addons_kits"
                        @change="changeKitBySolarKit(index, $event, item)"
                      >
                        <el-option
                          v-for="(kit,i) in item.addons_kits"
                          :key="i"
                          :label="kit.count_solar_panel"
                          :value="kit.id"
                        >
                          {{ kit.count_solar_panel + ' szt.' }} - {{ kit.kwp + ' kwp' }}
                        </el-option>
                      </el-select>
                    </tr>
                    <tr>
                      <td>Falownik domyśl.</td><td>{{ item.inverter }} - {{ item.count_inverter }} szt.</td>
                    </tr>
                    <tr>
                      <td>Kwota</td><td>
                        {{ getNettoPrice(item) }} PLN
                        <el-tag
                          type="success"
                          size="small"
                          effect="dark"
                        >
                          netto
                        </el-tag>
                      </td>
                    </tr>
                    <tr>
                      <td>Kwota opod.</td><td>
                        {{ getBruttoPrice(item) }} PLN
                        <el-tag
                          type="success"
                          size="small"
                          effect="dark"
                        >
                          brutto
                        </el-tag>
                        <el-tag
                          type="success"
                          size="small"
                          effect="dark"
                        >
                          {{ vat ? '23' : '8' }}%
                        </el-tag>
                      </td>
                    </tr>
                    <tr>
                      <td>Gwa. produktu</td><td>{{ item.warranty_product ? item.warranty_product : 'Brak informacji' }}</td>
                    </tr>
                    <tr>
                      <td>Gwa. wydajności</td><td>{{ item.warranty_performance ? item.warranty_performance : 'Brak informacji' }}</td>
                    </tr>
                  </table>
                </div>
                <div class="row">
                  <div class="col-12">
                    <el-checkbox v-model="item.canChangeInverter">
                      Zmienić falownik?
                    </el-checkbox>
                    <el-checkbox v-model="item.canAddons">
                      Chcesz dodać dodatki?
                    </el-checkbox>
                    <div v-if="item.canChangeInverter">
                      <div class="my-6">
                        <span>Wybierz inny falownik</span>
                        <el-select
                          v-model="item.inverter_id"
                          value-key="inverter"
                          class="w-100 calculator-select"
                          placeholder="Falownik"
                          name="inverter"
                        >
                          <el-option
                            v-for="inverter in item.inverter_list"
                            :key="inverter.id"
                            :label="inverter.name"
                            :value="inverter.id"
                          >
                            {{ inverter.name }}
                          </el-option>
                        </el-select>
                      </div>
                      <hr>
                    </div>
                    <div
                      v-if="item.canAddons"
                      class="my-6"
                    >
                      <span>Dodaj dodatki do kalkulacji</span>
                      <el-select
                        v-model="item.current_addon"
                        value-key="addons"
                        class="w-100 calculator-select"
                        placeholder="Dodatki do oferty"
                        name="addons"
                        @change="addAddonsToInputs($event, index)"
                      >
                        <el-option
                          v-for="(addon,i) in item.addons_list"
                          :key="addon.id"
                          :label="addon.name"
                          :value="i"
                        >
                          {{ addon.name }} + {{ addon.cost }} PLN netto
                        </el-option>
                      </el-select>

                      <div
                        v-if="item.selected_addon.name !== ''"
                        class="list-container my-3"
                      >
                        <div class="row block-addons">
                          <div class="col-6 block-addons-auto">
                            <i class="fas fa-angle-right"></i>
                            <span>{{ item.selected_addon.count }} {{ item.selected_addon.unit }} - {{ item.selected_addon.name }}</span>
                          </div>
                          <div class="col-6">
                            <input-number
                              v-model="item.selected_addon.count"
                              :min="1"
                              :max="99"
                              controls-position="right"
                            />
                          </div>
                          <div class="col-12 my-3">
                            <el-button
                              type="primary"
                              class="w-100"
                              @click="addAddonsToTable(index)"
                            >
                              Dodaj do listy
                            </el-button>
                          </div>
                        </div>
                      </div>
                      <div class="my-3">
                        <div v-for="(element,i) in item.added_addons_list">
                          <el-tooltip
                            class="item text-red"
                            effect="dark"
                            content="Usuń ten element"
                            placement="top"
                          >
                            <i
                              style="cursor:pointer"
                              class="fas fa-times"
                              @click="deleteElementList(index, i)"
                            ></i>
                          </el-tooltip>
                          - {{ element.count }}x
                          {{ element.name | truncate(35) }}<el-tag type="success">
                            {{ element.cost }} PLN netto
                          </el-tag>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="my-6">
                  <div v-if="item.bonus">
                    {{ item.bonus ? item.bonus : 'Brak informacji' }}
                  </div>
                  <hr>
                  <div
                    v-if="item.solar_card || item.inverter_card"
                    class="row"
                  >
                    <div
                      v-if="item.solar_card"
                      class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1"
                    >
                      <download-button
                        :file_name="item.solar_card"
                        icon="fas fa-solar-panel"
                        name="Panel"
                      />
                    </div>
                    <div
                      v-if="item.inverter_card"
                      class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1"
                    >
                      <download-button
                        :file_name="item.inverter_card"
                        icon="fas fa-window-restore"
                        name="Falownik"
                      />
                    </div>
                  </div>
                </div>
                <div class="my-5">
                  <div class="text-center">
                    <h5>Kwota całkowita {{ showTypePrice ? 'netto' : 'brutto' }}</h5>
                  </div>
                  <el-tag
                    type="success"
                    style="    font-size: 25px;"
                    class="w-100 text-center"
                  >
                    {{ getFullPrice(item) }} PLN
                  </el-tag>
                </div>
                <div
                  class="bottom clearfix mt-5"
                >
                  <el-button
                    :disabled="item.button_disabled"
                    class="w-100"
                    type="primary"
                    @click="submitForm(item)"
                  >
                    Wybierz ofertę
                  </el-button>
                </div>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
  import { postRequest, putRequest } from '@/shared/utils/http'
  import Dinero from 'dinero.js'
  import DownloadButton from '@/admin/pages/calculator/components/DownloadButton'

  export default {
    name: 'ViewOffers',
    components: { DownloadButton },
    props: {
      offers: {
        type: Array,
        default: () => [],
        required: true
      },
      pvgisData: {
        type: Object | String,
        required: true
      },
      form: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        test1: '',
        savedCalculation: false,
        updateCalculation: false,
        showTypePrice: false,
        loading: true,
        discount: false,
        vat: false,
        kitSelected: 0,
        calcId: '',
        activeNames: '1'
      }
    },
    mounted () {
      this.loading = false
    },
    methods: {
      submitForm: function (item) {
        item.button_disabled = true
        this.loading = true
        this.submit(item)
      },
      submit (item) {
        return new Promise((resolve, reject) => {
          let request
          request = postRequest('/api/offers', {
            model_type: this.$route.params.type,
            model_id: this.$route.params.id,
            construction_id: item.construction,
            company_id: item.company_id,
            price: this.discount ? Dinero({ amount: Math.round(item.price_with_discount * 100), precision: 2 }).getAmount() / 100 : Dinero({ amount: Math.round(item.price * 100), precision: 2 }).getAmount() / 100,
            price_with_construction: this.discount ? Dinero({ amount: Math.round(item.price_construction_with_discount * 100), precision: 2 }).getAmount() / 100 : Dinero({ amount: Math.round(item.price_construction * 100), precision: 2 }).getAmount() / 100,
            price_brutto: this.getBruttoPrice(item),
            vat_23: this.vat,
            type_offer: this.vat, // przemyśleć czy type_offer ma sens dla takiego działania
            kwp: this.pvgisData.power_needed,
            phase: this.form.phase,
            kit_id: item.id,
            discount: this.discount,
            area_points: this.form.area_points,
            area: this.form.area,
            azimuth: this.form.azimuth,
            azimuth_points: this.form.azimuth_points,
            yearly_use: this.form.yearly_use ? this.form.yearly_use : '',
            angle: this.pvgisData.roof_degree,
            insulation: this.pvgisData.insulation,
            energy_production: this.pvgisData.energy_production ? this.pvgisData.energy_production : '',
            loss_temperature: this.pvgisData.loss.temperature,
            loss_system: -6,
            loss_position: this.pvgisData.loss.position,
            per_month_data: this.pvgisData.per_month_data,
            added_addons_list: item.added_addons_list,
            inverter_id: item.inverter_id,
            inverter_kit_id: item.inverterItem.id,
            consumption: this.pvgisData.consumption
          })
          request
            .then(response => {
              this.$emit('saved')
              this.$router.push({ name: 'admin.offers.show', params: { id: response.id } })
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      BackToCalculator () {
        this.$emit('resetCalculator')
      },
      getAmountOffer (price) {
        return Dinero({ amount: Math.round(price * 100), precision: 2 }).getAmount() / 100
      },
      getNettoPrice (item) {
        let price = null
        if (!item) {
          console.log('Problem z wprowadzoną kwotą')
          return
        }
        price = (this.discount) ? item.price_construction_with_discount : item.price_construction

        return Dinero({ amount: Math.round(price * 100), precision: 2 }).getAmount() / 100
      },
      getBruttoPrice (item) {
        let price = null
        let priceConstruction = null
        let priceConstructionWithDiscount = null

        if (!item) {
          console.log('Problem z wprowadzoną kwotą')
          return
        }

        if (this.vat) {
          priceConstruction = item.price_construction_vat23
          priceConstructionWithDiscount = item.price_construction_with_discount_vat23
        } else {
          priceConstruction = item.price_construction_vat8
          priceConstructionWithDiscount = item.price_construction_with_discount_vat8
        }
        price = (this.discount) ? priceConstructionWithDiscount : priceConstruction

        return Dinero({ amount: Math.round(price * 100), precision: 2 }).getAmount() / 100
      },
      getFullPrice (item) {
        let addonsPrice = 0
        let price = null

        if (this.showTypePrice) {
          price = this.getNettoPrice(item)
        } else {
          price = this.getBruttoPrice(item)
        }

        const vat = (this.vat) ? 1.23 : 1.08

        item.added_addons_list.map(el => {
          if (this.showTypePrice) {
            addonsPrice = addonsPrice + (el.cost)
          } else {
            addonsPrice = addonsPrice + (el.cost * vat)
          }
        })
        addonsPrice = Dinero({ amount: Math.round(addonsPrice * 100), precision: 2 })
        price = Dinero({ amount: Math.round(price * 100), precision: 2 })

        if (this.discount === false) {
          addonsPrice = Dinero({ amount: Math.round(addonsPrice.getAmount() / 0.95), precision: 2 })
        }

        if (item.inverter_id !== item.inverterItem.id) {
          if (item.canChangeInverter === true) {
            let priceCurrentInverter = item.inverterItem.cost
            let priceChoosenInverter = this.getInverterCost(item.inverter_id)

            priceCurrentInverter = Dinero({ amount: Math.round(priceCurrentInverter * 100), precision: 2 })
            priceChoosenInverter = Dinero({ amount: Math.round(priceChoosenInverter * 100), precision: 2 })
            let differenceCost = priceCurrentInverter.subtract(priceChoosenInverter)

            if (this.discount === false) {
              differenceCost = Dinero({ amount: Math.round(differenceCost.getAmount() / 0.95), precision: 2 })
            }
            if (!this.showTypePrice) {
              differenceCost = differenceCost.multiply(vat)
            }

            price = price.subtract(differenceCost)
          } else {
            item.inverter_id = item.inverterItem.id
          }
        }

        return price.add(addonsPrice).getAmount() / 100
      },
      getInverterCost (inverterId) {
        let chosenInverterCost = ''
        this.$config.calculator.inverters.map((item) => {
          if (item.id === inverterId) {
            chosenInverterCost = parseFloat(item.cost)
          }
        })

        return chosenInverterCost
      },
      switchCurrentKit (index, kit) {
        this.$emit('update-offers', index, kit)
      },
      changeKitBySolarConstruction (index, kit, item) {
        this.savedCalculation = false
        this.$emit('update-by-construction-kit', index, kit, item)
      },
      changeKitBySolarKit (index, kit, item) {
        this.savedCalculation = false
        this.$emit('update-by-kit', index, kit, item)
      },
      changeKitByPanel (index, kit, item) {
        this.savedCalculation = false
        this.$emit('update-by-panel', index, kit, item)
      },
      addAddonsToInputs (indexAddons, indexKit) {
        // eslint-disable-next-line camelcase
        const choosen_addon = this.offers[indexKit].addons_list[indexAddons]
        this.offers[indexKit].selected_addon.id = choosen_addon.id
        this.offers[indexKit].selected_addon.unit = choosen_addon.unit
        this.offers[indexKit].selected_addon.name = choosen_addon.name
        this.offers[indexKit].selected_addon.cost = choosen_addon.cost
        console.log(choosen_addon)
      },
      addAddonsToTable (indexKit) {
        const currentAddon = this.offers[indexKit].selected_addon

        if (currentAddon.count === '') {
          return false
        }
        this.offers[indexKit].added_addons_list.push({
          id: currentAddon.id,
          name: currentAddon.name,
          count: currentAddon.count,
          cost: currentAddon.cost * parseInt(currentAddon.count),
          unit: currentAddon.unit
        })
        this.$message.success('Dodano dodatek do zestawu')
        this.resetSelectedAddon(indexKit)
      },
      resetSelectedAddon (indexKit) {
        this.offers[indexKit].selected_addon.unit = ''
        this.offers[indexKit].selected_addon.name = ''
        this.offers[indexKit].selected_addon.cost = ''
        this.offers[indexKit].selected_addon.count = 1
        this.offers[indexKit].current_addon = null
      },
      deleteElementList (indexKit, i) {
        this.offers[indexKit].added_addons_list.splice(i, 1)
      },
      saveActuallyCalculations () {
        this.loading = true
        if (this.updateCalculation) {
          this.updateCalc()
        } else {
          this.saveCalc()
        }
      },
      saveCalc () {
        return new Promise((resolve, reject) => {
          let request
          request = postRequest('/api/calculations', {
            vat: this.vat,
            discount: this.discount,
            model_type: this.$route.params.type,
            model_id: this.$route.params.id,
            pvgisData: this.pvgisData,
            form: this.form,
            calculations: this.offers
          })
          request
            .then(response => {
              this.$emit('saved')
              this.$message.success('Pomyślnie zapisano kalkulację w systemie.')
              this.savedCalculation = true
              this.updateCalculation = true
              this.calcId = response.id
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              this.loading = false
              reject(error)
            })
        })
      },
      updateCalc () {
        return new Promise((resolve, reject) => {
          let request
          request = putRequest('/api/calculations/' + this.calcId, {
            vat: this.vat,
            discount: this.discount,
            model_type: this.$route.params.type,
            model_id: this.$route.params.id,
            pvgisData: this.pvgisData,
            form: this.form,
            calculations: this.offers
          })
          request
            .then(response => {
              this.$emit('saved')
              this.$message.success('Pomyślnie nadpisano kalkulację w systemie.')
              this.savedCalculation = true
              this.updateCalculation = true
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              this.loading = false
              reject(error)
            })
        })
      },
      setCheckedGroup ($event, index) {
        if ($event === true) {
          this.checkedGroup.push(index)
        } else {
          const indexKit = this.checkedGroup.indexOf(index)
          this.checkedGroup.splice(indexKit, 1)
        }
      },
      duplicate (index) {
        if (this.savedCalculation) {
          this.updateCalculation = true
        }
        this.savedCalculation = false

        const cloneKit = { ...this.offers[index] }
        this.offers.splice(index + 1, 0, cloneKit)
      }
    }
  }
</script>

<style scoped>
.el-select > .el-input.el-input--suffix input {
  background: #67c23a !important;
  color: white !important;
}
input.el-input__inner {
  background: #97d700 !important;
  color: white !important;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.image {
  width: 264px;
  height: 132px;
  margin: auto;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
.adv-block{
  position: absolute;
}
.carousel-3d-slide {
  background-color: #fff !important;
}

.block-addons{
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid #f3f3f3;
}
.block-addons-auto{
  margin-top: auto;
  margin-bottom: auto;
}
.duplicate-button{
  margin-right: 15px;
}
.checkbox-section{
  margin:10px;
}
</style>
