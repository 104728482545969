.list-unstyled {
  padding-left: 0;
  list-style: none;
}

dl {
  margin-bottom: 1rem;

  dt {
    color: $color-text-lighter;
    text-transform: uppercase;
    font-size: 0.75rem;

    &:after {
      display: inline;
      content: ':';
    }
  }

  dd {
    margin: 0.2rem 0 0.75rem 0;
  }
}

.dl-header {
  padding-bottom: 0.3rem;
  color: $color-text-lighter;
  font-weight: 400;
  text-transform: uppercase;
  font-size: .9rem;
  border-bottom: 1px solid $color-border;
}