
.confident-data {
  padding: 4px 0;

  i {
    vertical-align: top;
  }

  > div {
    display: inline-block;
    margin: 0 0 0 15px;
  }
}
