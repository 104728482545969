<template>
  <div>
    <el-card
      v-if="form.company_id"
      class="mt-3"
    >
      <div class="d-flex justify-content-between flex-lg-row flex-xl-row flex-column flex-nowrap align-items-center">
        <div>
          <h5 class="my-0">
            Aktualnie wybrana firma: <strong> {{ selectedCompany }} </strong>
          </h5>
        </div>
        <div>
          <el-button
            class="button-mobile-app"
            @click="previousPage"
          >
            Cofnij
          </el-button>
          <el-button
            v-if="!form.product_number && $can('products.save-draft')"
            class="button-mobile-app"
            type="warning"
            @click="saveDraft"
          >
            <i class="far fa-save"></i> {{ form.is_draft ? 'Nadpisz wersje roboczą' : 'Zapisz wersje roboczą' }}
          </el-button>
          <el-button
            class="button-mobile-app"
            type="primary"
            @click="nextPage"
          >
            Już wybrane
          </el-button>
        </div>
      </div>
    </el-card>
    <el-card class="mt-3">
      <div class="col-lg-12 col-md-12 col-xl-12 order-lg-1 order-xl-1 mb-3">
        <h3 class="text-center">
          Wybierz dostawcę umowy
        </h3>
      </div>
    </el-card>
    <el-card class="mt-3">
      <div class="row">
        <div
          v-for="item in $config.companiesFullList"
          class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="Kilknij, aby wybrać tego dostawcę"
            placement="top-start"
          >
            <el-card
              class="mt-2 dashboard-button my-auto"
              @click.native="selectAgreementProvider(item.id)"
              @mouseover.native="setHoverState(true)"
              @mouseleave.native="setHoverState(false)"
            >
              <div class="row justify-content-center align-content-center">
                <div class="col-12">
                  <div class="icons text-center">
                    <img
                      :src="item.logo_company"
                      class="logo-company"
                      alt="logo-firmowe"
                    >
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </el-card>
          </el-tooltip>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    name: 'SelectAgreement',
    props: {
      icon: {
        type: String | Boolean,
        default: 'fab fa-sellsy'
      },
      type: {
        type: String,
        default: 'info',
        validator: (value) => {
          return [
            'primary', 'success', 'warning', 'danger', 'info', 'text'
          ].includes(value)
        }
      },
      resource: {
        type: String,
        default: ''
      },
      form: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        hoverState: false
      }
    },
    computed: {
      selectedCompany () {
        // this.$config.companiesFullList[form.company_id].name
        const company = this.$config.companiesFullList.find(el => el.id === this.form.company_id)
        if (!company) {
          return ''
        }
        return company.name
      }
    },
    mounted () {
      if (this.form.type_product === 2) {
        if (this.form.company_id !== null) {
          this.nextPage()
        }
      }
    },
    methods: {
      nextPage: function () {
        this.$emit('nextPage')
      },
      previousPage: function () {
        this.$emit('previousPage')
      },
      setHoverState (state) {
        this.hoverState = state
      },
      saveDraft: function () {
        this.$emit('save-draft')
      },
      actionMoveToComponent () {
        this.$router.push({ name: this.resource })
      },
      selectAgreementProvider (val) {
        this.form.company_id = val
        this.nextPage()
      }
    }
  }
</script>

<style scoped>
.dashboard-button{
  cursor: pointer;
  color: #3f4443;
  padding: 40px 0px;
  transition: 0.6s;
  background:#fff !important;
}
.el-card{
  height: 100%
}
.dashboard-button:hover{
  color: #97d700;
  border-color: #e0f3b3;
  background-color: #f5fbe6;
}
.title-box{
  font-size:18px
}
.icons {
  margin-left: 5px;
  font-size:20px;
  color: #97d700;
}
.my-auto{
  margin-top:auto;
  margin-bottom:auto
}
.logo-company{
  width: 250px !important;
}
</style>
