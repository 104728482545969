<template>
  <transition name="slide-fade">
    <div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-xl-12">
          <TaskBlock class="mt-1" />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-6 col-md-12 col-xl-6">
          <last-contacts />
        </div>
        <div class="col-lg-6 col-md-12 col-xl-6">
          <div class="row mt-3">
            <div
              v-if="$can('admin.surveys.stats')"
              class="col-lg-4 col-xl-4 col-sm-12"
            >
              <stat-block
                name="Preleady"
                icon="fas fa-poll-h"
                type="surveys-stats"
                text="Liczba wprowadzonych ankiet"
              />
            </div>
            <div
              v-if="$can('admin.lead.read')"
              class="col-lg-4 col-xl-4 col-sm-12"
            >
              <stat-block
                name="Leady"
                icon="fas fa-chalkboard-teacher"
                type="leads-stats"
                text="Liczba dodanych leadów"
              />
            </div>
            <div
              v-if="$can('admin.offers.read')"
              class="col-lg-4 col-xl-4 col-sm-12"
            >
              <stat-block
                name="Oferty"
                icon="fas fa-file-contract"
                type="offers-stats"
                text="Liczba wybranych ofert"
              />
            </div>
            <div
              v-if="$can('admin.calculations.read')"
              class="col-lg-4 col-xl-4 col-sm-12"
            >
              <stat-block
                name="Kalkulacje"
                icon="far fa-id-card"
                type="calculations-stats"
                text="Liczba dokonanych kalkulacji"
              />
            </div>
            <div
              v-if="$can('clients.read')"
              class="col-lg-4 col-xl-4 col-sm-12"
            >
              <stat-block
                name="Klienci"
                icon="fas fa-user-friends"
                type="clients-stats"
                text="Liczba dodanych klientów"
              />
            </div>
            <div
              v-if="$can('admin.sales.read')"
              class="col-lg-4 col-xl-4 col-sm-12"
            >
              <stat-block
                name="Umowy"
                icon="fas fa-file-contract"
                type="sales-stats"
                text="Liczba dodanych umów"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          v-if="$can('admin.calendar.read')"
          class="col-lg-12 col-md-12 col-xl-12 mb-2 d-none d-lg-block d-xl-block"
        >
          <main-calendar class="mt-2" />
        </div>
        <div
          v-if="$can('admin.lead.read')"
          class="col-lg-12 col-md-12 col-xl-12 mt-1"
        >
          <LeadsBlock />
        </div>
        <div
          v-if="$can('admin.sales.read')"
          class="col-lg-12 col-md-12 col-xl-12 mt-3"
        >
          <AgreementBlock class="mt-1" />
        </div>
        <div
          v-if="$can('admin.leads.blacklist.read')"
          class="col-lg-6 col-md-12 col-xl-6 mt-3"
        >
          <BlackList class="mt-1" />
        </div>
        <div
          v-if="$can('admin.logs.read')"
          class="col-lg-6 col-md-12 col-xl-6 mt-3"
        >
          <LogsBlock class="mt-1" />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import MainCalendar from '../../components/Calendar/MainCalendar'
  import TaskBlock from './components/TaskBlock'
  import LogsBlock from './components/LogsBlock'
  import LeadsBlock from './components/LeadsBlock'
  import AgreementBlock from './components/AgreementBlock.vue'
  import BlackList from './components/BlackList'
  import StatBlock from '@/admin/pages/dashboard/components/StatBlock'
  import LastContacts from '@/admin/pages/dashboard/components/LastContacts'

  export default {
    components: {
      LastContacts,
      StatBlock,
      BlackList,
      MainCalendar,
      TaskBlock,
      LogsBlock,
      LeadsBlock,
      AgreementBlock
    },

    data: () => {
      return {
        loading: true,
        monthName: '',
        leads: '',
        clients: '',
        value: new Date(),
        leadsTableData: '',
        clientsTableData: '',
        currentData: ''
      }
    },
    mounted () {
      this.$root.title = 'Dashboard'
      this.loading = false
      this.getActuallyData()
    },

    methods: {
      moveToNews (id) {
        this.$router.push({ name: 'admin.news.show', params: { id: id } })
      },
      goToModule (link) {
        this.$router.push({ path: link })
      },
      getActuallyData: function () {
        this.currentData = new Date().toJSON().slice(0, 10).replace(/-/g, '-')
      }
    }
  }
</script>
<style scoped>
.el-card-height-max{
  min-height: 100%;
}
</style>
