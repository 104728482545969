.app-container-collapse {
  display: grid;
  grid-template-columns: 64px 1fr;
  transition: 0.5s;
}
.app-container {
  display: grid;
  height: 100%;
  grid-template-columns: 180px 1fr;
  transition: 0.5s;
}
@media screen and (max-width: 992px) {
  .app-container{
    display: contents !important;
    grid-template-columns: 64px 1fr;
  }
  .app-container-collapse {
    display: contents !important;
    grid-template-columns: 64px 1fr;
    transition: 0.5s;
  }
}
.el-submenu .el-menu-item {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
  min-width: 160px;
}

.main {
  padding: 20px;
  overflow: auto;
}

.notifications {
  padding: 25px;
}

.button-go-all{
  margin-left:5px;
}

body {
  overflow-x: hidden;
  background: #f8f9fb !important;
}
.header-list-text{
  font-size:12px;
}

.el-card__header {
  border-bottom: 1px solid #dee2e6;
  background: #3f4443 !important;
  color: #fff;
  padding: 8px 20px !important;
}

.vutable-body{
  tr {
    cursor: pointer;
    font-size: 14px;
  }
}

.vuetable-pagination-info {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 13px;
}

.table-filters--handle {
  color: #a4a4a4;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 0;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
}

.snotifyToast__body {
  font-size: 10px;
}

.el-upload {
  width: 100% !important;
}
.el-upload-dragger{
  width: 100% !important;
}

@media screen and (max-width: 992px) {
  .button-mobile-app{
    margin-left: 0px !important;
    width: 100% !important;
    margin-bottom: 10px;
  }
}

.dashboard-button{
  cursor: pointer;
  color: #3f4443;
  transition: 0.6s;
  background-image: radial-gradient(circle at 13% 47%, rgba(140, 140, 140,0.03) 0%, rgba(140, 140, 140,0.03) 25%,transparent 25%, transparent 100%),radial-gradient(circle at 28% 63%, rgba(143, 143, 143,0.03) 0%, rgba(143, 143, 143,0.03) 16%,transparent 16%, transparent 100%),radial-gradient(circle at 81% 56%, rgba(65, 65, 65,0.03) 0%, rgba(65, 65, 65,0.03) 12%,transparent 12%, transparent 100%),radial-gradient(circle at 26% 48%, rgba(60, 60, 60,0.03) 0%, rgba(60, 60, 60,0.03) 6%,transparent 6%, transparent 100%),radial-gradient(circle at 97% 17%, rgba(150, 150, 150,0.03) 0%, rgba(150, 150, 150,0.03) 56%,transparent 56%, transparent 100%),radial-gradient(circle at 50% 100%, rgba(25, 25, 25,0.03) 0%, rgba(25, 25, 25,0.03) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 55% 52%, rgba(69, 69, 69,0.03) 0%, rgba(69, 69, 69,0.03) 6%,transparent 6%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));
}
.dashboard-button:hover{
  color: #97d700;
  border-color: #e0f3b3;
  background-color: #f5fbe6;
}
.title-box{
  font-size:18px
}
.icons {
  margin-left: 5px;
  font-size:20px;
  color: #97d700;
}

.el-card-height-max{
  min-height: 100%;
}

.img-sidebar{
  max-height: 40px;
  max-width: 100%;
  margin-top: 11px;
}

.gm-buttons {
  display: flex;
}
.gm-control{
  border-radius: 15px;
  cursor: pointer;
  z-index: 0;
  color: #333;
  padding: 10px 20px;
  margin: 5px;
  background: #fff;
}

.calculator-select > .el-input.el-input--suffix input {
  background: #67c23a !important;
  color: white !important;
}

.position-fixed{
  position: fixed;
  right: 10px;
  bottom: 20px;
  z-index: 999;
}

#mapCalculable{
  height:190px;
}

@media print {
  .page-print {page-break-after: always;}
}

.el-drawer {
  width: 70% !important;
}

.el-drawer__body {
  overflow: hidden !important;
}

.el-input-number .el-input__inner {
  -webkit-appearance: none;
  padding-left: 0px !important;
  padding-right: 0px !important;
  text-align: center;
}

.el-submenu .el-menu-item {
  min-width: 180px !important;
}

.el-submenu.is-active .el-submenu__title {
  background: #b0b0b0 !important;
}

.read-notification {
  background: #f4f4f4 !important;
}

.card-icon {
  font-size: 4.5em;
  color: map_get($colors, icon-active) !important;
}

.vl {
  border-left: 6px solid green;
  height: 100%;
}

fieldset > .row > div:first-child {
  background: map_get($colors, primary) !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
