[v-cloak] {
  display: none;
}

html, body {
  height: 100%;
}

body {
  overflow-y: scroll;
  background: $background;
  color: $color-text;
  font-family: $font-family-sans-serif;
}

a {
  color: $color-text-link;
  text-decoration: none;

  &:hover, &:focus {
    color: $color-text-link-hover;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix {
  @include clearfix;
}

h1 {
  font-size: 1.3rem;
}

//h1, h2, h3, h4, h5, h6 {
//  font-family: $font-family-header;
//}

.title-bar {
  margin-bottom: 1.3rem;
}

.is-inactive {
  color: map_get($colors, color-text-lighter);
  text-decoration: line-through;
}

span.inactive {
  color: map_get($colors, danger);
  font-size: 0.8rem;
}

.box-details {
  margin-bottom: 1rem;
  padding: 10px;
  background: map_get($colors, lighter-grey);
}

hr {
  border: 0;
}

.quick-contact {
  li {
    padding: 0 0 10px;

    i {
      color: map_get($colors, grey);
    }
  }
}

.el-breadcrumb__item {
  .el-breadcrumb__inner {
    line-height: 1.3;
  }
}

.el-popover {
  word-break: normal;
  text-align: left;
}

.clock__hours, .clock__minutes, .clock__seconds {
  background: linear-gradient(to bottom, #3f4443 50%, #3f4449 50%) !important;
}


.header-list{
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: inherit;
  font-size: 12px;
  li{
    display: inline-block;
    width: 110px;
    height: 100%;
    text-align: center;
    cursor:pointer;
    &:hover, &:focus {
      transition: 0.3s;
      background: rgb(151,215,0);
    }
  }
}
.el-menu {
  border-right: none !important;
}

.w-100 {
  width: 100%!important;
}

.complete-icon{
  &:hover, &:focus {
    transition: 0.3s;
    color: rgb(151,215,0);
  }
}

.el-dialog__header {
  padding: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f3f3f3 !important;
}

.el-dialog__body {
  padding: 10px 20px 20px 30px !important;
}

.form-group {
  display: inline-block;
}