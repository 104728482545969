<template>
  <div>
    <div class="row mt-3">
      <div class="col-12 col-xl-9">
        <el-card
          v-loading="loading"
          class=""
        >
          <title-card name="Główny formularz" />
          <fieldset>
            <div class="row">
              <div class="col-lg-3 col-xl-2">
                <h6>Główne ustawienia</h6>
              </div>
              <div class="col-lg-9 col-xl-10">
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.usable_area)"
                    :errors="formErrors"
                    prop="usable_area"
                    label="Powierzchnia użytkowa budynku - m2"
                  >
                    <input-number
                      v-model="form.heater.usable_area"
                      v-validate="rules.heater.usable_area"
                      :precision="2"
                      name="usable_area"
                      type="text"
                    />
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.type)"
                    :errors="formErrors"
                    prop="type"
                    label="Grzewka - rodzaj"
                  >
                    <el-select
                      v-model="form.heater.type"
                      v-validate="rules.heater.type"
                      :filterable="true"
                      disabled
                      class="w-100"
                      name="type"
                    >
                      <el-option
                        v-for="(item,index) in typeHeater"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </custom-form-item>
                </div>

                <div v-if="isHeaterPumpType">
                  <custom-form-item
                    :required="checkRequired(rules.heater.warranty_time)"
                    :errors="formErrors"
                    prop="warranty_time"
                    label="Gwarancja na produkt"
                  >
                    <el-input
                      v-model="form.heater.warranty_time"
                      v-validate="rules.heater.warranty_time"
                      name="warranty_time"
                      type="text"
                    />
                  </custom-form-item>
                </div>
                <div v-if="isHeaterPumpType">
                  <custom-form-item
                    :required="checkRequired(rules.heater.warranty_time_service)"
                    :errors="formErrors"
                    prop="warranty_time_service"
                    label="Gwarancja na roboty montażowe"
                  >
                    <el-input
                      v-model="form.heater.warranty_time_service"
                      v-validate="rules.heater.warranty_time_service"
                      name="warranty_time_service"
                      type="text"
                    />
                  </custom-form-item>
                </div>
              </div>
            </div>
          </fieldset>
        </el-card>
        <el-card
          v-if="isHeaterPumpType"
          v-loading="loading"
          class="mt-3"
        >
          <title-card name="Pompy ciepła - formularz" />
          <fieldset>
            <div class="row">
              <div class="col-lg-3 col-xl-2">
                <h6>Wybór pompy</h6>
              </div>
              <div class="col-lg-9 col-xl-10">
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.heat_pump_name)"
                    :errors="formErrors"
                    prop="heat_pump_name"
                    label="Nazwa"
                  >
                    <el-input
                      v-model="form.heater.heat_pump_name"
                      v-validate="rules.heater.heat_pump_name"
                      name="heat_pump_name"
                      type="text"
                    />
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.heat_pump_power)"
                    :errors="formErrors"
                    prop="heat_pump_power"
                    label="Moc - zakres"
                  >
                    <el-select
                      v-model="form.heater.heat_pump_power"
                      v-validate="rules.heater.heat_pump_power"
                      :filterable="true"
                      class="w-100"
                      name="heat_pump_power"
                    >
                      <el-option
                        v-for="(item,index) in powerPumps"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.heat_pump_count)"
                    :errors="formErrors"
                    prop="heat_pump_count"
                    label="Ilość"
                  >
                    <input-number
                      v-model="form.heater.heat_pump_count"
                      v-validate="rules.heater.heat_pump_count"
                      name="heat_pump_count"
                      type="text"
                    />
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.heat_pump_exchanger_co)"
                    :errors="formErrors"
                    prop="heat_pump_exchanger_co"
                    label="Wymiennik CO - wybierz"
                  >
                    <el-select
                      v-model="form.heater.heat_pump_exchanger_co"
                      v-validate="rules.heater.heat_pump_exchanger_co"
                      :filterable="true"
                      class="w-100"
                      name="heat_pump_exchanger_co"
                    >
                      <el-option
                        v-for="(item,index) in exchangerC0"
                        :key="index"
                        :label="item.name"
                        :value="item.name"
                      />
                    </el-select>
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.heat_pump_exchanger_cwu)"
                    :errors="formErrors"
                    prop="heat_pump_exchanger_cwu"
                    label="Wymiennik CWU - wybierz"
                  >
                    <el-select
                      v-model="form.heater.heat_pump_exchanger_cwu"
                      v-validate="rules.heater.heat_pump_exchanger_cwu"
                      :filterable="true"
                      class="w-100"
                      name="heat_pump_exchanger_cwu"
                    >
                      <el-option
                        v-for="(item,index) in exchangerCWU"
                        :key="index"
                        :label="item.name"
                        :value="item.name"
                      />
                    </el-select>
                  </custom-form-item>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div class="row">
              <div class="col-lg-3 col-xl-2">
                <h6>Konfiguracja dodatkowa</h6>
              </div>
              <div class="col-lg-9 col-xl-10">
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.heat_count_people_using_cwu)"
                    :errors="formErrors"
                    prop="heat_count_people_using_cwu"
                    label="Ilość osób użytkujących CWU"
                  >
                    <input-number
                      v-model="form.heater.heat_count_people_using_cwu"
                      v-validate="rules.heater.heat_count_people_using_cwu"
                      name="heat_count_people_using_cwu"
                      type="text"
                    />
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.heat_daily_person_cwu)"
                    :errors="formErrors"
                    prop="heat_daily_person_cwu"
                    label="Dzienne zapotrzebowanie - 30/40/50 lub podaj inne"
                  >
                    <input-number
                      v-model="form.heater.heat_daily_person_cwu"
                      v-validate="rules.heater.heat_daily_person_cwu"
                      name="heat_daily_person_cwu"
                      type="text"
                    />
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.heat_watter_heating_installation)"
                    :errors="formErrors"
                    prop="heat_watter_heating_installation"
                    label="Obecna instalacja podgrzewania wody"
                  >
                    <el-select
                      v-model="form.heater.heat_watter_heating_installation"
                      v-validate="rules.heater.heat_watter_heating_installation"
                      :filterable="true"
                      class="w-100"
                      name="heat_watter_heating_installation"
                    >
                      <el-option
                        v-for="(item,index) in heatWatterInstallations"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.heat_electric_place)"
                    :errors="formErrors"
                    prop="heat_electric_place"
                    label="Instalacja elektryczna przy montażu"
                  >
                    <el-select
                      v-model="form.heater.heat_electric_place"
                      v-validate="rules.heater.heat_electric_place"
                      :filterable="true"
                      class="w-100"
                      name="heat_electric_place"
                    >
                      <el-option
                        v-for="(item,index) in heatingWatterCurrent"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.heat_wall_external)"
                    :errors="formErrors"
                    prop="heat_wall_external"
                    label="Ściana pomieszczenia jest ściana zew. budynku"
                  >
                    <el-select
                      v-model="form.heater.heat_wall_external"
                      v-validate="rules.heater.heat_wall_external"
                      :filterable="true"
                      class="w-100"
                      name="heat_wall_external"
                    >
                      <el-option
                        v-for="(item,index) in heatWallExternal"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.heat_group)"
                    :errors="formErrors"
                    prop="heat_group"
                    label="Grupa pompowa"
                  >
                    <input-number
                      v-model="form.heater.heat_group"
                      v-validate="rules.heater.heat_group"
                      name="heat_group"
                      type="text"
                    />
                  </custom-form-item>
                </div>
              </div>
            </div>
          </fieldset>
        </el-card>
        <el-card
          v-if="isBoilerType"
          v-loading="loading"
          class="mt-3"
        >
          <title-card name="Kotły - formularz" />
          <fieldset>
            <div class="row">
              <div class="col-lg-3 col-xl-2">
                <h6>Wybór kotła</h6>
              </div>
              <div class="col-lg-9 col-xl-10">
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.boiler_coal_name)"
                    :errors="formErrors"
                    prop="boiler_coal_name"
                    label="Nazwa"
                  >
                    <el-input
                      v-model="form.heater.boiler_coal_name"
                      v-validate="rules.heater.boiler_coal_name"
                      name="boiler_coal_name"
                      type="text"
                    />
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.boiler_coal_power)"
                    :errors="formErrors"
                    prop="boiler_coal_power"
                    label="Moc - kW"
                  >
                    <input-number
                      v-model="form.heater.boiler_coal_power"
                      v-validate="rules.heater.boiler_coal_power"
                      :precision="3"
                      name="boiler_coal_power"
                      type="text"
                    />
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.boiler_coal_boiler_coal_count)"
                    :errors="formErrors"
                    prop="boiler_coal_boiler_coal_count"
                    label="Ilość"
                  >
                    <input-number
                      v-model="form.heater.boiler_coal_boiler_coal_count"
                      v-validate="rules.heater.boiler_coal_boiler_coal_count"
                      name="boiler_coal_boiler_coal_count"
                      type="text"
                    />
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.boiler_coal_driver)"
                    :errors="formErrors"
                    prop="boiler_coal_driver"
                    label="Sterownik kotła"
                  >
                    <el-input
                      v-model="form.heater.boiler_coal_driver"
                      v-validate="rules.heater.boiler_coal_driver"
                      name="boiler_coal_driver"
                      type="text"
                    />
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.boiler_coal_is_mounting)"
                    :errors="formErrors"
                    prop="boiler_coal_is_mounting"
                    label="Czy chcesz dodać montaż?"
                  >
                    <el-switch
                      v-model="form.heater.boiler_coal_is_mounting"
                      name="boiler_coal_is_mounting"
                    />
                  </custom-form-item>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div class="row">
              <div class="col-lg-3 col-xl-2">
                <h6>Dostosowanie wyjścia kominowego</h6>
              </div>
              <div class="col-lg-9 col-xl-10">
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.boiler_chimney_outlet)"
                    :errors="formErrors"
                    prop="boiler_chimney_outlet"
                    label="Wyjście kominowe"
                  >
                    <el-select
                      v-model="form.heater.boiler_chimney_outlet"
                      v-validate="rules.heater.boiler_chimney_outlet"
                      :filterable="true"
                      class="w-100"
                      name="boiler_chimney_outlet"
                    >
                      <el-option
                        v-for="(item,index) in chimneys"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </custom-form-item>
                </div>
                <div v-if="form.heater.boiler_chimney_outlet === 1">
                  <custom-form-item
                    :required="checkRequired(rules.heater.boiler_chimney_outlet_circle)"
                    :errors="formErrors"
                    prop="boiler_chimney_outlet_circle"
                    label="Okrąg - średnica [mm]"
                  >
                    <input-number
                      v-model="form.heater.boiler_chimney_outlet_circle"
                      v-validate="rules.heater.boiler_chimney_outlet_circle"
                      :precision="2"
                      name="boiler_chimney_outlet_circle"
                      type="text"
                    />
                  </custom-form-item>
                </div>
                <div v-if="form.heater.boiler_chimney_outlet === 2">
                  <custom-form-item
                    :required="checkRequired(rules.heater.boiler_chimney_outlet_rectangle)"
                    :errors="formErrors"
                    prop="boiler_chimney_outlet_rectangle"
                    label="Prostokąt - długość x wysokość [mm]"
                  >
                    <el-input
                      v-model="form.heater.boiler_chimney_outlet_rectangle"
                      v-validate="rules.heater.boiler_chimney_outlet_rectangle"
                      name="boiler_chimney_outlet_rectangle"
                      type="text"
                    />
                  </custom-form-item>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div class="row">
              <div class="col-lg-3 col-xl-2">
                <h6>Układ kotła</h6>
              </div>
              <div class="col-lg-9 col-xl-10">
                <div>
                  <div class="image-boilers">
                    <el-image
                      src="/img/kotly.jpg"
                      fit="contain"
                    />
                  </div>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.boiler_type)"
                    :errors="formErrors"
                    prop="boiler_type"
                    label="Wybierz układ kotła"
                  >
                    <el-select
                      v-model="form.heater.boiler_type"
                      v-validate="rules.heater.boiler_type"
                      :filterable="true"
                      class="w-100"
                      name="boiler_type"
                    >
                      <el-option
                        v-for="(item,index) in boilerTypes"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </custom-form-item>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div class="row">
              <div class="col-lg-3 col-xl-2">
                <h6>Konfiguracja kotła</h6>
              </div>
              <div class="col-lg-9 col-xl-10">
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.boiler_hinges)"
                    :errors="formErrors"
                    prop="boiler_hinges"
                    label="Wybierz zawiasy"
                  >
                    <el-select
                      v-model="form.heater.boiler_hinges"
                      v-validate="rules.heater.boiler_hinges"
                      :filterable="true"
                      class="w-100"
                      name="boiler_hinges"
                    >
                      <el-option
                        v-for="(item,index) in hinges"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </custom-form-item>
                </div>
              </div>
            </div>
          </fieldset>
        </el-card>
        <el-card
          v-loading="loading"
          class="mt-3"
        >
          <title-card name="Pozostałe ustawienia" />
          <fieldset>
            <div class="row">
              <div class="col-lg-3 col-xl-2">
                <h6>Instalacja ciepłownicza</h6>
              </div>
              <div class="col-lg-9 col-xl-10">
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.heating_installation_type)"
                    :errors="formErrors"
                    prop="heating_installation_type"
                    label="Rodzaj instalacji grzewczej"
                  >
                    <el-select
                      v-model="form.heater.heating_installation_type"
                      v-validate="rules.heater.heating_installation_type"
                      :filterable="true"
                      class="w-100"
                      name="heating_installation_type"
                    >
                      <el-option
                        v-for="(item,index) in heatingInstallations"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </custom-form-item>
                </div>
                <div v-if="form.heater.heating_installation_type !== 2">
                  <custom-form-item
                    :required="checkRequired(rules.heater.system_layout_co)"
                    :errors="formErrors"
                    prop="system_layout_co"
                    label="Układ instalacji C0"
                  >
                    <el-select
                      v-model="form.heater.system_layout_co"
                      v-validate="rules.heater.system_layout_co"
                      :filterable="true"
                      class="w-100"
                      name="system_layout_co"
                    >
                      <el-option
                        v-for="(item,index) in heaterLayout"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </custom-form-item>
                </div>
                <div v-if="form.heater.heating_installation_type !== 2">
                  <custom-form-item
                    :required="checkRequired(rules.heater.technology_central_heating_installation)"
                    :errors="formErrors"
                    prop="technology_central_heating_installation"
                    label="Technologia wykonania instalacji C0"
                  >
                    <el-select
                      v-model="form.heater.technology_central_heating_installation"
                      v-validate="rules.heater.technology_central_heating_installation"
                      :filterable="true"
                      class="w-100"
                      name="technology_central_heating_installation"
                    >
                      <el-option
                        v-for="(item,index) in technologyHeating"
                        :key="index"
                        :value="item.value"
                        :label="item.name"
                      />
                    </el-select>
                  </custom-form-item>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div class="row">
              <div class="col-lg-3 col-xl-2">
                <h6>Budynek</h6>
              </div>
              <div class="col-lg-9 col-xl-10">
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.boiler_mounting_place)"
                    :errors="formErrors"
                    prop="boiler_mounting_place"
                    label="Miejsce montażu urządzenia"
                  >
                    <el-select
                      v-model="form.heater.boiler_mounting_place"
                      v-validate="rules.heater.boiler_mounting_place"
                      :filterable="true"
                      class="w-100"
                      name="boiler_mounting_place"
                    >
                      <el-option
                        v-for="(item,index) in mountingPlaces"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.mounting_place_size)"
                    :errors="formErrors"
                    prop="mounting_place_size"
                    label="Wymiary miejsca montażu - Wysokość X Szerokość X Długość w cm"
                  >
                    <el-input
                      v-model="form.heater.mounting_place_size"
                      v-validate="rules.heater.mounting_place_size"
                      name="mounting_place_size"
                      type="text"
                    />
                  </custom-form-item>
                </div>
                <div>
                  <custom-form-item
                    :required="checkRequired(rules.heater.doors_size)"
                    :errors="formErrors"
                    prop="doors_size"
                    label="Wymiary drzwi - Wysokość x Szerokość w cm"
                  >
                    <el-input
                      v-model="form.heater.doors_size"
                      v-validate="rules.heater.doors_size"
                      name="doors_size"
                      type="text"
                    />
                  </custom-form-item>
                </div>
              </div>
            </div>
          </fieldset>
        </el-card>
        <el-card
          v-loading="loading"
          class="mt-3"
        >
          <title-card name="Finanse - formularz" />
          <fieldset>
            <div class="row">
              <div class="col-lg-3 col-xl-2">
                <h6>Finanse</h6>
              </div>
              <div class="col-lg-9 col-xl-10">
                <custom-form-item
                  :required="checkRequired(rules.heater.payment_type)"
                  :errors="formErrors"
                  prop="payment_type"
                  label="Rodzaj płatności"
                >
                  <el-select
                    v-model="form.heater.payment_type"
                    v-validate="rules.heater.payment_type"
                    :filterable="true"
                    class="w-100"
                    name="payment_type"
                    @change="updateVAT"
                  >
                    <el-option
                      v-for="(item,index) in typeFinancial"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    />
                  </el-select>
                </custom-form-item>
                <custom-form-item
                  :required="checkRequired(rules.heater.vat)"
                  :errors="formErrors"
                  prop="vat"
                  label="Rodzaj VAT"
                >
                  <el-select
                    v-model="form.heater.vat"
                    v-validate="rules.heater.vat"
                    :filterable="true"
                    class="w-100"
                    name="vat"
                    @change="updateVAT"
                  >
                    <el-option
                      v-for="(item,index) in vatType"
                      :key="index"
                      :label="item.name"
                      :value="item.value"
                    />
                  </el-select>
                </custom-form-item>
                <custom-form-item
                  :required="checkRequired(rules.heater.price_netto)"
                  :errors="formErrors"
                  prop="price_netto"
                  label="Kwota Netto"
                >
                  <input-number
                    v-model="form.heater.price_netto"
                    v-validate="rules.heater.price_netto"
                    :precision="2"
                    class="w-100"
                    name="price_netto"
                    type="number"
                    @change="updateNetto"
                  />
                </custom-form-item>
                <custom-form-item
                  :required="checkRequired(rules.heater.price_netto)"
                  :errors="formErrors"
                  prop="price_brutto"
                  label="Kwota brutto"
                >
                  <input-number
                    v-model="form.heater.price_brutto"
                    v-validate="rules.heater.price_netto"
                    :precision="2"
                    class="w-100"
                    name="price_brutto"
                    type="number"
                    @change="updateBrutto"
                  />
                </custom-form-item>

                <div class="row">
                  <div class="col-md-12 col-lg-6 col-xl-6">
                    <custom-form-item
                      v-if="form.heater.payment_type === 2 || form.heater.payment_type === 1"
                      :required="checkRequired(rules.heater.payment_credit_tranche)"
                      :errors="formErrors"
                      prop="payment_credit_tranche"
                      label="Kwota kredytu"
                    >
                      <input-number
                        v-model="form.heater.payment_credit_tranche"
                        v-validate="rules.heater.payment_credit_tranche"
                        :precision="2"
                        class="w-100"
                        name="payment_credit_tranche"
                        type="number"
                        @change="updateVAT"
                      />
                    </custom-form-item>
                  </div>
                </div>
                <hr v-if="form.heater.payment_type === 0 || form.heater.payment_type === 2">
                <div
                  v-if="form.heater.payment_type === 0 || form.heater.payment_type === 2"
                  class="row"
                >
                  <div class="col-md-12 col-lg-4 col-xl-4">
                    <custom-form-item
                      :required="checkRequired(rules.heater.payment_tranche_1)"
                      :errors="formErrors"
                      prop="payment_tranche_1"
                      label="Transza 1"
                    >
                      <input-number
                        v-model="form.heater.payment_tranche_1"
                        v-validate="rules.heater.payment_tranche_1"
                        :precision="2"
                        class="w-100"
                        name="payment_tranche_1"
                        type="number"
                      />
                    </custom-form-item>
                  </div>
                  <div class="col-md-12 col-lg-4 col-xl-4">
                    <custom-form-item
                      :required="checkRequired(rules.heater.payment_tranche_2)"
                      :errors="formErrors"
                      prop="payment_tranche_2"
                      label="Transza 2"
                    >
                      <input-number
                        v-model="form.heater.payment_tranche_2"
                        v-validate="rules.heater.payment_tranche_2"
                        :precision="2"
                        class="w-100"
                        name="payment_tranche_2"
                        type="number"
                      />
                    </custom-form-item>
                  </div>
                  <div class="col-md-12 col-lg-4 col-xl-4">
                    <custom-form-item
                      :required="checkRequired(rules.heater.payment_tranche_3)"
                      :errors="formErrors"
                      prop="payment_tranche_3"
                      label="Transza 3"
                    >
                      <input-number
                        v-model="form.heater.payment_tranche_3"
                        v-validate="rules.heater.payment_tranche_3"
                        :precision="2"
                        class="w-100"
                        name="payment_tranche_3"
                        type="number"
                      />
                    </custom-form-item>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </el-card>
      </div>
      <div class="col-3">
      </div>
    </div>
    <el-card
      class="mt-3"
    >
      <div class="d-flex justify-content-end">
        <div class="button-section">
          <el-button @click="$router.go(-1)">
            Powrót
          </el-button>
          <el-button
            :loading="loading"
            type="primary"
            @click="submit"
          >
            {{ isEdit ? 'Nadpisz' : 'Zapisz' }}
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  import forms from '@/shared/mixins/forms'
  import Dinero from 'dinero.js'
  import { formList } from '@/admin/pages/sales/config'
  import TitleCard from '@/admin/components/Title'
  import heaters from '@/admin/mixins/heaters'
  import SelectionCardHeatPump from '@/admin/pages/sales/components/SelectionCardHeatPump'

  export default {
    name: 'HeatingAgreement',
    components: { SelectionCardHeatPump, TitleCard },
    mixins: [
      forms, heaters
    ],
    props: {
      rules: {
        type: Object,
        required: true
      },
      type: {
        type: String,
        default: 'create'
      },
      form: {
        type: Object,
        required: true
      },
      step: {
        type: Number,
        default: 0
      },
      isEdit: {
        type: Boolean,
        required: true
      }
    },
    data () {
      return {
        loading: false
      }
    },
    computed: {
      typeFinancial () {
        return this.$config.agreementSettings.data.type_financial.settings
      },
      VATval () {
        if (this.form.heater.vat === 1) {
          return 1.08
        }
        if (this.form.heater.vat === 2) {
          return 1.23
        }
        return 0
      },

      vatType () {
        return [{ value: 1, name: '8%' },
                { value: 2, name: '23%' }
        ]
      },
      hasCellar () {
        return [{ value: false, name: 'Nie' },
                { value: true, name: 'Tak' }
        ]
      },
      installationCOList () {
        return [{ value: 1, name: 'Grzejniki' },
                { value: 2, name: 'Grzejniki+Ogrzewanie podłogowe' },
                { value: 3, name: 'Ogrzewanie podłogowe' },
                { value: 4, name: 'Inne' }
        ]
      },
      heaterList () {
        return [{ value: 1, name: 'Stalowe' },
                { value: 2, name: 'Aluminiowe' },
                { value: 3, name: 'Żeliwne' }
        ]
      },
      storeyTypes () {
        return [
          { value: 1, name: 'Parterowy' },
          { value: 2, name: 'Parterowy z poddaszem użytkowym' },
          { value: 3, name: 'Piętrowy' },
          { value: 4, name: 'Inny' }
        ]
      },
      isBoilerType () {
        return this.form.heater.type === 'boiler'
      },
      isHeaterPumpType () {
        return this.form.heater.type === 'heat_pump'
      }
    },
    mounted () {
      if (this.isEdit) {
        this.updateNetto(this.form.heater.price_netto)
      }
      this.form.heater.type = (this.form.type_product === 3) ? 'heat_pump' : 'boiler'
    },
    methods: {
      submit () {
        this.loading = true
        this.$validator.validate().then(valid => {
          if (valid) {
            this.$emit('submit-data')
            this.loading = false
            this.form.heater = Object.assign({}, formList.heater)
          } else {
            this.loading = false
            this.$message.error('Uzupełnij wszystkie pola przed wysłaniem formularza.')
          }
        })
      },
      updateNetto (val) {
        this.form.heater.price_brutto = val * this.VATval
        this.updateTranches(val, 'netto')
        if (this.form.heater.payment_type === 1) {
          this.updateCreditPrice(val, 'netto')
        }
      },
      updateBrutto (val) {
        this.form.heater.price_netto = val / this.VATval
        this.updateTranches(val, 'brutto')
        if (this.form.heater.payment_type === 1) {
          this.updateCreditPrice(val, 'brutto')
        }
      },
      updateVAT (val) {
        this.updateNetto(this.form.heater.price_netto)
        this.updateBrutto(this.form.heater.price_brutto)
      },
      prepareCost (price, type) {
        let cost = Dinero({ amount: Math.round(price * 100), precision: 2 })
        if (type === 'netto') {
          cost = cost.multiply(this.VATval)
        }
        if (this.form.heater.payment_type === 2) {
          const creditCost = Dinero({ amount: Math.round(this.form.heater.payment_credit_tranche * 100), precision: 2 })
          cost = cost.subtract(creditCost)
        }
        return cost
      },
      updateCreditPrice (price, type) {
        const cost = this.prepareCost(price, type)
        this.form.heater.payment_credit_tranche = cost.getAmount() / 100
      },
      updateTranches (price, type) {
        const cost = this.prepareCost(price, type)
        this.form.heater.payment_tranche_1 = cost.multiply(0.3).getAmount() / 100 // 30
        this.form.heater.payment_tranche_2 = cost.multiply(0.5).getAmount() / 100 // 50
        this.form.heater.payment_tranche_3 = cost.multiply(0.2).getAmount() / 100 // 20
      }
    }
  }
</script>

<style scoped>
fieldset > .row > div:first-child {
  background: #97d700 !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
</style>
