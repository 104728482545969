<template>
  <div>
    <el-card class="box-card">
      <div class="d-flex justify-content-between flex-lg-row flex-xl-row flex-column flex-nowrap align-items-center">
        <div class="order-1 p-2 bd-highlight ml-10">
          <div class="header-block">
            <h4>{{ $root.title }} - {{ item.name }}</h4>
          </div>
        </div>
        <div class="order-2">
          <el-button
            v-if="$can('admin.calculations.companies.update')"
            class="button-mobile-app"
            type="primary"
            @click="actionEdit"
          >
            <i class="far fa-edit"></i> Edytuj
          </el-button>
          <el-button
            v-if="$can('admin.calculations.companies.delete')"
            class="button-mobile-app"
            type="danger"
            @click="actionDelete"
          >
            <i class="far fa-trash-alt"></i> Usuń
          </el-button>
          <el-button
            class="button-mobile-app"
            @click="actionList"
          >
            <i class="fas fa-long-arrow-alt-left"></i> Powrót
          </el-button>
        </div>
      </div>
    </el-card>

    <div class="row mt-3">
      <div class="col-md-9">
        <el-tabs
          v-model="activeName"
          v-loading="loading"
          type="border-card"
        >
          <el-tab-pane
            label="Użytkownicy"
            name="first"
          >
            <div class="my-4">
              <h3 class="py-0 my-0">
                Przypisanie do zestawów firmy (Użytkownicy)
              </h3>
              <hr>
            </div>
            <div class="row">
              <div class="col ">
                <template>
                  <el-transfer
                    v-model="value"
                    :data="dataForm"
                    :format="{
                      noChecked: '${total}',
                      hasChecked: '${checked}/${total}'
                    }"
                    :button-texts="['Odepnij', 'Przypnij']"
                    :titles="['Do dodania', 'Dodano']"
                    filterable
                    @change="submitUsers"
                  />
                </template>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Oddziały"
            name="three"
          >
            <div class="my-4">
              <h3 class="py-0 my-0">
                Przypisanie do zestawów firmy (Oddziały)
              </h3>
              <hr>
            </div>
            <div class="row">
              <div class="col ">
                <template>
                  <el-transfer
                    v-model="valueBranch"
                    :data="dataFormBranch"
                    :format="{
                      noChecked: '${total}',
                      hasChecked: '${checked}/${total}'
                    }"
                    :button-texts="['Odepnij', 'Przypnij']"
                    :titles="['Do dodania', 'Dodano']"
                    filterable
                    @change="submitBranch"
                  />
                </template>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <commissions
          v-if="$can('admin.calculations.commissions.read') && item"
          :commissions="item.commissions"
          @update-commissions="updateCommissions"
          @add-commissions="addCommissions"
        />
        <product-managament
          v-if="item && item.productable"
          :id="id"
          :table="item.productable.data"
          class="mt-3"
          @update-table="updateProductable"
        />
        <el-card class="mt-3">
          <div>
            <h3 class="my-0">
              Zarządzanie falownikami
            </h3>
            <hr>
          </div>
          <div>
            <div
              v-for="element in inverterList"
              class="d-flex inverters-list justify-content-between align-items-center"
            >
              <div>{{ element.name }}</div>
              <div>
                <el-tag type="primary">
                  {{ element.pivot.cost }}
                </el-tag>
              </div>
            </div>
            <div v-if="!inverterList.length">
              Brak danych o falownikach.
            </div>
          </div>
        </el-card>
        <el-card class="mt-3">
          <div>
            <h3 class="my-0">
              Zarządzanie paneli
            </h3>
            <hr>
          </div>
          <div
            v-for="element in panelList"
            class="panels-list d-flex justify-content-between align-items-center"
          >
            <div>{{ element.name }}</div>
          </div>
          <div v-if="!panelList.length">
            Brak danych o panelach.
          </div>
        </el-card>
      </div>
      <div class="col-md-3">
        <el-card
          v-if="item"
          v-loading="loading"
        >
          <div class="text-center">
            <img
              :src="item.logo_company"
              width="225px"
            >
            <div class="text-center">
              <small>  {{ item.name }}</small>
            </div>
          </div>
        </el-card>
        <el-card class="mt-3">
          <div class="title">
            <h3 class="my-0">
              Domyślny dla podpięcia
            </h3>
            <hr>
          </div>
          <div class="buttons">
            <custom-form-item
              prop="name"
              label="Czy ustawić jako domyślny dla użytkowników?"
            >
              <el-switch
                v-model="item.default_assign"
                active-text="Tak"
                name="name"
                inactive-text="Nie"
                @change="updateDefaultAssign"
              />
            </custom-form-item>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { deleteRequest, getRequest, patchRequest } from '@/shared/utils/http'
  import Commissions from '@/admin/pages/calculations/children-pages/companies/components/Commissions'
  import ProductManagament from '@/admin/pages/calculations/children-pages/companies/components/ProductManagament'

  export default {
    name: 'CompanyShow',
    components: { ProductManagament, Commissions },
    data () {
      return {
        dataForm: [],
        dataFormGroup: [],
        dataFormBranch: [],
        value: [],
        valueBranch: [],
        valueGroup: [],
        loading: true,
        inverterList: [],
        panelList: [],
        activeName: 'first',
        item: null
      }
    },
    computed: {
      id () {
        return parseInt(this.$route.params.id)
      }
    },
    mounted () {
      this.$root.title = 'Karta firmy'

      getRequest('/api/users-all')
        .then((response) => {
          this.responseServer = response.data
          this.dataForm = this.responseServer.map(item => {
            return {
              key: item.id,
              label: item.firstName + ' ' + item.lastName
            }
          })
        })

      getRequest('/api/groups')
        .then((response) => {
          this.dataFormGroup = response.data.map(item => {
            return {
              key: item.id,
              label: item.name
            }
          })
        })
      getRequest('/api/branches')
        .then((response) => {
          this.dataFormBranch = response.data.map(item => {
            return {
              key: item.id,
              label: item.name
            }
          })
        })

      this.companiesData()
    },
    methods: {
      companiesData () {
        getRequest('/api/companies/' + this.id)
          .then((response) => {
            this.item = response
            this.panelList = response.panels
            this.inverterList = response.inverters
            this.value = this.item.users
            this.valueBranch = this.item.branches
            this.valueGroup = this.item.groups
            this.loading = false
          }).catch((response) => {
            this.loading = false
          })
      },
      submitUsers (val) {
        this.loading = true
        patchRequest(`/api/companies/${this.id}`, { users: val })
          .then(response => {
            this.$message({
              message: 'Użytownicy zostali zaktualizowani.',
              type: 'success'
            })
            this.value = response.users
            this.valueBranch = response.branches
            this.valueGroup = response.groups
            this.loading = false
          })
      },
      submitGroup (val) {
        this.loading = true
        patchRequest(`/api/companies/${this.id}`, { groups: val })
          .then(response => {
            this.$message({
              message: 'Grupy i użytkownicy zostali zaktualizowani.',
              type: 'success'
            })
            this.value = response.users
            this.valueBranch = response.branches
            this.valueGroup = response.groups
            this.loading = false
          })
      },
      submitBranch (val) {
        this.loading = true
        patchRequest(`/api/companies/${this.id}`, { branches: val })
          .then(response => {
            this.$message({
              message: 'Użytownicy i oddziały zostali zaktualizowani.',
              type: 'success'
            })
            this.value = response.users
            this.valueBranch = response.branches
            this.valueGroup = response.groups
            this.loading = false
          })
      },
      actionEdit () {
        this.$router.push({ name: 'admin.calculations.companies.update', params: { id: this.id } })
      },
      actionDelete: function () {
        this.$confirm('Czy na pewno chcesz usunąć?', 'Uwaga', {
          confirmButtonText: this.label,
          cancelButtonText: this.cancelLabel,
          type: 'warning'
        })
          .then(() => {
            this.$emit('requestStart')
            deleteRequest('/api/companies/' + this.id)
              .then(() => {
                this.$emit('success')
                this.$router.push({ name: 'admin.calculations.companies.index' })
              })
              .catch(() => {
                this.$emit('fail')
              })
          })
          .catch(() => {
          })
      },
      actionList () {
        this.$router.push({ name: 'admin.calculations.companies.index' })
      },
      updateDocumentTemplate (value) {
        patchRequest(`/api/companies-patch/${this.id}`, { document_template_id: value })
          .then(response => {
            this.$message({
              message: 'Dokument został pomyślnie dopięty.',
              type: 'success'
            })
            this.value = response.users
            this.valueBranch = response.branches
            this.valueGroup = response.groups
            this.loading = false
          })
      },
      updateCommissions (val, index) {
        console.log([val, index])
        this.item.commissions[index] = val
      },
      addCommissions (val) {
        this.item.commissions.push(val)
      },
      updateProductable (data) {
        // this.item.producindextable.data.splice(index, 1)
        this.item = data
      },
      updateDefaultAssign (val) {
        this.loading = true
        patchRequest(`/api/companies-patch/${this.id}`, { default_assign: val })
          .then(response => {
            this.$message({
              message: 'Firma została zaktualizowana',
              type: 'success'
            })
            this.value = response.users
            this.valueBranch = response.branches
            this.valueGroup = response.groups
            this.loading = false
          })
      }
    }
  }
</script>

<style scoped>
.inverters-list, .panels-list {
  padding-top:10px;
  padding-bottom:10px;
  border-bottom: 1px solid #eaeaea;
}

.inverters-list:hover, .panels-list:hover {
  transition: 0.3s;
  background: #eaeaea;
}
</style>
