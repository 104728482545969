
.attachment{
  border-right: 1px solid #abe338;
}
.attachment--buttons {
  min-width: 3rem;
  bottom: 0px;
  right: 0px;
}
.el-upload-dragger {
  .fas {
    font-size: 67px;
    color: #c0c4cc;
    margin: 40px 0 16px;
    line-height: 50px;
  }
}
