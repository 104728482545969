<template>
  <div class="mt-5">
    <block-button>
      <i class="fas fa-file"></i>
      <div class="data-box">2019-01-01</div>
    </block-button>
    <div>
      <h6 class="mt-1 pt-1 text-center">Umowa klienta {{ number }}</h6>
      <div class="text-center">
        <el-tag size="mini">Status</el-tag>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AutentiCard',
    props: {
      number: {
        type: Number,
        default: 0
      }
    }
  }
</script>

<style scoped>
    .data-box{
        position: relative;
        font-size: 12px;
        bottom: -20px;
    }
</style>
