<template>
  <el-container>
    <el-header style="text-align: right; font-size: 12px">
      <el-button
        v-if="enablePrint"
        type="primary"
        @click="print">
        Drukuj
      </el-button>
      <el-button
        v-else
        disabled
        type="primary">
        Trwa renderowanie...
      </el-button>
    </el-header>
    <el-main id="printMe">
      <div class="container">
        <div v-html="template"></div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
  import Editor from '@tinymce/tinymce-vue'
  import html2canvas from 'html2canvas'
  import LineChart from '@/admin/components/charts/LineChart'
  import {getRequest} from '@/shared/utils/http'
  import $Scriptjs from 'scriptjs'

  export default {
    name: 'Index',
    components: {LineChart, Editor},
    data () {
      return {
        enablePrint: true,
        output: null,
        fullScreenLoading: false,
        template: '',
        dataCenter: '',
        dataAzimuth: '',
        dataArea: '',
        calculator: {
          map: ''
        }
      }
    },
    computed: {
      slug () {
        return this.$route.params.slug
      },
      getImgBarChar () {
        let monthStr = this.months.map(x => "'" + x + "'").toString()
        return `https://quickchart.io/chart?width=300&height=680&c={type:'bar',data:{labels:[${monthStr}], datasets:[{label:'Nasłonecznienie',backgroundColor:'%23bcc102',data:[${this.solarData}]},{label:'Energia produkowana',backgroundColor:'%2397d700',data:[${this.monthData}]}]}}`
      }
    },

    mounted () {
      // this.fullScreen()
      getRequest('/api/external-calculation/' + this.slug)
        .then((response) => {
          this.template = response.template
        })
    },
    methods: {
      fullScreen () {
        this.fullScreenLoading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
      },
      print () {
        // Pass the element id here
        this.$htmlToPaper('printMe')
      },
      printMap () {
        html2canvas(document.querySelector('#mapCalculable'), {useCORS: true}).then(canvas => {
          console.log(canvas.toDataURL('image/png'))
          document.querySelector('#mapCalculable').innerHTML = '<img src="' + canvas.toDataURL('image/png') + '"/>'
        })
        this.enablePrint = true
        setTimeout(() => { this.fullScreenLoading.close() }, 800)
      },
      initMap () {
        this.calculator.map = new google.maps.Map(document.getElementById('mapCalculable'), {
          center: this.dataCenter,
          zoom: 19,
          disableDefaultUI: true,
          clickableIcons: false,
          zoomControl: true,
          // scrollwheel: false,
          fullscreenControl: true,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT
          },
          controlSize: 26,
          mapTypeId: google.maps.MapTypeId.HYBRID,
          tilt: 0,
          mapTypeControl: false
        })

        this.calculator.map.addListener('idle', () => {
          setTimeout(() => { this.printMap() }, 1500)
        })

        const flightPlanCoordinates = this.dataAzimuth

        const flightPlanCoordinates2 = this.dataArea
        const lineSymbol = {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        }

        const flightPath = new google.maps.Polyline({
          path: flightPlanCoordinates,
          icons: [
            {
              icon: lineSymbol,
              offset: '100%'
            }
          ],
          geodesic: true,
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 4
        })

        const bermudaTriangle = new google.maps.Polygon({
          paths: flightPlanCoordinates2,
          strokeColor: '#298115',
          strokeOpacity: 0.8,
          strokeWeight: 4,
          fillColor: '#05e509',
          fillOpacity: 0.45
        })

        bermudaTriangle.setMap(this.calculator.map)
        flightPath.setMap(this.calculator.map)
      }
    }
  }
</script>

<style scoped>
body {
  background: #fff !important;
}
.full-height-card{
  height: 100%;
}
.my-auto{
  margin-top: auto;
  margin-bottom: auto;
}
.el-header{
  background: #f3f3f3;
  padding:10px
}
#map {
  width: 100%;
  height: 250px;
}
</style>
