<template>
  <section
    v-if="item"
    class="heat-pump-offer-show"
  >
    <el-card v-loading="loading">
      <div class="d-flex justify-content-between flex-lg-row flex-xl-row flex-column flex-nowrap align-items-center">
        <div
          v-if="item.id"
          class="offer-information"
        >
          <div class="d-flex align-items-center">
            <div><i class="fsize fas fa-fire"></i></div>
            <div class="title-box">
              <h1 class="my-0">
                Kalkulacja pompy ciepła nr #{{ item.id }}
              </h1>
            </div>
          </div>
        </div>
        <div>
          <el-button
            type="danger"
            @click="handleDelete"
          >
            Usuń ofertę
          </el-button>
          <el-button @click="$router.go(-1)">
            Powrót
          </el-button>
        </div>
      </div>
    </el-card>
    <div class="row mt-3">
      <div class="col-md-12 col-lg-3">
        <el-card>
          <el-button
            type="primary"
            class="w-100"
            @click="createElementCalculation"
          >
            Dodaj nową ofertę
          </el-button>
        </el-card>
        <company-block
          class="mt-2"
          :company="item.company"
          @change-company="updateCompany"
        />
        <offers-list
          v-if="item.offers"
          :items="item.offers"
        />
        <contractor
          v-if="item.contractor"
          class="mt-2"
          :item="item.contractor"
          :contractor-type="item.contractor.type"
        />
        <user-block
          resource="admin.heat-pump-offers.update"
          type="heat-pump-offers"
          :object-id="item.id"
          permission="admin.heat-pump-offers.update"
          :item="item.user"
        />
      </div>
      <div class="col-md-12 col-lg-9">
        <div
          v-if="item.elements"
          class="row calculations"
        >
          <calculation-item
            v-for="(element,index) in item.elements.data"
            :key="index"
            :item="element"
            :index-offer="index"
            :loading="false"
            @update-offers="updateOffers"
            @delete-element="deleteElement"
            @add-element="addDuplicated"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import TitleCard from '@/admin/components/Title'
  import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from '@/shared/utils/http'
  import Contractor from '@/admin/components/Contractor'
  import UserBlock from '@/admin/components/User'
  import MapOffer from './components/Map'
  import Attachments from '@/admin/components/Attachments'
  import OffersList from './components/OffersList'
  import CalculationItem from '@/admin/pages/heat-pump-calculations/components/CalculationItem'
  import CompanyBlock from '@/admin/pages/heat-pump-calculations/components/company-block'

  export default {
    name: 'Show',
    components: { CompanyBlock, CalculationItem, OffersList, Attachments, UserBlock, Contractor, TitleCard, MapOffer },
    data () {
      return {
        activeName: 'first',
        item: null,
        showDialog: false,
        newPackageId: null,
        loading: false,
        newPumpId: null,
        isActive: null,
        isActivePump: null,
        editComponentShowDialog: false,
        newComponent: null,
        selectedComponents: [],
        index: null,
        showDialogAddon: false,
        newAddon: null
      }
    },
    computed: {
      id () {
        return parseInt(this.$route.params.id)
      }
    },
    mounted () {
      this.$root.title = 'Wyświetl - Kalkulacja Pomp Ciepła'
      this.fetchData()
    },
    methods: {
      fetchData () {
        getRequest('/api/heat-pump-calculations/' + this.id).then(response => {
          this.item = response.data
          this.newPackageId = response.data.package_id
          this.newPumpId = response.data.pump_id
          // this.isActive = this.packages.findIndex(e => {
          //   return e.id === response.data.package_id
          // })
          // this.isActivePump = this.pumps.findIndex(e => {
          //   return e.id === response.data.pump_id
          // })
        }).catch(error => {
          alert('error')
        })
      },
      handleDelete () {
        this.loading = true
        deleteRequest('/api/heat-pump-calculations/' + this.id)
          .then(response => {
            this.$router.push({ name: 'admin.heat-pump-calculations.index' })
          })
          .catch(error => {
            this.$snotify.error('Nie można usunąć oferty.')
          })
      },
      updateOffers (data, index) {
        this.item.data[index] = data
      },
      createElementCalculation () {
        getRequest('/api/heat-pump-calculations/' + this.id + '/create-element')
          .then(response => {
            this.item = response.data
          })
          .catch(error => {

          })
      },
      deleteElement (index) {
        console.log('will be deleted: ' + index)
        console.log(this.item.elements.data)
        this.item.elements.data.splice(index, 1)
        console.log(this.item.elements.data)
      },
      addDuplicated (element) {
        this.item.elements.data.push(element)
      },
      updateCompany (val) {
        patchRequest('/api/heat-pump-calculations/' + this.id, {
          company_id: val
        })
          .then(response => {
            this.item = response.data
          })
      }
    }
  }
</script>

<style scoped>
.el-button + .el-button {
  margin-left: 0px !important;
}
.title-box{
  margin-left: 20px;
}
.fsize{
  font-size: 3rem;
}
.button-select-item{
  padding: 10px 0px;
}
</style>
