

.user-contact-data{
  font-size: 13px;
  color: #a0a0a0;

}
.receivers-block{
  position: relative;
  margin-bottom: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(192, 192, 192);
  border-image: initial;
  border-radius: 4px;
  transition: border-color 0.2s ease 0s;
  padding: 16px 16px 32px;
  .main-block{
    .user-block{
      .user-detail{

      }
      .type-sign{
        padding-left:30px;
      }
    }
  }
}
.receivers{
  margin-bottom: 5px;
}
.upload-box{
  font-size: 67px;
  color: #c0c4cc;
  margin: 40px 0 16px;
  line-height: 50px;
}

.element-persons{
  border-bottom: 1px solid #cfcfcf;
}
