<template>
  <div class="header">
    <div
      class="w-100-el-card d-flex justify-content-between align-items-center"
    >
      <div class="d-none d-lg-inline d-xl-inline search-box ml-3">
        <global-search />
        <tools-dropdown
          v-if="$can('admin.tools.read')"
        />
      </div>
      <div class="mobile-button p-3">
        <el-button
          class="d-xl-none d-lg-none d-sm-block d-xs-block d-md-block"
          type="text"
          @click="openMenu"
        >
          <i class="fas fa-bars"></i>
        </el-button>
      </div>
      <div>
        <el-tooltip
          class="item"
          effect="dark"
          content="Odśwież pamięć aplikacji"
          placement="top"
        >
          <el-button
            type="text"
          >
            <i class="far fa-envelope"></i>
          </el-button>
          <el-button
            type="text"
            @click="resetPageFromServer"
          >
            <i class="fas fa-sync"></i>
          </el-button>
        </el-tooltip>
        <app-notifications
          ref="notifications"
          @set-notification="openNotification"
        />
        <show-notification
          :dialog-visible="showNotification"
          :notification="notification"
          @close-dialog="resetNotification"
        />
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <el-tag size="mini">ID: {{ user.id }}</el-tag> <span class="d-md-down-none">{{ user.fullName }}</span> <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="$can('admin.user.switch-mode')">
              <el-switch
                v-if="user"
                v-model="user.mode"
                active-text="Użytk."
                inactive-text="Pełny"
                @change="switchUserMode"
              />
            </el-dropdown-item>
            <el-dropdown-item v-if="$can('admin.acl.read')">
              <router-link
                :to="{name: 'admin.user.my-account'}"
                class="el-button el-button--text"
              >
                Moje konto
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item
              onclick="event.preventDefault();document.getElementById('logout-form').submit()"
            >
              <a
                href="/logout"
                class="dropdown-item"
              >
                <i class="fas fa-sign-out-alt"></i>
                Wyloguj
              </a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <form
        id="logout-form"
        action="/logout"
        method="POST"
        style="display: none;"
      >
        <input
          :value="csrfToken"
          type="hidden"
          name="_token"
        >
      </form>
    </div>
    <el-drawer
      :visible.sync="showMobileMenu"
      :before-close="closeMenu"
      title="Menu"
      direction="ltr"
    >
      <el-menu
        :default-active="$route.path"
        unique-opened
        router
        class="el-menu-vertical-demo"
      >
        <el-menu-item
          v-if="$can('admin.dashboard.read')"
          index="/admin/dashboard"
        >
          <i class="el-icon-menu"></i>
          <span>Dashboard</span>
        </el-menu-item>
        <el-menu-item
          v-if="$can('admin.surveys.read')"
          index="/admin/surveys"
        >
          <i class="el-icon-document"></i>
          <span>Ankiety</span>
        </el-menu-item>
        <el-menu-item
          v-if="$can('admin.lead.read')"
          index="/admin/lead"
        >
          <i class="el-icon-setting"></i>
          <span>Leady</span>
        </el-menu-item>
        <el-menu-item
          v-if="$can('clients.read')"
          index="/admin/clients"
        >
          <i class="el-icon-setting"></i>
          <span>Klienci</span>
        </el-menu-item>
        <el-menu-item
          v-if="$can('admin.offers.read')"
          index="/admin/offers"
        >
          <i class="el-icon-setting"></i>
          <span>Oferty</span>
        </el-menu-item>
        <el-menu-item
          v-if="$can('admin.calculations.read')"
          index="/admin/calculations"
        >
          <i class="el-icon-setting"></i>
          <span>Kalkulacje</span>
        </el-menu-item>
        <el-menu-item
          v-if="$can('admin.sales.read')"
          index="/admin/sales"
        >
          <i class="el-icon-setting"></i>
          <span>Sprzedaż</span>
        </el-menu-item>
        <el-menu-item
          v-if="$can('admin.news.read')"
          index="/admin/news"
        >
          <i class="el-icon-setting"></i>
          <span>Wiadomości</span>
        </el-menu-item>
        <el-menu-item
          v-if="$can('admin.tickets.read')"
          index="/admin/tickets"
        >
          <i class="el-icon-setting"></i>
          <span>Wsparcie</span>
        </el-menu-item>
        <el-menu-item
          v-if="$can('admin.user.read')"
          index="/admin/users"
        >
          <i class="el-icon-setting"></i>
          <span>Pracownicy</span>
        </el-menu-item>
        <el-menu-item
          v-if="$can('admin.files.read')"
          index="/admin/files"
        >
          <i class="el-icon-setting"></i>
          <span>Baza plików</span>
        </el-menu-item>
        <el-menu-item
          v-if="$can('admin.logs.read')"
          index="/admin/logs"
        >
          <i class="el-icon-setting"></i>
          <span>Logi</span>
        </el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import Avatar from 'vue-avatar'
  import VueClock from '@dangvanthanh/vue-clock'
  import ShowNotification from '@/admin/plugins/Notifications/ShowNotification'
  import { getRequest, postRequest } from '@/shared/utils/http'
  import GlobalSearch from '@/admin/components/GlobalSearch'
  import CreatorDropdown from '@/admin/components/CreatorDropdown'
  import ToolsDropdown from '@/admin/components/ToolsDropdown'

  export default {
    name: 'AppHeader',
    components: {
      ToolsDropdown,
      CreatorDropdown,
      GlobalSearch,
      ShowNotification,
      Avatar,
      VueClock
    },
    data () {
      return {
        showMobileMenu: false,
        notification: {},
        showNotification: false,
        query: '',
        userMode: true
      }
    },

    computed: {
      ...mapGetters({
        sidebar: 'app/sidebar',
        user: 'app/user'
      }),

      csrfToken: () => window.csrfToken
    },

    watch: {
      'user.mode' (newVal) {
        console.log(newVal)
      }
    },

    methods: {
      ...mapActions({
        toggleSideBar: 'app/toggleSideBar'
      }),
      goToCreate (link) {
        this.$router.push({ path: link })
      },
      resetPageFromServer () {
        window.location.href = window.location.href
      },
      openMenu () {
        this.showMobileMenu = true
      },
      closeMenu () {
        this.showMobileMenu = false
      },
      openNotification (val) {
        this.notification = {}
        this.notification = val
        if (this.notification) {
          this.showNotification = true
          this.readNotification()
        }
      },
      resetNotification () {
        this.showNotification = false
      },
      readNotification () {
        getRequest('/api/notifications/' + this.notification.id)
          .then(response => {})
      },
      switchUserMode (val) {
        postRequest('/api/users/switch-mode', { mode: val })
          .then(response => {
            const text = val ? 'Użytkownik' : 'Pełny'
            this.$snotify.success('Tryb zmieniony na ' + text)
            this.resetPageFromServer()
          })
          .catch(error => {
            this.$snotify.error('Błąd ze zmianą trybu pracy')
          })
      }
    }
  }
</script>
<style scoped>
.el-card__body {
  padding: 0px !important;
}
.mobile-button{
  margin-left: 20px;
}

</style>
