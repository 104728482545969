.aside {
  //width: 200px;
  transition: width 0.31s;
  background: #3f4443;

  .page-logo,
  .page-logo-hidden {
    background: #3f4443;
    height: 60px;
    color: #fff;
    padding: 10px 15px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
  }

  .el-menu {
    border-right: none;
    border-top: 1px solid #f3ede9 !important;
    color: #3a3430 !important;
    border-right: none !important;
    background: #3f4443 !important;

    .el-submenu__title,
    .el-menu-item {
      color: map_get($colors, light);
      transition: background-color 0.5s ease-in-out;
      border-bottom: 1px solid #545252;

      i {
        color: map_get($colors, light);
      }

      &:hover, &:focus, &.is-active {
        color: map_get($colors, primary);
        //background: map_get($colors, primary); //hover?
        background: map_get($colors, active-grey);
        i {
          color: map_get($colors, primary);
        }
      }
    }
  }

  .page-logo-hidden {
    display: none;
  }

  &.sidebar-hidden {
    width: 64px !important;

    .page-logo {
      display: none;
    }
    .page-logo-hidden {
      display: block;
    }
  }
}
@media screen and (max-width: 992px) {
  .aside{
    display: none !important;
  }
}
.icon-dashboard {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -7px;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  font-size: 12px;
}


// MENU SECTION

.el-menu-item {
  font-size: 13px;
  height: 39px !important;
  line-height: 39px !important;
}

.el-submenu__title {
  font-size: 13px;
  line-height: 39px !important;
  height: 39px !important;
}

//.el-submenu {
//  font-size: 13px;
//  line-height: 39px !important;
//  height: 39px !important;
//}