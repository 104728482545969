<template>
  <div>
    <div class="row">
      <div class="col">
        <h3>Lista zadań</h3>
        <p>System kontroli zadań w firmie</p>
      </div>
      <div
        class="col"
        style="padding-right: 35px;">
        <div
          class="row mb-5 mr-5"
          style="float:right">
          <el-button
            @click="centerDialogVisible = true"><i class="fas fa-calendar"></i> Kalendarz</el-button>
          <el-button
            @click="drawerTest = true"><i class="fas fa-calendar"></i> DRAWER</el-button>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12">
        <el-card>
          <div slot="header">
            Lista zadań
          </div>
          <div>
            <div class="mb-3">
              <filter-bar>
                <el-form-item
                  prop="type"
                  label="Typ zadania">
                  <checkbox-group
                    v-model="formFilters.type"
                    :items="typeCheckbox"
                    :check-all-field="true"
                    name="type"/>
                </el-form-item>

                <el-form-item
                  prop="important"
                  label="Piorytet">
                  <checkbox-group
                    v-model="formFilters.important"
                    :items="importantCheckbox"
                    :check-all-field="true"
                    name="important"/>
                </el-form-item>

                <el-form-item
                  prop="date_end"
                  label="Ustawienie wyświetlania">
                  <checkbox-group
                    v-model="formFilters.date_end"
                    :items="timeCheckbox"
                    :check-all-field="true"
                    name="date_end"/>
                </el-form-item>

              </filter-bar>
            </div>

            <vuetable class="table-scroll"
              v-loading="table.loading"
              ref="vuetable"
              v-bind="table.options"
              :fields="fields"
              :append-params="tableState.moreParams"
              v-on="table.events"
            >
              <template
                slot="complete"
                slot-scope="props"
                style="text-align: center"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Zakończ zadanie"
                  placement="top">
                  <i
                    class="far fa-check-circle complete-icon"
                    style="font-size: 22px;
    color: #bdbdbd;
    margin-top: 6px;"
                    @click.stop=""></i>
                </el-tooltip>
              </template>
              <template
                slot="actions"
                slot-scope="props">
                <div class="row-actions">
                  <avatar
                    :inline="true"
                    :size="35"
                    :username="props.rowData.user_name"
                    class="ml-5"/>
                </div>
              </template>
            </vuetable>
            <div class="vuetable-pagination row justify-content-between">
              <div class="col">
                <vuetable-pagination-info
                  ref="paginationInfo"
                  :info-template="table.pagination.infoTemplate"
                  :no-data-template="table.pagination.noDataTemplate"
                />
              </div>

              <div class="col">
                <vuetable-pagination
                  ref="pagination"
                  :css="table.pagination.css"
                  v-on="table.pagination.events"
                />
              </div>

              <div class="col-auto">
                <per-page
                  :items="tableState.perPage"
                  @changed="onChangePerPage"
                />
              </div>
            </div>
        </div></el-card>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogFormVisible"
      title="Dodaj nowe zadanie">
      <el-form
        v-loading="loading"
        ref="taskForm"
        :model="form"
        label-position="top"
        @submit.native.prevent="handleForm('taskForm')"
      >
        <form-alerts :messages="invalidMsg"/>

        <div class="row">
          <div class="col">
            <custom-form-item
              :errors="formErrors"
              prop="date_start"
              label="Data rozpoczecia">
              <el-date-picker
                id="date_start"
                v-bind="$config.datepicker"
                :picker-options="$config.datepickerOptions"
                v-model="form.date_start"
                format="yyyy-MM-dd HH:mm:SS"
                value-format="yyyy-MM-dd HH:mm:ss"
                name="date_start"
                type="date"
                unlink-panels/>
            </custom-form-item>
          </div>
          <div class="col">
            <custom-form-item
              :errors="formErrors"
              prop="date_end"
              label="Data zakończenia">
              <el-date-picker
                id="date_end"
                v-bind="$config.datepicker"
                :picker-options="$config.datepickerOptions"
                v-model="form.date_end"
                format="yyyy-MM-dd HH:mm:SS"
                value-format="yyyy-MM-dd HH:mm:ss"
                name="date_end"
                type="date"
                unlink-panels/>
            </custom-form-item>
          </div>
          <div class="col">
            <custom-form-item
              :errors="formErrors"
              prop="type"
              label="Kategoria">
              <el-select
                v-model="form.type"
                placeholder="Kategoria">
                <el-option
                  v-for="( item, index ) in category"
                  :key="index"
                  :label="item.name"
                  :value="index"/>
              </el-select>
            </custom-form-item>
          </div>
          <div class="col">
            <custom-form-item
              :errors="formErrors"
              prop="status"
              label="Status">
              <el-select
                v-model="form.status"
                placeholder="Status">
                <el-option
                  v-for="(item, index ) in status"
                  :key="index"
                  :label="item.name"
                  :value="index"/>
              </el-select>
            </custom-form-item>
          </div>
          <div class="col">
            <custom-form-item
              :errors="formErrors"
              prop="important"
              label="Piorytet">
              <el-select
                v-model="form.important"
                placeholder="Wybierz">
                <el-option
                  v-for="(item, index ) in priority"
                  :key="index"
                  :label="item.name"
                  :value="index"/>
              </el-select>
            </custom-form-item>
          </div>
        </div>

        <custom-form-item
          :errors="formErrors"
          prop="name"
          label="Temat wiadomości">
          <el-input
            v-model="form.name"
            name="name"
            type="text"/>
        </custom-form-item>

        <custom-form-item
          :errors="description"
          prop="description"
          label="Opis zadania">
          <el-input
            v-model="form.description"
            name="description"
            type="textarea"/>
        </custom-form-item>

        <div
          v-if="form.type == 2"
          class="row">
          <div class="col">
            <custom-form-item
              :errors="formErrors"
              prop="offer_id"
              label="Wybierz ofertę z systemu">
              <el-input
                v-model="form.offer_id"
                name="offer_id"
                type="text"/>
            </custom-form-item>
          </div>
          <div class="col">
            <custom-form-item
              :errors="formErrors"
              prop="client_id"
              label="Wybierz klienta z systemu">
              <el-input
                v-model="form.client_id"
                name="client_id"
                type="text"/>
            </custom-form-item>
          </div>
        </div>

        <div style="margin-bottom:15px">
          <el-checkbox v-model="form.calendar_add">Zapisać w kalendarzu?</el-checkbox>
        </div>

        <el-form-item>
          <el-button @click="dialogFormVisible = false">Anuluj</el-button>
          <el-button
            type="primary"
            @click="submitForm">{{ isEdit ? 'Edytuj' : 'Dodaj' }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="`Szczegóły zadania: ` + formDetails.name "
      :visible.sync="displayDetails">
      <div class="row">
        <div class="col">
          <el-button
            size="mini"
            round>Zakończ zadanie</el-button>
          <el-tooltip
            class="item"
            effect="dark"
            content="Edytuj zadanie"
            placement="top">
            <el-button
              icon="far fa-edit"
              size="mini"
              circle
              @click="editTask"/>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Usuń zadanie"
            placement="top">
            <el-button
              icon="fas fa-minus"
              type="danger"
              size="mini"
              plain
              circle/>
          </el-tooltip>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="row">
            <div class="col-5">
              <div class="row mt-2">
                <div
                  class="col"
                  style="display: inherit;">
                  <avatar
                    :inline="true"
                    :size="35"
                    :username="formDetails.user_name"
                    class="ml-5 mr-5 mt-3"/>
                  <dl>
                    <dt>
                      Utworzył
                    </dt>
                    <dd>
                      {{ formDetails.user_name }}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="col-3">
              <dl>
                <dt>
                  Kategoria
                </dt><dd>
                  <el-button
                    type="info"
                    size="mini"
                    on>
                  <i :class="task_category.icon"></i> {{ task_category.name }}</el-button>
                </dd>
              </dl>
            </div>
            <div class="col-4">
              <dl>
                <dt>
                  Status
                </dt><dd>
                  <el-button
                    :type="task_status.type"
                    size="mini"
                    plain>
                  <i :class="task_status.icon"></i> {{ task_status.name }}</el-button>
                </dd>
              </dl>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <dl>
                <dt>
                  Oferta
                </dt>
                <dd>
                  <a href="">Oferta #1232</a>
                </dd>
              </dl>
            </div>
            <div class="col-7">
              <dl>
                <dt>
                  Klient
                </dt>
                <dd>
                  <a href="">{{ formDetails.user_name }}</a>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="col">
          <dl>
            <dt>
              Rozpoczęto
            </dt>
            <dd>
              {{ formDetails.date_start | moment($config.date_format.short) }}
            </dd>
          </dl>
          <dl>
            <dt>
              Termin
            </dt>
            <dd>
              {{ formDetails.date_end | moment($config.date_format.short) }}
            </dd>
          </dl>
          <dl>
            <dt>
              Piorytet
            </dt>
            <dd>
              <el-button
                :type="task_priority.type"
                size="mini"
              >
              <i :class="task_priority.icon"></i> {{ task_priority.name }}</el-button>
            </dd>
          </dl>
        </div>
      </div>
      <div class="row mt-10">
        <div class="col">
          <custom-form-item
            prop="description"
            label="Opis zadania">
            <el-input
              :rows="3"
              v-model="formDetails.description"
              name="description"
              type="textarea"
              cols="100%"
              placeholder="Opis zadania"
              disabled
            />
          </custom-form-item>
        </div>
      </div>

    </el-dialog>
    <drawer-data
      :drawer="drawerTest"
      :data="formDetails"
      direction="rtl"
      @afterCloseDrawer="afterCloseDrawer"
    />
  </div>
</template>

<script>
  import { objToCheckboxArray } from '@/shared/utils/helpers'
  import { postRequest, putRequest, getRequest } from '@/shared/utils/http'
  import moment from 'moment'
  import { fields, sorting, filters, formFields } from './config'
  import Tables from '@/shared/mixins/tables'
  import Avatar from 'vue-avatar'
  import DrawerData from './components/DrawerData'
  import cloneDeep from 'lodash-es/cloneDeep'

  export default {
    name: 'MainTask',
    components: {Avatar, DrawerData},
    mixins: [Tables],

    data: () => {
      return {
        isEdit: false,
        drawerTest: false,
        table: {
          options: {
            'api-url': '/api/tasks-list'
          }
        },
        status: [],
        category: [],
        priority: [],
        fields: fields,
        formFilters: filters,
        dialogFormVisible: false,
        dialogShowDetails: false,
        displayDetails: false,
        task_category: '',
        task_status: '',
        task_priority: '',
        formDetails: {},
        showForm: {
          name: ''
        },
        form: Object.assign({}, formFields),
        todoList: [],
        todayDate: ''
      }
    },

    computed: {
      typeCheckbox () {
        return objToCheckboxArray(this.category_list)
      },
      importantCheckbox () {
        return objToCheckboxArray(this.important)
      },
      timeCheckbox () {
        return objToCheckboxArray(this.timeStatus)
      }

    },

    created () {
      this.$root.title = 'Lista zadań'

      this.setInitialOptions({ sorting: cloneDeep(sorting), filters: cloneDeep(filters) })

      this.todayDate = moment(String(new Date())).format('DD.MM.YYYY')
      this.status = this.$config.tasks.status
      this.category = this.$config.tasks.category
      this.priority = this.$config.tasks.priority
      this.category_list = this.$config.tasks.category_list
      this.important = this.$config.tasks.priority_list
      this.timeStatus = this.$config.tasks.time_status
    },
    methods: {
      getList: function () {
        getRequest('/api/tasks-list')
          .then((response) => {
            this.todoList = response.data
          })
      },
      submitForm: function () {
        return new Promise((resolve, reject) => {
          let request
          if (this.isEdit == true) {
            request = putRequest('/api/tasks/' + this.form.id, this.form)
          } else {
            request = postRequest('/api/tasks', this.form)
          }

          request
            .then(response => {
              this.isEdit = false
              this.$refs.vuetable.reload()
              this.$emit('saved')
              this.dialogFormVisible = false
              resolve(response)
            })
            .catch(error => {
              reject(error)
              // })
            })
        })
      },
      transformDate: function (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      },
      onRowClicked (e) {
        this.category_task(e.data.type)
        this.status_task(e.data.status)
        this.priority_task(e.data.important)
        this.formDetails = e.data
        this.drawerTest = true
      },
      category_task (id) {
        this.task_category = this.$config.tasks.category[id]
      },
      status_task (id) {
        this.task_status = this.$config.tasks.status[id]
      },
      priority_task (id) {
        this.task_priority = this.$config.tasks.priority[id]
      },
      editTask () {
        this.isEdit = true
        this.form = this.formDetails
        this.displayDetails = false
        this.dialogFormVisible = true
      },

      afterCloseDrawer: function () {
        this.drawerTest = false
      }

    }
  }
</script>
<style>

  .item-task {
    border-bottom:1px solid #dedede;
    padding: 15px;
    cursor: pointer;
    transition: 0.6s;
  }
  .item-task:hover{
    background: #e0e0e0 !important;
  }

</style>
