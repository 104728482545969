<template>
  <div>
    <el-select
      v-if="!edit"
      v-model="val"
      v-validate="validate"

      :loading="isLoading"
      :filterable="true"
      :clearable="true"
      :remote="true"
      :remote-method="autocompleteMethod"
      :name="name"
      :disabled="disabled"
      class="mb-3"
      reserve-keyword
      style="width:100%"
      @change="emitChange"
    >
      <el-option
        v-for="(item,index) in cities"
        :key="index"
        :label="item.city"
        :value="item"
        class="el-select-dropdown__item-custom"
      >
        <span>{{ item.city }}, woj: {{ item.voivodeship }}, pow: {{ item.county }}, gmin: {{ item.community }},</span>
      </el-option>
    </el-select>
    <el-input
      v-else
      v-model="value"
      :disabled="edit"
    />
  </div>
</template>

<script>
  import { getRequest, postRequest } from '@/shared/utils/http'

  export default {
    name: 'AutocompleteCity',

    props: {
      group: {
        type: String,
        default: 'all',
        validator: (value) => {
          return ['admin', 'agency', 'all'].includes(value)
        }
      },
      name: {
        type: String,
        required: true
      },
      validate: {
        type: Object,
        default: () => {
        }
      },
      value: {
        type: Number | String,
        default: 0
      },
      disabled: {
        type: Boolean,
        default: true
      },
      edit: {
        type: Boolean,
        default: false
      },
      province: {
        type: String,
        default: null
      },
      initial: {
        type: Object,
        default: () => {
        }
      }
    },

    data () {
      return {
        cities: [],
        isLoading: false,
        val: null,
        searchable: true
      }
    },

    computed: {
      url () {
        return '/api/teryt/city'
      }
    },

    watch: {
      'value' (nextVal) {
        if (!this.cities.length) {
          this.val = nextVal
        // this.cities.push({
        //   city: nextVal,
        //   voivodeship: null,
        //   county: null,
        //   community: null,
        //   addons: null
        // })
        }
      }
    },

    mounted () {
      this.$nextTick(function () {
        if (this.initial) {
        }
      })
    },

    methods: {
      emitChange (val) {
        this.$emit('input', val)
        this.searchable = false
        this.val = this.val.city
      },

      autocompleteMethod (query) {
        this.isLoading = true
        this.cities = []
        if (query.length >= 3) {
          postRequest(this.url, {
            city: query,
            province: this.province
          })
            .then(response => {
              this.isLoading = false
              this.cities = response.map(item => {
                return {
                  city: item.name,
                  voivodeship: item.voivodeship,
                  county: item.county,
                  community: item.community,
                  addons: item.city_addons
                }
              })
            })
            .catch(error => {
              this.isLoading = false
              console.error(error)
            })
        }
      }
    }
  }
</script>
<style scoped>
.el-select-dropdown__item-custom span:last-child {
  color: #6b6d6d;
}
</style>
