
<script>
  import {Line, Bar} from 'vue-chartjs'
  export default {
    name: 'BarChart',
    extends: Bar,
    data () {
      return {
        chartData: {
          labels: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
          datasets: [
            {
              label: 'Energia generowana',
              data: [],
              fill: true,
              borderColor: '#97d700',
              backgroundColor: '#97d700',
              borderWidth: 0.5
            },
            {
              label: 'Nasłonecznienie',
              data: [],
              fill: true,
              borderColor: '#b39e16',
              backgroundColor: '#bcc102',
              borderWidth: 0.5
            }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [ {
              gridLines: {
                display: true
              }
            }]
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted () {
      this.chartData.datasets[0].data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 12]
      this.chartData.datasets[1].data = [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
      this.renderChart(this.chartData, this.options)
    }
  }
</script>
<style scoped>
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Aligner-item {
  max-width: 50%;
}

.Aligner-item--top {
  align-self: flex-start;
}

.Aligner-item--bottom {
  align-self: flex-end;
}
</style>
