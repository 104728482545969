<template>
  <el-card>
    <div class="row">
      <div
        v-if="agreements.length == 0"
        class="col-12">
        <el-alert
          title="Brak umów dla tego podmiotu."
          type="warning"/>
      </div>
      <div
        v-for="item in agreements"
        class="col-lg-12 col-md-12 col-xl-3 order-lg-1 order-xl-1">
        <agreement-button
          :agreement_id="item.id"
          class="mt-lg-3"
          icon="fas fa-file-alt"
        >
          {{ item.agreement_number }}
          <span slot="desc">Przejdź do umowy</span>
          <div class="value">
            <el-tag
              effect="plain"
              type="success">
              {{ item.price_brutto }} PLN
            </el-tag>
          </div>
        </agreement-button>
      </div>
    </div>
  </div></el-card>
</template>

<script>
import AgreementButton from './AgreementButton'

export default {
    name: 'AgreementList',
    components: {
      AgreementButton
    },
    props: {
      agreements: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {

      }
    }
  }
</script>

<style scoped>
.value{
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 15px;
}
</style>
