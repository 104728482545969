<template>
  <section>
    <el-form
      ref="productForm"
      :model="form"
      label-position="top"
      @submit.native.prevent="handleForm('productForm')"
    >
      <el-card v-if="$can('admin.sales.switch')">
        <div class="row">
          <div class="col-12 my-auto">
            <div class="d-flex justify-content-between align-content-center">
              <div>
              </div>
              <div>
                <el-button
                  v-if="$store.state.app.agreement.step > 2 && $store.state.app.agreement.step <= 6 && form.type_product === 1"
                  type="primary"
                  size="small"
                  @click="previousPage"
                >
                  <i class="fas fa-arrow-left"></i>
                </el-button>
                <el-button
                  v-if="$store.state.app.agreement.step > 1 && $store.state.app.agreement.step <= 5 && form.type_product === 1"
                  type="primary"
                  size="small"
                  @click="nextPage"
                >
                  <i class="fas fa-arrow-right"></i>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <client-create
        v-if="$store.state.app.agreement.step === 0"
        :form="form"
        :rules="rules"
        @nextPage="nextPage"
        @previousPage="previousPage"
      />

      <select-product-type
        v-if="$store.state.app.agreement.step === 1"
        :form="form"
        @nextPage="nextPage"
        @previousPage="previousPage"
      />

      <goods-sales
        v-if="$store.state.app.agreement.step === 4 && form.type_product === 4"
        :form="form"
        :is-edit="isEdit"
        @submit-form="submitForm"
      />

      <choose-offer
        v-if="$store.state.app.agreement.step === 2"
        :offers="offers"
        :form="form"
        @nextPage="nextPage"
        @previousPage="previousPage"
      />
      <address-create
        v-if="$store.state.app.agreement.step === 3"
        :is-edit="isEdit"
        :form="form"
        :rules="rules"
        :loading="isLoading"
        @nextPage="nextPage"
        @previousPage="previousPage"
        @save-draft="submitDraftForm"
      />
      <pv-agreement
        v-if="form.type_product === 1"
        :form="form"
        :rules="rules"
        @actualStep="setActualStep"
        @save-draft="submitDraftForm"
      />

      <select-agreement-provider
        v-if="$store.state.app.agreement.step === 4 && form.type_product === 2 || $store.state.app.agreement.step === 4 && form.type_product === 3 || $store.state.app.agreement.step === 4 && form.type_product === 6"
        :form="form"
        @nextPage="nextPage"
        @previousPage="previousPage"
        @save-draft="submitDraftForm"
      />
      <p-v-aneks
        v-if="form.type_product === 2 && $store.state.app.agreement.step === 5"
        :form="form"
        :is-edit="isEdit"
        :rules="rules"
        @submit-data="submitForm"
      />
      <selection-card-heat-pump
        v-if="(form.type_product === 3 && $store.state.app.agreement.step === 5)"
        :form="form"
        :rules="rules"
        @save-draft="submitDraftForm"
        @next-page="nextPage"
        @previous-page="previousPage"
      />
      <heating-agreement
        v-if="(form.type_product === 3 && $store.state.app.agreement.step === 6) || (form.type_product === 6 && $store.state.app.agreement.step === 5)"
        :form="form"
        :is-edit="isEdit"
        :rules="rules"
        @submit-data="submitForm"
      />
      <transition name="slide-fade">
        <product-summary
          v-if="$store.state.app.agreement.step === 7"
          :is-edit="isEdit"
          :form="form"
          @update-allow="canSaveProduct"
        />
      </transition>
      <el-card
        v-if="$store.state.app.agreement.step === 7"
        class="col-12 mt-5"
      >
        <div>
          <div class="text-end">
            <el-button
              @click="previousPage"
            >
              Cofnij
            </el-button>
            <el-button
              :disabled="!allowCreateProduct"
              type="primary"
              native-type="submit"
            >
              {{ isEdit ? 'Edytuj produkt' : 'Utwórz produkt' }}
            </el-button>
          </div>
        </div>
      </el-card>
    </el-form>
  </section>
</template>
<script>

  import SelectProductType from '@/admin/pages/sales/components/SelectProductType'
  import forms from '@/shared/mixins/forms'
  import PvAgreement from '@/admin/pages/sales/components/products/PVAgreement'
  import ClientCreate from '@/admin/pages/sales/components/ClientCreate'
  import AddressCreate from '@/admin/pages/sales/components/AddressCreate'
  import { products as pvagreementRules } from '@/admin/utils/validation'
  import ProductSummary from '@/admin/pages/sales/components/ProductSummary'
  import { getRequest, postRequest, putRequest } from '@/shared/utils/http'
  import PVAneks from '@/admin/pages/sales/components/products/PVAneks'
  import HeatingAgreement from '@/admin/pages/sales/components/products/HeatingAgreement'
  import SelectAgreementProvider from '@/admin/pages/sales/components/SelectAgreementProvider'
  import ChooseOffer from '@/admin/pages/sales/components/ChooseOffer'
  import GoodsSales from '@/admin/pages/sales/components/GoodsSales'
  import { formList } from '@/admin/pages/sales/config'
  import SelectionCardHeatPump from '@/admin/pages/sales/components/SelectionCardHeatPump'

  export default {
    name: 'FormProduct',
    components: {
      SelectionCardHeatPump,
      GoodsSales,
      ChooseOffer,
      HeatingAgreement,
      PVAneks,
      ProductSummary,
      SelectProductType,
      ClientCreate,
      AddressCreate,
      PvAgreement,
      SelectAgreementProvider
    },
    mixins: [forms],
    props: {
      form: {
        type: Object,
        required: true
      },
      isEdit: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        default: true
      },
      offers: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        showCreateButtonCard: false,
        allowCreateProduct: false
      }
    },
    computed: {
      currentId: function () {
        return this.isEdit ? this.$route.params.id : 0
      },
      rules: function () {
        if (this.isEdit === true) {
          if (this.form.type_product === 1) {
            return pvagreementRules.pvAgreement(this.currentId, this.form)
          }
          if (this.form.type_product === 2) {
            return pvagreementRules.pvAneks(this.currentId, this.form)
          }
          if (this.form.type_product === 3) {
            return pvagreementRules.pvHeater(this.currentId, this.form)
          }
          if (this.form.type_product === 4) {
            return pvagreementRules.pvGoods(this.currentId, this.form)
          }
          if (this.form.type_product === 6) {
            return pvagreementRules.pvHeater(this.currentId, this.form)
          }
        }
        return pvagreementRules.pvDefault(this.currentId, this.form)
      }
    },

    watch: {
      'form.offer_id' (newValue) {
        if (this.form.type_product !== 1) return
        this.$snotify.info('Rozpoczęto pobieranie danych o ofercie')
        getRequest('/api/offers/' + newValue + '/sale-creator')
          .then(response => {
            const offer = response.data
            this.form.offer = offer
            this.form.addons.price_brutto = offer.price_brutto
            this.form.addons.price_netto = offer.price_with_construction
            this.form.offer_generate_date = offer.created_at
            this.form.company_id = offer.company_id
            this.form.addons.power_installation = offer.kits.kwp
          })
          .catch(errorResponse => {
            this.$snotify.error('Problem z pobraniem infomracji o ofercie')
          })
      }
    },

    mounted () {
      if (this.$route.params.id_client !== undefined) {
        this.form.client_id = this.$route.params.id_client
        if (this.$store.state.app.agreement.calculation_id !== null) {
        // todo:here
        }
        this.$store.dispatch('app/incrementStep')
      } else {
        this.$store.dispatch('app/defaultStep')
      }
    },
    methods: {
      nextPage: function () {
        this.$store.dispatch('app/incrementStep')
        if (this.isEdit === true) {
          if (this.$store.state.app.agreement.step === 2) {
            this.$store.dispatch('app/incrementStep')
          }
        }
      },
      previousPage: function () {
        this.$store.dispatch('app/decrementStep')
      },
      setActualStep: function (val) {
        this.step = val
      },
      submitForm: function () {
        return new Promise((resolve, reject) => {
          let request
          if (this.isEdit) {
            request = putRequest('/api/sales/' + this.$route.params.id, this.form)
          } else {
            request = postRequest('/api/sales', this.form)
          }
          request
            .then(response => {
              delete this.form
              this.$emit('saved')
              this.$store.dispatch('app/resetCalculationMemory')
              this.$store.dispatch('app/defaultStep')
              this.$router.push({
                name: 'admin.sales.show',
                params: { id: response.data.id }
              })
              this.loading = false
              this.resetForm()
              resolve(response)
            })
            .catch(error => {
              this.errorBlock = true
              this.loading = false
              this.$message.error('Nie wszystko wypełnione!')
              this.$snotify.error(error.data.message)
              reject(error)
            })
        })
      },
      submitDraftForm () {
        return new Promise((resolve, reject) => {
          let request
          if (this.isEdit) {
            request = putRequest('/api/sales/' + this.$route.params.id + '/update-draft', this.form)
          } else {
            request = postRequest('/api/sales/create-draft', this.form)
          }
          request
            .then(response => {
              this.isLoading = false
              this.$emit('saved')
              if (!this.isEdit) {
                this.$snotify.success('Umowa zostala zapisana w wersje roboczą')
                this.$router.push(
                  {
                    name: 'admin.sales.update',
                    params: { id: response.data.id }
                  })
              } else {
                this.$snotify.success('Umowa została nadpisana w systemie.')
              }
              this.resetForm()
              resolve(response)
            })
            .catch(error => {
              this.isLoading = false
              this.$snotify.error('Błąd podczas zapisywania wersji roboczej!')
              reject(error)
            })
        })
      },
      canSaveProduct (val) {
        this.allowCreateProduct = val
      },
      resetForm () {
        this.$emit('reset-form')
      }
    }
  }
</script>

<style scoped>
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
