<template>
  <dl>
    <dt>{{ nameModule }}</dt>
    <dd v-if="showChangedValue">
      <el-popover
        :content="showChangedValue"
        placement="top-start"
        title="Ostatnie zmiany"
        width="200"
        trigger="hover">
        <span slot="reference">
          <el-badge
            is-dot
            class="item">
            <strong>{{ showDefaultValue }} </strong>
          </el-badge>
          <slot name="button-section"></slot>
        </span>
      </el-popover>
    </dd>
    <dd v-else>{{ showDefaultValue }}
      <slot name="button-section"></slot>
    </dd>
  </dl>
</template>

<script>
  export default {
    name: 'CustomAgreementValue',
    props: {
      nameModule: {
        type: String,
        default: ''
      },
      defaultValue: {
        type: String | Object | Number,
        default: ''
      },
      changedValue: {
        type: String,
        default: ''
      }
    },
    computed: {
      showChangedValue () {
        if (this.changedValue === '') {
          return ''
        }
        if (this.changedValue.hasOwnProperty('label')) {
          return 'Wartość zmieniona na: ' + this.changedValue.label
        }
        return 'Wartość zmieniona na: ' + this.changedValue
      },

      showDefaultValue () {
        if (this.defaultValue.hasOwnProperty('label')) {
          return this.defaultValue.label
        }

        return this.defaultValue
      }
    }
  }
</script>

<style scoped>
.el-badge__content.is-fixed.is-dot {
  right: 0px !important;
}
</style>
