.el-dropdown-link {
  cursor: pointer;
}

.el-dropdown-menu__item {
  a {
    color: inherit;

    &:hover, &:focus {
      text-decoration: none;
    }
  }
}

.el-form {
  &--label-top {
    label {
      margin-bottom: 0;
    }
  }
}

.el-card__header {
  > .clearfix {
    > span,
    > h2 {
      display: inline-block;
      min-height: 1.6rem;
    }
  }
}

.el-button {
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: none;
  }
}

.el-breadcrumb__item {
  .el-breadcrumb__inner {
    color: $color-text-link;
    font-weight: 500;
  }

  &:last-child .el-breadcrumb__inner {
    &, &:hover, a, a:hover {
      color: $color-text-dark;
      font-weight: 700;
    }
  }

  &.is-link {
    .el-breadcrumb__inner {
      cursor: pointer;
    }
  }
}

.el-picker-panel {
  [slot="sidebar"],
  &__sidebar {
    width: 145px;

    & + .el-picker-panel__body {
      margin-left: 145px;
    }
  }
}

.el-form-item {
  margin-bottom: 1.8rem;

  &__content {
    line-height: 1.5;
    .form-text {
    }
  }
}

.el-autocomplete {
  width: 100%;

  &-suggestion__list {
    li {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      border-top: 1px solid rgba($light-dark, 0.3);

      &:first-child {
        border-top: 0;
      }

      .value {
        font-weight: 700;
      }
    }
  }
}

.el-menu-item,
.el-submenu__title {
  i + span {
    margin-left: 5px;
  }
}

.el-card {
  &__header {
    border-bottom: 1px solid $border-color;
  }
}

.el-select {
  &--short {
    width: 80px;
  }
}

.el-dialog {
  &__body {
    img {
      max-width: 100%;
    }

    .el-dialog--center & {
      text-align: center;
    }
  }
}


.header-list-text{
  margin-bottom: 0px !important;
}

a {
  color: #97d700 !important;
}