<template>
  <div>
    <el-card
      v-loading="loading"
      class="mt-3"
    >
      <el-row style="text-align: right;">
        <el-button @click="$router.go(-1)">
          Powrót
        </el-button>
      </el-row>
    </el-card>
    <el-card
      v-loading="loading"
      class="mt-3"
    >
      <div
        class="row"
      >
        <div class="col-lg-12 col-md-12 col-xl-2 order-lg-1 order-xl-1 mt-3">
          <div>
            <h5>Dodatkowe opcje</h5>
            <hr style="border-top: 6px solid #97d700">
          </div>
          <div class="mt-3">
            <dl>
              <dt>Zapotrzebowanie mocy</dt>
              <dd>
                <el-tag type="info">
                  Potrzebna moc minimalna: {{ element.inputs ? element.inputs.pvgis.power_needed : '' }} kWp
                </el-tag>
              </dd>
            </dl>
            <hr>
            <dl>
              <dt>Fazy</dt>
              <dd>
                <el-tag type="info">
                  Ilość faz: {{ element.inputs ? element.inputs.phase : '' }}
                </el-tag>
              </dd>
            </dl>
            <hr>
            <dl v-if="element.inputs.pvgis.roof_degree > 0">
              <dt>Kąt dachu</dt>
              <dd>
                <el-tag type="info">
                  {{ element.inputs ? element.inputs.pvgis.roof_degree : '' }} [*]
                </el-tag>
              </dd>
            </dl>
            <hr v-if="element.inputs.pvgis.roof_degree">
            <dl>
              <dt>Rabat</dt>
              <dd>
                <el-switch
                  v-model="discount"
                  active-text="Z rabatem 5%"
                  inactive-text="bez rabatu"
                />
              </dd>
            </dl>
            <hr>
            <dl>
              <dt>Opodatkowanie</dt>
              <dd>
                <el-switch
                  v-model="vat"
                  active-text="23% VAT"
                  inactive-text="8% VAT"
                />
              </dd>
            </dl>
            <hr>
            <dl>
              <dt>Cena końcowa</dt>
              <dd>
                <el-switch
                  v-model="showTypePrice"
                  active-text="Kwoty netto"
                  inactive-text="Kwota brutto"
                />
              </dd>
            </dl>
          </div>
          <div>
            <div>
              <h5>Dane z PVGIS lokalizacyjne</h5>
              <hr style="border-top: 6px solid #97d700">
            </div>
            <div class="mt-3">
              <dl>
                <dt>Produkcja roczna energii z 1 kWp</dt>
                <dd>{{ element.inputs ? element.inputs.pvgis.energy_production : '' }} kWh</dd>
              </dl>
              <dl>
                <dt>Nasłonecznienie roczne</dt>
                <dd>{{ element.inputs ? element.inputs.pvgis.insulation : '' }} kWh/m2</dd>
              </dl>
              <dl>
                <dt>Straty ułożeniowe roczne</dt>
                <dd>{{ element.inputs ? element.inputs.pvgis.loss.position : '' }} %</dd>
              </dl>
              <dl>
                <dt>Straty Systemu roczne</dt>
                <dd>-6 %</dd>
              </dl>
              <dl>
                <dt>Straty temperaturowe roczne</dt>
                <dd>{{ element.inputs ? element.inputs.pvgis.loss.temperature : '' }} %</dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xl-10 order-lg-1 order-xl-1 mt-3">
          <div class="row">
            <div
              v-for="(item,index) in element.kits"
              class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1 mt-3"
            >
              <el-card
                :body-style="{padding: '0px'}"
                shadow="always"
              >
                <div style="position: absolute;top: 0px;left: 0px;">
                  <el-tag
                    v-if="item.addons_information"
                    type="danger"
                    class="w-100"
                    effect="dark"
                  >
                    {{ item.addons_information ? item.addons_information : 'Brak informacji' }}
                  </el-tag>
                </div>
                <div class="my-6">
                  <img
                    :src="item.provider_logo"
                    class="image mt-1"
                  >
                </div>
                <div style="padding: 14px;">
                  <div class="w-100 text-center py-3">
                    <el-select
                      v-model="item.construction"
                      :disabled="true"
                      class="w-100 calculator-select"
                      value-key="construction"
                      placeholder="Wybierz konstrukcje"
                      name="new_construction"
                      @change="changeKitBySolarConstruction(index, $event, item)"
                    >
                      <el-option
                        v-for="construction in item.constructions_list"
                        :key="construction.id"
                        :label="construction.name"
                        :value="construction.id"
                      />
                    </el-select>
                    <el-tag
                      v-if="discount"
                      effect="dark"
                      type="danger"
                      class="w-100 mt-2"
                    >
                      RABAT 5%
                    </el-tag>
                  </div>
                  <div>
                    <table class="table">
                      <tr>
                        <td>Moc zestawu</td><td>{{ item.kwp }} kWp</td>
                      </tr>
                      <tr>
                        <td>Fazy</td><td>{{ item.phase }}</td>
                      </tr>
                      <tr>
                        <td>Nazwa paneli</td>
                        <el-select
                          v-model="item.solar_panel_name"
                          :disabled="true"
                          value-key="solar_panel_name"
                          class="w-100 calculator-select"
                          placeholder="Wybierz Panel"
                          name="solar_panel_name"
                          @change="changeKitByPanel(index, $event, item)"
                        >
                          <el-option
                            v-for="item in item.addons_panels"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          />
                        </el-select>
                      </tr>
                      <tr>
                        <td>Ilość paneli</td>
                        <el-select
                          v-model="item.count_solar_panel"
                          :disabled="true"
                          value-key="kit"
                          class="w-100 calculator-select"
                          placeholder="Dostosuj zestaw"
                          name="new_addons_kits"
                          @change="changeKitBySolarKit(index, $event, item)"
                        >
                          <el-option
                            v-for="(kit,i) in item.addons_kits"
                            :key="i"
                            :label="kit.count_solar_panel"
                            :value="kit.id"
                          >
                            {{ kit.count_solar_panel + ' szt.' }} - {{ kit.kwp + ' kwp' }}
                          </el-option>
                        </el-select>
                      </tr>
                      <tr>
                        <td>Falownik domyśl.</td><td>{{ item.inverter }} - {{ item.count_inverter }} szt.</td>
                      </tr>
                      <tr>
                        <td>Kwota</td><td>
                          {{ getNettoPrice(item) }} PLN
                          <el-tag
                            type="success"
                            size="small"
                            effect="dark"
                          >
                            netto
                          </el-tag>
                        </td>
                      </tr>
                      <tr>
                        <td>Kwota opod.</td><td>
                          {{ getBruttoPrice(item) }} PLN
                          <el-tag
                            type="success"
                            size="small"
                            effect="dark"
                          >
                            brutto
                          </el-tag>
                          <el-tag
                            type="success"
                            size="small"
                            effect="dark"
                          >
                            {{ vat ? '23' : '8' }}%
                          </el-tag>
                        </td>
                      </tr>
                      <tr>
                        <td>Gwa. produktu</td><td>{{ item.warranty_product ? item.warranty_product : 'Brak informacji' }}</td>
                      </tr>
                      <tr>
                        <td>Gwa. wydajności</td><td>{{ item.warranty_performance ? item.warranty_performance : 'Brak informacji' }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <el-checkbox v-model="item.canChangeInverter">
                        Zmienić falownik?
                      </el-checkbox>
                      <el-checkbox v-model="item.canAddons">
                        Chcesz dodać dodatki?
                      </el-checkbox>
                      <div v-if="item.canChangeInverter">
                        <div class="my-6">
                          <span>Wybierz inny falownik</span>
                          <el-select
                            v-model="item.inverter_id"
                            :disabled="true"
                            value-key="inverter"
                            class="w-100 calculator-select"
                            placeholder="Falownik"
                            name="inverter"
                          >
                            <el-option
                              v-for="inverter in item.inverter_list"
                              :key="inverter.id"
                              :label="inverter.name"
                              :value="inverter.id"
                            >
                              {{ inverter.name }}
                            </el-option>
                          </el-select>
                        </div>
                        <hr>
                      </div>
                      <div
                        v-if="item.canAddons"
                        class="my-6"
                      >
                        <span>Dodaj dodatki do kalkulacji</span>
                        <el-select
                          v-model="item.current_addon"
                          :disabled="true"
                          value-key="addons"
                          class="w-100 calculator-select"
                          placeholder="Dodatki do oferty"
                          name="addons"
                          @change="addAddonsToInputs($event, index)"
                        >
                          <el-option
                            v-for="(addon,i) in item.addons_list"
                            :key="addon.id"
                            :label="addon.name"
                            :value="i"
                          >
                            {{ addon.name }} + {{ addon.cost }} PLN netto
                          </el-option>
                        </el-select>

                        <div
                          v-if="item.selected_addon.name !== ''"
                          class="list-container my-3"
                        >
                          <div class="row block-addons">
                            <div class="col-6 block-addons-auto">
                              <i class="fas fa-angle-right"></i>
                              <span>{{ item.selected_addon.count }} {{ item.selected_addon.unit }} - {{ item.selected_addon.name }}</span>
                            </div>
                            <div class="col-6">
                              <input-number
                                v-model="item.selected_addon.count"
                                :min="1"
                                :max="99"
                                controls-position="right"
                              />
                            </div>
                            <div class="col-12 my-3">
                              <el-button
                                type="primary"
                                class="w-100"
                                @click="addAddonsToTable(index)"
                              >
                                Dodaj do listy
                              </el-button>
                            </div>
                          </div>
                        </div>
                        <div class="my-3">
                          <div v-for="(element,i) in item.added_addons_list">
                            <el-tooltip
                              class="item text-red"
                              effect="dark"
                              content="Usuń ten element"
                              placement="top"
                            >
                              <i
                                style="cursor:pointer"
                                class="fas fa-times"
                                @click="deleteElementList(index, i)"
                              ></i>
                            </el-tooltip>
                            - {{ element.count }}x
                            {{ element.name | truncate(35) }}<el-tag type="success">
                              {{ element.cost }} PLN netto
                            </el-tag>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-6">
                    <div v-if="item.bonus">
                      {{ item.bonus ? item.bonus : 'Brak informacji' }}
                    </div>
                    <hr>
                    <div
                      v-if="item.solar_card || item.inverter_card"
                      class="row"
                    >
                      <div
                        v-if="item.solar_card"
                        class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1"
                      >
                        <download-button
                          :file_name="item.solar_card"
                          icon="fas fa-solar-panel"
                          name="Panel"
                        />
                      </div>
                      <div
                        v-if="item.inverter_card"
                        class="col-lg-6 col-xl-6 col-sm-12 order-lg-1 order-xl-1"
                      >
                        <download-button
                          :file_name="item.inverter_card"
                          icon="fas fa-window-restore"
                          name="Falownik"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="my-5">
                    <div class="text-center">
                      <h5>Kwota całkowita {{ showTypePrice ? 'netto' : 'brutto' }}</h5>
                    </div>
                    <el-tag
                      type="success"
                      style="    font-size: 25px;"
                      class="w-100 text-center"
                    >
                      {{ getFullPrice(item) }} PLN
                    </el-tag>
                  </div>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  import { getRequest, postRequest } from '@/shared/utils/http'
  import Dinero from 'dinero.js'
  import DownloadButton from '@/admin/pages/calculator/components/DownloadButton'

  export default {
    name: 'ViewOffers',
    components: { DownloadButton },
    data () {
      return {
        element: {
          inputs: {
            pvgis: {
              energy_production: '',
              insulation: '',
              loss: {
                position: '',
                temperature: ''
              },
              roof_degree: '',
              power_needed: ''
            }
          }
        },
        test1: '',
        showTypePrice: false,
        loading: true,
        discount: false,
        vat: false,
        kitSelected: 0,
        activeNames: '1'
      }
    },
    computed: {
      id () {
        return parseInt(this.$route.params.id)
      }
    },
    mounted () {
      this.loading = false
      this.$root.title = 'Archiwalna kalkulacja'
      getRequest('/api/archives/' + this.id)
        .then((response) => {
          this.element = response.data
          this.loading = false
        }).catch((response) => {
          this.$router.go(-1)
          this.loading = false
        })
    },
    methods: {
      BackToCalculator () {
        this.$emit('resetCalculator')
      },
      getAmountOffer (price) {
        return Dinero({ amount: Math.round(price * 100), precision: 2 }).getAmount() / 100
      },
      getNettoPrice (item) {
        let price = null
        if (!item) {
          console.log('Problem z wprowadzoną kwotą')
          return
        }
        price = (this.discount) ? item.price_construction_with_discount : item.price_construction

        return Dinero({ amount: Math.round(price * 100), precision: 2 }).getAmount() / 100
      },
      getBruttoPrice (item) {
        let price = null
        let priceConstruction = null
        let priceConstructionWithDiscount = null

        if (!item) {
          console.log('Problem z wprowadzoną kwotą')
          return
        }

        if (this.vat) {
          priceConstruction = item.price_construction_vat23
          priceConstructionWithDiscount = item.price_construction_with_discount_vat23
        } else {
          priceConstruction = item.price_construction_vat8
          priceConstructionWithDiscount = item.price_construction_with_discount_vat8
        }
        price = (this.discount) ? priceConstructionWithDiscount : priceConstruction

        return Dinero({ amount: Math.round(price * 100), precision: 2 }).getAmount() / 100
      },
      getFullPrice (item) {
        let addonsPrice = 0
        let price = null

        if (this.showTypePrice) {
          price = this.getNettoPrice(item)
        } else {
          price = this.getBruttoPrice(item)
        }

        const vat = (this.vat) ? 1.23 : 1.08

        item.added_addons_list.map(el => {
          if (this.showTypePrice) {
            addonsPrice = addonsPrice + (el.cost)
          } else {
            addonsPrice = addonsPrice + (el.cost * vat)
          }
        })
        addonsPrice = Dinero({ amount: Math.round(addonsPrice * 100), precision: 2 })
        price = Dinero({ amount: Math.round(price * 100), precision: 2 })

        if (this.discount === false) {
          addonsPrice = Dinero({ amount: Math.round(addonsPrice.getAmount() / 0.95), precision: 2 })
        }

        if (item.inverter_id !== item.inverterItem.id) {
          if (item.canChangeInverter === true) {
            let priceCurrentInverter = item.inverterItem.cost
            let priceChoosenInverter = this.getInverterCost(item.inverter_id)

            priceCurrentInverter = Dinero({ amount: Math.round(priceCurrentInverter * 100), precision: 2 })
            priceChoosenInverter = Dinero({ amount: Math.round(priceChoosenInverter * 100), precision: 2 })
            let differenceCost = priceCurrentInverter.subtract(priceChoosenInverter)

            if (this.discount === false) {
              differenceCost = Dinero({ amount: Math.round(differenceCost.getAmount() / 0.95), precision: 2 })
            }
            if (!this.showTypePrice) {
              differenceCost = differenceCost.multiply(vat)
            }

            price = price.subtract(differenceCost)
          } else {
            item.inverter_id = item.inverterItem.id
          }
        }

        return price.add(addonsPrice).getAmount() / 100
      },
      getInverterCost (inverterId) {
        let chosenInverterCost = ''
        this.$config.calculator.inverters.map((item) => {
          if (item.id === inverterId) {
            chosenInverterCost = parseFloat(item.cost)
          }
        })

        return chosenInverterCost
      },
      switchCurrentKit (index, kit) {
        this.$emit('update-offers', index, kit)
      },
      changeKitBySolarConstruction (index, kit, item) {
        this.$emit('update-by-construction-kit', index, kit, item)
      },
      changeKitBySolarKit (index, kit, item) {
        this.$emit('update-by-kit', index, kit, item)
      },
      changeKitByPanel (index, kit, item) {
        this.$emit('update-by-panel', index, kit, item)
      },
      addAddonsToInputs (indexAddons, indexKit) {
        const choosen_addon = this.offers[indexKit].addons_list[indexAddons]
        this.offers[indexKit].selected_addon.id = choosen_addon.id
        this.offers[indexKit].selected_addon.unit = choosen_addon.unit
        this.offers[indexKit].selected_addon.name = choosen_addon.name
        this.offers[indexKit].selected_addon.cost = choosen_addon.cost
        console.log(choosen_addon)
      },
      addAddonsToTable (indexKit) {
        const currentAddon = this.offers[indexKit].selected_addon

        if (currentAddon.count === '') {
          return false
        }
        this.offers[indexKit].added_addons_list.push({
          id: currentAddon.id,
          name: currentAddon.name,
          count: currentAddon.count,
          cost: currentAddon.cost * parseInt(currentAddon.count),
          unit: currentAddon.unit
        })
        this.$message.success('Dodano dodatek do zestawu')
        this.resetSelectedAddon(indexKit)
      },
      resetSelectedAddon (indexKit) {
        this.offers[indexKit].selected_addon.unit = ''
        this.offers[indexKit].selected_addon.name = ''
        this.offers[indexKit].selected_addon.cost = ''
        this.offers[indexKit].selected_addon.count = 1
        this.offers[indexKit].current_addon = null
      },
      deleteElementList (indexKit, i) {
        this.offers[indexKit].added_addons_list.splice(i, 1)
      }
    }
  }
</script>

<style scoped>
.el-select > .el-input.el-input--suffix input {
  background: #67c23a !important;
  color: white !important;
}
input.el-input__inner {
  background: #97d700 !important;
  color: white !important;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.image {
  width: 264px;
  height: 132px;
  margin: auto;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
.adv-block{
  position: absolute;
}
.carousel-3d-slide {
  background-color: #fff !important;
}

.block-addons{
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid #f3f3f3;
}
.block-addons-auto{
  margin-top: auto;
  margin-bottom: auto;
}
</style>
