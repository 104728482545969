<template>
  <div>
    <el-card
      v-loading="loading"
      class="mb-2">
      <div>
        <h4 class="my-0"><i class="fas fa-file-import"></i> Podpięcie masowe falowników pod firmy</h4>
      </div>
    </el-card>
    <el-card
      v-loading="loading"
      class="mb-2">
      <div class="mb-3">
        <h5 class="my-0"><i class="fas fa-file-import"></i> Importer falowników + podpięcie pod firmy</h5>
        <hr>
        <el-alert
          :closable="false"
          title="Uwaga!"
          type="info"
          show-icon>
          Program będzie działał tylko na falownikach, które zostaną wrzucone do pliku XLSX. Pozostałe, które są aktywne w systemie zostaną pominięte. Dla przykładu, gdy wstawimy tylko jeden
          rekord z ID Firmy 14 - wszystkie pozostałe zostaną usunięte. Pozostanie tylko jeden rekord.
        </el-alert>
      </div>
      <el-upload
        :auto-upload="true"
        :show-file-list="false"
        :on-success="handleFileSuccess"
        :on-error="handleFileError"
        :before-upload="beforeFileUpload"
        :action="apiUrl"
        class="upload-demo"
        drag>
        <form-alerts :messages="invalidMsg"/>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Przerzuć tutaj gotowy plik lub <em>kliknij</em></div>
        <div
          slot="tip"
          class="el-upload__tip">Maksymalnie przesyłany plik to 15MB</div>
      </el-upload>
    </el-card>
  </div>
</template>

<script>
  import forIn from 'lodash-es/forIn'
  import forms from '../../../../../shared/mixins/forms'
  export default {
    name: 'Import',

    mixins: [forms],

    data () {
      return {
        apiUrl: '/api/inverters/assign-import/upload',
        csv: {},
        uploadEndpoint: '',
        allowedFileSize: 15,
        allowedMimeTypes: this.$config.uploads.tickets.allowed_file_size,
        allowedFileTypes: this.$config.uploads.tickets.allowed_file_types,
        loading: false
      }
    },
    methods: {
      handleFileSuccess (res, file) {
        if ('error' in res) {
          this.$snotify.error(res.message)
        } else if (res.message) {
          this.$snotify.success(res.message)
          this.$emit('added', res.data)
        }
        this.loading = false
      },
      handleFileError (res) {
        const response = JSON.parse(res.message)
        if ('error' in res) {
          this.$snotify.error(response.message)
        }
        this.$snotify.error(response.message)

        this.loading = false
      },
      beforeFileUpload (file) {
        this.loading = true
        const isValidType = this.$config.uploads.tickets.allowed_mime_types.includes(file.type)
        const isValidSize = file.size / 1024 / 1024 < this.allowedFileSize // 3MB

        this.clearInvalidMsg()

        if (!isValidType) {
          this.$snotify.error('Plik jest w nieobsługiwanym formacie.')
        }
        if (!isValidSize) {
          this.$snotify.error(`Plik ma rozmiar większy niż ${this.allowedFileSize}MB! Dodaj mniejszy plik.`)
        }

        const isOK = isValidType && isValidSize
        if (isOK) {
          this.loading = true
          this.$snotify.info('System rozpoczyna wgrywanie zestawów i podpinanie ich. Pozostań na stronie.')
        }

        return isOK
      }
    }
  }
</script>

<style scoped>
.form-group {
  display: inline-block !important;
}
</style>
