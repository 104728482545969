<template>
  <section>
    <el-card
      v-loading="loading"
      class="box-card"
    >
      <div class="d-flex justify-content-between flex-lg-row flex-xl-row flex-column flex-nowrap align-items-center">
        <div class="order-1 p-2 bd-highlight ml-10">
          <div class="header-block">
            <h4>{{ $root.title }} - {{ item.full_name }}</h4>
            <span v-if="item.city !== null ">{{ item.full_address }}, {{ item.city }} {{ item.postcode }}</span>
            <div>
              <span v-if="item.call_date">
                Termin spotkania/kontaktu:
                <strong>{{ item.call_date }}</strong>
                <el-tag
                  type="danger"
                  size="mini"
                  plain
                >WAŻNE
                </el-tag>
              </span>
            </div>
          </div>
        </div>
        <div class="order-2">
          <el-button
            v-if="isCreatedClient == false"
            class="button-mobile-app"
            type="primary"
            @click="transformToClient"
          >
            <i class="fas fa-user-friends"></i> Przetwórz w klienta
          </el-button>
          <el-button
            v-else
            class="button-mobile-app"
            @click="moveToClient"
          >
            <i class="fas fa-arrow-right"></i> Przejdź do klienta
          </el-button>
          <el-button
            class="button-mobile-app"
            type="primary"
            @click="actionEdit"
          >
            <i class="far fa-edit"></i> Edytuj
          </el-button>
          <el-dropdown
            class="button-mobile-app"
            @command="handleCommand"
          >
            <el-button
              type="primary"
              class="button-mobile-app"
            >
              <i class="fas fa-calculator"></i> Nowa kalkulacja <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="$can('admin.calculations.create')"
                :command="{type: 'pv', contractor: 'lead'}"
              >
                PV
              </el-dropdown-item>
              <el-dropdown-item
                v-if="$can('admin.heat-pump-calculations.create')"
                :command="{type: 'heat_pump', contractor: 'lead'}"
              >
                Pompa Ciepła
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            v-if="$can('admin.lead.delete')"
            class="button-mobile-app"
            type="danger"
            @click="actionDelete"
          >
            <i class="far fa-trash-alt"></i> Usuń Lead
          </el-button>
          <el-button
            class="button-mobile-app"
            @click="$router.go(-1)"
          >
            <i class="fas fa-long-arrow-alt-left"></i> Powrót
          </el-button>
        </div>
      </div>
    </el-card>
    <el-tabs
      type="card"
      class="mt-10"
    >
      <el-tab-pane label="Dane osobowe">
        <div class="row mt-2">
          <div class="col-lg-9 col-xl-9 col-sm-12 order-lg-1 order-xl-1">
            <el-card
              v-loading="loading"
              class="box-card"
            >
              <div>
                <h5 class="my-0 py-0">
                  Informacje podstawowe
                </h5>
                <hr>
              </div>
              <div class="row">
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Źródło kontaktu</dt>
                    <dd>{{ source }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Typ kontaktu</dt>
                    <dd>{{ type_contact }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Czym zainteresowany?</dt>
                    <dd>{{ interested }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.type_contact != 0"
                  class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1"
                >
                  <div>
                    <dl>
                      <dt>Nazwa firmy</dt>
                      <dd> {{ item.name_company }}</dd>
                    </dl>
                  </div>
                </div>
                <div
                  v-if="item.type_contact != 0"
                  class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>NIP</dt>
                    <dd> {{ item.nip }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.type_contact != 0"
                  class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>REGON</dt>
                    <dd> {{ item.regon }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Dane personalne</dt>
                    <dd>{{ item.full_name }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Email</dt>
                    <dd>{{ item.email }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Telefon 1</dt>
                    <dd>{{ item.phone }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Telefon 2</dt>
                    <dd>{{ item.phone_2 }}</dd>
                  </dl>
                </div>
              </div>
            </el-card>
            <el-card
              v-loading="loading"
              class="box-card mt-3"
            >
              <div>
                <h5 class="my-0 py-0">
                  Dane adresowe
                </h5>
                <hr>
              </div>
              <div class="row">
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Ulica</dt>
                    <dd>{{ item.address }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Numer budynku</dt>
                    <dd>{{ item.property_number }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Numer lokalu</dt>
                    <dd>{{ item.apartment_number }}</dd>
                  </dl>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Miasto</dt>
                    <dd>{{ item.city }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Kod pocztowy</dt>
                    <dd>{{ item.postcode }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Województwo</dt> <dd>{{ item.province }}</dd></dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Gmina</dt> <dd>{{ item.county }}</dd></dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl><dt>Powiat</dt> <dd>{{ item.community }}</dd></dl>
                </div>
              </div>
            </el-card>
            <el-card
              v-loading="loading"
              class="box-card mt-3"
            >
              <div>
                <h5 class="my-0 py-0">
                  Dane techniczne
                </h5>
                <hr>
              </div>
              <div class="row">
                <div
                  v-if="item.google_map_url"
                  class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Mapka do terenu klienta</dt>
                    <dd>
                      <el-link
                        :href="item.google_map_url"
                        target="_blank"
                        type="success"
                      >
                        Kliknij, aby przejść do linku
                      </el-link>
                    </dd>
                  </dl>
                </div>
                <div
                  class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Dostawca</dt>
                    <dd>{{ item.provider !== null ? providerShow : 'Brak danych' }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Rodzaj dachu</dt>
                    <dd>{{ item.roof_type !== null ? roofTypeShow : 'Brak danych' }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Powierzchnia dachu</dt>
                    <dd>{{ item.roof_area ? item.roof_area + ' m²' : 'Brak danych' }} </dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Roczne zużycie energii</dt>
                    <dd>{{ item.annual_energy_consumption ? item.annual_energy_consumption : 'Brak danych' }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Zgoda marketingowa 1</dt>
                    <dd>{{ item.permission_first ? 'Tak' : 'Nie' }}</dd>
                  </dl>
                </div>
                <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1">
                  <dl>
                    <dt>Zgoda marketingowa 2</dt>
                    <dd>{{ item.permission_second ? 'Tak' : 'Nie' }}</dd>
                  </dl>
                </div>
                <div
                  v-if="item.source === 13"
                  class="col-lg-3 col-xl-3 col-sm-12 order-lg-1 order-xl-1"
                >
                  <dl>
                    <dt>Ankieter</dt>
                    <dd>{{ item.pollster }}</dd>
                  </dl>
                </div>
              </div>
            </el-card>
            <div
              v-if="isCreatedClient"
              class="attachments-move d-flex justify-content-end align-items-center mt-3"
            >
              <el-button
                size="medium"
                type="primary"
                @click="handleMoveAttachments"
              >
                <i class="fad fa-repeat"></i> Przenieś załączniki na klienta
              </el-button>
            </div>
            <attachments
              v-if="item.attachments"
              :files="item.attachments.data"
              :name-endpoint="nameEndpoint"
              :upload-endpoint="uploadEndpoint"
              :delete-endpoint="deleteEndpoint"
              :allowed-mime-types="$config.uploads.tickets.allowed_mime_types"
              :allowed-file-size="$config.uploads.tickets.allowed_file_size"
              :allowed-file-types="$config.uploads.tickets.allowed_file_types"
              multiple
              @added="onAttachmentAdded"
              @media-name="onAttachmentNameChanged"
              @deleted="onAttachmentDeleted"
            />
          </div>
          <div class="col-lg-3 col-xl-3 col-sm-12 order-lg-2 order-xl-2">
            <el-card
              v-loading="loading"
              class="mb-3"
            >
              <div class="text-center">
                <h5 class="my-0 py-0">
                  Aktualny status leada
                </h5>
                <div class="mt-3">
                  <el-tag
                    class="w-75"
                    effect="dark"
                    type="info"
                    style="font-size:18px"
                  >
                    {{ status }}
                  </el-tag>
                  <el-popover
                    v-model="updateStatusVisible"
                    placement="top"
                  >
                    <el-radio-group
                      v-model="newStatus"
                      name="status"
                    >
                      <el-radio
                        v-for="(val, key) in $config.agreementSettings.data.statuses_lead.settings"
                        :key="key"
                        :label="parseInt(key)"
                      >
                        {{ val.label }}
                      </el-radio>
                    </el-radio-group>

                    <div>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="updateStatus"
                      >
                        Zmień status
                      </el-button>
                      <el-button
                        size="mini"
                        type="text"
                        @click="updateStatusVisible = false"
                      >
                        anuluj
                      </el-button>
                    </div>
                    <custom-button
                      :id="item.id"
                      slot="reference"
                      :circle="true"
                      type="primary"
                      size="mini"
                      title="Zmień status"
                      permission="admin.lead.status.admin"
                      resource="admin.lead.update"
                    />
                  </el-popover>
                </div>
              </div>
              <hr>
              <div
                v-if="item.status === 7"
                class="info"
              >
                <el-alert
                  :closable="false"
                  type="info"
                >
                  Brak możliwych aktywnych statusów
                </el-alert>
              </div>
              <div class="block--information">
                <el-button
                  v-if="item.status === 0 || item.status === 9"
                  :loading="loading"
                  plain
                  class="w-100"
                  type="success"
                  @click="onStatusChange(10)"
                >
                  <i class="far fa-handshake"></i> Umówione spotkanie
                </el-button>
                <el-button
                  v-if="item.status === 0 || item.status === 9 || item.status === 12"
                  :loading="loading"
                  plain
                  class="w-100"
                  type="danger"
                  @click="onStatusChange(4)"
                >
                  <i class="fas fa-exclamation-triangle"></i> Niezainteresowany
                </el-button>
                <el-button
                  v-if="item.status === 0 || item.status === 9"
                  :loading="loading"
                  class="w-100"
                  plain
                  type="success"
                  @click="onStatusChange(9)"
                >
                  <i class="far fa-calendar-plus"></i> Inny termin kontaktu
                </el-button>
                <el-button
                  v-if="item.status === 0 || item.status === 9"
                  :loading="loading"
                  class="w-100"
                  type="danger"
                  plain
                  @click="onStatusChange(5)"
                >
                  <i class="fas fa-exclamation"></i> Brak Kontaktu
                </el-button>
                <el-button
                  v-if="item.status === 0 || item.status === 9"
                  :loading="loading"
                  class="w-100"
                  type="success"
                  plain
                  @click="onStatusChange(3)"
                >
                  <i class="fas fa-share-square"></i> Wysłana kalkulacja na maila
                </el-button>
                <el-button
                  v-if="item.status === 0 || item.status === 9"
                  :loading="loading"
                  class="w-100"
                  plain
                  type="danger"
                  @click="onStatusChange(8)"
                >
                  <i class="fas fa-exclamation"></i> Błędne dane
                </el-button>
                <el-button
                  v-if="item.status === 10 || item.status === 13"
                  :loading="loading"
                  class="w-100"
                  plain
                  type="success"
                  @click="onStatusChange(13)"
                >
                  <i class="fas fa-exchange-alt"></i> Przełóż spotkanie
                </el-button>
                <el-button
                  v-if="item.status === 10 || item.status === 13"
                  :loading="loading"
                  class="w-100"
                  type="warning"
                  plain
                  @click="onStatusChange(11)"
                >
                  <i class="fas fa-times"></i> Odwołane spotkanie
                </el-button>
                <el-button
                  v-if="item.status === 10 || item.status === 13"
                  :loading="loading"
                  class="w-100"
                  type="success"
                  plain
                  @click="onStatusChange(12)"
                >
                  <i class="far fa-handshake"></i> Zakończone spotkanie
                </el-button>
                <el-button
                  v-if="item.status === 6 || item.status === 4 || item.status === 5 || item.status === 8 || item.status === 11"
                  :loading="loading"
                  class="w-100"
                  plain
                  type="success"
                  @click="onStatusChange(0)"
                >
                  <i class="fas fa-share"></i> Reaktywuj leada
                </el-button>
                <el-button
                  v-if="item.status === 12 || item.status === 3"
                  :loading="loading"
                  class="w-100"
                  plain
                  type="success"
                  @click="onStatusChange(7)"
                >
                  <i class="fas fa-file-contract"></i> Podpisano umowę
                </el-button>
                <el-button
                  v-if="item.status === 10 || item.status === 3 || item.status === 13"
                  :loading="loading"
                  class="w-100"
                  plain
                  type="danger"
                  @click="onStatusChange(6)"
                >
                  <i class="fas fa-times"></i> Rezygnacja
                </el-button>
              </div>
            </el-card>
            <el-card
              v-if="$can('admin.comments.read')"
              v-loading="loading"
              class="box-card mt-3"
            >
              <comments-module
                :comments="item.comments"
                type="lead"
                @update-comments="updateComments"
              />
            </el-card>
            <el-card
              v-loading="loading"
              class="box-card mt-3"
            >
              <div class="block--information">
                <div class="block--header">
                  <h5 class="my-0 py-0">
                    Dodatkowe informacje
                  </h5>
                  <hr>
                </div>
                <div class="block--content">
                  <dl>
                    <dt>Notatka Biuro</dt>
                    <dd
                      v-if="item.notes_office"
                      class="textform-notes"
                      v-html="item.notes_office"
                    ></dd>
                    <dd v-else>
                      Brak informacji w systemie
                    </dd>
                  </dl>
                </div>
                <div
                  v-if="
                    item.notes"
                  class="row"
                >
                  <div class="col-12">
                    <dl>
                      <dt>Notatka Leada</dt>
                      <dd
                        v-if="item.notes"
                        class="textform-notes"
                        v-html="item.notes"
                      ></dd>
                      <dd v-else>
                        Brak informacji w systemie
                      </dd>
                    </dl>
                    <dl>
                      <dt>Ostatni kontakt</dt>
                      <dd v-if="item.last_call_date">
                        {{ item.last_call_date }}
                      </dd>
                      <dd v-else>
                        Brak daty w systemie
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </el-card>
            <user-block
              resource="admin.lead.change"
              type="leads"
              :object-id="item.id"
              permission="admin.lead.change"
              :item="item.user"
            />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
        v-if="item.calculations"
        label="Kalkulacje PV"
      >
        <calculations-list
          :items="item.calculations"
          :is-allowed-to-move="isCreatedClient"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="item.offers"
        :items="item.offers"
        label="Oferty PV"
      >
        <offers-list
          :items="item.offers"
          :is-allowed-to-move="isCreatedClient"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="item.heat_pump_calculations"
        label="Kalkulacje Pomp Ciepła"
      >
        <calculations-heat-pump-list
          :is-allowed-to-move="isCreatedClient"
          :items="item.heat_pump_calculations"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="item.heat_pump_offers"
        label="Oferty Pomp Ciepła"
      >
        <offer-heat-pump-list
          :is-allowed-to-move="isCreatedClient"
          :items="item.heat_pump_offers"
        />
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :visible.sync="anotherTimeContactDialog"
      title="Wprowadź datę "
    >
      <div class="text-center">
        <dl>
          <dt>Data kontaktu/spotkania</dt>
          <dd>
            <div>
              <el-date-picker
                v-model="newCallDate"
                type="datetime"
                format="yyyy-MM-dd HH:mm:SS"
                value-format="yyyy-MM-dd HH:mm:ss"
                class="my-1"
                placeholder="Wybierz termin kontaktu"
                @change="validDateFormat"
              />
            </div>
          </dd>
        </dl>
      </div>
      <el-row class="text-end">
        <el-button
          v-if="item.status === 0 || item.status === 9"
          :loading="loading"
          :disable="isNotCorrectData"
          type="primary"
          @click="onStatusChange(9)"
        >
          <i class="far fa-calendar-plus"></i>  Potwierdź - Inny termin kontaktu
        </el-button>
        <el-button
          v-if="item.status === 10 || item.status === 13"
          :loading="loading"
          :disable="isNotCorrectData"
          type="primary"
          @click="onStatusChange(13)"
        >
          <i class="fas fa-exchange-alt"></i> Potwierdź - Przełóż spotkanie
        </el-button>
        <el-button
          v-if="item.status === 0 || item.status === 9"
          :loading="loading"
          :disable="isNotCorrectData"
          type="primary"
          @click="onStatusChange(10)"
        >
          <i class="far fa-handshake"></i> Potwierdź - Umówione spotkanie
        </el-button>
      </el-row>
    </el-dialog>
  </section>
</template>

<script>
  import { deleteRequest, getRequest, postRequest } from '@/shared/utils/http'
  import Avatar from 'vue-avatar'
  import Attachments from '@/admin/components/Attachments'
  import { patchRequest } from '../../../shared/utils/http'
  import Autocomplete from '../users/components/Autocomplete'
  import OfferList from '@/admin/pages/calculator/components/OfferList'
  import CalculationList from '@/admin/pages/calculator/components/CalculationList'
  import CommentsModule from '@/admin/components/CommentsModule'
  import NameModelCard from '@/admin/components/NameModelCard'
  import UserBlock from '@/admin/components/User'
  import CalculationsList from '@/admin/components/lead-client-addons/CalculationsList'
  import OffersList from '@/admin/components/lead-client-addons/OffersList'
  import sellingProcess from '@/admin/mixins/sellingProcess'
  import CalculationsHeatPumpList from '@/admin/components/lead-client-addons/CalculationsHeatPumpList'
  import OfferHeatPumpList from '@/admin/components/lead-client-addons/OfferHeatPumpList'

  export default {
    name: 'LeadsName',
    components: {
      OfferHeatPumpList,
      CalculationsHeatPumpList,
      OffersList,
      CalculationsList,
      UserBlock,
      NameModelCard,
      CommentsModule,
      CalculationList,
      OfferList,
      Avatar,
      Autocomplete,
      Attachments
    },
    mixins: [sellingProcess],

    data: () => {
      return {
        item: {},
        anotherTimeContactDialog: false,
        loading: false,
        isLoaded: false,
        message: 'Jesteś pewien, że chcesz usunąć tego leada?',
        isCreatedClient: false,
        isClientExist: false,
        updateStatusVisible: false,
        updateCallDateVisible: false,
        updateUserVisible: false,
        newStatus: null,
        newCallDate: null,
        NewUser: '',
        showChangeDataPicker: false,
        isNotCorrectData: false
      }
    },

    computed: {
      id () {
        return parseInt(this.$route.params.id)
      },
      status () {
        if (!this.$config.agreementSettings.data.statuses_lead.settings[this.item.status]) {
          return ''
        }
        return this.$config.agreementSettings.data.statuses_lead.settings[this.item.status].label
      },
      source () {
        if (!this.$config.agreementSettings.data.source.settings[this.item.source]) {
          return ''
        }
        return this.$config.agreementSettings.data.source.settings[this.item.source].label
      },
      type_contact () {
        if (!this.$config.agreementSettings.data.type_client.settings[this.item.type_contact]) {
          return ''
        }
        return this.$config.agreementSettings.data.type_client.settings[this.item.type_contact].label
      },
      interested () {
        if (!this.$config.agreementSettings.data.interested.settings[this.item.interested]) {
          return ''
        }
        return this.$config.agreementSettings.data.interested.settings[this.item.interested].label
      },
      nameEndpoint: function () {
        return `/api/leads/${this.id}/attachments`
      },

      uploadEndpoint: function () {
        return `/api/leads/${this.id}/upload`
      },

      deleteEndpoint: function () {
        return `/api/leads/${this.id}/attachments`
      },
      providerShow () {
        if (!this.$config.agreementSettings.data.electric_provider.settings[this.item.provider]) {
          return ''
        }
        return this.$config.agreementSettings.data.electric_provider.settings[this.item.provider].label
      },
      roofTypeShow () {
        if (!this.$config.agreementSettings.data.roof_type.settings[this.item.roof_type]) {
          return ''
        }
        return this.$config.agreementSettings.data.roof_type.settings[this.item.roof_type].label
      }
    },
    watch: {
      'id' (newValue, oldValue) {
        this.loading = true
        getRequest('/api/leads/' + newValue)
          .then((response) => {
            this.item = response.data
            this.isCreatedClient = this.item.isClientExist
            this.isLoaded = true
            this.loading = false
          }).catch((response) => {
            this.$router.go(-1)
          })
      }
    },
    mounted () {
      this.$root.title = 'Karta użytkownika Lead'
      this.fetchData()
    },
    created () {
      this.$events.on('move-offers', e => this.handleMoveOffers())
      this.$events.on('move-calculations', e => this.handleMoveCalculations())
      this.$events.on('move-heat-pump-calculations', e => this.handleMoveHeatPumpCalculations())
      this.$events.on('move-heat-pump-offers', e => this.handleMoveHeatPumpOffers())
      this.$events.on('move-attachments', e => this.handleMoveAttachments())
    },

    beforeDestroy () {
      this.$events.remove('move-offers')
      this.$events.remove('move-calculations')
      this.$events.remove('move-attachments')
    },

    methods: {
      fetchData () {
        getRequest('/api/leads/' + this.id)
          .then((response) => {
            this.item = response.data
            this.isCreatedClient = this.item.isClientExist
            this.isLoaded = true
          }).catch((error) => {
            this.$snotify.error(error.data.message)
            this.$router.go(-1)
          })
      },
      backToList () {
        this.$router.go(-1)
      },
      goEdit () {
        this.$router.push({ path: '/admin/lead/' + this.id + '/edit' })
      },
      goToUser (id) {
        this.$router.push({ path: '/admin/users/' + id })
      },
      createCalculation () {
        this.moveToCalculationCreate()
      },
      warningCalculation () {
        this.$message({
          message: 'Należy uzupełnić pola związane z adresem podmiotu.',
          type: 'info'
        })
        if (this.$can('admin.lead.update')) {
          this.$router.push({ name: 'admin.lead.update', params: { id: this.id } })
        }
      },
      moveToCalculationCreate () {
        this.$router.push({ path: '/admin/calculator/' + this.id + '/lead' })
      },
      transformToClient: function () {
        this.$confirm('Rozpoczynasz przekształcanie leada w klienta. Pamiętaj o uzupełnieniu potrzebnych informacji przed wygenerowaniem umowy.', 'Uwaga', {
          confirmButtonText: this.label,
          cancelButtonText: this.cancelLabel,
          type: 'warning'
        }).then(() => {
          this.$emit('requestStart')
          this.loading = true
          getRequest('/api/leads/' + this.id + '/transform-client')
            .then((response) => {
              if (response.data) {
                this.$emit('success')
                const client = response.data
                this.loading = false
                this.$router.push({ path: '/admin/clients/' + client.id })
              } else {
                this.$message({
                  message: 'Klient został już wcześniej utworzony z tej karty Leada.',
                  type: 'info'
                })
                this.loading = false
              }
            })
            .catch(() => {
              this.$emit('fail')
            })
        })
          .catch(() => {
          })
      },
      actionEdit: function () {
        if (this.isCreatedClient === true) {
          this.$confirm('Edytujesz dane Leada, który jest już utworzony jako klient. Dane po stronie klienta nie zostaną zmienione. Kontynuować?', 'Uwaga', {
            confirmButtonText: this.label,
            cancelButtonText: this.cancelLabel,
            type: 'warning'
          }).then(() => {
            this.$router.push({ name: 'admin.lead.update', params: { id: this.id } })
          })
            .catch(() => {

            })
        } else {
          this.$router.push({ name: 'admin.lead.update', params: { id: this.id } })
        }
      },
      actionChangeUser: function () {
        this.updateUserVisible = true
      },
      actionDelete: function () {
        this.$confirm(this.message, 'Uwaga', {
          confirmButtonText: this.label,
          cancelButtonText: this.cancelLabel,
          type: 'warning'
        })
          .then(() => {
            this.$emit('requestStart')
            deleteRequest('/api/leads/' + this.id)
              .then(() => {
                this.$emit('success')
                this.$router.push({ path: '/admin/lead' })
              })
              .catch((error) => {
                this.$snotify.error(error.data.message)
                this.$emit('fail')
              })
          })
          .catch(() => {
          })
      },
      updateStatus () {
        // Wprowadzić zmianę przesyłanej wiadomości ze względu na ID
        patchRequest(`/api/leads/${this.id}`, { status: this.newStatus })
          .then(response => {
            this.$message({
              message: 'Status został zaktualizowany.',
              type: 'success'
            })
            this.item.status = response.data.status
            this.$config.reminders.splice(0, 1)
          })
        this.updateStatusVisible = false
      },
      updateCallDate () {
        patchRequest(`/api/leads/${this.id}`, { call_date: this.newCallDate })
          .then(response => {
            this.$message({
              message: 'Data kontaktu została zaktualizowana.',
              type: 'success'
            })
            this.item.call_date = response.data.call_date
            this.showChangeDataPicker = false
          })
        this.updateCallDateVisible = false
      },
      updateUser () {
        patchRequest(`/api/leads/${this.id}`, { user_id: this.NewUser })
          .then(response => {
            this.$message({
              message: 'Osoba prowadząca została zmieniona',
              type: 'success'
            })
            this.item.user = response.data.user
          })
        this.updateUserVisible = false
      },
      setMeetingHeld () {
        this.$emit('requestStart')
        patchRequest(`/api/leads/${this.id}`, { meeting_held: true })
          .then(response => {
            this.$message({
              message: 'Potwierdzono zakończenie spotkania',
              type: 'success'
            })
            this.item.meeting_held = true
            this.item.status = response.data.status
          })
      },
      onAttachmentAdded (file) {
        this.item.attachments.data.push(file.file)
      },
      onAttachmentNameChanged (file) {
        const index = this.item.attachments.findIndex((item) => {
          return item.id === file.id
        })
        this.item.attachments.splice(index, 1, file)
      },
      onAttachmentDeleted (id) {
        const index = this.item.attachments.data.findIndex((item) => {
          return item.id === id
        })
        this.item.attachments.data.splice(index, 1)
      },
      onStatusChange (statusId) {
        if (statusId === 9 || statusId === 13 || statusId === 10) {
          if (this.anotherTimeContactDialog === false) {
            this.anotherTimeContactDialog = true
            return false
          }
          this.updateCallDate(this.item.call_date)
          this.newCallDate = null
        }
        if (statusId === 12) {
          this.setMeetingHeld()
        }
        this.loading = true
        patchRequest(`/api/leads/${this.id}`, { status: statusId })
          .then(response => {
            this.item.status = response.data.status
            this.anotherTimeContactDialog = false
            this.loading = false
          })
        this.updateStatusVisible = false
      },
      updateComments (comment) {
        this.item.comments.data.push(comment)
      },
      moveToClient () {
        this.$router.push({ name: 'admin.clients.show', params: { id: this.item.client_id } })
      },
      validDateFormat (val) {
        this.isNotCorrectData = !val
      },
      // todo WTF IS THAT?? :D
      handleArrowAction (type) {
        this.loading = true
        if (type === 'next') {
          this.$router.push({ name: 'admin.lead.show', params: { id: this.id + 1 } })
        } else {
          if (this.id > 1) {
            this.$router.push({ name: 'admin.lead.show', params: { id: this.id - 1 } })
          }
        }
      },
      handleMoveOffers () {
        this.$snotify.info('Przenoszenie ofert rozpoczętę')
        this.moveAction('offers')
      },
      handleMoveCalculations () {
        this.$snotify.info('Przenoszenie kalkulacji rozpoczętę')
        this.moveAction('calculations')
      },
      handleMoveHeatPumpCalculations () {
        this.$snotify.info('Przenoszenie kalkulacji rozpoczętę')
        this.moveAction('heat-pump-calculations')
      },
      handleMoveAttachments () {
        this.$snotify.info('Przenoszenie załączników rozpoczętę')
        this.moveAction('attachments')
      },
      handleMoveHeatPumpOffers () {
        this.$snotify.info('Przenoszenie kalkulacji rozpoczętę')
        this.moveAction('heat-pump-offers')
      },
      moveAction (type) {
        postRequest('/api/leads/' + this.id + '/move-sources', { type: type })
          .then(response => {
            this.fetchData()
          })
          .catch(errorResponse => {
            this.$snotify.error('Migracja zasobów nie została zakończona')
          })
      }
    }
  }
</script>
<style scoped>

.el-button + .el-button {
  margin: 10px 0px 0px 0px;
}
    .header-block {
      margin-left: 20px;
    }
    .textform-notes p{
      margin-block-start: 0px !important;
      margin-block-end: 0px !important;
    }
</style>
