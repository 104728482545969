<template>
  <div>
    <el-card
      v-loading="loading"
      class="mt-3"
    >
      <el-row style="text-align: right;">
        <el-button
          v-if="$can('admin.calculations.inverters.update')"
          class="button-mobile-app"
          type="primary"
          @click="editAction"
        >
          <i class="far fa-edit"></i> Edytuj
        </el-button>
        <el-button
          v-if="$can('admin.calculations.inverters.delete')"
          class="button-mobile-app"
          type="danger"
          @click="deleteAction"
        >
          <i class="far fa-trash-alt"></i> Usuń
        </el-button>
        <el-button @click="$router.go(-1)">
          Powrót
        </el-button>
      </el-row>
    </el-card>
    <div class="row mt-3">
      <div class="col-lg-8 col-md-12 col-xl-8">
        <el-card v-loading="loading">
          <div>
            <h5 class="my-0 py-0">
              Dane falownika
            </h5>
            <hr>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1">
              <dl><dt>Nazwa falownika</dt><dd>{{ item.name }}</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-2 order-xl-3">
              <dl><dt>Maksymalna moc dla falownika</dt><dd>{{ item.max_power }} kWp</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-2 order-xl-3">
              <dl><dt>Nominalna moc dla falownika</dt><dd>{{ item.nominal_power }} kWp</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-3 order-xl-3">
              <dl><dt>Fazy</dt><dd>{{ item.phase }}</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-3 order-xl-3">
              <dl><dt>Gwarancja</dt><dd>{{ item.warranty }}</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-3 order-xl-3">
              <dl><dt>Nazwa pliku karty PDF</dt><dd>{{ item.card }}</dd></dl>
            </div>
          </div>
        </el-card>
      </div>
      <div class="col-lg-4 col-md-12 col-xl-4">
        <assign-companies
          :attached-companies="item.attached_companies"
          @update-inverter="updateModel"
        />
        <el-card
          v-loading="loading"
          class="mt-3"
        >
          <div>
            <h5 class="my-0">
              Załącz kartę PDF <el-button
                v-if="item.card"
                type="text"
                @click="previewCard"
              >
                Podgląd karty
              </el-button>
            </h5>
            <hr>
          </div>
          <el-upload
            v-if="fileList"
            :file-list="fileList"
            :on-success="handleSuccess"
            :before-upload="beforeFileUpload"
            :on-error="handleFileError"
            :action="'/api/inverters/'+ id +'/upload_pdf'"
            class="upload-demo"
            drag
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              Wrzuć plik tutaj lub <em>naciśnij by załadować</em>
            </div>
            <div
              slot="tip"
              class="el-upload__tip"
            >
              Pliki PDF nie większe niż 15MB
            </div>
          </el-upload>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { deleteRequest, getRequest } from '@/shared/utils/http'
  import AssignCompanies from '@/admin/pages/calculations/children-pages/inverters/components/AssignCompanies'

  export default {
    name: 'Show',
    components: { AssignCompanies },
    data () {
      return {
        item: '',
        loading: true,
        fileList: []
      }
    },
    computed: {
      id () {
        return parseInt(this.$route.params.id)
      }
    },
    mounted () {
      this.$root.title = 'Falownik'

      getRequest('/api/inverters/' + this.id)
        .then((response) => {
          this.item = response.data
          this.loading = false
        }).catch((response) => {
          this.$router.go(-1)
        })
    },
    methods: {
      editAction () {
        this.$router.push({ name: 'admin.calculations.inverters.update', params: { id: this.id } })
      },
      deleteAction: function () {
        this.$confirm('Czy chcesz usunąć ten falownik?', 'Uwaga', {
          confirmButtonText: this.label,
          cancelButtonText: this.cancelLabel,
          type: 'warning'
        })
          .then(() => {
            this.$emit('requestStart')
            deleteRequest('/api/inverters/' + this.id)
              .then(() => {
                this.$emit('success')
                this.$router.push({ path: '/admin/calculations/inverters' })
              })
              .catch(() => {
                this.$emit('fail')
              })
          })
          .catch(() => {
          })
      },
      beforeFileUpload (file) {
        this.$message.info('Ładowanie karty do systemu')

        const isValidSize = file.size / 1024 / 1024 < 15 // 3MB

        if (!isValidSize) {
          this.$message.error('Plik ma rozmiar większy niż 15MB! Dodaj mniejszy plik.')
        }

        const isOK = isValidSize

        if (isOK) {
          this.loading = true
        }

        return isOK
      },
      handleSuccess (response) {
        this.item = response.data
        this.loading = false
        this.$notifySuccess(response.message)
      },
      handleFileError (res) {
        this.$notifyError('Wystąpił problem podczas ładowania plików. Zgłoś sprawę do administratora.')
        this.loading = false
      },
      previewCard () {
        const href = '/storage/documents/' + this.item.card
        window.open(href, '_blank')
      },
      updateModel (res) {
        this.item = res
      }
    }
  }
</script>

<style scoped>
.list-group-item {
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
  margin-left: -35px;
}
.rounded-pill{
  border-radius: 11px;
  padding:2px 5px;
  color:white;
}
</style>
