.tasks-dashboard{
  margin-top: -10px !important;
}
.tasks-item{
  cursor: pointer;
  &:hover{
  background:#eaf7cc;
    transition: 0.4s
  }
}
