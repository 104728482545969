<template>
  <div>
    <small>Produkcja dla połaci</small>
    <table class="table">
      <tr v-for="(slope,index) in constructions_slopes">
        <th>{{ index+1 }}. połać</th><td>{{ productionValue(slope, index) }} kWh</td>
      </tr>
    </table>
  </div>
</template>

<script>
  import {floatFormatter} from '@/admin/utils/helpers'

  export default {
    name: 'ProductionSlopeComponent',
    props: {
      slopes: {
        type: Array,
        required: true
      },
      constructions_slopes: {
        type: Array,
        required: true
      },
      pvgis_production_slopes: {
        type: Array,
        required: true
      },
      panel: {
        type: Number,
        required: true
      },
      panels: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        slopesProductionTable: []
      }
    },
    computed: {
      countProductionPower () {
        return 0
      }
    },
    methods: {
      productionValue (slope, index) {
        let value = this.panelObject(this.panel).kwp * slope.panel_count * this.pvgis_production_slopes[index].energy_production
        return floatFormatter(value)
      },
      panelObject (panelId) {
        return this.panels.find((item) => {
          if (item.id === panelId) {
            return item.name
          }
        })
      }
    }
  }
</script>

<style scoped>
.table th {
  border-bottom: 1px solid #dcdfe6!important;
  text-align: center;
  font-size:14px
}
.table td {
  border-bottom: 1px solid #dcdfe6!important;
  text-align: center;
  font-size:14px
}
</style>
