.table > :not(:first-child) {
  border-top: 2px solid #cbcbcb !important;
}

.table {
  //width: 100%;
  //max-width: 100%;
  //margin-bottom: 1rem;
  //background-color: #fff;
  border-collapse: collapse;

  th {
    text-align: inherit;
    font-size:12px;
  }

  th,
  td {
    //padding: .75rem;
    //vertical-align: top;
    //border-top: 1px solid $table-border-color;
    //border-right: 1px solid #dcdfe6 !important;
    //border: 1px solid #dcdfe6 !important;
    &.fit-col {
      width: 1%;
      white-space: nowrap;
    }
  }

  .fa-sort {
    color: map_get($colors, grey);
  }

  //thead th {
  //  vertical-align: bottom;
  //  border-bottom: (2 * 1px) solid $table-border-color;
  //}
  //
  //tbody + tbody {
  //  border-top: (2 * 1px) solid $table-border-color;
  //}
}

//.table-bordered {
//  border: 1px solid $table-border-color;
//
//  th,
//  td {
//    border: 1px solid $table-border-color;
//  }
//
//  thead {
//    th,
//    td {
//      border-bottom-width: (2 * 1px);
//    }
//  }
//}
//
//.table-borderless {
//  th,
//  td,
//  thead th,
//  tbody + tbody {
//    border: 0;
//  }
//}
//
//.table-striped {
//  tbody tr:nth-of-type(odd) {
//    background-color: rgba(#000, .05);
//  }
//}
//
//
//.table-hover {
//  tbody tr {
//    &:hover {
//      background-color: rgba(#000, .05);
//    }
//  }
//}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

//@each $color, $value in $theme-colors {
//  @include table-row-variant($color, theme-color-level($color, -9));
//}
//
//@include table-row-variant(active, $table-active-bg);

.resetFilterBtn {
  margin: 0 15px;
}

.table-detailed {
  tbody {
    td, th {
      cursor: pointer;
    }
  }
}

.vuetable-detail-row {
  td, th {
    cursor: auto;
  }

  th {
    background: #fff;
  }
;

  td {
    background: map_get($colors, lighter-grey);
  }

  &:hover {
    > td {
      background: map_get($colors, lighter-grey);
    }
  }

}

.table-header {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
}

.table-filters {
  border-bottom: 2px solid map_get($colors, primary);

  &--handle {
    color: map_get($colors, grey);
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 0;
    cursor: pointer;
    text-align: center;

    i {
      &:first-child {
        margin: 0 5px 0 0;
      }
      &:last-child {
        margin: 0 0 0 5px;
      }
    }

    &:hover {
      color: $color-text;
    }
  }
}

.table-striped tbody tr {
  font-size: 0.65rem !important;
}

.table-scroll table {
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
}



.vuetable {
  &-th-id {
    min-width: 60px;
  }
}