$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
);


$colors: (
        light: #fff,
        primary: rgb(151,215,0),
        accent: #A1CB38,
        light-grey: #f1f1f1,
        lighten-grey: #f7f7f7,
        success: #67c23a,
        warning: #e6a23c,
        danger: #f56c6c,
        info: #909399,
        blue: #009ff5,
        grey: #a4a4a4,
        medium-grey: #ccc,
        lighter-grey: #f3f8f9,
        dark: #463f3a,
        darker: #3a3430,
        active-green: rgb(83, 114, 9),
        active-grey: #2c302f,
        icon-active: #ff9900,
);

:root {
  --color-primary: #{map_get($colors, primary)};
  --bs-body-font-size: 0.75rem !important;

}

$--color-primary: map_get($colors, primary);
$color-text: #303133;
$color-text-light: lighten($color-text, 10%);
$color-text-lighter: lighten($color-text, 20%);
$color-text-dark: darken($color-text, 10%);

$background-aside: map_get($colors, light-grey);
$background: map_get($colors, lighten-grey);

//$color-text-invert: #29487f;
//$color-text-invert-light: #29487f;
//$color-text-invert-dark: #29487f;

$color-icon: #666;
$color-light-icon: #aaa;

$color-text-link: #ffffff;
$color-text-link-hover: lighten($color-text-link, 20%);

$color-border: #dcdfe6;

$color-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
$color-shadow-dark: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .12);
$color-shadow-light: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

// element.io colors
$--color-primary: map_get($colors, primary);
$--color-success: map_get($colors, success);
$--color-warning: map_get($colors, warning);
$--color-danger: map_get($colors, danger);
$--color-info: map_get($colors, info);
$--link-color: $color-text-link;
$--link-hover-color: $color-text-link-hover;
$--background-color-base: $background;
$--border-color-base: $color-border;
$--border-color-light: lighten($color-border, 10%);
$--border-color-lighter: lighten($color-border, 20%);
$--border-color-extra-light: lighten($color-border, 30%);
$--box-shadow-base: $color-shadow;
$--box-shadow-dark: $color-shadow-dark;
$--box-shadow-light: $color-shadow-light;
$--icon-color: $color-icon;

// tables
$table-border-color: $color-border;
$--table-border-color: $color-border;

// typography
//$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-header: Barlow, sans-serif;
$font-family-body: Barlow, sans-serif;
$font-family-sans-serif: $font-family-body;
$headings-font-family: $font-family-header;
$headings-font-weight: 300;

$blue: #009ff5;
$dark-blue: #29487f;
$green: #5cb85c;
$cyan: #5bc0de;
$orange: #f0ad4e;
$red: #d9534f;
$light-grey: #f7f7f7;
$lighter-grey: #f8f9fb;
$grey: #f3f8f9;
$dark: #484848;
$light-dark: #545c64;

$bg-primary: $blue;
$bg-info: $cyan;
$bg-success: $green;
$bg-warning: $orange;
$bg-danger: $red;
$bg-muted: $grey;

// ===============================

$header-height: 60px;