<template>
  <el-button
    :disabled="disabled"
    type="primary"
    plain
    class="download-button button-mobile-app"
    @click="openDocument(file_name)">
    <i :class="icon"></i> {{ name }}
  </el-button>
</template>

<script>
  export default {
    name: 'DownloadButton',
    props: {
      disabled: {
        type: Boolean,
        default: false,
        required: false
      },
      name: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      file_name: {
        type: String | Object,
        required: true
      }
    },
    methods: {
      openDocument (documentName) {
        let href = '/storage/documents/' + documentName
        window.open(href, '_blank')
      }
    }
  }
</script>

<style scoped>
.download-button{
  width: 100%;
  border: 2px solid #f3f3f3;
}
.text-download-button{
  margin-top: 10px;
}
</style>
