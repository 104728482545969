.el-form-item {
  //margin-bottom: 1rem;

  &__label {
    line-height: 1.5;
  }
}

.el-form--label-top {
  .el-form-item__label {
    padding: 0 0 5px;
  }
}



.help {
  display: block;

  &.is-danger {
    color: map_get($colors, danger);
  }
  &.is-info {
    color: map_get($colors, blue);
  }
  &.is-success {
    color: map_get($colors, success);
  }

  &.has-icon {
    display: flex;

    .fas, .far {
      font-size: 2em;
      margin-right: 10px;
    }
  }
}

.el-radio-group {
  label,
  .el-radio + .el-radio {
    display: block;
    margin: 0 0 0.5rem;
  }

  &--inline {
    .el-radio {
      display: inline-block;
      margin-bottom: 0;

      & + .el-radio {
        display: inline-block;
        margin-left: 30px;
        margin-bottom: 0;
      }
    }
  }
}

.el-radio {
  &.is-bordered {
    &.is-block {
      display: flex;
      flex-grow: 1;
      padding: 20px;
      height: auto;
      margin-left: 0;
    }
  }
}

.radio-vertical-group {
  .el-radio-group {
    display: flex;
    flex-wrap: wrap;
  }
}

.el-checkbox-group {
  label,
  .el-checkbox + .el-checkbox {
    display: block;
    margin: 0 0 0.5rem;
  }

  &--inline {
    .el-checkbox {
      display: inline-block;
      margin-bottom: 0;

      & + .el-checkbox {
        display: inline-block;
        margin-left: 30px;
        margin-bottom: 0;
      }
    }
  }
}

.check-all {
  .el-form--label-top & {
    margin-bottom: 0.4rem;
  }

  .el-checkbox__input {
    .el-checkbox__inner {
      background: map_get($colors, grey);
      border-color: darken(map_get($colors, grey), 10%);
    }

    &.is-checked,
    &.is-indeterminate {
      .el-checkbox__inner {
        background: $color-text-light;
        border-color: darken($color-text-light, 10%);
      }
    }
  }
}

.el-select-dropdown {
  &__item {
    &-custom {
      display: flex;
      justify-content: space-between;

      span {
        margin: 0 0 0 10px;

        &:first-child {
          margin: 0;
        }

        &:last-child {
          color: $grey;
        }
      }
    }
  }
}

.el-input-number {
  &.is-without-controls {
    .el-input__inner {
      text-align: left;
    }
  }
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-template-rows: 1fr;
  grid-column-gap: 40px;

  &--main {
    grid-column: 1;
    padding: 30px 30px 30px 0;

  }

  &--aside {
    grid-column: 2;
    background: map_get($colors, lighter-grey);
    padding: 30px;

  }
}


fieldset {
  margin-bottom: 1rem;
}

fieldset {
  border: none;
  margin: 0 0 1.25rem;
  padding: 0;
  border-bottom: 4px solid map_get($colors, lighter-grey);


  > .row {
    margin: 0;

    > div:first-child {
      display:grid;
      margin-bottom: 1rem;
      padding-top: 15px;
      padding-bottom: 15px;
      background: map_get($colors, primary);
    }
  }

  h6 {
    margin:auto;
    color: #f3f3f3;
  }
}

legend {
  font-weight: 300;
  color: $color-text-light;
  font-size: 1.2rem;
  padding-bottom: 0.8rem;
}

//.col-form-info {
//  width: 300px;
//  background: map_get();
//}

.el-tabs__nav {
  .el-tabs__item {
    &.is-validation-error {
      color: $--color-danger;
      //f12a

      &::after {
        display: inline-block;
        margin-left: 0.5em;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f071";
      }
    }
  }
}

.multiple-fields {
  padding: 2rem;
  background: map_get($colors, lighter-grey);
  @extend .mb-4;
  position: relative;

  h3 {
    margin-top: 0;
    font-weight: 400;
  }

  &--delete-button {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.avatar-uploader {
  position: relative;

  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-align: center;
  }

  .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-upload {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 28px;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    overflow: hidden;
  }

  .avatar {
    width: 178px;
    display: block;
  }
}
