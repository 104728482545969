<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="Kliknij, aby przejść do listy"
    placement="top"
  >
    <div
      class="card top_widget"
      @click="routeTo"
    >
      <div class="body">
        <div class="icon">
          <i :class="icon"></i>
        </div>
        <div class="content">
          <div class="text mb-2 text-uppercase">
            {{ name }}
          </div>
          <h3
            v-if="isLoaded"
            class="number mb-0"
          >
            {{ stats.all | numFormat('0') }}
          </h3>
          <h3 v-else>
            <el-skeleton :rows="1" />
          </h3>
          <small class="text-muted">{{ text }}</small>
        </div>
      </div>
    </div>
  </el-tooltip>
</template>

<script>
  import { getRequest } from '@/shared/utils/http'

  export default {
    name: 'StatBlock',
    props: {
      name: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        stats: '',
        routerLink: '',
        isLoaded: false
      }
    },
    mounted () {
      let api = '/api/'
      switch (this.type) {
        case 'clients-stats':
          api = api + 'clients-stats'
          this.routerLink = 'admin.clients.index'
          break
        case 'leads-stats':
          api = api + 'leads-stats'
          this.routerLink = 'admin.lead.index'
          break
        case 'sales-stats':
          api = api + 'sales-stats'
          this.routerLink = 'admin.sales.index'
          break
        case 'surveys-stats':
          api = api + 'surveys-stats'
          this.routerLink = 'admin.surveys.index'
          break
        case 'calculations-stats':
          api = api + 'calculations-stats'
          this.routerLink = 'admin.calculations.index'
          break
        case 'offers-stats':
          api = api + 'offers-stats'
          this.routerLink = 'admin.offers.index'
          break
      }

      getRequest(api)
        .then((response) => {
          this.stats = response
          this.isLoaded = true
        })
    },

    methods: {
      routeTo () {
        this.$router.push({ name: this.routerLink })
      }
    }
  }
</script>

<style scoped>
.card {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #ffffff;
  position: relative;
  margin-bottom: 30px;
  border-color: #ebebeb;
  border: 1px solid #ebebeb;
  width: 100%;
  cursor:pointer;
}

.card:hover{
  background: #97d700;
  transition: all 0.3s;
  color: white;
}

.card .body {
  color: #555555;
  font-weight: 400;
  padding: 20px;
}
.top_widget .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #88c200;
  color:white;
  width: 30px;
  height: 30px;
  right: 20px;
}
</style>
