<template>
  <fieldset>
    <div class="row">
      <div class="col-lg-3 col-xl-2">
        <h6>Dane adresowe</h6>
      </div>
      <div class="col-lg-9 col-xl-10">
        <custom-form-item
            :required="checkRequired(rules.province)"
            :errors="formErrors"
            prop="województwo"
            label="Województwo">
          <el-select
              v-validate="rules.province"
              v-model="form.province"
              class="w-100"
              placeholder="Województwo"
              data-cy="province"
              default-first-option
              name="province">
            <el-option
                v-for="(item,index) in provincesList"
                :key="index"
                :label="item"
                :value="item"/>
          </el-select>
        </custom-form-item>
        <custom-form-item
            :required="checkRequired(rules.city)"
            :errors="formErrors"
            prop="city"
            label="Miasto">
          <autocomplete-city
              v-model="form.city"
              :initial="form"
              :disabled="false"
              :province="form.province"
              :edit="edit"
              group="all"
              name="city"
              @input="getSelectedCity"
          />
        </custom-form-item>
        <custom-form-item
            :required="checkRequired(rules.address)"
            :errors="formErrors"
            prop="address"
            label="Ulica">
          <autocomplete-address
              v-model="form.address"
              :addons="selectedCity"
              :edit="edit"
              :disabled="lockStatus.address"
              group="all"
              name="address"
          />
        </custom-form-item>
        <custom-form-item
            :required="checkRequired(rules.property_number)"
            :errors="formErrors"
            prop="property_number"
            label="Nr budynku">
          <el-input
              v-validate="rules.property_number"
              v-model="form.property_number"
              :disabled="lockStatus.property"
              data-cy="property_number"
              name="property_number"
              type="text"/>
        </custom-form-item>
        <custom-form-item
            :required="checkRequired(rules.apartment_number)"
            :errors="formErrors"
            prop="numer lokalu"
            label="Nr lokalu">
          <el-input
              v-validate="rules.apartment_number"
              v-model="form.apartment_number"
              :disabled="lockStatus.apartment"
              data-cy="apartment_number"
              name="numer lokalu"
              type="text"/>
        </custom-form-item>
        <custom-form-item
            :required="checkRequired(rules.postcode)"
            :errors="formErrors"
            prop="postcode"
            label="Kod pocztowy">
          <el-input
              v-validate="rules.postcode"
              v-mask="'##-###'"
              v-model="form.postcode"
              :disabled="lockStatus.postcode"
              data-cy="postcode"
              name="postcode"
              type="text"/>
        </custom-form-item>
        <custom-form-item
            :required="checkRequired(rules.community)"
            :errors="formErrors"
            prop="community"
            label="Gmina">
          <el-input
              v-validate="rules.community"
              v-model="form.community"
              :disabled="lockStatus.community"
              data-cy="community"
              name="community"
              type="text"/>
        </custom-form-item>
        <custom-form-item
            :required="checkRequired(rules.county)"
            :errors="formErrors"
            prop="county"
            label="Powiat">
          <el-input
              v-validate="rules.county"
              v-model="form.county"
              :disabled="lockStatus.county"
              data-cy="county"
              name="county"
              type="text"/>
        </custom-form-item>
        <el-popover
            v-model="visible"
            placement="top"
            width="160">
          <p>Potwierdzając odblokujesz możliwość zmiany miasta i ulicy. By wykluczyć błędy przy wyszukiwaniu pola te zostaną zresetowane do wyszukiwarki.</p>
          <div style="text-align: right; margin: 0">
            <el-button
                size="mini"
                type="text"
                @click="visible = false">Powrót</el-button>
            <el-button
                type="primary"
                size="mini"
                @click="resetForm">Tak</el-button>
          </div>
          <el-button
              slot="reference"
              class="w-100 my-1"
              type="primary">
            <i class="fas fa-unlock"></i> Resetuj formularz Teryt
          </el-button>
        </el-popover>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import forms from '../../shared/mixins/forms'
  import AutocompleteCity from './TerytAddons/AutocompleteCity'
  import AutocompleteAddress from './TerytAddons/AutocompleteAddress'

  export default {
    name: 'TerytAddressForm',
    components: {AutocompleteCity, AutocompleteAddress},
    mixins: [forms],
    props: {
      form: {
        type: Object,
        default: () => {}
      },
      rules: {
        type: Object,
        default: () => {}
      },
      edit: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        visible: false,
        selectedCity: {},
        lockStatus: {
          city: false,
          address: true,
          property: true,
          apartment: true,
          postcode: true,
          county: true,
          province: true,
          community: true
        },
        cityList: [],
        countiesList: [],
        addressList: []
      }
    },
    computed: {
      provincesList () {
        return this.$config.clients.provinces
      }
    },
    methods: {
      getSelectedCity (val) {
        this.selectedCity = val
        this.form.city = val.city
        this.form.province = val.voivodeship
        this.form.community = val.community
        this.form.county = val.county
        this.setDisabledAddressForm()
      },
      setDisabledAddressForm () {
        this.lockStatus.city = true
        this.lockStatus.address = false
        this.lockStatus.property = false
        this.lockStatus.apartment = false
        this.lockStatus.postcode = false
        this.lockStatus.county = true
      },
      setDefaultAddressForm () {
        this.lockStatus.city = false
        this.lockStatus.address = true
        this.lockStatus.property = true
        this.lockStatus.apartment = true
        this.lockStatus.postcode = true
        this.lockStatus.county = true
        this.lockStatus.county = true
      },
      resetForm () {
        this.setDefaultAddressForm()
        this.visible = false
        this.edit = false
        // this.form.city = ''
        // this.form.address = ''
        // this.form.apartment_number = ''
        // this.form.property_number = ''
        // this.form.province = ''
        // this.form.county = ''
        // this.form.community = ''
        // this.form.postcode = ''
      }
    }

  }
</script>
<style scoped>
fieldset > .row > div:first-child {
  background: #97d700 !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
</style>