<template>
  <el-card class="mt-3">
    <el-form
      ref="productListForm"
      :model="form"
      label-position="top"
      @submit.native.prevent="handleForm('productListForm')"
    >
      <form-alerts
        :messages="invalidMsg"
        class="my-6"
      />
      <fieldset>
        <div class="row">
          <div class="col-lg-3 col-xl-2">
            <h6>Dane produktu</h6>
          </div>
          <div class="col-lg-9 col-xl-10">
            <custom-form-item
              :errors="formErrors"
              :required="checkRequired(rules.name)"
              prop="name"
              label="Nazwa produktu"
            >
              <el-input
                v-model="form.name"
                v-validate="rules.name"
                name="name"
                type="text"
              />
            </custom-form-item>
            <custom-form-item
              :errors="formErrors"
              :required="checkRequired(rules.icon)"
              prop="icon"
              label="Dobierz ikonę"
            >
              <i :class="icon"></i>
              <vfa-picker v-model="form.icon">
                <template #activator="{ on }">
                  <input
                    v-model="form.icon"
                    placeholder="Icon Class"
                    type="text"
                    @click="on"
                  >
                </template>
              </vfa-picker>
            </custom-form-item>
          </div>
        </div>
      </fieldset>

      <div class="d-flex justify-content-end">
        <el-button
          :loading="loading"
          type="info"
          @click="$router.go(-1)"
        >
          Powrót
        </el-button>
        <el-button
          :loading="loading"
          native-type="submit"
          type="primary"
        >
          {{ isEdit ? 'Zapisz' : 'Utwórz' }}
        </el-button>
        <el-button
          v-if="isEdit"
          :loading="loading"
          @click="saveAndOut"
        >
          Zapisz i zamknij
        </el-button>
      </div>
    </el-form>
  </el-card>
</template>

<script>
  import forms from '@/shared/mixins/forms'
  import { productListValid } from '@/admin/utils/validation'
  import { postRequest, putRequest } from '@/shared/utils/http'

  export default {
    name: 'ProductListForm',
    mixins: [forms],

    props: {
      form: {
        type: Object,
        required: true
      },
      isEdit: { // czy element jest edytowany?
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        outAfterSave: false,
        loading: false
      }
    },
    computed: {
      currentId: function () {
        return this.isEdit ? this.$route.params.id : 0
      },
      rules: function () {
        return productListValid(this.currentId)
      },
      icon: function () {
        if (!this.form.icon) {
          return ''
        }

        return 'big-icon fas fa-' + this.form.icon
      }
    },
    methods: {
      submitForm: function () {
        this.loading = true
        return new Promise((resolve, reject) => {
          let request
          if (this.isEdit) {
            const frm = Object.assign({}, this.form)
            request = putRequest('/api/products-list/' + this.$route.params.id, frm)
          } else {
            request = postRequest('/api/products-list', this.form)
          }
          request
            .then(response => {
              this.$emit('saved')
              if (this.outAfterSave || !this.isEdit) {
                this.$router.push({ name: 'admin.product-list.show', params: { id: response.data.id } })
              }
              this.outAfterSave = false
              this.loading = false

              resolve(response)
            })
            .catch(error => {
              reject(error)
              this.loading = false
              this.outAfterSave = false
            })
        })
      },
      saveAndOut () {
        this.outAfterSave = true
        this.submitForm()
      }
    }
  }
</script>

<style scoped>
.big-icon{
  font-size:45px
}
</style>
