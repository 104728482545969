<template>
  <div>
    <el-menu
      :collapse="collapse"
      :default-active="$route.path"
      unique-opened
      router
    >
      <div class="page-logo">
        <router-link
          to="/admin/dashboard"
        >
          <img
            src="/img/logo_icon.png"
            alt="logo-system"
            class="img-sidebar"
          >
        </router-link>
        <div class="button-collapse">
          <el-button
            v-model="collapse"
            size="mini"
            type="primary"
            @click="handleCollapse"
          >
            <i
              v-if="collapse"
              class="fas fa-angle-double-right"
            ></i>
            <i
              v-else
              class="fas fa-angle-double-left"
            ></i>
          </el-button>
        </div>
      </div>
      <el-menu-item
        v-if="$can('admin.dashboard.read')"
        index="/admin/dashboard"
      >
        <i class="fas fa-columns"></i>
        <span slot="title">
          <span>Dashboard</span>
        </span>
      </el-menu-item>

      <el-menu-item
        v-if="$can('admin.news.read')"
        index="/admin/news"
      >
        <i class="fas fa-mail-bulk"></i>
        <span slot="title">
          <span>Wiadomości</span>
        </span>
      </el-menu-item>

      <el-submenu
        index="nav-acquiring"
      >
        <template slot="title">
          <i class="fas fa-plus"></i>
          <span>Pozyskiwanie</span>
        </template>
        <el-menu-item-group>
          <span slot="title">Pozyskiwanie klientów</span>
          <el-menu-item
            v-if="$can('admin.surveys.read')"
            index="/admin/surveys"
          >
            <span>Preleady</span>
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.lead.read')"
            index="/admin/lead"
          >
            <span>Leady</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu
        index="nav-sales"
      >
        <template slot="title">
          <i class="fas fa-shopping-cart"></i>
          <span>Sprzedaż</span>
        </template>
        <el-menu-item-group>
          <span slot="title">Sprzedaż</span>
          <el-menu-item
            v-if="$can('clients.read')"
            index="/admin/clients"
          >
            Klienci
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.sales.read')"
            index="/admin/sales"
          >
            Lista umów
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.document-signing.read')"
            index="/admin/document-signing"
          >
            Podpisywanie
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu
        v-if="$can('admin.user.read')"
        index="nav-users"
      >
        <template slot="title">
          <i class="fas fa-child"></i>
          <span>Pracownicy</span>
        </template>
        <el-menu-item-group>
          <span slot="title">Pracownicy</span>
          <el-menu-item index="/admin/users">
            Lista
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.branches.read')"
            index="/admin/settings/branches"
          >
            Oddziały
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.user.create')"
            index="/admin/users/create"
          >
            Dodaj pracownika
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.acl.read')"
            index="/admin/roles"
          >
            Role
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.acl.read')"
            index="/admin/permissions"
          >
            Uprawnienia
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-menu-item
        v-if="$can('admin.document_templates.read')"
        index="/admin/document-templates/"
      >
        <i class="fas fa-file-download"></i>

        <span slot="title">
          <span>Szablony dok.</span>
        </span>
      </el-menu-item>

      <el-submenu
        v-if="$can('admin.calculations.admin')"
        index="nav-calculations"
      >
        <template slot="title">
          <i class="fas fa-wrench"></i>
          <span>Kalk. ustaw.</span>
        </template>
        <el-menu-item-group>
          <span slot="title">Ustawienia kalkulacji</span>
          <el-menu-item
            v-if="$can('admin.special-offers.read')"
            index="/admin/special-offers"
          >
            Specjalna oferta
          </el-menu-item>
          <el-menu-item
            index="/admin/heat-pumps"
          >
            Pompy
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.calculations.companies.read')"
            index="/admin/calculations/companies"
          >
            Firmy
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.calculations.kits.read')"
            index="/admin/calculations/kits"
          >
            Zestawy
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.calculations.inverters.read')"
            index="/admin/calculations/inverters"
          >
            Falowniki
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.calculations.panels.read')"
            index="/admin/calculations/panels"
          >
            Panele
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.calculations.constructions.read')"
            index="/admin/calculations/constructions"
          >
            Konstrukcje
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.calculations.addons.read')"
            index="/admin/calculations/addons"
          >
            Dodatki
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.calculations.provisions.read')"
            index="/admin/calculations/provisions"
          >
            Prowizje
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.calculations.margins.read')"
            index="/admin/calculations/margins"
          >
            Narzuty
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.product-list.read')"
            index="/admin/product-list"
          >
            Produkty
          </el-menu-item>
          <el-menu-item
            v-if="$can('admin.calculations.settings.read')"
            index="/admin/calculations/settings"
          >
            Ustawienia
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <!--      <el-menu-item-->
      <!--        v-if="$can('admin.document_templates.read')"-->
      <!--        index="/admin/tools"-->
      <!--      >-->
      <!--        <i class="fas fa-cog"></i>-->

      <!--        <span slot="title">-->
      <!--          <span>Narzędzia</span>-->
      <!--        </span>-->
      <!--      </el-menu-item>-->

      <!--      <el-menu-item-->
      <!--        v-if="$can('admin.files.read')"-->
      <!--        index="/admin/files"-->
      <!--      >-->
      <!--        <i class="fas fa-file-download"></i>-->
      <!--        <span slot="title">-->
      <!--          <span>Baza plików</span>-->
      <!--        </span>-->
      <!--      </el-menu-item>-->
    </el-menu>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Sidebar',

    props: {
      collapse: {
        type: Boolean,
        default: false
      }
    },

    data: () => {
      return {
        centerDialogVisible: false
      }
    },
    computed: {
      ...mapGetters({
        user: 'app/user'
      })

    },
    methods: {
      handleCollapse () {
        this.$emit('set-collapse', !this.collapse)
      }
    }
  }
</script>
<style scoped>
.button-collapse{
  position: absolute;
  right: -22px;
  z-index: 999;
}
</style>
