.light-icon {
  color: $color-light-icon;
}

.el-icon {
  &-arrow-up,
  &-arrow-down,
  &-arrow-right,
  &-arrow-left,
  &-circle-close,
  &-close,
  &-warning,
  &-error,
  &-info,
  &-success,
  &-d-arrow-left,
  &-d-arrow-right,
  &-loading
  {
    font-family: "Font Awesome 5 Free";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 900;
  }

  &-arrow-up:before {
      content: "\f106";
  }

  &-arrow-down:before {
      content: "\f107";
  }

  &-arrow-right:before {
      content: "\f105";
  }

  &-arrow-left:before {
      content: "\f104";
  }

  &-circle-close:before {
      content: "\f057";
  }

  &-close:before {
      content: "\f00d";
  }

  &-warning:before {
      content: "\f071";
  }

  &-error:before {
      content: "\f057";
  }

  &-info:before {
      content: "\f05a";
  }

  &-success:before {
      content: "\f058";
  }

  &-d-arrow-left:before {
    content: "\f100";
  }

  &-d-arrow-right:before {
    content: "\f101";
  }

  &-loading {
    animation: fa-spin 2s infinite linear;

    &:before {
      content: "\f110";
    }
  }
}
