.avatar-uploader {
  position: relative;

  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-align: center;
  }

  .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-upload {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 28px;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    overflow: hidden;
  }

  .avatar {
    width: 178px;
    display: block;
  }
}

table {
  .avatar {
    height: 35px;
    border-radius: 50%;
  }
}

.user-details {
  .avatar {
    max-width: 300px;
    border-radius: 50%;
    border: 5px solid map_get($colors, grey);
  }
}

.avatar-inline {
  .vue-avatar--wrapper {
    vertical-align: middle;
    margin-right: 10px;
  }
}