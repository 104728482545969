<template>
  <div>
    <el-card
      v-loading="loading"
      class="mt-3"
    >
      <el-row style="text-align: right;">
        <el-button
          v-if="$can('admin.calculations.panels.update')"
          class="button-mobile-app"
          type="primary"
          @click="editAction"
        >
          <i class="far fa-edit"></i> Edytuj
        </el-button>
        <el-button
          v-if="$can('admin.calculations.panels.delete')"
          class="button-mobile-app"
          type="danger"
          @click="deleteAction"
        >
          <i class="far fa-trash-alt"></i> Usuń
        </el-button>
        <el-button @click="$router.go(-1)">
          Powrót
        </el-button>
      </el-row>
    </el-card>
    <div class="row mt-3">
      <div class="col-lg-8 col-md-12 col-xl-8">
        <el-card v-loading="loading">
          <div>
            <h5 class="my-0 py-0">
              Dane panelu
            </h5>
            <hr>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-1 order-xl-1">
              <dl><dt>Nazwa panelu</dt><dd>{{ item.name }}</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-3 order-xl-3">
              <dl><dt>Aktywny</dt><dd>{{ item.active ? 'Tak' : 'Nie' }}</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-2 order-xl-3">
              <dl><dt>Moc panelu</dt><dd>{{ item.kwp }} kWp</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-3 order-xl-3">
              <dl><dt>Z Zestawu</dt><dd>{{ item.prices_table }}</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-3 order-xl-3">
              <dl><dt>Gwarancja produkcyjna</dt><dd>{{ item.warranty_produce }}</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-3 order-xl-3">
              <dl><dt>Gwarancja wydajności</dt><dd>{{ item.warranty_performance }}</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-3 order-xl-3">
              <dl><dt>Nazwa pliku karty PDF</dt><dd>{{ item.card }}</dd></dl>
            </div>
            <div class="col-lg-4 col-md-12 col-xl-4 order-lg-3 order-xl-3">
              <dl><dt>Dodatkowe informacje</dt><dd>{{ item.info }}</dd></dl>
            </div>
          </div>
        </el-card>
        <el-card
          v-if="$can('admin.calculations.panels.update')"
          v-loading="loading"
          class="mt-3"
        >
          <el-transfer
            v-model="companies"
            :data="dataForm"
            :format="{
              noChecked: '${total}',
              hasChecked: '${checked}/${total}'
            }"
            :button-texts="['Odepnij', 'Przypnij']"
            :titles="['Do dodania', 'Dodano']"
            filterable
            @change="submitCompanies"
          />
        </el-card>
      </div>
      <div class="col-lg-4 col-md-12 col-xl-4">
        <el-card
          v-if="item.attached_companies"
          v-loading="loading"
        >
          <div>
            <h5 class="my-0 py-0">
              Przypisane firmy
            </h5>
            <hr>
          </div>
          <div v-if="item.attached_companies.length > 0">
            <div
              v-for="element in item.attached_companies"
              class="d-flex justify-content-between align-items-center"
            >
              <div>
                <img
                  :src="element.logo_company"
                  width="100px"
                >
              </div>
              <div>{{ element.name }}</div>
            </div>
          </div>
          <div v-else>
            <p>Brak powiązań dla tego falownika</p>
          </div>
        </el-card>
        <el-card
          v-loading="loading"
          class="mt-3"
        >
          <div>
            <h5 class="my-0">
              Załącz kartę PDF <el-button
                v-if="item.card"
                type="text"
                @click="previewCard"
              >
                Podgląd karty
              </el-button>
            </h5>
            <hr>
          </div>
          <el-upload
            :file-list="fileList"
            :on-success="handleSuccess"
            :before-upload="beforeFileUpload"
            :on-error="handleFileError"
            :action="'/api/panels/'+ id +'/upload_pdf'"
            class="upload-demo"
            drag
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              Wrzuć plik tutaj lub <em>naciśnij by załadować</em>
            </div>
            <div
              slot="tip"
              class="el-upload__tip"
            >
              Pliki PDF nie większe niż 15MB
            </div>
          </el-upload>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { deleteRequest, getRequest, patchRequest, postRequest } from '@/shared/utils/http'
  import forIn from 'lodash-es/forIn'

  export default {
    name: 'Show',
    data () {
      return {
        item: '',
        companies: [],
        dataForm: [],
        fileList: [],
        loading: true
      }
    },
    computed: {
      id () {
        return parseInt(this.$route.params.id)
      }
    },
    mounted () {
      this.$root.title = 'Panel'

      getRequest('/api/companies-list')
        .then((response) => {
          this.responseServer = response.data
          this.dataForm = this.responseServer.map(item => {
            return {
              key: item.id,
              label: item.name
            }
          })
        })

      getRequest('/api/panels/' + this.id)
        .then((response) => {
          this.item = response.data
          this.companies = response.data.attached_companiesList
          this.loading = false
        }).catch((response) => {
          this.$router.go(-1)
        })
    },
    methods: {
      editAction () {
        this.$router.push({ name: 'admin.calculations.panels.update', params: { id: this.id } })
      },
      deleteAction: function () {
        this.$confirm('Czy chcesz usunąć ten panel?', 'Uwaga', {
          confirmButtonText: this.label,
          cancelButtonText: this.cancelLabel,
          type: 'warning'
        })
          .then(() => {
            this.$emit('requestStart')
            deleteRequest('/api/panels/' + this.id)
              .then(() => {
                this.$emit('success')
                this.$router.push({ path: '/admin/calculations/panels' })
              })
              .catch(() => {
                this.$emit('fail')
              })
          })
          .catch(() => {
          })
      },
      submitCompanies (val) {
        this.loading = true
        patchRequest(`/api/panels/${this.id}`, { companies: val })
          .then(response => {
            this.$message({
              message: 'Firmy zostały zaktualizowane.',
              type: 'success'
            })
            this.companies = response.data.attached_companiesList
            this.item = response.data
            this.loading = false
          })
      },
      beforeFileUpload (file) {
        this.$message.info('Ładowanie karty do systemu')

        const isValidSize = file.size / 1024 / 1024 < 15 // 3MB

        if (!isValidSize) {
          this.$message.error('Plik ma rozmiar większy niż 15MB! Dodaj mniejszy plik.')
        }

        const isOK = isValidSize

        if (isOK) {
          this.loading = true
        }

        return isOK
      },
      handleSuccess (response) {
        this.item = response.data
        this.loading = false
        this.$notifySuccess(response.message)
      },
      handleFileError (res) {
        this.$notifyError('Wystąpił problem podczas ładowania plików. Zgłoś sprawę do administratora.')
        this.loading = false
      },
      previewCard () {
        const href = '/storage/documents/' + this.item.card
        window.open(href, '_blank')
      },
      changeStatus (companyId, status) {
        return new Promise((resolve, reject) => {
          postRequest('/api/panels/' + this.id + '/status-assign', {
            status: status,
            company_id: companyId
          })
            .then(response => {
              this.$emit('saved')
              this.item = response.data
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      }
    }
  }
</script>

<style scoped>
.list-group-item {
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
  margin-left: -35px;
}
.rounded-pill{
  border-radius: 11px;
  padding:2px 5px;
  color:white;
}
</style>
